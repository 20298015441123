import React, { lazy, Suspense } from 'react';

const LazyPageContentDetail = lazy(() => import('./PageContentDetail'));

const PageContentDetail = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageContentDetail {...props} />
  </Suspense>
);

export default PageContentDetail;

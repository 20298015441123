import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listPostsCustom = /* GraphQL */ `query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
    $userId: ModelIDKeyConditionInput
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        title
        description
        likeCount
        commentCount
        shareCount
        bookmarkCount
        bookmarks (userId: $userId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $userId) {
          items {
            id
            likeState
          }
        }
        status
        createdAt
        updatedAt
        owner
        mediaList (sortDirection: ASC) {
          items {
            id
            key
            file_type
          }
        }
        user {
          id
          name
          picture
        }
      }
      nextToken
      __typename
    }
  }
`;

export const getPostBeforeAuthCustom = /* GraphQL */ `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    userId
    title
    description
    likeCount
    commentCount
    shareCount
    bookmarkCount
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    mediaList (sortDirection: ASC) {
      items {
        id
        key
        file_type
      }
    }
    user {
      id
      name
      email
      picture
    }
    viewCount
    createdAt
    updatedAt
    owner
    __typename
  }
}
`;

export const getPostAfterAuthCustom = /* GraphQL */ `query GetPost($id: ID!, $userId: ModelIDKeyConditionInput) {
  getPost(id: $id) {
    id
    userId
    title
    description
    type
    likeCount
    bookmarks (userId: $userId) {
      items {
        id
        bookmarkState
      }
    }
    likes (userId: $userId) {
      items {
        id
        likeState
      }
    }
    commentCount
    shareCount
    bookmarkCount
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    mediaList (sortDirection: ASC) {
      items {
        id
        key
        file_type
      }
    }
    user {
      id
      name
      email
      picture
    }
    viewCount
    createdAt
    updatedAt
    owner
    __typename
  }
}
`;

export const postsByTypeAndCreatedAtAfterAuthCustom = /* GraphQL */ `query PostsByTypeAndCreatedAt(
  $type: PostType!
  $createdAt: ModelStringKeyConditionInput
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postsByTypeAndCreatedAt(
    type: $type
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      mediaList (sortDirection: ASC) {
        items {
          id
          key
          file_type
          postId
        }
      }
      bookmarks (userId: $userId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $userId) {
          items {
            id
            likeState
          }
        }
      user {
        id
        name
        picture
      }
    }
    nextToken
    __typename
  }
}
`;

export const postsByTypeAndCreatedAtBeforeAuthCustom = /* GraphQL */ `query PostsByTypeAndCreatedAt(
  $type: PostType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postsByTypeAndCreatedAt(
    type: $type
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      mediaList (sortDirection: ASC) {
        items {
          id
          key
          file_type
          postId
        }
      }
      user {
        id
        name
        picture
      }
    }
    nextToken
    __typename
  }
}
`;

export const postsByTypeAndStatusAfterAuthCustom = /* GraphQL */ `query PostsByTypeAndStatus(
  $type: PostType!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $userId: ModelIDKeyConditionInput
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postsByTypeAndStatus(
    type: $type
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) 
  {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      mediaList (sortDirection: ASC) {
        items {
          id
          key
          file_type
          postId
        }
      }
      bookmarks (userId: $userId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $userId) {
          items {
            id
            likeState
          }
        }
      user {
        id
        name
        picture
      }
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PostsByTypeAndStatusQueryVariables,
  APITypes.PostsByTypeAndStatusQuery
>;
export const postsByTypeAndStatusBeforeAuthCustom = /* GraphQL */ `query PostsByTypeAndStatus(
  $type: PostType!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postsByTypeAndStatus(
    type: $type
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) 
  {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      mediaList (sortDirection: ASC) {
        items {
          id
          key
          file_type
          postId
        }
      }
      user {
        id
        name
        picture
      }
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PostsByTypeAndStatusQueryVariables,
  APITypes.PostsByTypeAndStatusQuery
>;

export const postsByUserIdCustom = /* GraphQL */ `query PostsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      mediaList (sortDirection: ASC) {
        items {
          id
          key
          file_type
          postId
        }
      }
      user {
        id
        name
        picture
      }
    }
    nextToken
    __typename
  }
}
`;

export const postsByUserIdAndStatusCustom = /* GraphQL */ `query PostsByUserIdAndStatus(
  $userId: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postsByUserIdAndStatus(
    userId: $userId
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      mediaList (sortDirection: ASC) {
        items {
          id
          key
          file_type
          postId
        }
      }
      user {
        id
        name
        picture
      }
    }
    nextToken
    __typename
  }
}
`;

export const PostListByTitleAndStatusCustom = /* GraphQL */ `query PostListByTitleAndStatus(
  $userId: ModelIDKeyConditionInput
  $status: Status!
  $title: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  PostListByTitleAndStatus(
    status: $status
    title: $title
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      mediaList (sortDirection: ASC) {
        items {
          id
          key
          file_type
          postId
        }
      }
      bookmarks (userId: $userId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $userId) {
          items {
            id
            likeState
          }
        }
      user {
        id
        name
        picture
      }
    }
    nextToken
    __typename
  }
}
` ;

export const commentsByFeedIdAndCreatedAtCustom = /* GraphQL */ `query CommentsByFeedIdAndCreatedAt(
  $feedId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByFeedIdAndCreatedAt(
    feedId: $feedId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      comment
      productId
      feedId
      postId
      parentCommentId
      userId
      user {
        id
        name
        picture
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const commentsByPostIdAndCreatedAtCustom = /* GraphQL */ `query CommentsByPostIdAndCreatedAt(
  $postId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByPostIdAndCreatedAt(
    postId: $postId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      comment
      productId
      feedId
      postId
      parentCommentId
      userId
      user {
        id
        name
        picture
      }
      createdAt
      updatedAt
    }
    nextToken
    __typename
  }
}
`;

export const commentsByProductIdAndCreatedAtCustom = /* GraphQL */ `query CommentsByProductIdAndCreatedAt(
  $productId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByProductIdAndCreatedAt(
    productId: $productId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      comment
      productId
      feedId
      postId
      parentCommentId
      userId
      user {
        id
        name
        picture
      }
      createdAt
      updatedAt
    }
    nextToken
    __typename
  }
}
`;
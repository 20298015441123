/* eslint-disable */
import { IonApp, IonRouterOutlet, createAnimation, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
/**globel css */
import './globel.scss';

/**Components */
import OtpVerify from './RevampComponent/ComponentOtpVerify/ComponentOtpVerify';
import ForgotPassword from './components/PageForgotPassword/PageForgotPassword';
import PageResetPassword from './components/PageResetPassword/PageResetPassword';
import PageHome from './RevampComponent/PageHome/PageHome.lazy';
import PageFeed from './components/PageFeed/PageFeed';
import PageChangeMobileNo from './RevampComponent/PageChangeMobileNo/PageChangeMobileNo';
import ComponentMap from './components/ComponentMap/ComponentMap';
import PageProfile from './RevampComponent/PageProfile/PageProfile.lazy';
import PagePrivacyPolicy from './RevampComponent/PagePrivacyPolicy/PagePrivacyPolicy.lazy';
import PageTnc from './RevampComponent/PageTnc/PageTnc.lazy';
import PageEstoreSubCategory from './components/PageEstoreSubCategory/PageEstoreSubCategory.lazy';
import PageCommentList from './RevampComponent/PageCommentList/PageCommentList.lazy';
import PageDeliveryInpormation from './components/PageDeliveryInpormation/PageDeliveryInpormation.lazy';
import PageProfileEdit from './RevampComponent/PageProfileEdit/PageProfileEdit.lazy';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Suspense, useEffect } from 'react';
import AuthGuard from './authGuard/AuthGuard';
import { App as CapAPP, URLOpenListenerEvent } from '@capacitor/app';
import PageConfirmation from './components/PageConfirmation/PageConfirmation.lazy';
import { ErrorBoundary } from "react-error-boundary";
import PageReviewAdd from './components/PageReviewAdd/PageReviewAdd.lazy';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/keyboard';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import PageNotification from './RevampComponent/PageNotification/PageNotification.lazy';
import PageCampaignList from './components/PageCampaignList/PageCampaignList.lazy';
import PageCampaignAdd from './components/PageCampaignAdd/PageCampaignAdd.lazy';
import PagePostList from './components/PagePostList/PagePostList';
import PagePostDetail from './components/PagePostDetail/PagePostDetail';
import PageFollowersList from './RevampComponent/PageFollowersList/PageFollowersList.lazy';
import PageHistory from './RevampComponent/PageHistory/PageHistory.lazy';
import PageExplore from './RevampComponent/PageExplore/PageExplore.lazy';
import PageProductDetail from './RevampComponent/PageProductDetail/PageProductDetail.lazy';
import PageContentDetail from './RevampComponent/PageContentDetail/PageContentDetail.lazy';
import PageLogin from './RevampComponent/PageLogin/PageLogin.lazy';
import PageRegister from './RevampComponent/PageRegister/PageRegister.lazy';
import PageStore from './RevampComponent/PageStore/PageStore.lazy';
import PageSearch from './RevampComponent/PageSearch/PageSearch.lazy';
import PageStoreList from './RevampComponent/PageStoreList/PageStoreList.lazy';
import PageEstoreProductDetail from './RevampComponent/PageEstoreProductDetail/PageEstoreProductDetail.lazy';
import PageCart from './RevampComponent/PageCart/PageCart.lazy';
import PageCheckout from './RevampComponent/PageCheckout/PageCheckout.lazy';
import PageEstoreMyOrder from './RevampComponent/PageEstoreMyOrder/PageEstoreMyOrder.lazy';
import PageOrderDetail from './RevampComponent/PageOrderDetail/PageOrderDetail.lazy';
import PageReviewList from './RevampComponent/PageReviewList/PageReviewList.lazy';
import PagePostAdd from './RevampComponent/PagePostAdd/PagePostAdd.lazy';
import PageRequestDonationAdd from './RevampComponent/PageRequestDonationAdd/PageRequestDonationAdd.lazy';
import PagePropertyListing from './RevampComponent/PagePropertyListing/PagePropertyListing.lazy';
import PagePropertyDetail from './RevampComponent/PagePropertyDetail/PagePropertyDetail.lazy';
import PageActivityList from './RevampComponent/PageActivityList/PageActivityList.lazy';
import PageChat from './RevampComponent/PageChat/PageChat.lazy';
import PageChatDetail from './RevampComponent/PageChatDetail/PageChatDetail.lazy';
import PageWishlist from './RevampComponent/PageWishlist/PageWishlist.lazy';
import { ContentDetailPage, PostDetailPage } from './RevampComponent/PageExploreDetailHoc/PageExploreDetailHoc';
import AppContextProvider from './store/AppContext';
import UserContextProvider from './store/UserContext';
import Home from './pages/Home';
import PageSupport from './RevampComponent/PageSupport/PageSupport.lazy';
import PageLanding from './RevampComponent/PageLanding/PageLanding.Lazy';
import PageOrderSuccess from './RevampComponent/PageOrderSuccess/PageOrderSuccess.lazy';

setupIonicReact({
  mode:'ios'
});

const NewApps: React.FC = () => {

  useEffect(()=> {
    if (isPlatform('capacitor')) {
      StatusBar.show()
      console.log(window.location.href,  'DeepLink0>>>>>>');
      if (isPlatform('ios')) {
        StatusBar.setStyle({ style: Style.Light })
      }


      CapAPP.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        if (event && event.url.includes('code=')) {
          const codeRegex = /code=([^&]+)/;
          const stateRegex = /state=([^&]+)/;
          const params = `${event.url.match(codeRegex)?.[0]}&${event.url.match(stateRegex)?.[0]}`;
          const url = `${document.location.origin}/?${params}`;
          document.location.href = url;
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }
  }, []);

  /**
   * Fallback
   * @param param0
   * @returns 
   */
  function Fallback({ error, resetErrorBoundary }:any) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
  
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  }
  const logError = (error: Error, info: { componentStack?: string | null, digest?: string | null }) => {
    // Do something with the error, e.g. log to an external API
    console.log('Error in logError', error, info)
  };

  const animationBuilder = (baseEl:any, opts:any) => {
    const enteringAnimation = createAnimation()
      .addElement(opts.enteringEl)
      .fromTo('opacity', 0, 1)
      .duration(250);
    
    const leavingAnimation = createAnimation()
      .addElement(opts.leavingEl)
      .fromTo('opacity', 1, 0)
      .duration(250);
    
    const animation = createAnimation()
      .addAnimation(enteringAnimation)
      .addAnimation(leavingAnimation);
    
    return animation;
  };
  return (
    <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
      <IonApp className="newApp">
        <IonReactRouter forceRefresh={false}>
          <Suspense>
            <UserContextProvider>
              <AppContextProvider>
                <IonRouterOutlet animated={false} animation={animationBuilder}>
                  <Route exact path="/">
                    <Redirect to="/store" />
                  </Route>
                  {/* <Route path="/" component={PageHome} exact={true}></Route> */}
                  <Route path="/explore/:id" component={ContentDetailPage} exact={true} />
                  <Route path="/content/:id" component={PostDetailPage} exact={true} />
                  <Route path="/explore" component={PageExplore} exact={true}></Route>
                  <Route path="/login" component={PageLogin} exact={true}></Route>
                  <Route path="/support" component={PageSupport} exact={true}></Route>
                  <Route path="/landing" component={PageLanding} exact={true}></Route>

                  <Route path="/signup" component={PageRegister} exact={true}></Route>
                  <Route path="/forgotPassword" component={ForgotPassword} exact={true}></Route>
                  <Route path="/reset/:email" component={PageResetPassword} exact={true}></Route>
                  <Route path="/otp/verify/:type/:value" component={OtpVerify} exact={true}></Route>

                  <Route path="/home" component={PageHome} exact={true}></Route>
                  <Route path="/feed" component={PageFeed} exact={true}></Route>

                  <Route path="/feedDetail/:type/:productVariantId" component={PageProductDetail} exact={true}></Route>

                  <Route path="/postDetail/:type/:postId" component={PageContentDetail} exact={true}></Route>

                  <Route path="/changeMobileNo" component={PageChangeMobileNo} exact={true}></Route>
                  <Route path="/map" component={ComponentMap}></Route>

                  <Route path="/privacyPolicy" component={PagePrivacyPolicy} ></Route>
                  <Route path="/termsOfUse" component={PageTnc} ></Route>

                  <Route path="/store" component={PageStore} exact={true}></Route>
                  <Route path="/store/list" component={PageStoreList} exact={true}></Route>

                  <Route path="/search/:type" component={PageSearch} ></Route>

                  <Route path="/estoreCategory/:categoryId" component={PageEstoreSubCategory} ></Route>

                  <AuthGuard path="/orders" component={PageEstoreMyOrder}></AuthGuard>
                  <AuthGuard path="/order/:orderId/:orderProductId" exact component={PageOrderDetail}></AuthGuard>

                  <AuthGuard path="/addReview/:productVariantId/:estoreOrderProductId" component={PageReviewAdd} ></AuthGuard>
                  <AuthGuard path="/addReviewBooking/:productVariantId/:bookingId" component={PageReviewAdd} ></AuthGuard>

                  <AuthGuard path="/editReview/:reviewId" exact={true} component={PageReviewAdd} ></AuthGuard>
                  <AuthGuard path="/reviewList/:productId" component={PageReviewList} ></AuthGuard>

                  <Route path="/estoreProductDetail/:productVariantId" component={PageEstoreProductDetail} ></Route>
                  <AuthGuard path="/commentList/:productId" component={PageCommentList} ></AuthGuard>

                  <AuthGuard path="/chat" component={PageChat} exact={true} ></AuthGuard>
                  <AuthGuard path="/chatDetail" component={PageChatDetail} exact></AuthGuard>
                  <AuthGuard path="/chatDetail/:groupId" component={PageChatDetail} exact></AuthGuard>

                  <AuthGuard path="/profile" exact={true} component={PageProfile}></AuthGuard>
                  <AuthGuard path="/profileEdit/:type" component={PageProfileEdit} exact></AuthGuard>

                  <AuthGuard path="/profile/:userId" exact={true} component={PageProfile}></AuthGuard>
                  <AuthGuard path="/company/:companyId" exact={true} component={PageProfile}></AuthGuard>
                  <AuthGuard path="/followers/:userId" exact={true} component={PageFollowersList}></AuthGuard>
                  <AuthGuard path="/followers/company/:companyId" exact={true} component={PageFollowersList}></AuthGuard>
                  <AuthGuard path="/history" exact={true} component={PageHistory}></AuthGuard>

                  <AuthGuard path="/addProduct/:type" component={PageRequestDonationAdd} exact={true}></AuthGuard>
                  <AuthGuard path="/editProduct/:type/:productVariantId" component={PageRequestDonationAdd} exact={true}></AuthGuard>

                  <AuthGuard path="/cart" component={PageCart} ></AuthGuard>
                  <AuthGuard path="/delivery" component={PageDeliveryInpormation} ></AuthGuard>
                  <AuthGuard path="/cartCheckout" component={PageCheckout} ></AuthGuard>
                  <AuthGuard path="/activities" component={PageActivityList}></AuthGuard>
                  <AuthGuard path="/notification" exact={true} component={PageNotification}></AuthGuard>

                  <AuthGuard path="/order-success" component={PageOrderSuccess} ></AuthGuard>


                  <Route path="/verified" component={PageConfirmation}></Route>

                  <AuthGuard path="/createPost" component={PagePostAdd}></AuthGuard>
                  <AuthGuard path="/editPost/:postId" component={PagePostAdd}></AuthGuard>

                  <Route path="/properties" exact={true} component={PagePropertyListing}></Route>
                  <Route path="/property/:propertyId" component={PagePropertyDetail} exact={true}></Route>
                  <AuthGuard path="/wishlist/:type" exact={true} component={PageWishlist}></AuthGuard>
                  <AuthGuard path="/saved/:type" exact={true} component={PageWishlist}></AuthGuard>
                  

                  {/* <IonSplitPane contentId="main">
                    <Menu />
                    <IonRouterOutlet id="main">
                      <Route path="/" exact={true}>
                        <Redirect to="/page/Inbox" />
                      </Route>
                      <Route path="/page/:name" exact={true}>
                        <Page />
                      </Route>
                    </IonRouterOutlet>
                  </IonSplitPane> */}
                </IonRouterOutlet>
              </AppContextProvider>
            </UserContextProvider>
          </Suspense>
        </IonReactRouter>
      </IonApp>
    </ErrorBoundary>
  );
};

export default NewApps;

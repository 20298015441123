import React, { FC } from 'react';
import styles from './ComponentYoutubePlayer.module.scss';
import { IonContent, IonHeader, IonIcon, IonImg, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import ComponentHeader from '../ComponentHeader/ComponentHeader';

interface ComponentYoutubePlayerProps {
  title: string;
  videoId: string;
  onClose: ()=> void
}

const ComponentYoutubePlayer: FC<ComponentYoutubePlayerProps> = (props) => (
  <>
    <ComponentHeader title={props.title}></ComponentHeader>
    <IonContent className={styles.ComponentYoutubePlayer} data-testid="ComponentYoutubePlayer">
      <iframe frameBorder="0"
        allowFullScreen={false}
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        title={props.title}
        width="100%"
        height="100%"
        className='youtubeVideo'
        src={`https://www.youtube.com/embed/${props.videoId}?autoplay=1&amp;enablejsapi=1&amp;fullscreen=1&amp;origin=https%3A%2F%2Fc77o1v.csb.app&amp;widgetid=3`} id="widget4">
      </iframe>
    </IonContent>
  </>
);

export default ComponentYoutubePlayer;

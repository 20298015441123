/* eslint-disable */
import { AnimationBuilder, IonLoading, IonModal, NavContext, NavContextState, RouterDirection, useIonAlert, useIonToast } from '@ionic/react';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { CacheLS } from './CacheLs';
import { UserContext } from './UserContext';
import { Geolocation } from '@capacitor/geolocation';
import { ALERT_MESSAGE, ErrMessages, LOADING_MESSAGES, MODULE_TYPE_LIKE, SuccessMessage } from '../shared/errorMsg';
import config from '../../src/APIs/aws-exports';
import { API, Hub } from 'aws-amplify';
import { estoreCartsByUserIdAndCreatedAtCustom, createEstoreCart, deleteEstoreCart, updateEstoreCart, onCreateChatMessagesCustom, getChatCustom, chatParticipantsByUserIdAndIsActiveCustom } from '../APIs/graphql/customQueries';
import { useWatchState } from '../customHooks/useWatch';
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { GraphQLSubscription } from '@aws-amplify/api'
import { ChatParticipantsByUserIdAndIsActiveQueryVariables, OnCreateChatSubscription, OnUpdateChatParticipantsSubscription, OnUpdateChatParticipantsSubscriptionVariables, ProductTypeSearch, ProductVariant } from '../APIs/API';
import { addProductReviewF, bookmarkSaveUnSaveF, editProductReviewF, initiateChatF, likeUnlikeF, updateShareCountF } from '../APIs/graphql/mutations';
import { onCreateChatParticipants, onUpdateChatParticipants } from '../APIs/graphql/subscriptions';
import { Share } from '@capacitor/share';
import { deleteMultipleProductF } from '../APIs/graphql/queries';
import ComponentFilePreview from '../components/ComponentFilePreview/ComponentFilePreview';
import { Clipboard } from '@capacitor/clipboard';
import { useHistory } from 'react-router';
import ComponentAlertConfrim from '../RevampComponent/ComponentAlertConfrim/ComponentAlertConfrim';

declare let google:any;

interface IState {
  showLoading: { value: boolean, message?: string };
  setIsLoading: (value: boolean, message?: string)=> void,
  presentToastError: (message: string, color?: string, duration?: number)=> any;
  s3Url: string,
  s3BucketUrl: string,
  myLocation: any,
  onImageError: any
  estoreRecentSearchList: any
  addRecentSearch: any
  clearAllRecentSearch: any
  removeRecentSearchByIndex: (index:number)=>void
  cartProducts: any
  cartProductVariantIdsCheckOut: any
  setCartProductVariantIdsCheckOut: any
  addProductToCart: (productVariantId:string, quantity:Number, companyId:string, addOn?: any, productVariantDetail?: ProductVariant) => Promise<any>;
  geyMyCartListProducts: ()=> {},
  cartCompanyListObj: any,
  setChatRoom: any,
  chatRoom: any,
  // chatList: any,
  initiateChat: (participantId: string, productParams:any) => Promise<void>,
  getChatRoomByChatId:(chatId:string)=> Promise<any>,
  chatRoomObjList: any,
  setChatRoomObjList: any,
  sortChatListObj: (chatRoomObjList:any)=> any,
  socialShare: (productId:string, title: string, text: string, url: string, dialogTitle: string, moduleKeyName?: string)  => any,
  bookmarkToggle: (product:any, index: number, initialKey:string, dataListObj:any, setDataListObj:any, type?: string) => any,
  likeUnlikeFeed: (product:any, index: number, initialKey:string, dataListObj:any, setDataListObj:any, type?: string) => any,
  deleteProductVariant: (productVariantIds: string[], dataListObj?: any, index?: number[], initialKey?: string, setDataListObj?: any) => any,
  showConfirmBox: (header:string, message:string, canceBtn: string, okBtn: string) => Promise<any>,
  showFilePreview: (productMediaList:any, index: number, path: string)=> any,
  addReviewRatingForProduct: (productVariantId: string, estoreOrderProductId: any, ratingObj: any, bookingId: any, productReviewId: string, reviewMediaList: any, review: string) => Promise<any>;
  getAverageRating: (ratingObj: any) => string,
  copyToClipBoard: (text:string, message?: string) => any
  loginConfirmBox: ()=> any
  getMyChatList:(subscribe?: boolean) => any
  goToPage:(url:string, direction?: RouterDirection) => any,
  IonicNavigator: NavContextState
}

const initialState = {
  showLoading: { value: false, message: 'Please wait' },
  s3BucketUrl:`https://${config.aws_user_files_s3_bucket}.s3.${config.aws_user_files_s3_bucket_region}.amazonaws.com`,
  s3Url: `https://${config.aws_user_files_s3_bucket}.s3.${config.aws_user_files_s3_bucket_region}.amazonaws.com/public`,
  myLocation: null,
  estoreRecentSearchList: CacheLS.getRecentEstoreSearches(),
  cartProducts: CacheLS.getCartItems(),
  cartProductVariantIdsCheckOut: CacheLS.getcartProductVariantIdsCheckOutItems(),
  cartCompanyListObj: CacheLS.getCartCompanyListObj(),
  setChatRoom: null,
  chatRoom: CacheLS.getOpenChatRoom(),
  // chatList: CacheLS.getChatList(),
  chatRoomObjList: CacheLS.getChatList(),
  setChatRoomObjList: {},
  loginConfirmBox: ()=> {}
} as IState;

interface AlertModal {
  title: string
  description: string
  ok: string
  cancel: string
  onConfirm: ()=> any
}

export const AppContext = createContext(initialState);

const AppContextProvider = (props: any) => {
  const { isAuthenticated, user } = React.useContext(UserContext);
  const toastositionAnchor = useRef<any>();
  const [ showLoading, setShowLoading ] = React.useState(initialState.showLoading);

  const [ cartCompanyListObj, setCartCompanyListObj ] = useWatchState(initialState.cartCompanyListObj, (newState:any, prevState:any)=> {
    CacheLS.setCartCompanyListObj(newState);
  });

  const [ cartProducts, setCartProducts ] = useWatchState(initialState.cartProducts, (newState:any, prevState:any)=> {
    CacheLS.setCartItems(newState);
    groupedProductsIntoComapny(newState)
  });

  const [ cartProductVariantIdsCheckOut, setCartProductVariantIdsCheckOut ] = useWatchState(initialState.cartProductVariantIdsCheckOut, (newState:any, prevState:any)=> {
    CacheLS.setCartProductVariantIdsCheckOutItems(newState)
  });

  const s3Url = initialState.s3Url;
  const s3BucketUrl = initialState.s3BucketUrl;

  const [ present ] = useIonToast();
  const [ myLocation, setMyLocation ] = useState({});
  const [ estoreRecentSearchList, setEstoreRecentSearchList ] = useState(initialState.estoreRecentSearchList);
  const IonicNavigator = useContext(NavContext);

  const [ chatRoom, setChatRoom ] = useWatchState(initialState.chatRoom, (newState:any, prevState:any)=> {
    CacheLS.setOpenChatRoom(newState)
  });

  const [ chatRoomObjList, setChatRoomObjList ] = useWatchState(initialState.chatRoomObjList, (newState:any, prevState:any)=> {
    // console.log(newState)
    if (newState) {
      CacheLS.setChatList(newState)
    }
  });
  const [ presentAlert, dismissAlert ] = useIonAlert();

  const showFilePreviewModal = useRef<HTMLIonModalElement>(null);
  const showAlertModal = useRef<HTMLIonModalElement>(null);
  const [ productMediaListForModal, setProductMediaListForModal ] = useState( { items: [], index: 0 } as any);
  // const [ chatList, setChatList ] = useWatchState(initialState.chatList, (newState:any, prevState:any)=> {
  //   CacheLS.setChatList(newState)
  // });
  const [ alertModal, setAlertModal ] = useState({} as AlertModal);
  const history = useHistory();

  useEffect(()=> {
    getMyLocation();
  }, []);

  useEffect(()=> {
    if (user && user.username) {
      geyMyCartListProducts()
      getMyChatList()
    }
  }, [user?.username])

  /**
   * setIsLoading
   * @param value
   * @param message
   */
  function setIsLoading(value: boolean, message?: string) {
    setShowLoading({ value: value, message: message });
  }

  /**
   * prsentTost
   */
  function presentToastError(message: string, color = 'danger', duration = 2000) {
    return present({
      message: message,
      color: color,
      duration: duration,
      position: 'bottom',
      // cssClass: 'custom-toast',
      positionAnchor: toastositionAnchor.current,
    });
  }

  /**
   * getMyLocation
   */
  async function getMyLocation() {
    try {
      const coordinates = await Geolocation.getCurrentPosition();
      const params = {
        lat: coordinates.coords.latitude,
        lng: coordinates.coords.longitude,
        address: null
      };
      setMyLocation(params);

      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(params.lat, params.lng);
      const request = { latLng: latlng };

      geocoder.geocode(request, (results:any, status:any) => {
        if (status === google.maps.GeocoderStatus.OK) {
          let formattedAddress = results[0].formatted_address;
          if (results[results.length - 3]) {
            formattedAddress = results[results.length - 3].formatted_address
          }

          params.address = formattedAddress;
          setMyLocation(params);
        }
      });
    }
    catch (error) {
      presentToastError(ErrMessages.ErrLocationPermission);
    }
  }

  /**
   * onImageError
   */
  function onImageError(event:any, type = null) {
    let img = 'assets/img/noImg.png'
    if (type == 'profile') {
      img = 'assets/svg/avatar.svg'
    }
    event.target.src = img;
  }

  /**
   * addRecentSearch
   */
  function addRecentSearch(event:string) {
    const temp = [ ...estoreRecentSearchList ];
    if (temp.includes(event)) {
      return
    }

    temp.unshift(event);
    if (temp.length > 8) {
      temp.slice(0, 8);
    }
    CacheLS.setRecentEstoreSearches(temp);
    setEstoreRecentSearchList(temp);
  }

  /**
   * clearAllRecentSearch
   */
  function clearAllRecentSearch() {
    setEstoreRecentSearchList([]);
    CacheLS.removeRecentEstoreSearches();
  }

  /**
   * removeRecentSearchByIndex
   */
  function removeRecentSearchByIndex(index:number) {
    const temp = [ ...estoreRecentSearchList ];
    temp.splice(index, 1);
    setEstoreRecentSearchList(temp);
    CacheLS.setRecentEstoreSearches(temp);
    setEstoreRecentSearchList(temp);
  }

  /**
   * addProductToCart
   */
  async function addProductToCart (productVariantId: string, quantity: Number, companyId: string, add_ons:any = null, productVariant: ProductVariant = null as any) {
    setIsLoading(true)
    try {
      let params:any = {
        userId: user.username,
        productVariantId: productVariantId,
        quantity: quantity,
        companyId: companyId,
        status: "Active"
      }
      if (add_ons && add_ons.length) {
        params.add_ons = add_ons
      }

      let payload:any = {
        query: createEstoreCart,
        variables: {
          input: params
        },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      }

      let cartTemp = { ...cartProducts };
      if (cartTemp[productVariantId]) {
        payload.query = updateEstoreCart
        params.id = cartTemp[productVariantId].id

        if (params.quantity < 1) {
          // Delete entry from cart
          payload.query = deleteEstoreCart
          payload.variables.input = {
            id: cartTemp[productVariantId].id
          }
        }
      }

      const cartAddResp:any = await API.graphql(payload)
      
      if (params.id && params.quantity < 1) {
        // Delete product
        delete cartTemp[productVariantId] 
      }
      else {
        cartTemp[productVariantId] = cartAddResp.data.createEstoreCart || cartAddResp.data.updateEstoreCart
        if (productVariant) {
          cartTemp[productVariantId].productVariant = productVariant;
        }
      }

      setCartProducts(cartTemp);
      groupedProductsIntoComapny(cartTemp)

      if (params.id) {
        presentToastError(SuccessMessage.CartUpdate, "success");
      }
      else {
        presentToastError(SuccessMessage.CartAdd, "success");
      }

      return cartTemp;
    }
    catch (error:any) {
      presentToastError(ErrMessages.ErrGen)
      console.error("Exception in addProductToCart()", error)
    }
    finally {
      setIsLoading(false)
    }
  }

  /**
   * geyMyCartListProducts
  */
  async function geyMyCartListProducts() {
    try {
      CacheLS.removeCartItems()
      let tempCart:any = {} //{ }}
      let variables = {
        userId: user.username,
        filter: {
          status: {eq: 'Active'}
        } 
      }
      const listResp:any = await API.graphql({ query: estoreCartsByUserIdAndCreatedAtCustom, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: variables })
      const cartList =  listResp.data.estoreCartsByUserIdAndCreatedAt.items;
      cartList.map((cart:any, index: number) => {
        tempCart[cart.productVariantId] = cart
      })
      CacheLS.setCartItems(tempCart)
      setCartProducts(tempCart)
      // console.log(tempCart)
      groupedProductsIntoComapny(tempCart)
    }
    catch (error) {
      console.error("Exception in geyMyCartListProducts()", error)
    }
  }

  /**
   * setCompanyListObj
   * attach multiple product to its company
   */
  function groupedProductsIntoComapny(cartProductVaraintObj: any) {
    let temp = {} as any;
    const arrTemp = [];

    for (let productVariantId in cartProductVaraintObj) {
      if (temp[cartProductVaraintObj[productVariantId].companyId]) {
        temp[cartProductVaraintObj[productVariantId].companyId].productVariantIds.push(productVariantId);
        if (new Date(cartProductVaraintObj[productVariantId]).getTime() >  new Date(temp[cartProductVaraintObj[productVariantId].companyId]).getTime()) {
          temp[cartProductVaraintObj[productVariantId].companyId].updatedAt = cartProductVaraintObj[productVariantId].updatedAt
        }
      }
      else {
        temp[cartProductVaraintObj[productVariantId].companyId] = {
          company: cartProductVaraintObj[productVariantId].company,
          productVariantIds: [productVariantId],
          updatedAt: cartProductVaraintObj[productVariantId].updatedAt
        }
      }
    }

    setCartCompanyListObj(temp)
  }

  /**
   * initiateChat
   * @param participantId
   */
  async function initiateChat(participantId:any, productParams:any = null) {
    if (!user?.username) {
      await loginConfirmBox()
      return
    }

    console.log(participantId, productParams)
    setIsLoading(true)
    try {
      let params: GraphQLOptions = {
        query: initiateChatF,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: {
          input: {
            userId: user.username,
            participantId: participantId,
            productParams: productParams
          }
        }
      }



      const apiResp:any = await API.graphql(params);
      const resp = apiResp.data.initiateChatF
      if (resp.resp_status) {
        const roomTemp = JSON.parse(resp.resp_data)
        if (resp.resp_code == 'ALREADY_EXISTS') {
          console.log('ALREADY EXISTS', roomTemp, chatRoomObjList[roomTemp.chatId])

          const chatRoom = chatRoomObjList[roomTemp.chatId];
          if (chatRoom && chatRoom.id) {
            setChatRoom(chatRoom);
            return chatRoom
          }
          else {
            // chat room detail in local
            const chatRoom:any = await getChatRoomByChatId(roomTemp.chatId);
            const tempChatRoomObjList = { ...chatRoomObjList };
            tempChatRoomObjList[chatRoom.id] = chatRoom;

            setChatRoomObjList(tempChatRoomObjList);
            CacheLS.setChatList(tempChatRoomObjList);

            subscribeToMessages(roomTemp.chatId);
            setChatRoom(chatRoom);
            return chatRoom
          }
        }
        else {
          // new room created
          console.log(roomTemp)
          const chatRoom:any = await getChatRoomByChatId(roomTemp.chatId);
          console.log(chatRoom)
          const tempChatRoomObjList = { ...chatRoomObjList };
          tempChatRoomObjList[chatRoom.id] = chatRoom;

          setChatRoomObjList(tempChatRoomObjList);
          CacheLS.setChatList(tempChatRoomObjList);

          subscribeToMessages(roomTemp.chatId);
          setChatRoom(chatRoom);
          return chatRoom
        }
      }
      else {
        throw Error(resp.resp_message)
      }
    }
    catch (error:any) {
      presentToastError(error.message || ErrMessages.ErrGen)
      console.error("Exception in initiateChat()", error);
      // throw Error(error);
    }
    finally {
      setIsLoading(false)
    }
  }

  /**
   * getChatRoomByChatId
   */
  async function getChatRoomByChatId(chatId:string, subChatRoomObjList:any = null) {
    try {
      let params:GraphQLOptions = {
        query: getChatCustom,
        variables: {
          id: chatId
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }
      const chatRoomResp:any = await API.graphql(params);
      const chatRoom = chatRoomResp.data.getChat
      let chatObj = chatRoomWrapper(chatRoom);

      let tempChatRoomObjList = { ...chatRoomObjList };
      if (subChatRoomObjList) {
        tempChatRoomObjList = { ...subChatRoomObjList };
      }

      tempChatRoomObjList[chatObj.id] = chatObj;
      sortChatListObj(tempChatRoomObjList)

      return chatObj
    }
    catch (error) {
      console.error('Exception in getChatRoomByChatId()', error);
    }
  }

  /**
   * getMyChatList
   */
  async function getMyChatList(subscribe:boolean = true) {
    try {
      console.log(subscribe)
      let chatListTemp = [];
      const listResp:any = await API.graphql({ query: chatParticipantsByUserIdAndIsActiveCustom, variables: { userId: user.username, chatMessagesLimit: 1, isActive: { eq: 1 } } as ChatParticipantsByUserIdAndIsActiveQueryVariables, authMode: 'AMAZON_COGNITO_USER_POOLS' });
      const dataList = listResp.data.chatParticipantsByUserIdAndIsActive.items;
      console.log(dataList, ">>>>>>", user.username)
      let tempChatRoomObjList = { ...chatRoomObjList };

      for (let chatParticipant of dataList) {
        const chatRoom = chatParticipant.chat
        let chatObj:any = chatRoomWrapper(chatRoom);

        tempChatRoomObjList[chatObj.id] = chatObj
        if (chatListTemp.length && new Date(chatObj.lastUpdatesAt).getTime() > new Date(chatListTemp[0].lastUpdatesAt).getTime()) {
          chatListTemp.unshift(chatObj)
        }
        else {
          chatListTemp.push(chatObj);
        }
      }


      sortChatListObj(tempChatRoomObjList)
      CacheLS.setChatList(tempChatRoomObjList);

      if (!subscribe) {
        return
      }

      for (let chatId in tempChatRoomObjList) {
        subscribeToMessages(chatId);
        subscribeToOtherParticipantsUpdate(chatId)
      }
      subscribeToMineParticipantsUpdate();
      subscribeToNewChatRoom();
      // setChatList(chatListTemp);
    }
    catch (error) {
      console.error('Exception in getMyChatList', error);
    }
  }

  /**
   * sortChatListObj
   */
  function sortChatListObj (tempChatRoomObjList: any) {
    const sortable = Object.fromEntries(
      Object.entries(tempChatRoomObjList).sort(([,a]:any,[,b]:any) => new Date(b.lastUpdatesAt).getTime()-new Date(a.lastUpdatesAt).getTime())
    );
    CacheLS.setChatList(sortable);
    setChatRoomObjList(sortable);
  }

  /**
   * chatRoomWrapper
   */
  function chatRoomWrapper (chatRoom:any) {
    let chatObj = {
      id: chatRoom.id,
      group: chatRoom.group,
      name: chatRoom.name,
      lastMsg: null as any,
      image: null,
      lastUpdatesAt: chatRoom.createdAt,
      unreadMsg: null,
      unreadCount: 0,
      allParticipants: chatRoom.participants,
      otherParticipant: null,
      deletedBy: chatRoom.deletedBy ? JSON.parse(chatRoom.deletedBy) : null,
      notActive: null
    }

    if (!chatRoom.group && chatRoom.participants?.items?.length) {
      let otherParticipant = chatRoom.participants.items.find((participant :any)=> {
        if (participant.userId != user.username) {
          return participant
        }
      })

      chatObj.name = otherParticipant.user.name
      chatObj.image = otherParticipant.user.picture
      chatObj.otherParticipant = otherParticipant
      if (otherParticipant.isActive == 0) {
        // is wiil be used in chat detail page to activate chat participants
        chatObj.notActive = otherParticipant.id
      }
    }

    if (chatRoom.chatMessages.items.length) {
      chatObj.lastMsg = chatRoom.chatMessages.items[0]

      if (!chatObj.lastMsg.message && chatRoom.chatMessages.items[0].attachment) {
        chatObj.lastMsg.message = JSON.parse(chatRoom.chatMessages.items[0].attachment).title
      }

      chatObj.lastUpdatesAt = chatObj.lastMsg.createdAt
    }

    if (chatRoom.chatMessageUnreadCount && chatRoom.chatMessageUnreadCount.items.length) {
      chatObj.unreadCount = chatRoom.chatMessageUnreadCount.items[0].unreadCount
      chatObj.unreadMsg = chatRoom.chatMessageUnreadCount.items[0]
    }

    return chatObj
  }

  /**
   * subscribeToMessages
   */
  function subscribeToMessages (chatId: string) {
    const params:GraphQLOptions = {
      query: onCreateChatMessagesCustom,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      // authToken: user.signInUserSession.accessToken.jwtToken,
      variables: {
        filter: {
          chatId: { eq: chatId },
          userId: { ne: user.username }
        }
      }
    }

    const subscription = API.graphql<GraphQLSubscription<OnCreateChatSubscription>>(params);
    subscription.subscribe({
      next: (event: any) => {
        console.log(event.value)
        let message = event.value.data.onCreateChatMessages;
        const tempChatRoomObjListCache = CacheLS.getChatList()
        if (!tempChatRoomObjListCache[message.chatId]) {
          return
        }

        tempChatRoomObjListCache[message.chatId].unreadCount = tempChatRoomObjListCache[message.chatId].unreadCount + 1 || 1;
        tempChatRoomObjListCache[message.chatId].lastMsg = message
        tempChatRoomObjListCache[message.chatId].lastUpdatesAt = message.createdAt

        sortChatListObj(tempChatRoomObjListCache)
        Hub.dispatch('newMessage', event.value.data.onCreateChatMessages);
      },
      error: (error: any) => console.error(error, chatId)
    });
  }
  /**
   * subscribeToNewChatRoom 
   */
  async function subscribeToNewChatRoom() {
    try {
      const params:GraphQLOptions = {
        query: onCreateChatParticipants,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: {
          filter: {
            userId: { eq: user.username }
          }
        }
      }
  
      const subscription = API.graphql<GraphQLSubscription<OnCreateChatSubscription>>(params);
      subscription.subscribe({
        next: async (event: any) => {
          console.log(event, '>>>>>>')
          let participant = event.value.data.onCreateChatParticipants
          const tempChatRoomObjList = CacheLS.getChatList();

          if (!tempChatRoomObjList[participant.chatId]) {
            const chatRoom:any = await getChatRoomByChatId(participant.chatId);
            tempChatRoomObjList[chatRoom.id] = chatRoom;

            setChatRoomObjList(tempChatRoomObjList);
            CacheLS.setChatList(tempChatRoomObjList);

            subscribeToMessages(participant.chatId);
          }
        },
        error: (error:any) => {
          console.log(error);
        }
      })
    }
    catch (error) {
      presentToastError(ErrMessages.ErrGen);
      console.error('Exception in subscribeToMessages()', error);
    }
  }

  /**
   * subscribeToParticipantsUpdate
   */
  async function subscribeToMineParticipantsUpdate() {
    try {
      const params:GraphQLOptions = {
        query: onUpdateChatParticipants,
        variables: {
          filter: {
            userId: { eq: user.username },
            isActive: { eq: 1 }
          }
        } as OnUpdateChatParticipantsSubscriptionVariables,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      }

      const subscription = API.graphql<GraphQLSubscription<OnUpdateChatParticipantsSubscription>>(params);
      subscription.subscribe({
        next: async (event: any) => {
          console.log('event>>>>>>subscribeToMineParticipantsUpdate', event)
          if (event && event.value?.data?.onUpdateChatParticipants && event.value.data.onUpdateChatParticipants.isActive) {
            // this mean new chat message recieved
            const tempChatRoomObjListCache = CacheLS.getChatList()
            const chatRoom:any = await getChatRoomByChatId(event.value?.data?.onUpdateChatParticipants.chatId, tempChatRoomObjListCache);
            tempChatRoomObjListCache[chatRoom.id] = chatRoom;
            CacheLS.setChatList(tempChatRoomObjListCache)

            sortChatListObj(tempChatRoomObjListCache)
            subscribeToMessages(chatRoom.id);
          }
        },
        error: (error:any) => {
          console.log(error);
        }
      })
    }
    catch (error) {
      presentToastError(ErrMessages.ErrGen);
      console.error('Exception in subscribeToParticipantsUpdate()', error);
    }
  }

  /**
   * subscribeToOtherParticipantsUpdate
  */
  async function subscribeToOtherParticipantsUpdate(chatId: string) {
    try {
      const params:GraphQLOptions = {
        query: onUpdateChatParticipants,
        variables: {
          filter: {
            userId: { ne: user.username },
            chatId: { eq: chatId },
            isActive: { eq: 0 }
          }
        } as OnUpdateChatParticipantsSubscriptionVariables,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      }

      const subscription = API.graphql<GraphQLSubscription<OnUpdateChatParticipantsSubscription>>(params);
      subscription.subscribe({
        next: async (event: any) => {
          if (event && event.value?.data?.onUpdateChatParticipants) {
            // this mean new chat message recieved
            const tempChatRoomObjListCache = CacheLS.getChatList()
            if (tempChatRoomObjListCache[chatId]) {
              tempChatRoomObjListCache[chatId].notActive = event.value.data.onUpdateChatParticipants.id;
              setChatRoomObjList(tempChatRoomObjListCache)
            }
          }
        },
        error: (error:any) => {
          console.log(error);
        }
      })
    }
    catch (error) {
      presentToastError(ErrMessages.ErrGen);
      console.error('Exception in subscribeToParticipantsUpdate()', error);
    }
  }

  /**
   * socialShare
   */
  async function socialShare(productId: string, title: string, text: string, path: string, dialogTitle: string, moduleKeyName = 'productId') {
    setIsLoading(true)
    try {
      const resp = await Share.share({
        title: title,
        text: text,
        url: config.aws_web_url + path,
        dialogTitle: dialogTitle
      });

      const params:GraphQLOptions = {
        query: updateShareCountF,
        variables: {
          input: {
            [moduleKeyName]: productId
          }
        }
      }
      await API.graphql(params);
      return true
    }
    catch (error:any) {
      presentToastError(error.message);
      console.error('Exception in socialShare()', error)
      return false
    }
    finally {
      setIsLoading(false)
    }
  }
  /**
   * bookmarkToggle
   */
  async function bookmarkToggle(product:any, index: number, initialKey:string, dataListObj:any, setDataListObj:any, type = MODULE_TYPE_LIKE.PRODUCT) {
    try {
      if (!isAuthenticated) {
        await loginConfirmBox()
        return
      }

      setIsLoading(true)

      let bookmarkState = true
      if (product.bookmarks.items?.length && product.bookmarks.items[0].bookmarkState) {
        product.bookmarks.items = []
        product.bookmarkCount = product.bookmarkCount - 1
        bookmarkState = false
      }
      else {
        product.bookmarks.items = [ { id: user.username, bookmarkState: true } ]
        product.bookmarkCount = product.bookmarkCount + 1
        bookmarkState = true
      }

      let tempDataListObj = { ...dataListObj };
      tempDataListObj[initialKey].items[index] = product;
      setDataListObj(tempDataListObj);

      const params: GraphQLOptions = {
        query: bookmarkSaveUnSaveF,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: {}
      }

      const apiInput = {
        bookmarkState: bookmarkState,
        productId: product.id,
        feedId: null,
        postId: null,
        type: product.type == ProductTypeSearch.Store ? ProductTypeSearch.Store : 'Explore'
      }

      if (type == MODULE_TYPE_LIKE.FEED) {
        delete apiInput.productId
        apiInput.feedId = product.id
      }
      else if (type == MODULE_TYPE_LIKE.POST) {
        delete apiInput.productId
        apiInput.postId = product.id
      }
      // else {
      //   delete apiInput.productId
      //   apiInput.feedId = product.id
      // }
      params.variables = { input: apiInput }

      const resp = await API.graphql(params);
      console.log(resp)
    }
    catch (error) {
      presentToastError(ErrMessages.ErrGen);
      console.error('Exception in bookmarkToggle', error)
    }
    finally {
      setIsLoading(false)
    }
  }

  /**
   * likeUnlikeFeed
   */
  async function likeUnlikeFeed(product:any, index: number, initialKey:string, dataListObj:any, setDataListObj:any, type = 'product') {
    if (!isAuthenticated) {
      await loginConfirmBox()
      return
    }
    let likeState = true

    if (product.likes.items?.length && product.likes.items[0].likeState) {
      product.likes.items = []
      product.likeCount = product.likeCount - 1
      likeState = false
    }
    else {
      product.likes.items = [ { id: user.username, likeState: true } ]
      product.likeCount = product.likeCount + 1
      likeState = true
    }

    let tempDataListObj = { ...dataListObj };
    tempDataListObj[initialKey].items[index] = product;
    setDataListObj(tempDataListObj);

    const params: GraphQLOptions = {
      query: likeUnlikeF,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      // authToken: user.signInUserSession.accessToken.jwtToken,
      variables: {}
    }
    const likeInput =  {
      likeState: likeState,
      type: product.type,
      feedId: null,
      productId: product.id,
      postId: null,
      ownerId: product.userId
    }

    if (type == MODULE_TYPE_LIKE.FEED) {
      likeInput.feedId = product.id
      delete likeInput.productId
    }
    else if (type == MODULE_TYPE_LIKE.POST) {
      likeInput.postId = product.id
      delete likeInput.productId
    }

    params.variables = { input: likeInput }

    const resp = await API.graphql(params);
    console.log(resp)
  }

  /**
   * deleteProductVariant
   */
  async function deleteProductVariant(productVariantIds: string[], dataListObj?: any, indexsToRemove?: number[], initialKey?: string, setDataListObj?: any) {
    setIsLoading(true);
    try {
      const deleteResp:any = await API.graphql({ query: deleteMultipleProductF, variables: { productVariantIds }, authMode: 'AMAZON_COGNITO_USER_POOLS'  });
      if (deleteResp.data.deleteMultipleProductF.resp_status) {
        presentToastError(SuccessMessage.ProductDelete, 'success');

        if (dataListObj && initialKey && dataListObj[initialKey]) {
          if (indexsToRemove) {
            const tempDataListObj = { ... dataListObj };
            for (let index of indexsToRemove) {
              delete tempDataListObj[initialKey].items[index]
            }
            setDataListObj(tempDataListObj)
          }
        }

      }
      else {
        presentToastError(deleteResp.resp_message)
      }
    }
    catch (error: any) {
      console.error('Exception in deleteProductVariant', error)
      presentToastError(error.message)  
    }
    finally {
      setIsLoading(false)
    }
  }

  /**
   * showConfirmBox
   */
  function showConfirmBox(header:string, message:string, canceBtn: string, okBtn: string) {
    // const [ presentAlert, dismissAlert ] = useIonAlert();
    return new Promise((resolve, reject)=> {
      presentAlert({
        header: header,
        message: message,
        buttons: [
          {
            text: canceBtn,
            role: 'cancel',
            handler: dismissAlert
          },
          {
            text: okBtn,
            role: 'ok'
          }
        ],
        onDidDismiss: (event)=> {
          resolve(event.detail.role)
        }
      })
    })
  }

  /**
   * showFilePreview
   */
  function showFilePreview(productMediaList:any, index:number, path: string) {
    try {
      setProductMediaListForModal({ items: productMediaList, index: index, path: path })
      showFilePreviewModal.current?.present();
    }
    catch (error) {
      console.error('Exception in showFilePreview()', error);
    }
  }

  /**
   * addReviewRatingForProduct
   */
  async function addReviewRatingForProduct(productVariantId: string, estoreOrderProductId: any, ratingObj: any, bookingId: any, productReviewId: any, reviewMediaList: any, review: string) {
    setIsLoading(true);
    try {
      const params: GraphQLOptions = {
        query: addProductReviewF,
        variables: {
          input: {
            productVariantId: productVariantId,
            estoreOrderProductId: estoreOrderProductId,
            ratingObj: ratingObj,
            reviewMediaList: reviewMediaList,
            review: review,
            bookingId: bookingId
          }
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }
      if (productReviewId) {
        params.variables = {
          input: {
            productReviewId: productReviewId,
            review: review,
            ratingObj: ratingObj,
            reviewMediaList: reviewMediaList
          }
        }
        params.query = editProductReviewF
      }

      const apiResp: any = await API.graphql(params);
      const resp = apiResp.data.editProductReviewF || apiResp.data.addProductReviewF;

      if (resp.resp_status) {
        presentToastError(SuccessMessage.RatingAdd, 'success')
      }
      else {
        presentToastError(ErrMessages.ErrGen)
      }
      return resp
    }
    catch (error: any) {
      presentToastError(error.message || ErrMessages.ErrGen);
      console.error('Exception in addReviewRatingForProduct()', error);
      return false
    }
    finally {
      setIsLoading(false);
    }
  }

  /**
   * getAverageRating
   */
  function getAverageRating(ratingObj: any) {
    let totalRating = 0;
    let totalRatingAfterMultiply = 0;
    let ratingValue:any = { one: 1, two: 2, three: 3, four: 4, five: 5 };
    // let use weighted algorithm
    for (let key in ratingObj) {
      if (ratingObj[key] >= 0) {
        totalRating += ratingObj[key];
        totalRatingAfterMultiply += (ratingValue[key] * ratingObj[key]);
      }
    }
    if (totalRatingAfterMultiply / totalRating) {
      return (totalRatingAfterMultiply / totalRating).toFixed(1);
    }
    else {
      return ''
    }
  }

  /**
   * copyToClipBoard
   * @param text
   */
  async function copyToClipBoard(text: string, message?: string) {
    try {
      await Clipboard.write({ string: text });
      presentToastError(message || SuccessMessage.Copy, 'success');
    }
    catch (error) {
      presentToastError(ErrMessages.ErrGen)
    }
  }

  async function loginConfirmBox() {
    setAlertModal({
      title: ALERT_MESSAGE.LOGIN_CONFRIM.TITLE,
      description: ALERT_MESSAGE.LOGIN_CONFRIM.DESC,
      onConfirm: ()=> {
        console.log('ss')
        CacheLS.setRedirectUrl(window.location.href.replace(window.location.origin, ''));
        history.replace('/login')
      },
      ok: ALERT_MESSAGE.LOGIN_CONFRIM.OK,
      cancel: ALERT_MESSAGE.LOGIN_CONFRIM.CANCEL,
    })
    showAlertModal.current?.present()
    // const resp = await showConfirmBox(SuccessMessage.LoginNow, SuccessMessage.LoginMessage, SuccessMessage.LoginLater, SuccessMessage.LoginNow);
    // if (resp == 'ok') {
      
    //   history.replace('/login');
    // }
    // return resp;
  }

  function goToPage(url:string, direction: RouterDirection = 'forward') {
    IonicNavigator.navigate(url, direction);
  }
  return (
    <AppContext.Provider
      value={{
        showLoading,
        setIsLoading,
        bookmarkToggle,
        likeUnlikeFeed,
        onImageError,
        presentToastError,
        addRecentSearch,
        clearAllRecentSearch,
        removeRecentSearchByIndex,
        estoreRecentSearchList,
        s3BucketUrl,
        s3Url,
        myLocation,
        cartProducts,
        cartCompanyListObj,
        addProductToCart,
        geyMyCartListProducts,
        cartProductVariantIdsCheckOut,
        setCartProductVariantIdsCheckOut,
        setChatRoom,
        chatRoom,
        // chatList,
        chatRoomObjList,
        setChatRoomObjList,
        initiateChat,
        socialShare,
        deleteProductVariant,
        showConfirmBox,
        showFilePreview,
        addReviewRatingForProduct,
        getAverageRating,
        copyToClipBoard,
        loginConfirmBox,
        getChatRoomByChatId,
        sortChatListObj,
        getMyChatList,
        goToPage, 
        IonicNavigator,
      }}
    >

      <IonModal ref={showFilePreviewModal}>
        <ComponentFilePreview files={productMediaListForModal.items} activeIndex={productMediaListForModal.index} path={productMediaListForModal.path} closeModal={()=> showFilePreviewModal.current?.dismiss()}></ComponentFilePreview>
      </IonModal>
      <IonModal ref={showAlertModal} className="cmpBookingDetailBootomModal">
        <ComponentAlertConfrim
          title={alertModal.title}
          description={alertModal.description}
          ok={alertModal.ok}
          cancel={alertModal.cancel}
          onConfirm={()=> alertModal.onConfirm()}
          onClose={()=> showAlertModal.current?.dismiss()}
          openModal={true}
        ></ComponentAlertConfrim>
      </IonModal>
      <IonLoading isOpen={showLoading.value} message={ showLoading.message || LOADING_MESSAGES.PLS_WAIT }></IonLoading>
      <div ref={toastositionAnchor} style={{ position: 'absolute', top:"90%", width:"100%" }}></div>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
/* eslint-disable */
import { IonLoading, NavContext, useIonToast } from '@ionic/react';
import { API, Auth, Hub } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ErrMessages, FIREBASE, LOADING_MESSAGES, SuccessMessage } from '../shared/errorMsg';
import { CacheLS } from './CacheLs';
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { updateUserCustom } from '../APIs/graphql/customQueries';
import { useWatchState } from '../customHooks/useWatch';
import { isPlatform } from '@ionic/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { updateFcmTokenF } from '../APIs/graphql/mutations';
import { UpdateFcmTokenFMutationVariables, fcmTokenField } from '../APIs/API';
import { AppContext } from './AppContext';
import { Device } from '@capacitor/device';
import { useHistory } from 'react-router';
import { getUserCustom } from '../APIs/graphql/customUserQuery';

interface IState {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: any;
  errorMessage: string;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  changePhoneNumber: (phoneNumber:string)  => Promise<void>;
  changeUserInfo: (userInfo:any)  => Promise<void>;
  verifyUserAttribute: (attributeName:string)  => Promise<void>;
  resendSignUp: (value:any) => Promise<void>,
  setIsLoading: any;
  userInfoUpdate: any;
  companyList: any,
  completeNewPassword: (password:string)  => Promise<void>,
  verifyCurrentUserAttributeSubmit: (attributeName:string, otp: any)  => Promise<any>,
}

const initialState = {
  isLoading: false,
  isAuthenticated: true,
  user: CacheLS.getAuthUser(),
  errorMessage: '',
  login: async (username: string, password: string) => {
    return;
  },
  logout: () => {
    return;
  },
  changePhoneNumber: async (phoneNumber:any) => {
    return;
  },
  changeUserInfo: async (userInfo:any)  => {
    return
  },
  verifyUserAttribute: async (attributeName:string)  => {
    return;
  },
  resendSignUp: async (value:any) => {
    return;
  }
} as IState;


export const UserContext = createContext(initialState);

const UserContextProvider = (props: any) => {
  // const firebaseApp = initializeApp(FIREBASE.config);
  // const messaging = getMessaging(firebaseApp);
  const toastositionAnchor = useRef<any>();
  const history = useHistory();
  const { navigate } = useContext(NavContext);

  let messaging = null as any;
  const { setChatRoom, chatRoomObjList, myLocation} = useContext(AppContext);

  const [ isLoading, setIsLoading ] = useState(initialState.isLoading);
  const [ isAuthenticated, setIsAuthenticated ] = React.useState(initialState.isAuthenticated);
  const [ user, setUser ] = useWatchState(initialState.user, (newState:any, prevState:any)=> {
    if (!prevState && newState) {
      let redirectUrl = CacheLS.getRedirectUrl();
      if (redirectUrl) {
        // window.location.href = window.location.origin + redirectUrl;
        history.replace('/');
        history.push(redirectUrl);
        CacheLS.removeRedirectUrl()
      }
    }

    CacheLS.setAuthUser(newState);
  });

  const [ companyList, setCompanyList ] = React.useState([] as any)
  const [ present ] = useIonToast();

  const [ errorMessage, setErrorMessage ] = React.useState('');

  const [ fcmToken, setFcmToken ] = useState('');
  const [ deviceId, setDeviceId ] = useState('');

  useEffect(() => {
    if (isPlatform('capacitor')) {
      registerNotifications()
    }
    else {
      const firebaseApp = initializeApp(FIREBASE.config);
      messaging = getMessaging(firebaseApp);
      // generateWebNotifications()
    }
  }, [myLocation]);

  useEffect(()=> {
    init();
  }, [ ])

  useEffect(()=> {
    if (user && deviceId && fcmToken) {
      addTokenToUser(user, fcmToken, deviceId, false)
    }
  }, [ user, deviceId, fcmToken ])

  /**
   * registerNotifications
   */
  const registerNotifications = async () => {
    try {
      let permStatus = await PushNotifications.checkPermissions();
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }
    
      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await addListeners();

      const res =  await PushNotifications.register();
      console.log('permStatus,3', res)
    }
    catch (error) {
      console.error('Exception in registerNotifications', error);
    }
  }

  /**
   * addListners
   */
  const addListeners = async () => {
    try {
      const id = await Device.getId()
      setDeviceId(id.identifier);
      await PushNotifications.addListener('registration', token => {
        console.log('Registration token', token.value);
        setFcmToken(token.value);
      });
    
      await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });
    
      await PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push notification received: ', notification);
      });
    
      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification);
        if (notification && notification.notification.data) {
          const data = notification.notification.data
          if (data.module) {
            let route = data.route;
            switch (data.module) {
              case 'ORDER_PRODUCT_UPDATE':
                route = `/order/${data.orderId}/${data.id}`;
                break;
              case 'Booking':
                if (chatRoomObjList && chatRoomObjList[data.chatId]) {
                  setChatRoom(chatRoomObjList[data.chatId])
                  route = `/chatDetail`;
                }
                else {
                  if (data.bookingId) {
                    route = `/notification?bookingId=${data.bookingId}`;
                    return
                  }
                  else {
                    route = `/bookings`;
                  }
                }
                break;
              default:
                break;
            }
            if (route) {
              history.push(route)
            }
          }
        }
      });
    }
    catch (error) {
      console.error('Exception in addListeners()', error);
    }
  }

  /**
   * generateWebNotifications
   * @returns
   */
  async function generateWebNotifications() {
    try {
      const per = await Notification.requestPermission()
      if (per === 'granted') {
        let fcmToken = await getToken(messaging, { vapidKey: FIREBASE.FCM_KEY });
        console.log(fcmToken)
        const deviceInfo = await Device.getInfo();
        console.log('deviceInfo', deviceInfo)
        const { model, osVersion, platform, manufacturer } = deviceInfo;

        const deviceId = `${model}${osVersion}${platform}_${manufacturer.slice(0, 6)}`;
        setDeviceId(deviceId);

        CacheLS.setFcmToken(fcmToken);
        setFcmToken(fcmToken);
        console.log('Web Token', fcmToken);
      }
    }
    catch (error) {
      console.error('Exception in generateWebNotifications', error)
    }
  }

  /**
   * addTokenToUser
   */
  async function addTokenToUser(user: any, fcmToken: string, deviceId:string, removeFlag = false) {
    try {
      if (user && user.username && fcmToken.length > 2 && deviceId) {
        let params:GraphQLOptions = {
          query: updateFcmTokenF,
          variables: {
            input: {
              type: fcmTokenField.fcmToken,
              fcmToken: JSON.stringify({ [deviceId]: fcmToken }),
              removeFlag: removeFlag
            }
          } as UpdateFcmTokenFMutationVariables,
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
        let apiResp = await API.graphql(params);
        CacheLS.setFcmToken(fcmToken);
      }
    }
    catch (error) {
      console.error('Exception in addTokenToUser()', error)
    }
  }

  /**
   * login
   */
  async function login(username: string, password: string) {
    setIsLoading(true)
    try {
      const resp = await Auth.signIn({ username, password });
      if (resp && resp?.signInUserSession?.idToken?.payload?.companyList) {
        // If Seller View
        setCompanyList(JSON.parse(resp.signInUserSession.idToken.payload.companyList))
      }

      setUser(resp);
      setIsAuthenticated(true);
      setIsLoading(false)
      return resp
    }
    catch (error:any) {
      setIsAuthenticated(false);
      console.error('ERROR', error);
      throw error;
    }
    finally {
      setIsLoading(false)
    }
  }

  /**
   * completeNewPassword
   */
  async function completeNewPassword(newPassword: string) {
    try {
      const userResp = await Auth.completeNewPassword(user,newPassword);
      setUser(userResp);
      setIsAuthenticated(true);
    }
    catch (error) {
      console.error('ERROR');
      throw error;
    }
  }

  /**
   * logout
   */
  async function logout() {
    setIsLoading(true)
    try {
      if (user) {
        const respOne = await addTokenToUser(user, fcmToken, deviceId, true);
        const resp = await Auth.signOut({ global: true });
        CacheLS.logout()
        navigate('/login', 'root');
      }
      else if(!window.location.pathname.includes('login')) {
        CacheLS.logout()
        navigate('/login', 'root');
      }
    }
    catch (error) {
      console.error('Exception in logout', error);
    }
    finally {
      setIsAuthenticated(false);
      setUser(null);
      setCompanyList([])
      CacheLS.logout();
      setIsLoading(false)
    }
  }

  function presentToast(message: string, color = 'danger', duration = 2000) {
    return present({
      message: message,
      color: color,
      duration: duration,
      position: 'bottom',
      positionAnchor: toastositionAnchor.current,
    });
  }


  /**
   * changePhoneNumber
   */
  async function changePhoneNumber(phoneNumber: string) {
    try {
      const userA = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(userA, { phone_number: phoneNumber });
      // Send by default verication code
      await verifyUserAttribute('phone_number');
      const prevUserData = { ...user };
      prevUserData.attributes.phone_number = phoneNumber;
      prevUserData.attributes.phone_number_verified = false;
      setUser(prevUserData);
      return prevUserData;
    }
    catch (error) {
      throw error;
    }
  }

  /**
   * verifyCurrentUserAttributeSubmit
   */
  async function verifyCurrentUserAttributeSubmit(attribute:string, otpCode:any) {
    setIsLoading(true);
    try {
      await Auth.verifyCurrentUserAttributeSubmit(attribute, otpCode);
      if (attribute == 'phone_number') {
        let paramsUpdateUser:GraphQLOptions = {
          query: updateUserCustom,
          authMode: 'AMAZON_COGNITO_USER_POOLS',
          variables: {
            input: {
              id: user.username,
              mobile_verified: true
            }
          }
        }
        await API.graphql(paramsUpdateUser);
      }
      const prevUserData = { ...user };
      prevUserData.attributes[`${attribute}_verified`] = true;
      setUser(prevUserData);
      presentToast(SuccessMessage?.OtpVerified, 'success', 1000);
      return { ok: true }
    }
    catch (error:any) {
      presentToast((error?.message || ErrMessages?.ErrGen), 'danger', 1000);
      return { ok: false }
    }
    finally {
      setIsLoading(false);
    }
  }
  /**
   * changeUserInfo
   */
  async function changeUserInfo(userInfo:any) {
    try {
      let prevUserData = { ...user };

      const { name, mobile_no, bio, image } = userInfo
      let newParams:any = {
        name: name,
        phone_number: mobile_no
      }

      if (bio) {
        newParams['custom:bio'] = bio
      }
      if (image) {
        newParams['custom:image'] = image
      }
      
      const userS = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(userS, newParams);
      console.log(result)

      let redirect = false;
      if (prevUserData.attributes.phone_number != mobile_no) {
        prevUserData.attributes.phone_number_verified = false
        redirect = true
      }

      prevUserData.attributes = { ...prevUserData.attributes, ...newParams };
      console.log(prevUserData)
      setUser(prevUserData)
      if (redirect) {
        await verifyUserAttribute('phone_number');
        history.push(`/otp/verify/phone/${mobile_no}`);
      }

      let paramsUpdateUser:GraphQLOptions = {
        query: updateUserCustom,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: {
          input: {
            id: user.username,
            picture: prevUserData.attributes['custom:image'],
            name: prevUserData.attributes.name,
            mobile_no: prevUserData.attributes.mobile_no,
            mobile_verified: prevUserData.attributes.mobile_verified,
            bio: userInfo.bio || prevUserData.attributes['custom:bio']
          }
        }
      }
      API.graphql(paramsUpdateUser);

      return prevUserData
    }
    catch (error) {
      console.error("Exception in changeUserInfo()", error)
      throw error
    }
  }

  /**
 * verifyUserAttribute
 */
  async function verifyUserAttribute(attribute_name: string) {
    try {
      await Auth.verifyCurrentUserAttribute(attribute_name);
      presentToast(SuccessMessage.VerificationCodeSent, 'success', 1000);
    }
    catch (error:any) {
      presentToast(error.message, 'danger', 1000);
      throw error;
    }
  }
  /**
   * resendSignUp
   */
  async function resendSignUp(value:any) {
    try {
      const res = await Auth.resendSignUp(value);
      return res;
    }
    catch (error) {
      presentToast(SuccessMessage?.VerificationCodeSent, 'success', 1000);
      throw error;
    }
  }
  /**
   * init
   */
  async function init() {
    try {
      setIsLoading(true);
      const user = await Auth.currentAuthenticatedUser();

      if (user && user.signInUserSession.idToken.payload.companyList) {
        // If Seller View
        setCompanyList(JSON.parse(user.signInUserSession.idToken.payload.companyList))
      }

      if (user.authenticationFlowType == 'USER_SRP_AUTH') {
        user.attributes = user.signInUserSession.idToken.payload
        // user.username = user.attributes.sub
        const userDetail:any = await API.graphql({ query: getUserCustom, variables: { id: user.username, userId: user.username }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        if (userDetail.data.getUser) {
          user.attributes.phone_number_verified = userDetail.data.getUser.mobile_verified
          user.attributes = { ...user.attributes, ...userDetail.data.getUser }
          console.log(user.attributes)
        }        
      }

      if (!user?.attributes?.email) {
        const allAttributes = await Auth.userAttributes(user);
        console.log(allAttributes)
        for (const attribute of allAttributes) {
          user.attributes[attribute.Name] = attribute.Value
        }
      }

      if (user) {
        if(isPlatform('capacitor')) {
          registerNotifications()
        }
        else {
          generateWebNotifications()
        }
      }

      setIsAuthenticated(true);
      setUser(user);
      if (!user.attributes.phone_number && window.location.pathname != '/changeMobileNo') {
        history.push('/changeMobileNo')
      }
    }
    catch (error) {
      console.log('Exception in init()', error);
      setIsLoading(false);
      setUser(null);
      setIsAuthenticated(false);
      // logout();
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <UserContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        user,
        companyList,
        errorMessage,
        login,
        completeNewPassword,
        logout,
        changePhoneNumber,
        verifyCurrentUserAttributeSubmit,
        changeUserInfo,
        setIsLoading,
        verifyUserAttribute,
        userInfoUpdate: init,
        resendSignUp
      }}
    >
      <div ref={toastositionAnchor} style={{ position: 'absolute', top:"90%", width:"100%" }}></div>
      <IonLoading isOpen={isLoading} message={ LOADING_MESSAGES.PLS_WAIT }></IonLoading>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
import { IonAvatar, IonBadge, IonCol, IonContent, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonRow, IonText, useIonActionSheet } from '@ionic/react';
import { API } from 'aws-amplify';
import { bookmark, bookmarkOutline, chatboxEllipsesOutline, chatboxOutline, closeCircle, copyOutline, create, ellipsisVertical, heartOutline, heartSharp, location, shareSocialOutline, trash } from 'ionicons/icons';
import { useContext, useEffect } from 'react';
import { ProductFeedListByStatus, ProductFeedListByStatusAfterAuth, ProductFeedListByType, ProductFeedListByTypeAfterAuth } from '../../APIs/graphql/customQueries';
import styles from './ComponentFeed.module.scss';
import Moment from 'moment';
import { AppContext } from '../../store/AppContext';
import { AllProductTypes, ErrMessages, STATUS_CODE } from '../../shared/errorMsg';
import { useLazyLoading } from '../../customHooks/useLazyLoading';
import { openaiSearchProductF, updateProduct } from '../../APIs/graphql/mutations';
import { UserContext } from '../../store/UserContext';
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import config from '../../../src/APIs/aws-exports';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { ProductType, openAiSerchInput } from '../../APIs/API';

const ComponentFeed = (props: any) => {
  let initialKey = props.propSelectedTab + props?.searchTerm;
  const history = useHistory();

  const { dataListObj, loadMoreProduct, setDataListObj } = useLazyLoading(getFeedProducts, initialKey);

  const { s3Url, socialShare, onImageError, bookmarkToggle, likeUnlikeFeed, presentToastError, deleteProductVariant, copyToClipBoard } = useContext(AppContext);
  const { user } = useContext(UserContext);

  const limit = 5;
  const { t } = useTranslation()
  const [ present ] = useIonActionSheet();

  const ownerButtons = [
    {
      text: 'Delete Feed',
      icon: trash,
      role: 'delete'
    },
    {
      text: 'Edit Feed',
      icon: create,
      role: 'edit'
    },
    {
      text: 'Turn off commenting',
      icon: chatboxEllipsesOutline,
      role: 'comment'
    },
  ];
  const optionOtherButtons = [
    {
      text: 'Copy link',
      icon: copyOutline,
      role: 'copy'
    },
    {
      text: 'Share link',
      icon: shareSocialOutline,
      role: 'share'
    },
    {
      text: 'Cancel',
      icon: closeCircle,
      role: 'cancel'
    }
  ];

  useEffect(() => {
    const containerElement:any = document.getElementById('feedContainerId');
    containerElement.addEventListener('scroll', handleScroll);

    return () => {
      containerElement.removeEventListener('scroll', handleScroll);
    };
  }, [ initialKey ]);

  /**
   * handleScroll
   * custom scrool added because infinite loader don't work with snap
   * @param e
   */
  const handleScroll = (e:any) => {
    const element = e.target;
    const threshold = element.scrollHeight - element.clientHeight * 2;
    if (element.scrollTop >= threshold && !dataListObj?.[initialKey]?.isAllLoaded) {
      // User has reached the bottom of the scrollable area
      // Trigger the loading of more data here
      // Call your function to load more data or fetch the next page
      loadMoreProduct(e)
    }
  };

  /**
   * getFeedProducts
   */
  async function getFeedProducts(nextToken?:any) {
    try {
      const AllProductTypes1: any = AllProductTypes;
      let variables: any = {
        // filter: AllProductTypes1[selectedTab] ? {type:{ eq: selectedTab} }: null,
        type: AllProductTypes1[props.propSelectedTab] ? props.propSelectedTab : null,
        createdAtStatus: { le: { status: STATUS_CODE.ACTIVE, createdAt: new Date(new Date().setFullYear(new Date().getFullYear() + 2)) } },
        limit: limit,
        productVariantFilter: { attribute: { eq: 'default' } },
        productVariantLimit: 1,
        sortDirection: 'DESC',
        nextToken: nextToken
      };

      let auth = false;
      if (user) {
        variables.userId = { eq: user.username }
        auth = true
      }

      const params: GraphQLOptions = {
        query: user ? ProductFeedListByTypeAfterAuth : ProductFeedListByType,
        variables: variables
      };

      if (!variables.type || variables.type == 'all') {
        variables.status = STATUS_CODE.ACTIVE
        variables.type = { lt: 'St' }
        params.query = auth ? ProductFeedListByStatusAfterAuth : ProductFeedListByStatus;
      }

      if (props.searchTerm) {
        params.query = openaiSearchProductF
        const variableTemp = {
          searchText: props.searchTerm,
          limit: limit,
          type: AllProductTypes1[props.propSelectedTab] ? props.propSelectedTab : 'Feed',
          skip: nextToken || 0
        } as openAiSerchInput

        params.variables = { input: variableTemp }
        auth = false
      }

      if (auth) {
        params.authMode = 'AMAZON_COGNITO_USER_POOLS';
      }

      const apiResp: any = await API.graphql(params);
      const productList = apiResp.data?.productListByStatusAndType || apiResp.data?.productListByType || JSON.parse(apiResp.data.openaiSearchProductF.resp_data);

      if (productList.skip) {
        productList.nextToken = dataListObj[initialKey]?.items ? (dataListObj[initialKey]?.items?.length + productList.items.length) : productList.items.length
      }

      return { items: productList.items, nextToken: productList.nextToken, key: initialKey }
    }
    catch (error) {
      console.error('Exception in getFeedProducts()', error);
    }
  }

  /**
   * shareFeed
  */
  async function shareFeed(product: any, index: number) {
    try {
      let tempDataListObj = { ...dataListObj };

      const resp = await socialShare(product.id, 'Home360', '', `/feedDetail/${product.type}/${product.productVariants.items[0].id}`, 'Share the url');

      if (resp) {
        tempDataListObj[initialKey].items[index].shareCount = tempDataListObj[initialKey].items[index].shareCount + 1
        setDataListObj(tempDataListObj);
      }
    }
    catch (error) {
      console.error('Exception in shareFeed', error);
    }
  }
  /**
   * showMoreOptions
   */
  async function showMoreOptions(product:any, index: number) {
    try {
      let buttons:any = optionOtherButtons;
      if (product.userId == user.username && (product.type === AllProductTypes.Request || product.type === AllProductTypes.Donation)) {
        buttons = [ ...ownerButtons, ...optionOtherButtons ]
      }

      if (product.allowToComment == 0) {
        buttons[2].text = 'Turn on comments'
      }
      else {
        buttons[2].text = 'Turn off comments'
      }

      present({
        buttons: buttons,
        mode: 'md',
        onDidDismiss: async (event:any)=> {
          const { role } = event.detail

          switch (role) {
            case 'edit':
              history.push(`editProduct/${product.type}/${product.productVariants.items[0].id}`);
              break;
            case 'delete':
              await deleteProductVariant([ product.productVariants.items[0].id ], dataListObj, [ index ], initialKey, setDataListObj);
              break;
            case 'share':
              await shareFeed(product, index)
              break;
            case 'copy':
              copyToClipBoard(`${config.aws_web_url}/feedDetail/${product.type}/${product.productVariants.items[0].id}`);
              break;
            case 'comment':
              toogleAllowComment(product, index);
              break;
          }
        }
      });
    }
    catch (error) {
      console.error('Exception in showMoreButton', error)
    }
  }

  /**
   * goToCommentList
   * @param product
   */
  function goToCommentList(product: any) {
    if (product.allowToComment == 0) {
      presentToastError(ErrMessages.ErrCommentTurnOff)
      return
    }
    history.push(`commentList/${product.id}`)
  }

  /**
   * toogleAllowComment
   */
  async function toogleAllowComment(product: any, index: number) {
    try {
      let allowToComment = 1;
      if (product.allowToComment == 1) {
        allowToComment = 0
      }

      product.allowToComment = allowToComment

      await API.graphql({ query: updateProduct, variables: { input: { id: product.id, allowToComment: allowToComment } }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    }
    catch (error) {
      presentToastError(ErrMessages.ErrGen);
      console.error('Exception in toogleAllowComment', error);
    }
  }

  return (
    <IonContent className='ComponentFeed'>
      <div className={styles.ComponentFeed} data-testid="ComponentFeed" id='feedContainerId'>
        { dataListObj && dataListObj[initialKey] &&  dataListObj[initialKey].items.map((product:any, index:any)=> {
          return product?.productVariants?.items?.length ?
            <IonRow className={styles.feedContainer} key={product.id + index} >
              <IonCol size='12' className={styles.userNameDateCol}>
                <IonAvatar className={styles.userIcon}>
                  <img src={ product?.user?.picture ? s3Url + '/' + product?.user?.picture : 'assets/svg/avatar.svg' } onError={(e)=> onImageError(e, 'profile')} />
                </IonAvatar>
                <h6 className={styles.noMargin + ' capitalizeTransform ' + styles.titleDescContainer}>
                  { (product.type == ProductType.Design || product.type == ProductType.Service) ? (product?.company?.name || product?.user?.name) : product?.user?.name}
                  <IonText className={styles.dateText}>
                    { Moment(product.createdAt).format('DD MMM,YYYY') }
                  </IonText>
                </h6>
                {/* <IonIcon color='primary' icon={chatbubbleEllipses} className={styles.chatIcon}></IonIcon> */}
                <IonIcon icon={ellipsisVertical} className={styles.moreIcon} onClick={()=> showMoreOptions(product, index)}></IonIcon>
              </IonCol>

              <IonCol size='12' className={styles.slideContainer} onClick={(e: any) => history.push(`/feedDetail/${product.type}/${product.productVariants.items[0].id}`)}>
                <Swiper className={styles.swiperContainer} pagination={true} modules={ [ Pagination ] }>
                  {product.productVariants.items[0].productMediaList.items.sort((a:any, b:any) => a.position - b.position).map((media: any, meIndex: any) =>
                    <SwiperSlide className={styles.swiperSlider} key={meIndex + 'media'}>
                      <div className={styles.backGradient}></div>
                      <img src={s3Url + '/' + encodeURIComponent(media.key)} className={styles.feedImg} />
                      {/* <div style={{ backgroundImage: `url(${s3Url + '/' + encodeURIComponent(media.key)})` }} className={styles.dummy}></div> */}
                    </SwiperSlide>
                  )}
                </Swiper>
              </IonCol>

              <IonCol size='12' className={styles.productTitleDesc}>
                <h6 className={styles.noMargin + ' capitalizeTransform ' + styles.titleDescContainer}>
                  <IonBadge>{product.type != AllProductTypes.Service ? product.type : t('PageFeed.homeService') }</IonBadge>
                  {
                    (product.type == AllProductTypes.Donation || product.type == AllProductTypes.Request) && product.productVariants.items[0].quantity == 0 ? <>
                      <IonBadge className={styles.fullfilledBadge}>{t('PageFeed.fullfilled') }</IonBadge>
                    </> : ''
                  }
                  <span className='oneLineOnly'>
                    {product.title}
                  </span>
                  <p className={styles.descriptionText + ' oneLineOnly listHtmlDescription'} dangerouslySetInnerHTML={{ __html: product.description }}>
                  </p>
                  <IonText className={styles.locationText}>
                    <IonIcon icon={location} className={styles.locationIcon}></IonIcon>
                    <span>{product.productVariants.items[0].location.address}</span>
                  </IonText>
                </h6>
                <section className={styles.socialIcons}>
                  <div className={styles.socailIconSub} key={product.likeCount}>
                    <IonIcon icon={ product.likes?.items[0]?.likeState ? heartSharp : heartOutline } className={product.likes?.items[0]?.likeState ? 'redColor' : '' } onClick={(e)=> likeUnlikeFeed(product, index, initialKey, dataListObj, setDataListObj)}></IonIcon>
                    <IonText className={styles.socialCountSpan}>
                      { product.likeCount ? product.likeCount : '' }
                    </IonText>
                  </div>
                  <div className={styles.socailIconSub}>
                    <IonIcon icon={chatboxOutline} onClick={(e)=> goToCommentList(product)}></IonIcon>
                    <IonText className={styles.socialCountSpan}>
                      {product.commentCount || ''}
                    </IonText>
                  </div>
                  <div className={styles.socailIconSub}>
                    <IonIcon icon={shareSocialOutline} onClick={(e)=> shareFeed(product, index)}></IonIcon>
                    <IonText className={styles.socialCountSpan}>
                      {product.shareCount || ''}
                    </IonText>
                  </div>
                  <div className={styles.socailIconSub}>
                    <IonIcon icon={product.bookmarks?.items[0]?.bookmarkState ? bookmark : bookmarkOutline} onClick={(e)=> bookmarkToggle(product, index, initialKey, dataListObj, setDataListObj)}></IonIcon>
                    <IonText className={styles.socialCountSpan}>
                      { product.bookmarkCount || '' }
                    </IonText>
                  </div>
                </section>
              </IonCol>
            </IonRow>
            : ''
        }
        )}

        {
          !dataListObj?.[initialKey]?.isAllLoaded ?
            <>
              <IonInfiniteScroll onIonInfinite={(ev) => loadMoreProduct(ev)}>
                <IonInfiniteScrollContent loadingSpinner="bubbles" ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </>
            : ''
        }
        {
          dataListObj?.[initialKey]?.isAllLoaded && !dataListObj?.[initialKey].items.length ? <h5 className='text-center'>No Info Found</h5> : ''
        }
      </div>
    </IonContent>

  );
};
export default ComponentFeed;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://ezacvrkrlrgpllx4of77g6wfdq.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-4ndz4skko5ffjlpf43vmqxi7bi",
    "aws_cloud_logic_custom": [
        {
            "name": "stripe",
            "endpoint": "https://s1d580zf44.execute-api.ap-southeast-1.amazonaws.com/uat",
            "region": "ap-southeast-1"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://lhlu0nwpuf.execute-api.ap-southeast-1.amazonaws.com/uat",
            "region": "ap-southeast-1"
        },
        {
            "name": "openai",
            "endpoint": "https://zlnq21nj4d.execute-api.ap-southeast-1.amazonaws.com/uat",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:76f32ad2-5061-4dad-a4bc-99f74dfe2b52",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_b3HOglwU1",
    "aws_user_pools_web_client_id": "7iile0bs4878m5hfj8u70tjhkn",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "home360dev204151-uat",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_web_url": "https://master.d1q7c54e6740aw.amplifyapp.com",
    "aws_cognito_domain": "home360sg.auth.ap-southeast-1.amazoncognito.com"
};


export default awsmobile;

import React, { lazy, Suspense } from 'react';

const LazyPageEstoreSubCategory = lazy(() => import('./PageEstoreSubCategory'));

const PageEstoreSubCategory = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageEstoreSubCategory {...props} />
  </Suspense>
);

export default PageEstoreSubCategory;

import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import { FC, useContext, useEffect, useState } from 'react';
import styles from './ComponentMap.module.scss';
import { AppContext } from '../../store/AppContext';
import { Location } from '../../APIs/API';

declare let google: any;

interface location {
  lat: number,
  lng: number,
  address?: string
}
interface ComponentMapProps {
  propLocation?: any,
  showOnly?: boolean
  onLocationChange: (params: location) => any,
  emitCloseModal: (event:any) => any
}

const ComponentMap: FC<ComponentMapProps> = (props) => {
  const { myLocation } = useContext(AppContext)
  const [ showOnly, setShowOnly ] = useState(false);
  const [ geoLocation, setGeoLocation ] = useState({});
  const [ location, setLocation ] = useState( props.propLocation ? props.propLocation : myLocation);

  useEffect(() => {
    initMap();
  }, [ myLocation, location ]);

  /**
   * initMap
   */
  function initMap() {
    if (location && location.lat && location.lat) {
      const params = {
        lat: location.lat,
        lng: location.lng
      };

      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 8,
        center: params,
        scaleControl: false,
        gestureHandling: 'greedy'
      });

      setGeoLocation(params);
      props.onLocationChange(params);

      // Create the search box and link it to the UI element.
      const input = document.getElementById('pac-input');
      const searchBox = new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      // Bias the SearchBox results towards current map's viewport.
      map.addListener('bounds_changed', () => {
        searchBox.setBounds(map.getBounds());
      });

      const marker = new google.maps.Marker({
        map,
        position: params,
        draggable: showOnly ? false : true
      });

      // Marker dragged or dragging
      google.maps.event.addDomListener(marker, 'dragend', function (event: any) {
        // marker.setPosition(find_closest_point_on_path(e.latLng,padded_points));
        const params = {
          lat: marker.getPosition().lat(),
          lng: marker.getPosition().lng()
        };
        getAddress(params.lat, params.lng);
        map.panTo(params);
        setGeoLocation(params);
        props.onLocationChange(params);
      });


      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();
        places.forEach((place: any) => {
          if (!place.geometry || !place.geometry.location) {
            console.log('Returned place contains no geometry');
            return;
          }

          // Create a marker for each place.
          const newParams = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          marker.setPosition(newParams);

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
          getAddress(newParams.lat, newParams.lng);
          map.panTo(newParams);
          setGeoLocation(newParams);
          props.onLocationChange(newParams);
        });
        map.fitBounds(bounds);
      });
    }
  }

  /**
   * getAddress
   */
  function getAddress(lat: number, lng: number) {

    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(lat, lng);
    let request;
    request = { latLng: latlng };
    geocoder.geocode(request, (results: any, status: any) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const result = results[0];
        if (results[0].formatted_address && results[0].formatted_address != '' && results[0].formatted_address != undefined && results[0].formatted_address) {
          // let input:any = document.getElementById('pac-input')
          // input.setAttribute('value',results[0].formatted_address)
          // console.log('Finding Address', results[0]);
          const ge = { lat: lat, lng: lng, address: results[0].formatted_address };
          props.onLocationChange(ge);
        }
        else {
          const ge = { lat: lat, lng: lng, address: results[results.length - 1].long_name };
          props.onLocationChange(ge);
        }
      }
    });
  }

  return (
    <>
      <IonHeader className={styles.PageAddProductHeader}>
        <IonToolbar mode='ios'>
          <IonIcon className='rightCloseIcon' icon={closeCircleOutline} onClick={props.emitCloseModal}></IonIcon>
          <IonTitle>Map</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.ComponentMap} data-testid="ComponentMap">
        <div className={styles.search}>
          <input className={!props.showOnly ? 'hiddenAbs' : ''} placeholder="Location Name" autoCorrect="off" disabled={showOnly} autoCapitalize="off" spellCheck={false} type="text" name="searchElement" id="pac-input" />
        </div>

        <div id="map" className={styles.mapClass}></div>
        <div id="infowindow-content"></div>
      </IonContent>
      <IonFooter>
        <IonButton expand='block' onClick={props.emitCloseModal}>
          Done
        </IonButton>
      </IonFooter>
    </>
  );
};

export default ComponentMap;

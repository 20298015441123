import { IonApp, IonButton, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonLabel, IonRow, IonText, NavContext, useIonToast } from '@ionic/react';
import { Auth } from 'aws-amplify';
import { closeCircleOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrMessages, SuccessMessage } from '../../shared/errorMsg';
import { UserContext } from '../../store/UserContext';
import styles from './PageResetPassword.module.scss';
import { useHistory } from 'react-router';

const PageResetPassword = (props:any) => {
  const { emitCloseModal } = props;
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const [ customAttribute, setCustomAttribute ] = useState({ password_show: false, c_password_show: false });
  const [ present ] = useIonToast();
  const history = useHistory();
  const { t } = useTranslation();
  const { setIsLoading, completeNewPassword } = useContext(UserContext);

  async function changePassword(data: any) {
    const { password, c_password, otpCode } = data;
    if (password != c_password) {
      return present({
        message: ErrMessages.ErrPasswordConfirmPassword,
        duration: 1000,
        position: 'bottom',
        color: 'danger'
      });
    }

    setIsLoading(true);
    try {
      if (props.showMode == 'NEW_PASSWORD_REQUIRED') {
        // this is used when we delete user from cognito and import backup in cognito
        await completeNewPassword(password);
        emitCloseModal()
        history.push('/');
        return
      }
      else {
        await Auth.forgotPasswordSubmit(props.propEmail, otpCode, password);
      }

      present({ message: SuccessMessage.PasswordResetSucess, duration: 1000, color: 'success' });
      history.push('/login');
    }
    catch (error:any) {
      present({ message: error.message, duration: 1000, color: 'danger' });
      console.error('Exception in changePassword():', error);
    }
    finally {
      setIsLoading(false);
    }
  }
  /**
   * onToogle
   * use to change type and icons of input type password fields
  */
  const onToogle = (e: any) => {
    const data: any = { ...customAttribute };
    const name = e.target.name;
    data[name] = !data[name];
    setCustomAttribute(data);
  };
  return  (
    <IonApp>
      <IonHeader className={styles.PageResetPasswordHeader + ' ion-no-border'}>
        <IonIcon className={styles.closeIcon + ' closeIcon'} icon={closeCircleOutline} onClick={emitCloseModal}></IonIcon>
      </IonHeader>
      <IonContent>
        <div className={styles.PageResetPassword} data-testid="ForgotPassword">
          <div>
            <form  onSubmit={handleSubmit(changePassword)}>
              <IonText className="ion-text-center">
                <h5 className="regTitle">
                  <b>{!props.showMode ? t('PageResetPassword.changePassword') : t ('PageResetPassword.title')}</b>
                </h5>
              </IonText>
              <IonRow>
                {
                  !props.showMode ?
                    <IonCol size='12'>
                      <IonLabel position="stacked" className="inputLabel">{t('PageResetPassword.otpLabel')}*</IonLabel>
                      <IonInput type='number' {...register('otpCode', { required: true, maxLength: 6 })} />
                      {errors.password && <div className="invalid-feedback">{t('PageResetPassword.Enter6DigitCode')}</div>}
                    </IonCol>
                    : ''
                }
                <IonCol size='12'>
                  <IonIcon className={styles.inputIcons} name="password_show" icon={customAttribute.password_show ? eyeOutline : eyeOffOutline} onClick={onToogle}></IonIcon>
                  <IonLabel position="stacked" className="inputLabel">{t('PageResetPassword.password')}*</IonLabel>
                  <IonInput type={customAttribute.password_show ? 'text' : 'password'} {...register('password', { required: true, maxLength: 20 })} />
                  {errors.password && <div className="invalid-feedback">{t('PageResetPassword.passwordIsRequire')}</div>}
                </IonCol>
                <IonCol size='12'>
                  <IonIcon className={styles.inputIcons} name="c_password_show" icon={customAttribute.c_password_show ? eyeOutline : eyeOffOutline} onClick={onToogle}></IonIcon>
                  <IonLabel position="stacked" className="inputLabel">{t('PageResetPassword.confirmPassword')}*</IonLabel>
                  <IonInput type={customAttribute.c_password_show ? 'text' : 'password'} {...register('c_password', { required: true, maxLength: 20 })} />
                  {errors.c_password && <div className="invalid-feedback">{t('PageResetPassword.confirmPasswordIsRequire')}</div>}
                </IonCol>
                <IonCol size='12'>
                  <IonButton expand='block' className={styles.submitBtn} type='submit'>{t('PageResetPassword.resetBtn')}</IonButton>
                </IonCol>
              </IonRow>
            </form>
          </div>
        </div>
      </IonContent>
    </IonApp>
  );
};

export default PageResetPassword;

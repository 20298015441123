export const getUserCustom = /* GraphQL */ `query GetUser($id: ID!, $userId: ID!) {
    getUser(id: $id) {
      id
      username
      email
      name
      gender
      country
      bio
      picture
      followers
      followings
      followersList(userId: {eq: $userId}) {
        items {
          id
          userId
        }
      }
      online
      fcmToken
      merchantFcmToken
      createdAt
      mobile_verified
      updatedAt
      owner
      __typename
    }
  }
  `;
export const getCompanyCustom = /* GraphQL */ `query GetCompany($id: ID!, $userId: ID!) {
  getCompany(id: $id) {
    id
    ownerType
    uen
    name
    registration_name
    picture
    bio
    status
    followers
    followersList(userId: { eq: $userId }) {
      items{
        id
        userId
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;
export const updateUserAddressCustom = /* GraphQL */ `mutation UpdateUserAddress(
  $input: UpdateUserAddressInput!
  $condition: ModelUserAddressConditionInput
) {
  updateUserAddress(input: $input, condition: $condition) {
    id
  }
}
`;
export const followsByCompanyIdAndCreatedAtCustom = /* GraphQL */ `query FollowsByCompanyIdAndCreatedAt(
  $companyId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  followsByCompanyIdAndCreatedAt(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      user {
        id
        name
        picture
      }
      companyId
      company {
        id
        name
        picture
      }
      followerId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` ;

export const followsByFollowerIdAndCreatedAtCustom = /* GraphQL */ `query FollowsByFollowerIdAndCreatedAt(
  $followerId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  followsByFollowerIdAndCreatedAt(
    followerId: $followerId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      user {
        id
        name
        picture
      }
      companyId
      followerId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` ;
export const followsByUserIdAndCreatedAtCustom = /* GraphQL */ `query FollowsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  followsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      follower {
        id
        name
        picture
      }
      company {
        id
        name
        picture
      }
      companyId
      followerId
      createdAt
      updatedAt
      owner
    }
    nextToken
  }
}
`
import { API } from 'aws-amplify';
import { ViewProductFInput } from '../APIs/API';
import { viewProductF } from '../APIs/graphql/mutations';

/**
 * sortArray
 * @param productMediaList
 * @returns
 */
export function sortArray(productMediaList:any) {
  if (!productMediaList) {
    return []
  }
  return productMediaList.sort((a:any, b: any) => {
    // Handle null values by treating them as a large number
    const positionA = a.position !== null ? a.position : Number.MAX_SAFE_INTEGER;
    const positionB = b.position !== null ? b.position : Number.MAX_SAFE_INTEGER;
    return positionA - positionB;
  });
}

export const chunkHTMLContent = async (html:any, setContent: any, timeOut: number) => {
  const chunkedContent:any = [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(`<div>${html}</div>`, 'text/html');
  const nodes:any = Array.from(doc.body.childNodes);

  nodes.forEach((node:any) => {
    if (node.outerHTML) {
      chunkedContent.push(node.outerHTML);
    }
  });

  for (let i = 0; i < chunkedContent.length; i++) {
    await new Promise((resolve) => setTimeout(resolve, timeOut)); // Adjust the delay as needed
    setContent((prevContent:any) => prevContent + chunkedContent[i]);
  }
  return
};

export const viewAction = async (param: ViewProductFInput) => {
  try {
    const resp = await API.graphql({ query: viewProductF, variables: { input: param }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    console.log(resp);
  }
  catch (error) {
    console.error('Exception in viewAction()', error);
  }
}

/**
 * renderAttributeName
 * @param attributes
 * @returns
 */
export function renderAttributeName(attributes:any) {
  if (!attributes || (attributes && !attributes.length)) {
    return
  }

  let attributeCombo = '';
  for (let item of attributes) {
    attributeCombo += item.name + '-'
  }

  if (attributeCombo.toLocaleLowerCase() == 'default-') {
    return ''
  }

  return attributeCombo.slice(0, attributeCombo.length - 1) + ' :'
}

/**
 * getAverageRating
 */
export function getAverageRating(ratingObj: any) {
  let totalRating = 0;
  let totalRatingAfterMultiply = 0;
  let ratingValue:any = { one: 1, two: 2, three: 3, four: 4, five: 5 };
  // let use weighted algorithm
  for (let key in ratingObj) {
    if (ratingObj[key] >= 0) {
      totalRating += ratingObj[key];
      totalRatingAfterMultiply += (ratingValue[key] * ratingObj[key]);
    }
  }
  if (totalRatingAfterMultiply / totalRating) {
    return (totalRatingAfterMultiply / totalRating).toFixed(1);
  }
  else {
    return ''
  }
}

/**
 * formatNumberWithCommas
 * @param number
 * @returns
 */
export function formatNumberWithCommas(number:number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
import React, { lazy, Suspense } from 'react';

const LazyPageProfileEdit = lazy(() => import('./PageProfileEdit'));

const PageProfileEdit = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageProfileEdit {...props} />
  </Suspense>
);

export default PageProfileEdit;

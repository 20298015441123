import React, { FC } from 'react';
import styles from './ComponentAlertConfrim.module.scss';
import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonRow, IonText } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';

interface ComponentAlertConfrimProps {
  title: string
  description: string
  ok: string
  cancel: string
  openModal: boolean
  onClose: ()=> any
  onConfirm: ()=> any
}

const ComponentAlertConfrim: FC<ComponentAlertConfrimProps> = (props) => {
  return (
    <>
      <IonHeader className={styles.ComponentAlertConfrimHeader + ' ion-no-border'}>
        <IonIcon className={styles.closeIcon + ' closeIcon'} icon={closeCircleOutline} onClick={props.onClose}></IonIcon>
        <IonText className="ion-text-center">
          <h5 className={styles.headerTitle + ' regTitle'}>
            <b>{props.title}</b>
          </h5>
        </IonText>
      </IonHeader>
      <IonContent className={styles.ComponentAlertConfrim} data-testid="ComponentBookingDetail">
        <div className={styles.description}>{props.description}</div>
      </IonContent>
      <IonFooter className={styles.ComponentAlertConfrimFooter}>
        <IonRow>
          <IonCol size='6' onClick={props.onClose}>
            <IonButton className='hoverBtnEffect'>{props.cancel}</IonButton>
          </IonCol>
          <IonCol size='6' onClick={()=> { props.onClose(); props.onConfirm() }}>
            <IonButton>{props.ok}</IonButton>
          </IonCol>
        </IonRow>
      </IonFooter>
    </>
  );
}

export default ComponentAlertConfrim;

import { useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router';
import { UserContext } from '../store/UserContext';
import { CacheLS } from '../store/CacheLs';
import { AppContext } from '../store/AppContext';

const AuthGuard: React.FC<{ component: React.ComponentType, path: any, exact?: boolean }> = ({ component: Component, path, exact = true }) => {
  const history = useHistory();
  const { isAuthenticated } = useContext(UserContext); // Change this to your authentication logic
  const { loginConfirmBox } = useContext(AppContext);
  const handleRedirect = () => {
    // Save the current path to local storage
    CacheLS.setRedirectUrl(window.location.href.replace(window.location.origin, ''));
    // Redirect to the login page
    return <Redirect to="/login" />;
  };

  return (
    <Route path={path} exact={exact}
      render={(props) =>
        isAuthenticated ? (
          <Component />
        ) : (
          <>
            <Redirect to='/login'></Redirect>
          </>
        )
      }
    />
  );
};

export default AuthGuard;

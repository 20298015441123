import React, { lazy, Suspense } from 'react';

const LazyPageSearch = lazy(() => import('./PageSearch'));

const PageSearch = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageSearch {...props} />
  </Suspense>
);

export default PageSearch;

import { IonApp, IonCol, IonContent, IonHeader, IonIcon, IonImg, IonPage, IonRow, IonText, IonTitle, IonToolbar, NavContext } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ComponentOtpVerify.module.scss';
import OtpInput from 'react18-input-otp';
import { useHistory, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { UserContext } from '../../store/UserContext';
import { AppContext } from '../../store/AppContext';
import { SuccessMessage } from '../../shared/errorMsg';

interface OtpVerifyProps { }

const OtpVerify: FC<OtpVerifyProps> = (props:any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  const [ otp, setOtp ] = useState('');
  const history = useHistory();
  const { navigate } = useContext(NavContext);
  const { presentToastError } = useContext(AppContext);
  const { type, value } = useParams() as any; //type can be email, mobile etc and value is email id, mob no

  const [ minutes, setMinutes ] = useState(0);
  const [ seconds, setSeconds ] = useState(30);
  const { resendSignUp, verifyUserAttribute, setIsLoading, verifyCurrentUserAttributeSubmit } = useContext(UserContext);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [ seconds ]);

  /**
   * otpChange
   */
  function otpChange(otpCode:string) {
    setOtp(otpCode);
    if (otpCode && otpCode.toString().length == 6) {
      verifyOtp(otpCode);
    }
  }
  /**
   * resendOtp
   */
  async function resendOtp() {
    setSeconds(30);
    try {
      if (type && type.includes('phone')) {
        await verifyUserAttribute('phone_number');
      }
      else {
        await resendSignUp(value);
      }
    }
    catch (error) {
      console.error('Exception in resend otp', error);
    }
  }
  /**
   * verifyOtp
   */
  async function verifyOtp(otpCode:string) {
    setIsLoading(true);
    try {
      if (type && type.includes('phone')) {
        try {
          const resp:any = await verifyCurrentUserAttributeSubmit('phone_number', otpCode)
          if (resp.ok) {
            history.replace('/')
          }
        }
        catch (error:any) {
          presentToastError(error.message);
        }
      }
      else {
        try {
          const res = await Auth.confirmSignUp(value, otpCode);
          presentToastError(SuccessMessage.OtpVerified, 'success')
          history.replace('/verified');
        }
        catch (error:any) {
          presentToastError(error.message);
        }
      }
    }
    catch (error) {
      console.error('Exception in verifyOtp', error);
    }
    finally {
      setIsLoading(false);
    }
  }
  return (
    <IonPage>
      <IonHeader className={styles.OtpVerifyHeader}>
        <IonToolbar mode='ios'>
          <IonTitle>
            <IonIcon icon={chevronBack} onClick={history.goBack}></IonIcon>
            <span>{t('otpVerifyPage.title')}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={styles.OtpVerify} data-testid="OtpVerify">
          <div className={styles.container}>
            {
              type && type.includes('phone') ?
                (
                  <svg width="105" height="94" viewBox="0 0 105 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M64.3195 64.5434L42.908 64.557C40.0178 64.557 37.6753 62.2141 37.6753 59.3243V34.8779C37.6753 31.988 40.0178 29.6452 42.908 29.6452L64.3195 29.6316C67.2093 29.6316 69.5522 31.9744 69.5522 34.8643V59.3107C69.5522 62.2005 67.2093 64.5434 64.3195 64.5434Z" fill="#06FCF3" fillOpacity="0.01"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M77.3869 10.4303C77.3826 6.45253 74.4449 3.48427 71.1228 3.48846H71.1228L35.5726 3.53207C35.5726 3.53207 35.5726 3.53207 35.5726 3.53207C32.2504 3.5363 29.3186 6.51224 29.3226 10.4901C29.3226 10.4901 29.3226 10.4901 29.3226 10.4901L29.399 83.5691V83.5691C29.4031 87.5473 32.3408 90.5157 35.6628 90.5115L71.213 90.4679C74.5355 90.4637 77.4674 87.4874 77.4633 83.5099L77.3869 10.4303ZM71.1185 7.48865e-06C76.6347 -0.00692517 80.8693 4.80057 80.8754 10.4266L80.9518 83.5063C80.9575 89.1318 76.7332 93.9494 71.2174 93.9564L35.6672 94C30.1508 94.007 25.9163 89.199 25.9106 83.5727C25.9106 83.5727 25.9106 83.5727 25.9106 83.5727L25.8342 10.4937L25.8342 10.4937C25.8284 4.86782 30.0528 0.0505762 35.5682 0.0436132H35.5683L71.1184 7.48865e-06C71.1184 7.48865e-06 71.1185 7.48865e-06 71.1185 7.48865e-06Z" fill="#1F202B"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M41.6631 0.819063C42.6259 0.850516 43.3809 1.65652 43.3494 2.61931C43.3439 2.78779 43.402 2.91783 43.4692 2.99636C43.5243 3.06069 43.5713 3.07828 43.6002 3.08284H63.1836C63.2128 3.07827 63.2598 3.06063 63.3149 2.99628C63.3821 2.91778 63.4402 2.78778 63.4347 2.61931C63.4032 1.65652 64.1582 0.850516 65.121 0.819063C66.0838 0.787611 66.8898 1.54261 66.9213 2.50541C66.9875 4.53252 65.5093 6.48146 63.3095 6.56989C63.2862 6.57083 63.2628 6.5713 63.2394 6.5713H43.5443C43.5209 6.5713 43.4976 6.57083 43.4742 6.56989C41.2746 6.48144 39.7966 4.53233 39.8628 2.50541C39.8943 1.54261 40.7003 0.787611 41.6631 0.819063Z" fill="#1F202B"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M57.1884 53.5477L49.5975 53.5477C49.5956 53.5477 49.5941 53.5461 49.5941 53.5442C49.5941 53.5423 49.5956 53.5408 49.5975 53.5408L57.1884 53.5408C57.1903 53.5408 57.1918 53.5423 57.1918 53.5442C57.1918 53.5461 57.1903 53.5477 57.1884 53.5477Z" fill="#B8CCD7"/>
                    <g opacity="0.999997">
                      <path d="M52.1099 54.7758H5.23269C2.3425 54.7758 0 52.433 0 49.5431V25.0967C0 22.2069 2.3425 19.864 5.23269 19.864H52.1099C54.9997 19.864 57.3426 22.2069 57.3426 25.0967V49.5431C57.3426 52.433 54.9997 54.7758 52.1099 54.7758Z" fill="#EBF1F7"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M30.2449 33.5836C30.2449 32.6203 31.0259 31.8394 31.9892 31.8394H41.1833C42.1467 31.8394 42.9276 32.6203 42.9276 33.5836C42.9276 34.5469 42.1467 35.3278 41.1833 35.3278H31.9892C31.0259 35.3278 30.2449 34.5469 30.2449 33.5836Z" fill="#94A9B7"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M29.7998 41.8551C29.7998 40.8918 30.5807 40.1108 31.544 40.1108H47.211C48.1743 40.1108 48.9553 40.8918 48.9553 41.8551C48.9553 42.8184 48.1743 43.5993 47.211 43.5993H31.544C30.5807 43.5993 29.7998 42.8184 29.7998 41.8551Z" fill="#94A9B7"/>
                      <path d="M7.98026 46.5152C9.23575 43.745 12.1483 41.8022 15.5415 41.8022C18.9351 41.8022 21.8479 43.745 23.1034 46.5152H7.98026Z" fill="#1E4A5B"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M9.85778 45.4685H21.2258C19.9208 43.8849 17.8704 42.8487 15.5415 42.8487C13.2128 42.8487 11.1626 43.8849 9.85778 45.4685ZM7.02702 46.083C8.45656 42.9288 11.7484 40.7556 15.5415 40.7556C19.3348 40.7556 22.627 42.9288 24.0566 46.083C24.2034 46.407 24.1757 46.7832 23.983 47.0821C23.7903 47.381 23.459 47.5616 23.1034 47.5616H7.98023C7.62461 47.5616 7.29333 47.381 7.10064 47.0821C6.90795 46.7832 6.88022 46.407 7.02702 46.083Z" fill="#1E4A5B"/>
                      <path d="M19.8416 32.1351C19.8416 34.51 17.9163 36.435 15.5418 36.435C13.1668 36.435 11.2419 34.51 11.2419 32.1351C11.2419 29.7605 13.1668 27.8352 15.5418 27.8352C17.9163 27.8352 19.8416 29.7605 19.8416 32.1351Z" fill="#1E4A5B"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M15.5418 28.8819C13.7449 28.8819 12.2884 30.3386 12.2884 32.1352C12.2884 33.9322 13.7448 35.3886 15.5418 35.3886C17.3384 35.3886 18.7951 33.9321 18.7951 32.1352C18.7951 30.3386 17.3384 28.8819 15.5418 28.8819ZM10.1954 32.1352C10.1954 29.1827 12.5888 26.7888 15.5418 26.7888C18.4943 26.7888 20.8882 29.1826 20.8882 32.1352C20.8882 35.0882 18.4943 37.4816 15.5418 37.4816C12.5888 37.4816 10.1954 35.0882 10.1954 32.1352Z" fill="#1E4A5B"/>
                    </g>
                    <path d="M52.1099 54.7758H5.23269C2.3425 54.7758 0 52.433 0 49.5431V25.0967C0 22.2069 2.3425 19.864 5.23269 19.864H52.1099C54.9997 19.864 57.3426 22.2069 57.3426 25.0967V49.5431C57.3426 52.433 54.9997 54.7758 52.1099 54.7758Z" fill="#06FCF3" fillOpacity="0.01"/>
                    <path d="M82.9726 33.2175L86.4199 37.5411C87.3192 38.6685 88.6829 39.3254 90.1257 39.3254H95.6532C98.2709 39.3244 100.394 41.4461 100.395 44.0641C100.396 44.4203 100.356 44.7751 100.276 45.1222L99.0464 50.5112C98.7254 51.9181 99.0617 53.3947 99.9611 54.5232L103.409 58.8444C105.042 60.8925 104.705 63.8768 102.657 65.5094C102.38 65.7306 102.079 65.92 101.759 66.0739L96.7789 68.4648C95.4787 69.0903 94.5344 70.2747 94.2142 71.6819L92.9838 77.0702C92.4005 79.6237 89.8578 81.2207 87.3042 80.6378C86.9582 80.5586 86.6219 80.4411 86.302 80.2872L81.3229 77.8959C80.0228 77.2697 78.5077 77.2697 77.2079 77.8959L72.2278 80.2872C69.8689 81.4234 67.0359 80.4323 65.9001 78.0735C65.7456 77.7532 65.6273 77.4166 65.5485 77.0702L64.3184 71.6819C63.9982 70.2747 63.0535 69.0903 61.7534 68.4648L56.7733 66.0739C54.4144 64.9359 53.4243 62.1012 54.5623 59.7416C54.7161 59.4224 54.9055 59.1214 55.1264 58.8444L58.5716 54.5232C59.4719 53.3951 59.8089 51.9181 59.4866 50.5112L58.2559 45.1222C57.6736 42.5686 59.2727 40.0266 61.8263 39.4444C62.172 39.3655 62.5254 39.3254 62.8795 39.3254H68.4066C69.8497 39.3251 71.2144 38.6682 72.1148 37.5411L75.56 33.2175C77.1929 31.1708 80.1756 30.8349 82.2226 32.4678C82.4996 32.6886 82.7514 32.9405 82.9726 33.2175Z" fill="#20D56A"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M88.4113 50.5324C89.117 51.1881 89.1575 52.2918 88.5018 52.9974L77.9987 64.3008C77.6644 64.6605 77.194 64.8627 76.7029 64.8576C76.2119 64.8525 75.7457 64.6407 75.4189 64.2741L70.009 58.2052C69.368 57.4861 69.4313 56.3836 70.1504 55.7426C70.8695 55.1016 71.9721 55.1649 72.6131 55.8839L76.7477 60.5222L85.9462 50.6229C86.602 49.9172 87.7056 49.8767 88.4113 50.5324Z" fill="white"/>
                  </svg>
                )
                :
                (
                  <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74" fill="none">
                    <path d="M34.2824 0.925131L0 32.8415V32.8958H73.2664V32.8415L38.9841 0.925131C37.6602 -0.309028 35.6062 -0.309028 34.2824 0.925131Z" fill="#f60" />
                    <path d="M6.10352e-05 32.8955H73.2665V73.2653H6.10352e-05V32.8955Z" fill="#f60c" />
                    <path d="M54.9966 19.2401V50.0574C54.9966 52.4844 52.1664 54.4549 48.6805 54.4549H22.9817C19.4959 54.4549 16.6656 52.4844 16.6656 50.0574V19.2401C16.6656 16.8131 19.4959 14.8425 22.9817 14.8425H48.6805C52.1664 14.8425 54.9966 16.8131 54.9966 19.2401Z" fill="white" />
                    <path d="M54.9966 19.2401V50.0574C54.9966 52.4844 52.1664 54.4549 48.6805 54.4549H22.9817C19.4959 54.4549 16.6656 52.4844 16.6656 50.0574V19.2401C16.6656 16.8131 19.4959 14.8425 22.9817 14.8425H48.6805C52.1664 14.8425 54.9966 16.8131 54.9966 19.2401Z" stroke="#CCCCCC" strokeWidth="1.21566" />
                    <path d="M0.115015 32.906L72.7638 72.9638C72.7221 73.1255 72.7271 73.104 72.6854 73.2657H0.163015C0.0733282 73.2657 6.10352e-05 73.1924 6.10352e-05 73.1027V32.9742C6.10352e-05 32.9148 0.0632232 32.8769 0.115015 32.906Z" fill="#f60c" />
                    <path d="M73.1515 32.906L36.6333 53.0429L72.7637 72.9638C72.722 73.1242 72.7271 73.1053 72.6854 73.2657H73.1035C73.1932 73.2657 73.2665 73.1924 73.2665 73.1027V32.9729C73.2665 32.9148 73.2033 32.8769 73.1515 32.906Z" fill="#f60c" />
                    <g opacity="0.859552">
                      <path d="M36.6332 42.4443C31.8844 42.4443 28.021 38.5809 28.021 33.8321C28.021 29.0833 31.8844 25.2199 36.6332 25.2199C41.382 25.2199 45.2454 29.0833 45.2454 33.8321C45.2454 38.5809 41.382 42.4443 36.6332 42.4443ZM43.5313 26.9339C41.6888 25.0914 39.239 24.0767 36.6332 24.0767C34.0274 24.0767 31.5776 25.0914 29.7351 26.9339C27.8925 28.7765 26.8778 31.2263 26.8778 33.8321C26.8778 36.4379 27.8925 38.8876 29.7351 40.7302C31.5776 42.5728 34.0274 43.5875 36.6332 43.5875C39.239 43.5875 41.6888 42.5728 43.5313 40.7302C45.3739 38.8876 46.3887 36.4379 46.3887 33.8321C46.3887 31.2263 45.3739 28.7765 43.5313 26.9339Z" fill="#54B7A6" />
                      <path d="M41.2939 30.7018C41.0707 30.4786 40.7088 30.4786 40.4856 30.7018L35.438 35.7495L32.7809 33.0924C32.5577 32.8692 32.1957 32.8692 31.9725 33.0924C31.7493 33.3156 31.7493 33.6775 31.9725 33.9007L35.0337 36.962C35.1453 37.0736 35.2916 37.1294 35.4379 37.1294C35.5841 37.1294 35.7305 37.0736 35.8421 36.962L41.2939 31.5102C41.5171 31.287 41.5171 30.925 41.2939 30.7018Z" fill="#54B7A6" />
                    </g>
                  </svg>
                )
            }
            <IonRow>
              <IonCol size='12'>
                <h3>
                  {
                    type && type.includes('phone') ? t('otpVerifyPage.mobileVerfication') : t('otpVerifyPage.emailVerification')
                  }
                </h3>
                <h3 className={styles.mailCode}>
                  {
                    urlParams.get('type') == 'unconfirm' ? t('otpVerifyPage.JstEnterVerificationCodeTxt') : t('otpVerifyPage.EnterVerificationCodeTxt')
                  }
                </h3>
                <IonText className={styles.user_ide}>
                  {value}
                </IonText>
                {
                  type && type.includes('phone') ?
                    <h2 color="success" className={styles.user_ide} onClick={(e)=> history.push('/changeMobileNo')}>
                      {t('otpVerifyPage.changeMobileNumberTxt')}
                    </h2>
                    : ''
                }
              </IonCol>
              <IonCol size='12' className={styles.otpInputCol}>
                <OtpInput inputStyle="otpInputStyle" value={otp} onChange={otpChange}  numInputs={6} separator={<span> </span>} />
              </IonCol>
              <IonCol size='12'>
                <IonText className={styles.text_grey}>{t('otpVerifyPage.Enter6DigitCode')} </IonText><br></br>
              </IonCol>
            </IonRow>

            {seconds > 0 || minutes > 0 ? (
              <IonText className={styles.text_gr}>
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </IonText>
            ) : (
              <IonText color="primary" onClick={resendOtp}>Resend Code</IonText>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OtpVerify;

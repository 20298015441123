import { IonAvatar, IonCol, IonInfiniteScroll, IonInfiniteScrollContent, IonRow, IonText, useIonViewDidEnter } from '@ionic/react';
import React, { FC, forwardRef, useContext, useImperativeHandle, useState } from 'react';
import styles from './ComponentComments.module.scss';
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { commentAddF } from '../../APIs/graphql/mutations';
import { API } from 'aws-amplify';
import { UserContext } from '../../store/UserContext';
import { AppContext } from '../../store/AppContext';
import { useLazyLoading } from '../../customHooks/useLazyLoading';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ErrMessages, MODULE_TYPE_LIKE } from '../../shared/errorMsg';
import { commentsByFeedIdAndCreatedAtCustom, commentsByPostIdAndCreatedAtCustom, commentsByProductIdAndCreatedAtCustom } from '../../APIs/graphql/postCustom';


interface ComponentCommentsProps {
  productId?: String
  comment?: String
  postId?: String
  limit?: Number
  ref?: any,
  emitCommentAdded?: ()=> void
}

const ComponentComments: FC<ComponentCommentsProps>  = forwardRef((props, ref:any) => {
  const [ initialKey, setInitialKey ] = useState(props.productId + '_Comment');
  const { t } = useTranslation();
  const LIMIT = props.limit || 20;
  const { dataListObj, loadMoreProduct, setDataListObj } = useLazyLoading(getCommentList, initialKey )
  const { user, setIsLoading } = useContext(UserContext);
  const { s3Url, presentToastError, onImageError } = useContext(AppContext);
  const type = new URLSearchParams(useLocation().search).get('type')
  const ownerId = new URLSearchParams(useLocation().search).get('ownerId')

  useImperativeHandle(ref, () => ({
    childFunction: addComment
  }));

  async function getCommentList(nextToken?: String) {
    try {
      const params:GraphQLOptions = {
        query: commentsByProductIdAndCreatedAtCustom,
        variables: {
          productId: props.productId,
          limit: LIMIT,
          sortDirection: 'DESC',
          nextToken: nextToken
        }
      }

      if (type == MODULE_TYPE_LIKE.FEED) {
        params.query = commentsByFeedIdAndCreatedAtCustom
        params.variables = {
          feedId: props.productId,
          limit: LIMIT,
          sortDirection: 'DESC',
          nextToken: nextToken
        }
      }
      else if (type == MODULE_TYPE_LIKE.POST || props.postId) {
        params.query = commentsByPostIdAndCreatedAtCustom;
        params.variables = {
          postId: props.postId || props.productId,
          limit: LIMIT,
          sortDirection: 'DESC',
          nextToken: nextToken
        }
      }

      const resp:any = await API.graphql(params);
      const data = resp.data?.commentsByProductIdAndCreatedAt || resp.data?.commentsByFeedIdAndCreatedAt || resp.data?.commentsByPostIdAndCreatedAt;
      return { items: data.items, nextToken: data.nextToken, key: initialKey }
    }
    catch (error) {
      console.log(error)
    }
  }
  /**
   * addComment
   */
  async function addComment() {
    setIsLoading(true)
    try {
      const params:GraphQLOptions = {
        query: commentAddF,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: {}
      }

      const input:any = {
        comment: props.comment,
        productId: props.productId,
        feedId: null,
        ownerId: ownerId
      }

      if (type == MODULE_TYPE_LIKE.FEED) {
        input.productId = null
        input.feedId = props.productId
      }
      else if (type == MODULE_TYPE_LIKE.POST) {
        input.productId = null
        input.postId = props.productId
      }

      params.variables = { input: input }

      const apiResp:any = await API.graphql(params);
      if (apiResp.data.commentAddF.resp_status) {
        const commentData = JSON.parse(apiResp.data.commentAddF.resp_data);
        commentData.user = {
          id: user.username,
          name: user.attributes.name,
          picture: user.attributes['custom:image']
        }
        let temp = { ...dataListObj };
        temp[initialKey].items.unshift(commentData);
        setDataListObj(temp)
        if (props.emitCommentAdded) {
          props.emitCommentAdded()
        }
      }
      else {
        presentToastError(ErrMessages.ErrGen)
      }
    }
    catch (error:any) {
      console.log('Exception in addComment()', error);
      presentToastError(error.message)
    }
    finally {
      setIsLoading(false)
    }
  }
  return (
    <div className={styles.ComponentComments} data-testid="ComponentComments">
      <IonRow>
        {dataListObj[initialKey] && dataListObj[initialKey].items.map((commentData: any, index:number) =>
          <IonCol size='12' className={styles.listCol} key={index + 'COM'}>
            <section className={styles.commentDetail}>
              <IonAvatar>
                <img alt='profile' src={ commentData?.user?.picture ? s3Url + '/' + commentData?.user?.picture : 'assets/svg/avatar.svg' } onError={(e)=> onImageError(e, 'profile')} />
              </IonAvatar>
              <IonText className={styles.comment}>
                <h6>{commentData?.user?.name}</h6>
                {commentData.comment}
              </IonText>
            </section>
            {/* <section className={styles.commentSocialIcons}>
              <div className={styles.socailIconSub}>
                <IonIcon color='primary' icon={thumbsUp}></IonIcon>
                <IonText className={styles.socialCountSpan}>
                  <span>15</span>
                </IonText>
              </div>
              <div className={styles.socailIconSub}>
                <IonIcon icon={thumbsDown}></IonIcon>
                <IonText className={styles.socialCountSpan}>
                  <span>15</span>
                </IonText>
              </div>
              <div className={styles.socailIconSub}>
                <IonIcon icon={chatbox} ></IonIcon>
                <IonText className={styles.socialCountSpan}>
                  <span>15</span>
                </IonText>
              </div>
            </section> */}
          </IonCol>

        )}
        {
          !dataListObj?.[initialKey]?.isAllLoaded && !props.limit ?
            <IonInfiniteScroll onIonInfinite={(ev) => loadMoreProduct(ev)}>
              <IonInfiniteScrollContent loadingSpinner="bubbles" ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
            : ''
        }
        {
          dataListObj?.[initialKey]?.isAllLoaded && !dataListObj?.[initialKey].items.length ? <h5 className={styles.noComment}>No Comments Found</h5> : ''
        }
      </IonRow>
    </div>
  );
});

export default ComponentComments;

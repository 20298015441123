import { FC, useRef } from 'react';
import styles from './ComponentFeedFooter.module.scss';
import { IonIcon, IonLabel, IonModal, IonTabBar, IonTabButton } from '@ionic/react';
import { addCircle, card, chatbubble, grid, home, notifications, storefront } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import ComponentSelectProductType from '../ComponentSelectProductType/ComponentSelectProductType';
import { useHistory } from 'react-router';
import { ProductType } from '../../APIs/API';


interface ComponentFeedFooterProps {
  selectedTab?:string
}

const ComponentFeedFooter: FC<ComponentFeedFooterProps> = (prop:any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const modal = useRef<HTMLIonModalElement>(null);

  /**
   * goToAddProduct
   */
  function goToAddProduct() {
    if (prop.selectedTab == 'all' || prop.selectedTab == 'home' || !prop.selectedTab) {
      return modal.current?.present()
    }

    if (prop.selectedTab == 'campaign') {
      history.push('/campaign/add');
    }
    else if (prop.selectedTab == ProductType.Request || prop.selectedTab == ProductType.Donation) {
      history.push(`/addProduct/${prop.selectedTab}`);
    }
    else {
      return modal.current?.present()
    }
  }
  return (
    <div className={styles.ComponentFeedFooter} data-testid="ComponentFeedFooter">
      <IonTabBar selectedTab='feed'>
        <IonTabButton tab="home" href="/">
          <IonIcon icon={home} />
          <IonLabel>{t('PageFeed.home')}</IonLabel>
        </IonTabButton>

        {/* <IonTabButton tab="campaign" href="/campaign">
          <IonIcon icon={grid} />
          <IonLabel>{t('PageFeed.campaigns')}</IonLabel>
        </IonTabButton> */}

        {/* <IonTabButton tab="feed" href="/feed">
          <IonIcon icon={grid} />
          <IonLabel>{t('PageFeed.title')}</IonLabel>
        </IonTabButton> */}

        <IonTabButton tab="post" href="/posts">
          <IonIcon icon={grid} />
          <IonLabel>{t('PageFeed.post')}</IonLabel>
        </IonTabButton>

        <IonTabButton tab="addProduct" className={styles.addBtn} onClick={goToAddProduct}>
          <IonIcon icon={addCircle} />
          <IonLabel>{t('PageFeed.add')}</IonLabel>
        </IonTabButton>

        <IonTabButton tab="chat" href="/chat">
          <IonIcon icon={chatbubble} />
          <IonLabel>{t('PageFeed.chat')}</IonLabel>
        </IonTabButton>

        <IonTabButton tab="estore" href="/estore">
          <IonIcon icon={storefront} />
          <IonLabel>{t('PageFeed.store')}</IonLabel>
        </IonTabButton>

        {/* <IonTabButton tab="notification" href="/notification">
          <IonIcon icon={notifications} />
          <IonLabel>{t('PageFeed.notification')}</IonLabel>
        </IonTabButton> */}
      </IonTabBar>
      <IonModal ref={modal} backdropDismiss={true} className="selectProductModal">
        <ComponentSelectProductType closeModal={(e:any)=> modal.current?.dismiss()}></ComponentSelectProductType>
      </IonModal>
    </div>
  );
};

export default ComponentFeedFooter;

import { IonAvatar, IonCol, IonFooter, IonHeader, IonIcon, IonImg, IonPage, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonText, IonToolbar } from '@ionic/react';
import { heartOutline, notifications } from 'ionicons/icons';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ComponentFeed from '../../components/ComponentFeed/ComponentFeed';
import { AllProductTypes } from '../../shared/errorMsg';
import { AppContext } from '../../store/AppContext';
import { UserContext } from '../../store/UserContext';
import styles from './PageFeed.module.scss';
import ComponentFeedFooter from '../ComponentFeedFooter/ComponentFeedFooter';
import { useHistory } from 'react-router';

interface PageFeedProps {}

const PageFeed: FC<PageFeedProps> = (props:any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user, verifyUserAttribute, setIsLoading } = useContext(UserContext);
  const { presentToastError, s3Url, onImageError } = useContext(AppContext);

  const searchBarPlaceholder = t('PageFeed.headerSerachbarPlaceHolder');
  const [ selectedTab, setSelectedTab ] = useState('');
  const [ searchText, setSearchText ] = useState('' as any);

  useEffect(()=>{
    if (props.location.hash) {
      const et = props.location.hash.replace('#', '');
      setSelectedTab(et);
    }
    else {
      setSelectedTab('all');
    }
  }, [ props.location.hash ]);

  /**
   * segmentChange
   */
  function segmentChange(event:any) {
    setSelectedTab(event.detail.value);
    setSearchText('');
    history.push(`/feed#${event.detail.value}`);
  }

  /**
   * searchChange
   * @param event
   */
  function searchChange(event:any) {
    if (event.key == 'Enter') {
      setSearchText(event.target.value)
    }
  }

  /**
   * verifyMobNumber
   * redirect to mobile verification page
   */
  async function verifyMobNumber() {
    setIsLoading(true);
    try {
      await verifyUserAttribute('phone_number');
      history.push(`/otp/verify/phone/${user.attributes.phone_number}`);
    }
    catch (error:any) {
      presentToastError(error.message);
      console.error('Exception in verifyMobNumber()', error);
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <IonPage>
      <IonHeader className={styles.feedHeader}>
        <IonToolbar>
          <IonRow className={styles.logoTitleRow}>
            <IonCol size='6'>
              <IonImg src='/assets/logo/logo2.png' className={styles.logoImg}></IonImg>
              <IonText className={styles.title}>
                <h6>{t('PageFeed.title')}</h6>
              </IonText>
            </IonCol>
            <IonCol size='6'>
              <div className={styles.pullRight}>
                <IonIcon icon={heartOutline}></IonIcon>
                <IonIcon icon={notifications} className={styles.notificationIcon} onClick={()=> history.push('/notification')}/>
                {
                  <IonAvatar onClick={(e)=> history.push('profile')}>
                    <img src={user?.attributes['custom:image'] ? s3Url + '/' + user?.attributes['custom:image'] : 'assets/svg/avatar.svg' } onError={(e:any)=> onImageError(e, 'profile')} />
                  </IonAvatar>
                }
              </div>
            </IonCol>
            <IonRow>
              {
                user && user.attributes && !user.attributes.phone_number_verified ?
                  <IonCol size='12' className={styles.verifyBar}>
                    <div className={styles.textDiv}>
                      Mobile number is not verified <span onClick={verifyMobNumber}>Click here to verify</span>
                    </div>
                  </IonCol>
                  :
                  ''
              }
            </IonRow>
            <IonRow className={styles.seachBarFilterRow}>
              <IonCol size='11' className={styles.searchBarCol}>
                <IonSearchbar className={styles.searchBar} placeholder={searchBarPlaceholder} onKeyUp={searchChange} onIonCancel={(e)=> setSearchText('')}></IonSearchbar>
              </IonCol>
              <IonCol size='1' className={styles.filterBtn}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="22" height="22">
                  <path d="M2 7h.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2H9.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2zm4-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm24 11h-.142a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h20.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-4 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm4 7H19.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h10.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-14 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"/>
                </svg>
              </IonCol>
            </IonRow>
            <IonRow className={styles.segmentRow}>
              <IonSegment value={selectedTab} onIonChange={segmentChange} className={styles.headerSegment} scrollable={true}>
                <IonSegmentButton value='all'>
                  {t('PageFeed.all')}
                </IonSegmentButton>
                <IonSegmentButton value={AllProductTypes.Request}>
                  {t('PageFeed.requests')}
                </IonSegmentButton>
                <IonSegmentButton value={AllProductTypes.Donation}>
                  {t('PageFeed.donations')}
                </IonSegmentButton>
                <IonSegmentButton value={AllProductTypes.Service}>
                  {t('PageFeed.homeServices')}
                </IonSegmentButton>
                <IonSegmentButton value={AllProductTypes.Design}>
                  {t('PageFeed.homeDesigns')}
                </IonSegmentButton>
              </IonSegment>
            </IonRow>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <div className={styles.PageFeed} data-testid="PageFeed">
        {
          selectedTab && selectedTab.length ? <ComponentFeed propSelectedTab={selectedTab} searchTerm={searchText}></ComponentFeed> : ''
        }
      </div>
      <IonFooter>
        <ComponentFeedFooter selectedTab={selectedTab}></ComponentFeedFooter>
      </IonFooter>
    </IonPage>
  );
};

export default PageFeed;

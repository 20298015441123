import React, { FC, useContext, useRef } from 'react';
import styles from './ComponentFilePreview.module.scss';
import { IonButton, IonContent, IonFooter } from '@ionic/react';
import { AppContext } from '../../store/AppContext';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Pagination, Zoom } from 'swiper';

interface fileType {
  id: string,
  key: string,
  file_type: string
}

interface ComponentFilePreviewProps {
  files? : any[],
  activeIndex?: number,
  path?: string
  closeModal?: ()=> any
}

const ComponentFilePreview: FC<ComponentFilePreviewProps> = (props) => {
  const sliderRef = useRef<any>(null);
  const { s3BucketUrl } = useContext(AppContext);
  return (
    <>
      <IonContent className={styles.ComponentFilePreview} data-testid="ComponentFilePreview">
        <Swiper className={styles.sliderClass} ref={sliderRef} id="slider" initialSlide={props.activeIndex} pagination= { true } zoom= { true } modules={[ Pagination, Zoom ]}>
          {
            props.files && props.files?.map((file:fileType, index: number)=>
              <SwiperSlide className={styles.swiperSlide} key={index + 'media'}>
                {
                  file.file_type.includes('image') ? <div className="swiper-zoom-container"><img src={s3BucketUrl + `/${props.path}/` + encodeURIComponent(file.key)} alt="Image" /></div>
                    : file.file_type.includes('video') ?
                      <div className="swiper-zoom-container">
                        <video className="imgClass" controls preload="metadata" webkit-playsinline="webkit-playsinline" style={{ width: '100%' }}>
                          <source src={s3BucketUrl + `/${props.path}/` + file.key + '#t=0.2'} type="video/mp4" />
                        </video>
                      </div>
                      :
                      <div className={styles.swiperZoomContainer}>
                        <audio id="aud" controls preload="metadata">
                          <source src={s3BucketUrl + `/${props.path}/` + file.key} type="audio/mpeg" />
                        </audio>
                      </div>
                }
                {/* <div className={styles.swiperZoomContainer} dangerouslySetInnerHTML={{ __html: s3Url + file.key }}></div> */}
              </SwiperSlide>
            )
          }
        </Swiper>
      </IonContent>
      <IonFooter className={styles.ComponentFilePreviewFooter} onClick={props.closeModal}>
        <IonButton>Close</IonButton>
      </IonFooter>
    </>
  )
}

export default ComponentFilePreview;
import React, { lazy, Suspense } from 'react';

const LazyPagePropertyListing = lazy(() => import('./PagePropertyListing'));

const PagePropertyListing = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPagePropertyListing {...props} />
  </Suspense>
);

export default PagePropertyListing;

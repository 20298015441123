import React, { lazy, Suspense } from 'react';

const LazyPageRequestDonationAdd = lazy(() => import('./PageRequestDonationAdd'));

const PageRequestDonationAdd = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageRequestDonationAdd {...props} />
  </Suspense>
);

export default PageRequestDonationAdd;

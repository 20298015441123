import React, { lazy, Suspense } from 'react';

const LazyPagePrivacyPolicy = lazy(() => import('./PagePrivacyPolicy'));

const PagePrivacyPolicy = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPagePrivacyPolicy {...props} />
  </Suspense>
);

export default PagePrivacyPolicy;

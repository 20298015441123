import React, { ComponentType, useContext, useEffect, useRef, useState } from 'react';
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { UserContext } from '../../store/UserContext';
import { AppContext } from '../../store/AppContext';
import { ErrMessages, FILE_PATH } from '../../shared/errorMsg';
import { getProductVariant, getProductVariantAfterAuth } from '../../APIs/graphql/customQueries';
import { API } from 'aws-amplify';
import { getAverageRating } from '../../shared/UtilityContext';
import { useParams } from 'react-router';
import { User, ViewProductType } from '../../APIs/API';
import { useTranslation } from 'react-i18next';
import useVideoPlayer from '../../customHooks/useVideoPlayer';
import ComponentExploreDetail from '../ComponentExploreDetail/ComponentExploreDetail';
import { getPostAfterAuthCustom, getPostBeforeAuthCustom } from '../../APIs/graphql/postCustom';
import { IonAvatar, IonButton, IonCol, IonContent, IonFabButton, IonGrid, IonIcon, IonLabel, IonPage, IonRow, IonSkeletonText, IonText, IonToolbar } from '@ionic/react';
import styles from './PageExploreDetailHoc.module.scss';
import { bookmarkOutline, chatbubbleOutline, chevronBack, code, heartOutline, locationOutline, shareSocial } from 'ionicons/icons';

interface WithDetailData {
  name: string;
  description: string;
}
interface NAVPARAMS {
  id: string
  user: User
}

function withDetailData<T extends WithDetailData>(WrappedComponent: ComponentType<{ data: T } & NAVPARAMS>, fetchData: (id: string, user: User) => Promise<T>) {
  return function WithDetailComponent() {
    const [ data, setData ] = useState<T | null>(null);
    const navParams = useParams() as { id: string };
    const { t } = useTranslation();
    const { presentToastError, setIsLoading } = useContext(AppContext);
    const { user } = useContext(UserContext);

    useEffect(() => {
      const fetchDataAndSetState = async () => {
        setIsLoading(false);
        try {
          const result = await fetchData(navParams.id, user);
          setData(result);
        }
        catch (error) {
          presentToastError(ErrMessages.ErrGen);
          console.error('Error fetching data:', error);
        }
        finally {
          setIsLoading(false);
        }
      };

      fetchDataAndSetState();
    }, [ navParams.id, fetchData ]);

    /**
    * productContentView
    */
    function productSkeltonView() {
      return (
        <IonPage>
          <IonContent className={styles.PageExploreDetailHoc}>
            <div className={styles.feeds} id='feedContainerId'>
              <div className={styles.feedCard} id='feedCardDiv'>
                <div className={styles.PageContentDetailHeaderDiv}>
                  <IonToolbar>
                    <div className={styles.headerDiv}>
                    </div>

                    <IonFabButton className={styles.left + ' transparentButton'}>
                      <IonIcon icon={chevronBack}></IonIcon>
                    </IonFabButton>

                    <IonFabButton className={styles.right + ' transparentButton'} style={{ right: 89 + 'px' }}>
                      <IonIcon icon={heartOutline}></IonIcon>
                    </IonFabButton>

                    <IonFabButton className={styles.right + ' transparentButton'} style={{ right: 51 + 'px' }}>
                      <IonIcon icon={bookmarkOutline}></IonIcon>
                    </IonFabButton>

                    <IonFabButton className={styles.right + ' transparentButton'}>
                      <IonIcon icon={shareSocial}></IonIcon>
                    </IonFabButton>
                  </IonToolbar>
                </div>
                <>
                  <IonRow className={styles.headerRow}>
                    <IonSkeletonText animated style={{ width: '100%', height: '200px' }} />
                  </IonRow>

                  <IonGrid>
                    <IonRow className={styles.productDetailRow}>
                      {/* Show Categoroes in case of request and donation or title if design or service */}
                      <IonCol size="12" className={styles.productCatCol}>
                        <IonLabel className={styles.locationLabel}>
                          <IonIcon icon={locationOutline}></IonIcon>
                          <IonSkeletonText animated></IonSkeletonText>
                        </IonLabel>

                        <h6 className={styles.productCatTitle} key={'sd'}>
                          <IonSkeletonText animated />
                        </h6>
                      </IonCol>

                      <IonCol size='12' className={styles.descriptionCol}>
                        <IonSkeletonText animated />
                      </IonCol>
                      <IonCol size='12' className={styles.descriptionCol}>
                        <IonSkeletonText animated />
                      </IonCol>
                      <IonCol size='12' className={styles.descriptionCol}>
                        <IonSkeletonText animated />
                      </IonCol>
                      <IonCol size='12' className={styles.descriptionCol}>
                        <IonSkeletonText animated />
                      </IonCol>
                      <IonCol size='12' className={styles.descriptionCol}>
                        <IonSkeletonText animated />
                      </IonCol>

                      <IonCol size='12' className={styles.submitColBtn}>
                        <IonButton expand='block' className='primaryDarkBtn'>
                          <IonSkeletonText animated/>
                        </IonButton>
                      </IonCol>
                    </IonRow>

                    { /* Service Layout */}
                    <IonRow className={styles.ownerRow}>
                      <IonCol size='2' sizeMd='1' className={styles.imgCol}>
                        <IonAvatar className={styles.designerImg}>
                          <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
                        </IonAvatar>
                      </IonCol>
                      <IonCol size='8' sizeMd='9' className={styles.nameCol}>
                        <IonText className={styles.userDetailTex}>
                          <span className={styles.companyName}>
                            <IonSkeletonText animated />
                          </span>
                          <span className={styles.companyEmail}>
                            <IonSkeletonText animated />
                          </span>
                        </IonText>
                      </IonCol>
                      <IonCol size='2' className={styles.chatCol}>
                        <IonIcon icon={chatbubbleOutline}></IonIcon>
                      </IonCol>
                    </IonRow>

                    <IonRow className={styles.commentListRow}>
                      <IonCol size='12' className={styles.headingCol}>
                        <h5 className={styles.headingComment}>
                          Comments
                          <IonIcon icon={code}></IonIcon>
                        </h5>
                      </IonCol>
                      <IonCol size='12'>
                        <IonSkeletonText animated />
                      </IonCol>
                      <IonCol size='12'>
                        <IonSkeletonText animated />
                      </IonCol>
                      <IonCol size='12'>
                        <IonSkeletonText animated />
                      </IonCol>
                      <IonCol size='12'>
                        <IonSkeletonText animated />
                      </IonCol>
                      <IonCol size='12'>
                        <IonSkeletonText animated />
                      </IonCol>
                      <IonCol size='12'>
                        <IonSkeletonText animated />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </>
              </div>
            </div>
          </IonContent>
        </IonPage>
      )
    }
    if (!data) {
      return productSkeltonView()
    }

    return <IonPage>
      {
        data ? <WrappedComponent id={navParams.id} user={user} data={data} /> : productSkeltonView()
      }
    </IonPage>
  };
}


/**
 * productVariantDetail
 * @param productVariantId
 */
async function productVariantDetail(productVariantId: string, user: User) {
  const params:GraphQLOptions = {
    query: user && user.username ?  getProductVariantAfterAuth : getProductVariant,
    variables: {
      id: productVariantId
    },
    authMode: 'API_KEY'
  }
  if (user && user.username) {
    params.authMode = 'AMAZON_COGNITO_USER_POOLS'
    // params.authToken = user.signInUserSession.accessToken.jwtToken
  }

  const productResp: any = await API.graphql(params);
  // productResp.data.getProductVariant.product.categories.items.push(productResp.data.getProductVariant.product.categories.items[0])
  productResp.data.getProductVariant.product.averageRating = getAverageRating(productResp.data.getProductVariant.product.rating)
  return productResp.data.getProductVariant
}

const withProductDetail = withDetailData<{ name: string; description: string }>(
  ({ data, id, user }: { data: any; id: string; user?: User }) => (
    <>
      <ComponentExploreDetail moduleDetails={data} type={data.product.type} file_path={FILE_PATH.PUBLIC}></ComponentExploreDetail>
    </>
  ),
  productVariantDetail
);


/**
* fetchPostDetail
*/
async function fetchPostDetail(postId: string, user: User) {
  const payload: GraphQLOptions = {
    query: user && user.username ? getPostAfterAuthCustom : getPostBeforeAuthCustom,
    variables: { id: postId, userId: { eq: user?.username } },
    authMode:  (user && user.username) ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY'
  }
  const postResp: any = await API.graphql(payload);
  postResp.data.getPost.description = postResp.data.getPost.description.replace(/<a/gm, '<a target="_blank"');
  return postResp.data.getPost
}


const withPostDetail = withDetailData<{ name: string; description: string }>(
  ({ data, id, user }: { data: any; id: string; user?: User }) => (
    <>
      <ComponentExploreDetail moduleDetails={data} type={ViewProductType.Post} file_path={FILE_PATH.POST}></ComponentExploreDetail>
    </>
  ),
  fetchPostDetail
);

export const PostDetailPage = withPostDetail;
export const ContentDetailPage = withProductDetail;

// Similarly, you can create withPostDetail in the same way

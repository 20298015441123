import React, { lazy, Suspense } from 'react';

const LazyPageReviewAdd = lazy(() => import('./PageReviewAdd'));

const PageReviewAdd = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageReviewAdd {...props} />
  </Suspense>
);

export default PageReviewAdd;

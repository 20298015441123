import React, { lazy, Suspense } from 'react';

const LazyPageEstoreProductDetail = lazy(() => import('./PageEstoreProductDetail'));

const PageEstoreProductDetail = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageEstoreProductDetail {...props} />
  </Suspense>
);

export default PageEstoreProductDetail;

import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './ComponentYoutubeList.module.scss';
import axios from 'axios';
import { IonCard, IonCol, IonFabButton, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonModal, IonRow } from '@ionic/react';
import { useLazyLoading } from '../../customHooks/useLazyLoading';
import { play } from 'ionicons/icons';
import ComponentYoutubePlayer from '../ComponentYoutubePlayer/ComponentYoutubePlayer';
import ComponentHeader from '../ComponentHeader/ComponentHeader';
import useInfiniteScroll from '../../customHooks/useInfiniteScroll';
const API_KEY = 'AIzaSyCkOU2NT5HvUScXNEWK9uAD3EcnJXe-Hg0';
const API_URL = 'https://www.googleapis.com/youtube/v3/search';

interface ComponentYoutubeListProps {
  query: string
  limit: number
}

const ComponentYoutubeList: FC<ComponentYoutubeListProps> = (props) => {
  const INITIAL_KEY = props.query;
  const { dataListObj, loadMoreProduct } = useLazyLoading(searchYoutubeVideo, INITIAL_KEY)
  const [ selectedVideo, setSelectedVideo ] = useState({ title: '', videoId: '' });
  const modal = useRef<HTMLIonModalElement>(null);
  useInfiniteScroll('ComponentYoutubeList', dataListObj, INITIAL_KEY, loadMoreProduct);

  useEffect(()=> {
    // fetchNearbyPlaces();
  }, []);

  const fetchNearbyPlaces = async () => {
    try {
      const response = await axios.get(
        'https://maps.googleapis.com/maps/api/place/nearbysearch/json',
        {
          params: {
            location: {
              latitude: 1.35253,
              longitude: 103.85091
            },
            radius: 1000, // in meters (adjust as needed)
            key: 'AIzaSyCQ2xumPEtQcPlAe1Xsv1YHmL3FhJsyiDE',
            types: 'hospital', // Change this to other types like 'club', 'cinema', etc.
          }
        }
      );
      console.log(response)
    } catch (error) {
      console.error('Error fetching nearby places:', error);
    }
  };
  /**
   * searchYoutubeVideo
   */
  async function searchYoutubeVideo(nextToken?: any) {
    try {
      const params = {
        q: props.query,
        part: 'snippet',
        type: 'video',
        key: API_KEY,
        maxResults: 10, // Adjust as needed
        pageToken: nextToken,
      };
      if (!params.pageToken) {
        delete params.pageToken
      }

      const response = await axios.get(API_URL, {
        params: params,
      });
      console.log(response);
      return { items: response.data.items, nextToken: response.data.nextPageToken, key: props.query }
      // setYoutubeVideoList([ ...youtubeVideoList, ...response.data.items ])
    }
    catch (error) {
      console.error('Exception in searchYoutubeVideo()', error);
    }
  }
  return (
    <>
      <IonRow className={styles.ComponentYoutubeList} data-testid="ComponentYoutubeList" id='ComponentYoutubeList'>
        <ComponentHeader title='Related youtube videos'></ComponentHeader>
        { dataListObj[props.query]?.items?.map((video:any, index: number) => (
          <IonCol size='6' key={video.id.videoId + index }>
            <IonCard>
              <div className={styles.imageDiv}>
                <img src={video.snippet.thumbnails.high.url} className={styles.feedImg} />
                <IonFabButton onClick={()=> { setSelectedVideo({ title: video.snippet.title, videoId: video.id.videoId }); modal.current?.present(); } }>
                  <IonIcon icon={play}></IonIcon>
                </IonFabButton>
              </div>
              {/* <IonIcon icon={play}></IonIcon> */}
              {/* <iframe frameBorder="0"
                allowFullScreen={true}
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                title={video.snippet.title}
                width="100%"
                height="390"
                className='youtubeVideo'
                src={`https://www.youtube.com/embed/${video.id.videoId}?autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fc77o1v.csb.app&amp;widgetid=3`} id="widget4">
              </iframe> */}
              {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/Fm8h3cFLmdw?si=QjtBqC8ZjLs3JafE&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
              <div className={styles.cardBody}>
                <h4 className={styles.title + ' twoLinesOnly'} dangerouslySetInnerHTML={{ __html: video.snippet.title }}></h4>
              </div>
            </IonCard>
          </IonCol>
        ))
        }
        <IonModal ref={modal}>
          <ComponentYoutubePlayer title={selectedVideo.title} videoId={selectedVideo.videoId} onClose={()=> modal.current?.dismiss()}></ComponentYoutubePlayer>
        </IonModal>
      </IonRow>
    </>
  );
}

export default ComponentYoutubeList;

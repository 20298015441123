/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type searchProductInput = {
  type: searchProductType,
  searchTerm: Array< string | null >,
  limit: number,
  LastEvaluatedKey?: string | null,
};

export enum searchProductType {
  Feed = "Feed",
  Service = "Service",
  Design = "Design",
  Request = "Request",
  Donation = "Donation",
  Store = "Store",
}


export type PublicResp = {
  __typename: "PublicResp",
  resp_status?: boolean | null,
  resp_data?: string | null,
  resp_code?: string | null,
  resp_message?: string | null,
};

export type openAiSerchInput = {
  searchText?: string | null,
  type: ProductTypeSearch,
  categoryIds?: Array< string | null > | null,
  limit: number,
  skip: number,
  minMatch?: number | null,
  sortCriteria?: string | null,
  filterJSON?: string | null,
};

export enum ProductTypeSearch {
  Service = "Service",
  Design = "Design",
  Request = "Request",
  Donation = "Donation",
  Store = "Store",
  Feed = "Feed",
  RequestDonation = "RequestDonation",
  DIY = "DIY",
  Article = "Article",
  Renovation = "Renovation",
  Property = "Property",
}


export type updateShareCountInput = {
  productId?: string | null,
  feedId?: string | null,
  postId?: string | null,
  propertyId?: string | null,
};

export type openAISuggestionGeneratorInput = {
  type: ProductTypeSearch,
  query: string,
};

export type giveNextRelatedProductInput = {
  productVariantId?: string | null,
  postId?: string | null,
  propertyId?: string | null,
  accuracyPer?: string | null,
  existingProductVariantIds?: Array< string | null > | null,
};

export type DeleteUserInput = {
  id: string,
};

export type ModelUserConditionInput = {
  username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  mobile_no?: ModelStringInput | null,
  name?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  country?: ModelStringInput | null,
  mobile_verified?: ModelBooleanInput | null,
  bio?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  followers?: ModelIntInput | null,
  followings?: ModelIntInput | null,
  online?: ModelBooleanInput | null,
  fcmToken?: ModelStringInput | null,
  merchantFcmToken?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  id: string,
  username: string,
  email: string,
  mobile_no?: string | null,
  name?: string | null,
  gender?: string | null,
  country: string,
  mobile_verified?: boolean | null,
  bio?: string | null,
  picture?: string | null,
  userAddressList?: ModelUserAddressConnection | null,
  companyUser?: ModelCompanyUserConnection | null,
  companyReviews?: ModelCompanyReviewConnection | null,
  userProductList?: ModelProductConnection | null,
  productReview?: ModelProductReviewConnection | null,
  bookings?: ModelBookingConnection | null,
  estoreOrderList?: ModelEstoreOrdersConnection | null,
  followers?: number | null,
  followings?: number | null,
  followersList?: ModelFollowConnection | null,
  online?: boolean | null,
  fcmToken?: string | null,
  merchantFcmToken?: string | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type ModelUserAddressConnection = {
  __typename: "ModelUserAddressConnection",
  items:  Array<UserAddress | null >,
  nextToken?: string | null,
};

export type UserAddress = {
  __typename: "UserAddress",
  id: string,
  name: string,
  email?: string | null,
  mobile_no: string,
  address_one: string,
  address_two?: string | null,
  pincode?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  default?: number | null,
  userId: string,
  user?: User | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type ModelCompanyUserConnection = {
  __typename: "ModelCompanyUserConnection",
  items:  Array<CompanyUser | null >,
  nextToken?: string | null,
};

export type CompanyUser = {
  __typename: "CompanyUser",
  id: string,
  role: Role,
  userId: string,
  user?: User | null,
  companyId: string,
  company?: Company | null,
  modules?:  Array<ModuleList | null > | null,
  status?: CompanyUserStatus | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum Role {
  Admin = "Admin",
  Owner = "Owner",
  User = "User",
}


export type Company = {
  __typename: "Company",
  id: string,
  ownerType?: OwnerType | null,
  uen?: string | null,
  gstNo?: string | null,
  type?: Array< ComapnyType | null > | null,
  name?: string | null,
  email?: string | null,
  registration_name?: string | null,
  picture?: string | null,
  bio?: string | null,
  wlcmMsg?: string | null,
  address?: string | null,
  category?: string | null,
  status?: CompanyStatus | null,
  companyUserList?: ModelCompanyUserConnection | null,
  companyReviews?: ModelCompanyReviewConnection | null,
  productList?: ModelProductConnection | null,
  bookings?: ModelBookingConnection | null,
  chats?: ModelChatConnection | null,
  stripeAccountId?: string | null,
  followers?: number | null,
  followersList?: ModelFollowConnection | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export enum OwnerType {
  Individual = "Individual",
  Company = "Company",
}


export enum ComapnyType {
  Service = "Service",
  Design = "Design",
  Store = "Store",
  Property = "Property",
}


export enum CompanyStatus {
  Draft = "Draft",
  Pending = "Pending",
  Active = "Active",
  Inactive = "Inactive",
}


export type ModelCompanyReviewConnection = {
  __typename: "ModelCompanyReviewConnection",
  items:  Array<CompanyReview | null >,
  nextToken?: string | null,
};

export type CompanyReview = {
  __typename: "CompanyReview",
  id: string,
  stars?: number | null,
  comment?: string | null,
  userId: string,
  user?: User | null,
  companyId: string,
  company?: Company | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type Product = {
  __typename: "Product",
  id: string,
  title: string,
  description: string,
  type: ProductType,
  filterType: string,
  attributes?:  Array<ProductAttribute | null > | null,
  allowToComment?: number | null,
  feature?: number | null,
  brand?: string | null,
  total_sold?: number | null,
  rating?: RatingType | null,
  likeCount?: number | null,
  viewCount?: number | null,
  commentCount?: number | null,
  shareCount?: number | null,
  bookmarkCount?: number | null,
  totalReviewCount?: number | null,
  orderCount?: number | null,
  productVariants?: ModelProductVariantConnection | null,
  userId: string,
  user?: User | null,
  companyId?: string | null,
  company?: Company | null,
  categories?: ModelProductCategoriesConnection | null,
  productReviews?: ModelProductReviewConnection | null,
  metaKeywords?: string | null,
  sku?: string | null,
  likes?: ModelLikeConnection | null,
  bookmarks?: ModelProductBookmarksConnection | null,
  status?: Status | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export enum ProductType {
  Service = "Service",
  Design = "Design",
  Request = "Request",
  Donation = "Donation",
  Store = "Store",
}


export type ProductAttribute = {
  __typename: "ProductAttribute",
  name: string,
  options?: Array< string | null > | null,
};

export type RatingType = {
  __typename: "RatingType",
  one?: number | null,
  two?: number | null,
  three?: number | null,
  four?: number | null,
  five?: number | null,
};

export type ModelProductVariantConnection = {
  __typename: "ModelProductVariantConnection",
  items:  Array<ProductVariant | null >,
  nextToken?: string | null,
};

export type ProductVariant = {
  __typename: "ProductVariant",
  id: string,
  productId: string,
  product?: Product | null,
  title: string,
  description: string,
  attribute?: string | null,
  price?: number | null,
  list_price?: number | null,
  quantity?: number | null,
  deliveryMethod?: string | null,
  productCondition?: string | null,
  productUsed?: ProductUsedType | null,
  designStyle?: string | null,
  designPropertyType?: string | null,
  orderCount?: number | null,
  videoLink?: string | null,
  add_ons?:  Array<ProductAddOns | null > | null,
  location?: Location | null,
  status?: Status | null,
  productMediaList?: ModelProductMediaConnection | null,
  sku?: string | null,
  defaultPosition?: boolean | null,
  createdAt?: string | null,
  updatedAt: string,
  productProductVariantsId?: string | null,
  owner?: string | null,
};

export type ProductUsedType = {
  __typename: "ProductUsedType",
  day?: number | null,
  month?: number | null,
  year?: number | null,
};

export type ProductAddOns = {
  __typename: "ProductAddOns",
  id: string,
  title: string,
  description: string,
  imageKey?: string | null,
  price?: number | null,
  list_price?: number | null,
};

export type Location = {
  __typename: "Location",
  lat: number,
  lng: number,
  address?: string | null,
  pincode?: string | null,
};

export enum Status {
  Active = "Active",
  Inactive = "Inactive",
}


export type ModelProductMediaConnection = {
  __typename: "ModelProductMediaConnection",
  items:  Array<ProductMedia | null >,
  nextToken?: string | null,
};

export type ProductMedia = {
  __typename: "ProductMedia",
  id: string,
  file_type: string,
  key: string,
  product_variant_id: string,
  productVariant?: ProductVariant | null,
  position?: number | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type ModelProductCategoriesConnection = {
  __typename: "ModelProductCategoriesConnection",
  items:  Array<ProductCategories | null >,
  nextToken?: string | null,
};

export type ProductCategories = {
  __typename: "ProductCategories",
  id: string,
  categoryId: string,
  category?: Category | null,
  productId: string,
  product?: Product | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  name: string,
  type: ProductCategoryType,
  description?: string | null,
  picture?: string | null,
  parent_categoryId?: string | null,
  subCategories?: ModelCategoryConnection | null,
  productCategories?: ModelProductCategoriesConnection | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export enum ProductCategoryType {
  Service = "Service",
  Design = "Design",
  Request = "Request",
  Donation = "Donation",
  Store = "Store",
}


export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type ModelProductReviewConnection = {
  __typename: "ModelProductReviewConnection",
  items:  Array<ProductReview | null >,
  nextToken?: string | null,
};

export type ProductReview = {
  __typename: "ProductReview",
  id: string,
  stars: number,
  comment?: string | null,
  attribute?: string | null,
  userId: string,
  user?: User | null,
  productId: string,
  product?: Product | null,
  totalImagesCount?: number | null,
  estoreOrderProductId?: string | null,
  estoreOrderProduct?: EstoreOrderProduct | null,
  bookingId?: string | null,
  booking?: Booking | null,
  reviewMediaList?: ModelReviewMediaConnection | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type EstoreOrderProduct = {
  __typename: "EstoreOrderProduct",
  id: string,
  title: string,
  description: string,
  price: number,
  list_price: number,
  quantity: number,
  invoice?: string | null,
  userId: string,
  user?: User | null,
  companyId: string,
  company?: Company | null,
  productVariantId: string,
  productVariant?: ProductVariant | null,
  productMediaList?: ModelProductMediaConnection | null,
  attribute?: string | null,
  add_ons?: ProductAddOns | null,
  status: OrderStatusType,
  paymentStatus: PaymentStatusType,
  deliveryStatus: DeliverStatusType,
  statusDescription?: string | null,
  statusHistory?: string | null,
  cancellationReason?: string | null,
  estore_order_id?: string | null,
  estoreOrder?: EstoreOrders | null,
  productReviews?: ModelProductReviewConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum OrderStatusType {
  PLACED_PAYMENT_UNVERIFIED = "PLACED_PAYMENT_UNVERIFIED",
  PLACED = "PLACED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
  COMPLETE = "COMPLETE",
  RETURNREQ = "RETURNREQ",
  RETURNACCEPT = "RETURNACCEPT",
  RETURNCANCELL = "RETURNCANCELL",
  RETURNED = "RETURNED",
}


export enum PaymentStatusType {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  CANCELLED = "CANCELLED",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}


export enum DeliverStatusType {
  NOTSTARTEDYET = "NOTSTARTEDYET",
  PROCESSING = "PROCESSING",
  CANCELLED = "CANCELLED",
  SHIPPED = "SHIPPED",
  RETURNED = "RETURNED",
}


export type EstoreOrders = {
  __typename: "EstoreOrders",
  id: string,
  orderId: string,
  transaction_id?: string | null,
  order_price: number,
  discount?: number | null,
  sell_price?: number | null,
  name?: string | null,
  email?: string | null,
  mobile_no: string,
  address_one: string,
  address_two?: string | null,
  pincode?: string | null,
  city: string,
  state?: string | null,
  country: string,
  offerCodeId?: string | null,
  offerCode?: OfferCode | null,
  userId: string,
  user?: User | null,
  delivery_fee?: number | null,
  status: OrderStatusType,
  paymentStatus: PaymentStatusType,
  statusDescription?: string | null,
  estoreOrderProducts?: ModelEstoreOrderProductConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type OfferCode = {
  __typename: "OfferCode",
  id: string,
  code: string,
  description?: string | null,
  discountType: DiscountType,
  discountValue: number,
  maxUsage?: number | null,
  usageCount?: number | null,
  validFrom?: string | null,
  validTo?: string | null,
  minOrderValue?: number | null,
  maxDiscountAmount?: number | null,
  customerRestrictions?: Array< string | null > | null,
  productRestrictions?: Array< string | null > | null,
  status: Status,
  estoreOrders?: ModelEstoreOrdersConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export enum DiscountType {
  PERCENTAGE = "PERCENTAGE",
  FIXED_AMOUNT = "FIXED_AMOUNT",
}


export type ModelEstoreOrdersConnection = {
  __typename: "ModelEstoreOrdersConnection",
  items:  Array<EstoreOrders | null >,
  nextToken?: string | null,
};

export type ModelEstoreOrderProductConnection = {
  __typename: "ModelEstoreOrderProductConnection",
  items:  Array<EstoreOrderProduct | null >,
  nextToken?: string | null,
};

export type Booking = {
  __typename: "Booking",
  id: string,
  type: ProductType,
  quantity?: number | null,
  productVariantId: string,
  productVariant?: ProductVariant | null,
  userId: string,
  user?: User | null,
  fulfillerId: string,
  fulfiller?: User | null,
  companyId?: string | null,
  company?: Company | null,
  productReviews?: ModelProductReviewConnection | null,
  status: BookingStatus,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export enum BookingStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Cancelled = "Cancelled",
  Rejected = "Rejected",
}


export type ModelReviewMediaConnection = {
  __typename: "ModelReviewMediaConnection",
  items:  Array<ReviewMedia | null >,
  nextToken?: string | null,
};

export type ReviewMedia = {
  __typename: "ReviewMedia",
  id: string,
  file_type: string,
  key: string,
  reviewId: string,
  productReview?: ProductReview | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelLikeConnection = {
  __typename: "ModelLikeConnection",
  items:  Array<Like | null >,
  nextToken?: string | null,
};

export type Like = {
  __typename: "Like",
  id: string,
  type?: string | null,
  productId?: string | null,
  product?: Product | null,
  feedId?: string | null,
  feed?: Feed | null,
  propertyId?: string | null,
  property?: Property | null,
  postId?: string | null,
  post?: Post | null,
  likeState?: boolean | null,
  userId: string,
  user: User,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type Feed = {
  __typename: "Feed",
  id: string,
  title: string,
  description: string,
  type: string,
  likeCount?: number | null,
  likes?: ModelLikeConnection | null,
  commentCount?: number | null,
  shareCount?: number | null,
  viewCount?: number | null,
  bookmarkCount?: number | null,
  bookmarks?: ModelProductBookmarksConnection | null,
  tags?: ModelFeedTagsConnection | null,
  collaborations?: ModelFeedCollaborationConnection | null,
  userId: string,
  user: User,
  companyId?: string | null,
  company?: Company | null,
  mediaList?: ModelFeedMediaConnection | null,
  status?: Status | null,
  location?: Location | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type ModelProductBookmarksConnection = {
  __typename: "ModelProductBookmarksConnection",
  items:  Array<ProductBookmarks | null >,
  nextToken?: string | null,
};

export type ProductBookmarks = {
  __typename: "ProductBookmarks",
  id: string,
  type?: string | null,
  productId?: string | null,
  product?: Product | null,
  feedId?: string | null,
  feed?: Feed | null,
  postId?: string | null,
  post?: Post | null,
  propertyId?: string | null,
  property?: Property | null,
  bookmarkState?: boolean | null,
  userId: string,
  user: User,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type Post = {
  __typename: "Post",
  id: string,
  type?: PostType | null,
  userId: string,
  user: User,
  title: string,
  description: string,
  likeCount?: number | null,
  likes?: ModelLikeConnection | null,
  commentCount?: number | null,
  comments?: ModelCommentsConnection | null,
  shareCount?: number | null,
  viewCount?: number | null,
  bookmarkCount?: number | null,
  bookmarks?: ModelProductBookmarksConnection | null,
  status?: Status | null,
  location?: Location | null,
  mediaList?: ModelFeedMediaConnection | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export enum PostType {
  DIY = "DIY",
  Article = "Article",
  Renovation = "Renovation",
}


export type ModelCommentsConnection = {
  __typename: "ModelCommentsConnection",
  items:  Array<Comments | null >,
  nextToken?: string | null,
};

export type Comments = {
  __typename: "Comments",
  id: string,
  comment: string,
  productId?: string | null,
  product?: Product | null,
  feedId?: string | null,
  feed?: Feed | null,
  postId?: string | null,
  post?: Post | null,
  parentCommentId?: string | null,
  commentReplyList?: ModelCommentsConnection | null,
  user: User,
  userId: string,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type ModelFeedMediaConnection = {
  __typename: "ModelFeedMediaConnection",
  items:  Array<FeedMedia | null >,
  nextToken?: string | null,
};

export type FeedMedia = {
  __typename: "FeedMedia",
  id: string,
  file_type: string,
  key: string,
  feedId: string,
  Feed?: Feed | null,
  postId: string,
  post?: Post | null,
  propertyId: string,
  property?: Property | null,
  position?: number | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type Property = {
  __typename: "Property",
  id: string,
  title: string,
  type: PropertyType,
  description: string,
  price: number,
  bed?: number | null,
  bath?: number | null,
  sqft?: number | null,
  psf?: number | null,
  listingId?: number | null,
  category?: string | null,
  detailsJson?:  Array<KeyValue | null > | null,
  userId: string,
  user: User,
  companyId?: string | null,
  company?: Company | null,
  mediaList?: ModelFeedMediaConnection | null,
  bookmarkCount?: number | null,
  bookmarks?: ModelProductBookmarksConnection | null,
  likeCount?: number | null,
  viewCount?: number | null,
  likes?: ModelLikeConnection | null,
  unitFeatures?: Array< string | null > | null,
  facilities?: Array< string | null > | null,
  status?: Status | null,
  location?: Location | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export enum PropertyType {
  Sale = "Sale",
  Rent = "Rent",
  Buy = "Buy",
  Condo = "Condo",
  HDB = "HDB",
}


export type KeyValue = {
  __typename: "KeyValue",
  key?: string | null,
  value?: string | null,
};

export type ModelFeedTagsConnection = {
  __typename: "ModelFeedTagsConnection",
  items:  Array<FeedTags | null >,
  nextToken?: string | null,
};

export type FeedTags = {
  __typename: "FeedTags",
  id: string,
  feedId: string,
  feed?: Feed | null,
  productVariantId: string,
  productVariant?: ProductVariant | null,
  userId?: string | null,
  companyId?: string | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type ModelFeedCollaborationConnection = {
  __typename: "ModelFeedCollaborationConnection",
  items:  Array<FeedCollaboration | null >,
  nextToken?: string | null,
};

export type FeedCollaboration = {
  __typename: "FeedCollaboration",
  id: string,
  type?: string | null,
  feedId: string,
  feed?: Feed | null,
  companyId?: string | null,
  company?: Company | null,
  merchantId?: string | null,
  merchant?: User | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type ModelBookingConnection = {
  __typename: "ModelBookingConnection",
  items:  Array<Booking | null >,
  nextToken?: string | null,
};

export type ModelChatConnection = {
  __typename: "ModelChatConnection",
  items:  Array<Chat | null >,
  nextToken?: string | null,
};

export type Chat = {
  __typename: "Chat",
  id: string,
  name?: string | null,
  group?: boolean | null,
  image?: string | null,
  participants?: ModelChatParticipantsConnection | null,
  chatMessages?: ModelChatMessagesConnection | null,
  companyId?: string | null,
  company?: Company | null,
  createdAt?: string | null,
  chatMessageUnreadCount?: ModelChatMessageUnreadCountConnection | null,
  deletedBy?: string | null,
  updatedAt: string,
};

export type ModelChatParticipantsConnection = {
  __typename: "ModelChatParticipantsConnection",
  items:  Array<ChatParticipants | null >,
  nextToken?: string | null,
};

export type ChatParticipants = {
  __typename: "ChatParticipants",
  id: string,
  chatId: string,
  chat?: Chat | null,
  userId: string,
  user?: User | null,
  isActive?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelChatMessagesConnection = {
  __typename: "ModelChatMessagesConnection",
  items:  Array<ChatMessages | null >,
  nextToken?: string | null,
};

export type ChatMessages = {
  __typename: "ChatMessages",
  id: string,
  message?: string | null,
  attachment?: string | null,
  attachment_type?: string | null,
  bookingId?: string | null,
  propertyId?: string | null,
  chatId: string,
  chat?: Chat | null,
  userId: string,
  User?: User | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type ModelChatMessageUnreadCountConnection = {
  __typename: "ModelChatMessageUnreadCountConnection",
  items:  Array<ChatMessageUnreadCount | null >,
  nextToken?: string | null,
};

export type ChatMessageUnreadCount = {
  __typename: "ChatMessageUnreadCount",
  id: string,
  userId: string,
  chatId: string,
  chat?: Chat | null,
  unreadCount: number,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type ModelFollowConnection = {
  __typename: "ModelFollowConnection",
  items:  Array<Follow | null >,
  nextToken?: string | null,
};

export type Follow = {
  __typename: "Follow",
  id: string,
  userId?: string | null,
  user?: User | null,
  companyId?: string | null,
  company?: Company | null,
  followerId?: string | null,
  follower?: User | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type ModuleList = {
  __typename: "ModuleList",
  moduleName?: string | null,
  add?: boolean | null,
  edit?: boolean | null,
  delete?: boolean | null,
  read?: boolean | null,
};

export enum CompanyUserStatus {
  Invited = "Invited",
  Active = "Active",
  Deactive = "Deactive",
}


export type DeleteCompanyInput = {
  id: string,
};

export type ModelCompanyConditionInput = {
  ownerType?: ModelOwnerTypeInput | null,
  uen?: ModelStringInput | null,
  gstNo?: ModelStringInput | null,
  type?: ModelComapnyTypeListInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  registration_name?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  wlcmMsg?: ModelStringInput | null,
  address?: ModelStringInput | null,
  category?: ModelStringInput | null,
  status?: ModelCompanyStatusInput | null,
  stripeAccountId?: ModelStringInput | null,
  followers?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCompanyConditionInput | null > | null,
  or?: Array< ModelCompanyConditionInput | null > | null,
  not?: ModelCompanyConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelOwnerTypeInput = {
  eq?: OwnerType | null,
  ne?: OwnerType | null,
};

export type ModelComapnyTypeListInput = {
  eq?: Array< ComapnyType | null > | null,
  ne?: Array< ComapnyType | null > | null,
  contains?: ComapnyType | null,
  notContains?: ComapnyType | null,
};

export type ModelCompanyStatusInput = {
  eq?: CompanyStatus | null,
  ne?: CompanyStatus | null,
};

export type DeleteCompanyPrivateInfoInput = {
  id: string,
};

export type ModelCompanyPrivateInfoConditionInput = {
  companyId?: ModelIDInput | null,
  companyDocImage?: ModelStringInput | null,
  contactName?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  contactNo?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  bankCode?: ModelStringInput | null,
  beneficiaryName?: ModelStringInput | null,
  bankAccNo?: ModelStringInput | null,
  bankDocumnet?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCompanyPrivateInfoConditionInput | null > | null,
  or?: Array< ModelCompanyPrivateInfoConditionInput | null > | null,
  not?: ModelCompanyPrivateInfoConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type CompanyPrivateInfo = {
  __typename: "CompanyPrivateInfo",
  id: string,
  companyId: string,
  company?: Company | null,
  companyDocImage?: string | null,
  contactName?: string | null,
  contactEmail?: string | null,
  contactNo?: string | null,
  conatactDocImage?: DocumentImage | null,
  bankName?: string | null,
  bankCode?: string | null,
  beneficiaryName?: string | null,
  bankAccNo?: string | null,
  bankDocumnet?: string | null,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type DocumentImage = {
  __typename: "DocumentImage",
  front?: string | null,
  back?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type ModelCategoryConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelProductCategoryTypeInput | null,
  description?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  parent_categoryId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductCategoryTypeInput = {
  eq?: ProductCategoryType | null,
  ne?: ProductCategoryType | null,
};

export type DeleteCommentsInput = {
  id: string,
};

export type ModelCommentsConditionInput = {
  comment?: ModelStringInput | null,
  productId?: ModelIDInput | null,
  feedId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  parentCommentId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCommentsConditionInput | null > | null,
  or?: Array< ModelCommentsConditionInput | null > | null,
  not?: ModelCommentsConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type CreateUserInput = {
  id?: string | null,
  username: string,
  email: string,
  mobile_no?: string | null,
  name?: string | null,
  gender?: string | null,
  country: string,
  mobile_verified?: boolean | null,
  bio?: string | null,
  picture?: string | null,
  followers?: number | null,
  followings?: number | null,
  online?: boolean | null,
  fcmToken?: string | null,
  merchantFcmToken?: string | null,
  createdAt?: string | null,
};

export type UpdateUserInput = {
  id: string,
  username?: string | null,
  email?: string | null,
  mobile_no?: string | null,
  name?: string | null,
  gender?: string | null,
  country?: string | null,
  mobile_verified?: boolean | null,
  bio?: string | null,
  picture?: string | null,
  followers?: number | null,
  followings?: number | null,
  online?: boolean | null,
  fcmToken?: string | null,
  merchantFcmToken?: string | null,
  createdAt?: string | null,
};

export type CreateUserAddressInput = {
  id?: string | null,
  name: string,
  email?: string | null,
  mobile_no: string,
  address_one: string,
  address_two?: string | null,
  pincode?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  default?: number | null,
  userId: string,
  createdAt?: string | null,
};

export type ModelUserAddressConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  mobile_no?: ModelStringInput | null,
  address_one?: ModelStringInput | null,
  address_two?: ModelStringInput | null,
  pincode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  default?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserAddressConditionInput | null > | null,
  or?: Array< ModelUserAddressConditionInput | null > | null,
  not?: ModelUserAddressConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateUserAddressInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  mobile_no?: string | null,
  address_one?: string | null,
  address_two?: string | null,
  pincode?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  default?: number | null,
  userId?: string | null,
  createdAt?: string | null,
};

export type DeleteUserAddressInput = {
  id: string,
};

export type CreateCompanyInput = {
  id?: string | null,
  ownerType?: OwnerType | null,
  uen?: string | null,
  gstNo?: string | null,
  type?: Array< ComapnyType | null > | null,
  name?: string | null,
  email?: string | null,
  registration_name?: string | null,
  picture?: string | null,
  bio?: string | null,
  wlcmMsg?: string | null,
  address?: string | null,
  category?: string | null,
  status?: CompanyStatus | null,
  stripeAccountId?: string | null,
  followers?: number | null,
  createdAt?: string | null,
};

export type UpdateCompanyInput = {
  id: string,
  ownerType?: OwnerType | null,
  uen?: string | null,
  gstNo?: string | null,
  type?: Array< ComapnyType | null > | null,
  name?: string | null,
  email?: string | null,
  registration_name?: string | null,
  picture?: string | null,
  bio?: string | null,
  wlcmMsg?: string | null,
  address?: string | null,
  category?: string | null,
  status?: CompanyStatus | null,
  stripeAccountId?: string | null,
  followers?: number | null,
  createdAt?: string | null,
};

export type CreateCompanyPrivateInfoInput = {
  id?: string | null,
  companyId: string,
  companyDocImage?: string | null,
  contactName?: string | null,
  contactEmail?: string | null,
  contactNo?: string | null,
  conatactDocImage?: DocumentImageInput | null,
  bankName?: string | null,
  bankCode?: string | null,
  beneficiaryName?: string | null,
  bankAccNo?: string | null,
  bankDocumnet?: string | null,
  createdAt?: string | null,
};

export type DocumentImageInput = {
  front?: string | null,
  back?: string | null,
};

export type UpdateCompanyPrivateInfoInput = {
  id: string,
  companyId?: string | null,
  companyDocImage?: string | null,
  contactName?: string | null,
  contactEmail?: string | null,
  contactNo?: string | null,
  conatactDocImage?: DocumentImageInput | null,
  bankName?: string | null,
  bankCode?: string | null,
  beneficiaryName?: string | null,
  bankAccNo?: string | null,
  bankDocumnet?: string | null,
  createdAt?: string | null,
};

export type CreateCompanyUserInput = {
  id?: string | null,
  role: Role,
  userId: string,
  companyId: string,
  modules?: Array< ModuleListInput | null > | null,
  status?: CompanyUserStatus | null,
};

export type ModuleListInput = {
  moduleName?: string | null,
  add?: boolean | null,
  edit?: boolean | null,
  delete?: boolean | null,
  read?: boolean | null,
};

export type ModelCompanyUserConditionInput = {
  role?: ModelRoleInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  status?: ModelCompanyUserStatusInput | null,
  and?: Array< ModelCompanyUserConditionInput | null > | null,
  or?: Array< ModelCompanyUserConditionInput | null > | null,
  not?: ModelCompanyUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelRoleInput = {
  eq?: Role | null,
  ne?: Role | null,
};

export type ModelCompanyUserStatusInput = {
  eq?: CompanyUserStatus | null,
  ne?: CompanyUserStatus | null,
};

export type UpdateCompanyUserInput = {
  id: string,
  role?: Role | null,
  userId?: string | null,
  companyId?: string | null,
  modules?: Array< ModuleListInput | null > | null,
  status?: CompanyUserStatus | null,
};

export type DeleteCompanyUserInput = {
  id: string,
};

export type CreateCompanyReviewInput = {
  id?: string | null,
  stars?: number | null,
  comment?: string | null,
  userId: string,
  companyId: string,
};

export type ModelCompanyReviewConditionInput = {
  stars?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  and?: Array< ModelCompanyReviewConditionInput | null > | null,
  or?: Array< ModelCompanyReviewConditionInput | null > | null,
  not?: ModelCompanyReviewConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateCompanyReviewInput = {
  id: string,
  stars?: number | null,
  comment?: string | null,
  userId?: string | null,
  companyId?: string | null,
};

export type DeleteCompanyReviewInput = {
  id: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  name: string,
  type: ProductCategoryType,
  description?: string | null,
  picture?: string | null,
  parent_categoryId?: string | null,
  createdAt?: string | null,
};

export type UpdateCategoryInput = {
  id: string,
  name?: string | null,
  type?: ProductCategoryType | null,
  description?: string | null,
  picture?: string | null,
  parent_categoryId?: string | null,
  createdAt?: string | null,
};

export type CreateProductInput = {
  id?: string | null,
  title: string,
  description: string,
  type: ProductType,
  filterType: string,
  attributes?: Array< ProductAttributeInput | null > | null,
  allowToComment?: number | null,
  feature?: number | null,
  brand?: string | null,
  total_sold?: number | null,
  rating?: RatingTypeInput | null,
  likeCount?: number | null,
  viewCount?: number | null,
  commentCount?: number | null,
  shareCount?: number | null,
  bookmarkCount?: number | null,
  totalReviewCount?: number | null,
  orderCount?: number | null,
  userId: string,
  companyId?: string | null,
  metaKeywords?: string | null,
  sku?: string | null,
  status?: Status | null,
  createdAt?: string | null,
};

export type ProductAttributeInput = {
  name: string,
  options?: Array< string | null > | null,
};

export type RatingTypeInput = {
  one?: number | null,
  two?: number | null,
  three?: number | null,
  four?: number | null,
  five?: number | null,
};

export type ModelProductConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelProductTypeInput | null,
  filterType?: ModelStringInput | null,
  allowToComment?: ModelIntInput | null,
  feature?: ModelIntInput | null,
  brand?: ModelStringInput | null,
  total_sold?: ModelIntInput | null,
  likeCount?: ModelIntInput | null,
  viewCount?: ModelIntInput | null,
  commentCount?: ModelIntInput | null,
  shareCount?: ModelIntInput | null,
  bookmarkCount?: ModelIntInput | null,
  totalReviewCount?: ModelIntInput | null,
  orderCount?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  metaKeywords?: ModelStringInput | null,
  sku?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductTypeInput = {
  eq?: ProductType | null,
  ne?: ProductType | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type UpdateProductInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  type?: ProductType | null,
  filterType?: string | null,
  attributes?: Array< ProductAttributeInput | null > | null,
  allowToComment?: number | null,
  feature?: number | null,
  brand?: string | null,
  total_sold?: number | null,
  rating?: RatingTypeInput | null,
  likeCount?: number | null,
  viewCount?: number | null,
  commentCount?: number | null,
  shareCount?: number | null,
  bookmarkCount?: number | null,
  totalReviewCount?: number | null,
  orderCount?: number | null,
  userId?: string | null,
  companyId?: string | null,
  metaKeywords?: string | null,
  sku?: string | null,
  status?: Status | null,
  createdAt?: string | null,
};

export type DeleteProductInput = {
  id: string,
};

export type CreateFeedInput = {
  id?: string | null,
  title: string,
  description: string,
  type: string,
  likeCount?: number | null,
  commentCount?: number | null,
  shareCount?: number | null,
  viewCount?: number | null,
  bookmarkCount?: number | null,
  userId: string,
  companyId?: string | null,
  status?: Status | null,
  location?: LocationInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type LocationInput = {
  lat: number,
  lng: number,
  address?: string | null,
  pincode?: string | null,
};

export type ModelFeedConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  likeCount?: ModelIntInput | null,
  commentCount?: ModelIntInput | null,
  shareCount?: ModelIntInput | null,
  viewCount?: ModelIntInput | null,
  bookmarkCount?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFeedConditionInput | null > | null,
  or?: Array< ModelFeedConditionInput | null > | null,
  not?: ModelFeedConditionInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateFeedInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  type?: string | null,
  likeCount?: number | null,
  commentCount?: number | null,
  shareCount?: number | null,
  viewCount?: number | null,
  bookmarkCount?: number | null,
  userId?: string | null,
  companyId?: string | null,
  status?: Status | null,
  location?: LocationInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteFeedInput = {
  id: string,
};

export type CreateFeedCollaborationInput = {
  id?: string | null,
  type?: string | null,
  feedId: string,
  companyId?: string | null,
  merchantId?: string | null,
  createdAt?: string | null,
};

export type ModelFeedCollaborationConditionInput = {
  type?: ModelStringInput | null,
  feedId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  merchantId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFeedCollaborationConditionInput | null > | null,
  or?: Array< ModelFeedCollaborationConditionInput | null > | null,
  not?: ModelFeedCollaborationConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateFeedCollaborationInput = {
  id: string,
  type?: string | null,
  feedId?: string | null,
  companyId?: string | null,
  merchantId?: string | null,
  createdAt?: string | null,
};

export type DeleteFeedCollaborationInput = {
  id: string,
};

export type CreateFeedTagsInput = {
  id?: string | null,
  feedId: string,
  productVariantId: string,
  userId?: string | null,
  companyId?: string | null,
  createdAt?: string | null,
};

export type ModelFeedTagsConditionInput = {
  feedId?: ModelIDInput | null,
  productVariantId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFeedTagsConditionInput | null > | null,
  or?: Array< ModelFeedTagsConditionInput | null > | null,
  not?: ModelFeedTagsConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateFeedTagsInput = {
  id: string,
  feedId?: string | null,
  productVariantId?: string | null,
  userId?: string | null,
  companyId?: string | null,
  createdAt?: string | null,
};

export type DeleteFeedTagsInput = {
  id: string,
};

export type CreateFeedMediaInput = {
  id?: string | null,
  file_type: string,
  key: string,
  feedId: string,
  postId: string,
  propertyId: string,
  position?: number | null,
  createdAt?: string | null,
};

export type ModelFeedMediaConditionInput = {
  file_type?: ModelStringInput | null,
  key?: ModelStringInput | null,
  feedId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  position?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFeedMediaConditionInput | null > | null,
  or?: Array< ModelFeedMediaConditionInput | null > | null,
  not?: ModelFeedMediaConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateFeedMediaInput = {
  id: string,
  file_type?: string | null,
  key?: string | null,
  feedId?: string | null,
  postId?: string | null,
  propertyId?: string | null,
  position?: number | null,
  createdAt?: string | null,
};

export type DeleteFeedMediaInput = {
  id: string,
};

export type CreateLikeInput = {
  id?: string | null,
  type?: string | null,
  productId?: string | null,
  feedId?: string | null,
  propertyId?: string | null,
  postId?: string | null,
  likeState?: boolean | null,
  userId: string,
  createdAt?: string | null,
};

export type ModelLikeConditionInput = {
  type?: ModelStringInput | null,
  productId?: ModelIDInput | null,
  feedId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  likeState?: ModelBooleanInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelLikeConditionInput | null > | null,
  or?: Array< ModelLikeConditionInput | null > | null,
  not?: ModelLikeConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateLikeInput = {
  id: string,
  type?: string | null,
  productId?: string | null,
  feedId?: string | null,
  propertyId?: string | null,
  postId?: string | null,
  likeState?: boolean | null,
  userId?: string | null,
  createdAt?: string | null,
};

export type DeleteLikeInput = {
  id: string,
};

export type CreateViewsInput = {
  id?: string | null,
  productId?: string | null,
  feedId?: string | null,
  postId?: string | null,
  propertyId?: string | null,
  viewCount?: number | null,
  type: ViewProductType,
  userId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum ViewProductType {
  Service = "Service",
  Design = "Design",
  Request = "Request",
  Donation = "Donation",
  Store = "Store",
  Post = "Post",
  Property = "Property",
}


export type ModelViewsConditionInput = {
  productId?: ModelIDInput | null,
  feedId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  viewCount?: ModelIntInput | null,
  type?: ModelViewProductTypeInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelViewsConditionInput | null > | null,
  or?: Array< ModelViewsConditionInput | null > | null,
  not?: ModelViewsConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModelViewProductTypeInput = {
  eq?: ViewProductType | null,
  ne?: ViewProductType | null,
};

export type Views = {
  __typename: "Views",
  id: string,
  productId?: string | null,
  product?: Product | null,
  feedId?: string | null,
  feed?: Feed | null,
  postId?: string | null,
  post?: Post | null,
  propertyId?: string | null,
  property?: Property | null,
  viewCount?: number | null,
  type: ViewProductType,
  userId: string,
  user: User,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateViewsInput = {
  id: string,
  productId?: string | null,
  feedId?: string | null,
  postId?: string | null,
  propertyId?: string | null,
  viewCount?: number | null,
  type?: ViewProductType | null,
  userId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteViewsInput = {
  id: string,
};

export type CreateProductBookmarksInput = {
  id?: string | null,
  type?: string | null,
  productId?: string | null,
  feedId?: string | null,
  postId?: string | null,
  propertyId?: string | null,
  bookmarkState?: boolean | null,
  userId: string,
  createdAt?: string | null,
};

export type ModelProductBookmarksConditionInput = {
  type?: ModelStringInput | null,
  productId?: ModelIDInput | null,
  feedId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  bookmarkState?: ModelBooleanInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProductBookmarksConditionInput | null > | null,
  or?: Array< ModelProductBookmarksConditionInput | null > | null,
  not?: ModelProductBookmarksConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateProductBookmarksInput = {
  id: string,
  type?: string | null,
  productId?: string | null,
  feedId?: string | null,
  postId?: string | null,
  propertyId?: string | null,
  bookmarkState?: boolean | null,
  userId?: string | null,
  createdAt?: string | null,
};

export type DeleteProductBookmarksInput = {
  id: string,
};

export type CreateCommentsInput = {
  id?: string | null,
  comment: string,
  productId?: string | null,
  feedId?: string | null,
  postId?: string | null,
  parentCommentId?: string | null,
  userId: string,
  createdAt?: string | null,
};

export type UpdateCommentsInput = {
  id: string,
  comment?: string | null,
  productId?: string | null,
  feedId?: string | null,
  postId?: string | null,
  parentCommentId?: string | null,
  userId?: string | null,
  createdAt?: string | null,
};

export type CreateProductVariantInput = {
  id?: string | null,
  productId: string,
  title: string,
  description: string,
  attribute?: string | null,
  price?: number | null,
  list_price?: number | null,
  quantity?: number | null,
  deliveryMethod?: string | null,
  productCondition?: string | null,
  productUsed?: ProductUsedTypeInput | null,
  designStyle?: string | null,
  designPropertyType?: string | null,
  orderCount?: number | null,
  videoLink?: string | null,
  add_ons?: Array< ProductAddOnsInput | null > | null,
  location?: LocationInput | null,
  status?: Status | null,
  sku?: string | null,
  defaultPosition?: boolean | null,
  createdAt?: string | null,
  productProductVariantsId?: string | null,
};

export type ProductUsedTypeInput = {
  day?: number | null,
  month?: number | null,
  year?: number | null,
};

export type ProductAddOnsInput = {
  id: string,
  title: string,
  description: string,
  imageKey?: string | null,
  price?: number | null,
  list_price?: number | null,
};

export type ModelProductVariantConditionInput = {
  productId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  attribute?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  list_price?: ModelFloatInput | null,
  quantity?: ModelFloatInput | null,
  deliveryMethod?: ModelStringInput | null,
  productCondition?: ModelStringInput | null,
  designStyle?: ModelStringInput | null,
  designPropertyType?: ModelStringInput | null,
  orderCount?: ModelIntInput | null,
  videoLink?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  sku?: ModelStringInput | null,
  defaultPosition?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProductVariantConditionInput | null > | null,
  or?: Array< ModelProductVariantConditionInput | null > | null,
  not?: ModelProductVariantConditionInput | null,
  updatedAt?: ModelStringInput | null,
  productProductVariantsId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateProductVariantInput = {
  id: string,
  productId?: string | null,
  title?: string | null,
  description?: string | null,
  attribute?: string | null,
  price?: number | null,
  list_price?: number | null,
  quantity?: number | null,
  deliveryMethod?: string | null,
  productCondition?: string | null,
  productUsed?: ProductUsedTypeInput | null,
  designStyle?: string | null,
  designPropertyType?: string | null,
  orderCount?: number | null,
  videoLink?: string | null,
  add_ons?: Array< ProductAddOnsInput | null > | null,
  location?: LocationInput | null,
  status?: Status | null,
  sku?: string | null,
  defaultPosition?: boolean | null,
  createdAt?: string | null,
  productProductVariantsId?: string | null,
};

export type DeleteProductVariantInput = {
  id: string,
};

export type CreateProductMediaInput = {
  id?: string | null,
  file_type: string,
  key: string,
  product_variant_id: string,
  position?: number | null,
  createdAt?: string | null,
};

export type ModelProductMediaConditionInput = {
  file_type?: ModelStringInput | null,
  key?: ModelStringInput | null,
  product_variant_id?: ModelIDInput | null,
  position?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProductMediaConditionInput | null > | null,
  or?: Array< ModelProductMediaConditionInput | null > | null,
  not?: ModelProductMediaConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateProductMediaInput = {
  id: string,
  file_type?: string | null,
  key?: string | null,
  product_variant_id?: string | null,
  position?: number | null,
  createdAt?: string | null,
};

export type DeleteProductMediaInput = {
  id: string,
};

export type CreateProductCategoriesInput = {
  id?: string | null,
  categoryId: string,
  productId: string,
};

export type ModelProductCategoriesConditionInput = {
  categoryId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  and?: Array< ModelProductCategoriesConditionInput | null > | null,
  or?: Array< ModelProductCategoriesConditionInput | null > | null,
  not?: ModelProductCategoriesConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateProductCategoriesInput = {
  id: string,
  categoryId?: string | null,
  productId?: string | null,
};

export type DeleteProductCategoriesInput = {
  id: string,
};

export type CreateProductReviewInput = {
  id?: string | null,
  stars: number,
  comment?: string | null,
  attribute?: string | null,
  userId: string,
  productId: string,
  totalImagesCount?: number | null,
  estoreOrderProductId?: string | null,
  bookingId?: string | null,
  createdAt?: string | null,
};

export type ModelProductReviewConditionInput = {
  stars?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  attribute?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  totalImagesCount?: ModelIntInput | null,
  estoreOrderProductId?: ModelIDInput | null,
  bookingId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProductReviewConditionInput | null > | null,
  or?: Array< ModelProductReviewConditionInput | null > | null,
  not?: ModelProductReviewConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateProductReviewInput = {
  id: string,
  stars?: number | null,
  comment?: string | null,
  attribute?: string | null,
  userId?: string | null,
  productId?: string | null,
  totalImagesCount?: number | null,
  estoreOrderProductId?: string | null,
  bookingId?: string | null,
  createdAt?: string | null,
};

export type DeleteProductReviewInput = {
  id: string,
};

export type CreateReviewMediaInput = {
  id?: string | null,
  file_type: string,
  key: string,
  reviewId: string,
};

export type ModelReviewMediaConditionInput = {
  file_type?: ModelStringInput | null,
  key?: ModelStringInput | null,
  reviewId?: ModelIDInput | null,
  and?: Array< ModelReviewMediaConditionInput | null > | null,
  or?: Array< ModelReviewMediaConditionInput | null > | null,
  not?: ModelReviewMediaConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateReviewMediaInput = {
  id: string,
  file_type?: string | null,
  key?: string | null,
  reviewId?: string | null,
};

export type DeleteReviewMediaInput = {
  id: string,
};

export type CreateEstoreOrdersInput = {
  id?: string | null,
  orderId: string,
  transaction_id?: string | null,
  order_price: number,
  discount?: number | null,
  sell_price?: number | null,
  name?: string | null,
  email?: string | null,
  mobile_no: string,
  address_one: string,
  address_two?: string | null,
  pincode?: string | null,
  city: string,
  state?: string | null,
  country: string,
  offerCodeId?: string | null,
  userId: string,
  delivery_fee?: number | null,
  status: OrderStatusType,
  paymentStatus: PaymentStatusType,
  statusDescription?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelEstoreOrdersConditionInput = {
  orderId?: ModelStringInput | null,
  transaction_id?: ModelStringInput | null,
  order_price?: ModelFloatInput | null,
  discount?: ModelFloatInput | null,
  sell_price?: ModelFloatInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  mobile_no?: ModelStringInput | null,
  address_one?: ModelStringInput | null,
  address_two?: ModelStringInput | null,
  pincode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  offerCodeId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  delivery_fee?: ModelFloatInput | null,
  status?: ModelOrderStatusTypeInput | null,
  paymentStatus?: ModelPaymentStatusTypeInput | null,
  statusDescription?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEstoreOrdersConditionInput | null > | null,
  or?: Array< ModelEstoreOrdersConditionInput | null > | null,
  not?: ModelEstoreOrdersConditionInput | null,
};

export type ModelOrderStatusTypeInput = {
  eq?: OrderStatusType | null,
  ne?: OrderStatusType | null,
};

export type ModelPaymentStatusTypeInput = {
  eq?: PaymentStatusType | null,
  ne?: PaymentStatusType | null,
};

export type UpdateEstoreOrdersInput = {
  id: string,
  orderId?: string | null,
  transaction_id?: string | null,
  order_price?: number | null,
  discount?: number | null,
  sell_price?: number | null,
  name?: string | null,
  email?: string | null,
  mobile_no?: string | null,
  address_one?: string | null,
  address_two?: string | null,
  pincode?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  offerCodeId?: string | null,
  userId?: string | null,
  delivery_fee?: number | null,
  status?: OrderStatusType | null,
  paymentStatus?: PaymentStatusType | null,
  statusDescription?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteEstoreOrdersInput = {
  id: string,
};

export type CreateEstoreOrderProductInput = {
  id?: string | null,
  title: string,
  description: string,
  price: number,
  list_price: number,
  quantity: number,
  invoice?: string | null,
  userId: string,
  companyId: string,
  productVariantId: string,
  attribute?: string | null,
  add_ons?: ProductAddOnsInput | null,
  status: OrderStatusType,
  paymentStatus: PaymentStatusType,
  deliveryStatus: DeliverStatusType,
  statusDescription?: string | null,
  statusHistory?: string | null,
  cancellationReason?: string | null,
  estore_order_id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelEstoreOrderProductConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  list_price?: ModelFloatInput | null,
  quantity?: ModelIntInput | null,
  invoice?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  productVariantId?: ModelIDInput | null,
  attribute?: ModelStringInput | null,
  status?: ModelOrderStatusTypeInput | null,
  paymentStatus?: ModelPaymentStatusTypeInput | null,
  deliveryStatus?: ModelDeliverStatusTypeInput | null,
  statusDescription?: ModelStringInput | null,
  statusHistory?: ModelStringInput | null,
  cancellationReason?: ModelStringInput | null,
  estore_order_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEstoreOrderProductConditionInput | null > | null,
  or?: Array< ModelEstoreOrderProductConditionInput | null > | null,
  not?: ModelEstoreOrderProductConditionInput | null,
};

export type ModelDeliverStatusTypeInput = {
  eq?: DeliverStatusType | null,
  ne?: DeliverStatusType | null,
};

export type UpdateEstoreOrderProductInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  price?: number | null,
  list_price?: number | null,
  quantity?: number | null,
  invoice?: string | null,
  userId?: string | null,
  companyId?: string | null,
  productVariantId?: string | null,
  attribute?: string | null,
  add_ons?: ProductAddOnsInput | null,
  status?: OrderStatusType | null,
  paymentStatus?: PaymentStatusType | null,
  deliveryStatus?: DeliverStatusType | null,
  statusDescription?: string | null,
  statusHistory?: string | null,
  cancellationReason?: string | null,
  estore_order_id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteEstoreOrderProductInput = {
  id: string,
};

export type CreateEstoreOrdersRefundInput = {
  id?: string | null,
  orderProductId: string,
  companyId: string,
  refund_price: number,
  reason: string,
  status: PaymentStatusType,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelEstoreOrdersRefundConditionInput = {
  orderProductId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  refund_price?: ModelFloatInput | null,
  reason?: ModelStringInput | null,
  status?: ModelPaymentStatusTypeInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEstoreOrdersRefundConditionInput | null > | null,
  or?: Array< ModelEstoreOrdersRefundConditionInput | null > | null,
  not?: ModelEstoreOrdersRefundConditionInput | null,
  owner?: ModelStringInput | null,
};

export type EstoreOrdersRefund = {
  __typename: "EstoreOrdersRefund",
  id: string,
  orderProductId: string,
  orderProduct?: EstoreOrderProduct | null,
  companyId: string,
  company?: Company | null,
  refund_price: number,
  reason: string,
  status: PaymentStatusType,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateEstoreOrdersRefundInput = {
  id: string,
  orderProductId?: string | null,
  companyId?: string | null,
  refund_price?: number | null,
  reason?: string | null,
  status?: PaymentStatusType | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteEstoreOrdersRefundInput = {
  id: string,
};

export type CreateTransactionLogsInput = {
  id?: string | null,
  orderId?: string | null,
  type: string,
  response: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelTransactionLogsConditionInput = {
  orderId?: ModelStringInput | null,
  type?: ModelStringInput | null,
  response?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTransactionLogsConditionInput | null > | null,
  or?: Array< ModelTransactionLogsConditionInput | null > | null,
  not?: ModelTransactionLogsConditionInput | null,
  owner?: ModelStringInput | null,
};

export type TransactionLogs = {
  __typename: "TransactionLogs",
  id: string,
  orderId?: string | null,
  type: string,
  response: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdateTransactionLogsInput = {
  id: string,
  orderId?: string | null,
  type?: string | null,
  response?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteTransactionLogsInput = {
  id: string,
};

export type CreateEstoreCartInput = {
  id?: string | null,
  quantity: number,
  userId: string,
  productVariantId: string,
  add_ons?: Array< ProductAddOnsInput | null > | null,
  companyId: string,
  status: Status,
  createdAt?: string | null,
};

export type ModelEstoreCartConditionInput = {
  quantity?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  productVariantId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelEstoreCartConditionInput | null > | null,
  or?: Array< ModelEstoreCartConditionInput | null > | null,
  not?: ModelEstoreCartConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type EstoreCart = {
  __typename: "EstoreCart",
  id: string,
  quantity: number,
  userId: string,
  user?: User | null,
  productVariantId: string,
  productVariant?: ProductVariant | null,
  add_ons?:  Array<ProductAddOns | null > | null,
  companyId: string,
  company?: Company | null,
  status: Status,
  createdAt?: string | null,
  updatedAt: string,
};

export type UpdateEstoreCartInput = {
  id: string,
  quantity?: number | null,
  userId?: string | null,
  productVariantId?: string | null,
  add_ons?: Array< ProductAddOnsInput | null > | null,
  companyId?: string | null,
  status?: Status | null,
  createdAt?: string | null,
};

export type DeleteEstoreCartInput = {
  id: string,
};

export type CreateBookingInput = {
  id?: string | null,
  type: ProductType,
  quantity?: number | null,
  productVariantId: string,
  userId: string,
  fulfillerId: string,
  companyId?: string | null,
  status: BookingStatus,
  createdAt?: string | null,
};

export type ModelBookingConditionInput = {
  type?: ModelProductTypeInput | null,
  quantity?: ModelIntInput | null,
  productVariantId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  fulfillerId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  status?: ModelBookingStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelBookingConditionInput | null > | null,
  or?: Array< ModelBookingConditionInput | null > | null,
  not?: ModelBookingConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelBookingStatusInput = {
  eq?: BookingStatus | null,
  ne?: BookingStatus | null,
};

export type UpdateBookingInput = {
  id: string,
  type?: ProductType | null,
  quantity?: number | null,
  productVariantId?: string | null,
  userId?: string | null,
  fulfillerId?: string | null,
  companyId?: string | null,
  status?: BookingStatus | null,
  createdAt?: string | null,
};

export type DeleteBookingInput = {
  id: string,
};

export type CreateChatInput = {
  id?: string | null,
  name?: string | null,
  group?: boolean | null,
  image?: string | null,
  companyId?: string | null,
  createdAt?: string | null,
  deletedBy?: string | null,
};

export type ModelChatConditionInput = {
  name?: ModelStringInput | null,
  group?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  deletedBy?: ModelStringInput | null,
  and?: Array< ModelChatConditionInput | null > | null,
  or?: Array< ModelChatConditionInput | null > | null,
  not?: ModelChatConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateChatInput = {
  id: string,
  name?: string | null,
  group?: boolean | null,
  image?: string | null,
  companyId?: string | null,
  createdAt?: string | null,
  deletedBy?: string | null,
};

export type DeleteChatInput = {
  id: string,
};

export type CreateChatParticipantsInput = {
  id?: string | null,
  chatId: string,
  userId: string,
  isActive?: number | null,
};

export type ModelChatParticipantsConditionInput = {
  chatId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  isActive?: ModelIntInput | null,
  and?: Array< ModelChatParticipantsConditionInput | null > | null,
  or?: Array< ModelChatParticipantsConditionInput | null > | null,
  not?: ModelChatParticipantsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateChatParticipantsInput = {
  id: string,
  chatId?: string | null,
  userId?: string | null,
  isActive?: number | null,
};

export type DeleteChatParticipantsInput = {
  id: string,
};

export type CreateChatMessagesInput = {
  id?: string | null,
  message?: string | null,
  attachment?: string | null,
  attachment_type?: string | null,
  bookingId?: string | null,
  propertyId?: string | null,
  chatId: string,
  userId: string,
  createdAt?: string | null,
};

export type ModelChatMessagesConditionInput = {
  message?: ModelStringInput | null,
  attachment?: ModelStringInput | null,
  attachment_type?: ModelStringInput | null,
  bookingId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  chatId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelChatMessagesConditionInput | null > | null,
  or?: Array< ModelChatMessagesConditionInput | null > | null,
  not?: ModelChatMessagesConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateChatMessagesInput = {
  id: string,
  message?: string | null,
  attachment?: string | null,
  attachment_type?: string | null,
  bookingId?: string | null,
  propertyId?: string | null,
  chatId?: string | null,
  userId?: string | null,
  createdAt?: string | null,
};

export type DeleteChatMessagesInput = {
  id: string,
};

export type CreateChatMessageUnreadCountInput = {
  id?: string | null,
  userId: string,
  chatId: string,
  unreadCount: number,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelChatMessageUnreadCountConditionInput = {
  userId?: ModelIDInput | null,
  chatId?: ModelIDInput | null,
  unreadCount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatMessageUnreadCountConditionInput | null > | null,
  or?: Array< ModelChatMessageUnreadCountConditionInput | null > | null,
  not?: ModelChatMessageUnreadCountConditionInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateChatMessageUnreadCountInput = {
  id: string,
  userId?: string | null,
  chatId?: string | null,
  unreadCount?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteChatMessageUnreadCountInput = {
  id: string,
};

export type CreateNotificationsInput = {
  id?: string | null,
  userId: string,
  senderId?: string | null,
  read: number,
  title: string,
  description: string,
  imageUrl?: string | null,
  data?: string | null,
  createdAt?: string | null,
};

export type ModelNotificationsConditionInput = {
  userId?: ModelIDInput | null,
  senderId?: ModelIDInput | null,
  read?: ModelIntInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationsConditionInput | null > | null,
  or?: Array< ModelNotificationsConditionInput | null > | null,
  not?: ModelNotificationsConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Notifications = {
  __typename: "Notifications",
  id: string,
  userId: string,
  senderId?: string | null,
  sender?: User | null,
  read: number,
  title: string,
  description: string,
  imageUrl?: string | null,
  data?: string | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type UpdateNotificationsInput = {
  id: string,
  userId?: string | null,
  senderId?: string | null,
  read?: number | null,
  title?: string | null,
  description?: string | null,
  imageUrl?: string | null,
  data?: string | null,
  createdAt?: string | null,
};

export type DeleteNotificationsInput = {
  id: string,
};

export type CreatePostInput = {
  id?: string | null,
  type?: PostType | null,
  userId: string,
  title: string,
  description: string,
  likeCount?: number | null,
  commentCount?: number | null,
  shareCount?: number | null,
  viewCount?: number | null,
  bookmarkCount?: number | null,
  status?: Status | null,
  location?: LocationInput | null,
  createdAt?: string | null,
};

export type ModelPostConditionInput = {
  type?: ModelPostTypeInput | null,
  userId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  likeCount?: ModelIntInput | null,
  commentCount?: ModelIntInput | null,
  shareCount?: ModelIntInput | null,
  viewCount?: ModelIntInput | null,
  bookmarkCount?: ModelIntInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelPostConditionInput | null > | null,
  or?: Array< ModelPostConditionInput | null > | null,
  not?: ModelPostConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPostTypeInput = {
  eq?: PostType | null,
  ne?: PostType | null,
};

export type UpdatePostInput = {
  id: string,
  type?: PostType | null,
  userId?: string | null,
  title?: string | null,
  description?: string | null,
  likeCount?: number | null,
  commentCount?: number | null,
  shareCount?: number | null,
  viewCount?: number | null,
  bookmarkCount?: number | null,
  status?: Status | null,
  location?: LocationInput | null,
  createdAt?: string | null,
};

export type DeletePostInput = {
  id: string,
};

export type CreateRecommendationInput = {
  id?: string | null,
  userId?: string | null,
  productId?: string | null,
  postId?: string | null,
  feedId?: string | null,
  createdAt?: string | null,
  ttl: number,
};

export type ModelRecommendationConditionInput = {
  userId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  feedId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelRecommendationConditionInput | null > | null,
  or?: Array< ModelRecommendationConditionInput | null > | null,
  not?: ModelRecommendationConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type Recommendation = {
  __typename: "Recommendation",
  id: string,
  userId?: string | null,
  user?: User | null,
  productId?: string | null,
  product?: Product | null,
  postId?: string | null,
  feedId?: string | null,
  createdAt?: string | null,
  ttl: number,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateRecommendationInput = {
  id: string,
  userId?: string | null,
  productId?: string | null,
  postId?: string | null,
  feedId?: string | null,
  createdAt?: string | null,
  ttl?: number | null,
};

export type DeleteRecommendationInput = {
  id: string,
};

export type CreateRecommendationViewsInput = {
  id?: string | null,
  userId: string,
  createdAt?: string | null,
};

export type ModelRecommendationViewsConditionInput = {
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelRecommendationViewsConditionInput | null > | null,
  or?: Array< ModelRecommendationViewsConditionInput | null > | null,
  not?: ModelRecommendationViewsConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type RecommendationViews = {
  __typename: "RecommendationViews",
  id: string,
  userId: string,
  user: User,
  createdAt?: string | null,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateRecommendationViewsInput = {
  id: string,
  userId?: string | null,
  createdAt?: string | null,
};

export type DeleteRecommendationViewsInput = {
  id: string,
};

export type CreateFollowInput = {
  id?: string | null,
  userId?: string | null,
  companyId?: string | null,
  followerId?: string | null,
  createdAt?: string | null,
};

export type ModelFollowConditionInput = {
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  followerId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFollowConditionInput | null > | null,
  or?: Array< ModelFollowConditionInput | null > | null,
  not?: ModelFollowConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateFollowInput = {
  id: string,
  userId?: string | null,
  companyId?: string | null,
  followerId?: string | null,
  createdAt?: string | null,
};

export type DeleteFollowInput = {
  id: string,
};

export type CreatePropertyInput = {
  id?: string | null,
  title: string,
  type: PropertyType,
  description: string,
  price: number,
  bed?: number | null,
  bath?: number | null,
  sqft?: number | null,
  psf?: number | null,
  listingId?: number | null,
  category?: string | null,
  detailsJson?: Array< KeyValueInput | null > | null,
  userId: string,
  companyId?: string | null,
  bookmarkCount?: number | null,
  likeCount?: number | null,
  viewCount?: number | null,
  unitFeatures?: Array< string | null > | null,
  facilities?: Array< string | null > | null,
  status?: Status | null,
  location?: LocationInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type KeyValueInput = {
  key?: string | null,
  value?: string | null,
};

export type ModelPropertyConditionInput = {
  title?: ModelStringInput | null,
  type?: ModelPropertyTypeInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  bed?: ModelFloatInput | null,
  bath?: ModelFloatInput | null,
  sqft?: ModelFloatInput | null,
  psf?: ModelFloatInput | null,
  listingId?: ModelIntInput | null,
  category?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  bookmarkCount?: ModelIntInput | null,
  likeCount?: ModelIntInput | null,
  viewCount?: ModelIntInput | null,
  unitFeatures?: ModelStringInput | null,
  facilities?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPropertyConditionInput | null > | null,
  or?: Array< ModelPropertyConditionInput | null > | null,
  not?: ModelPropertyConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPropertyTypeInput = {
  eq?: PropertyType | null,
  ne?: PropertyType | null,
};

export type UpdatePropertyInput = {
  id: string,
  title?: string | null,
  type?: PropertyType | null,
  description?: string | null,
  price?: number | null,
  bed?: number | null,
  bath?: number | null,
  sqft?: number | null,
  psf?: number | null,
  listingId?: number | null,
  category?: string | null,
  detailsJson?: Array< KeyValueInput | null > | null,
  userId?: string | null,
  companyId?: string | null,
  bookmarkCount?: number | null,
  likeCount?: number | null,
  viewCount?: number | null,
  unitFeatures?: Array< string | null > | null,
  facilities?: Array< string | null > | null,
  status?: Status | null,
  location?: LocationInput | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeletePropertyInput = {
  id: string,
};

export type CreateAdminConfigInput = {
  id?: string | null,
  type?: string | null,
  value?: string | null,
};

export type ModelAdminConfigConditionInput = {
  type?: ModelStringInput | null,
  value?: ModelStringInput | null,
  and?: Array< ModelAdminConfigConditionInput | null > | null,
  or?: Array< ModelAdminConfigConditionInput | null > | null,
  not?: ModelAdminConfigConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type AdminConfig = {
  __typename: "AdminConfig",
  id: string,
  type?: string | null,
  value?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateAdminConfigInput = {
  id: string,
  type?: string | null,
  value?: string | null,
};

export type DeleteAdminConfigInput = {
  id: string,
};

export type CreateOfferCodeInput = {
  id?: string | null,
  code: string,
  description?: string | null,
  discountType: DiscountType,
  discountValue: number,
  maxUsage?: number | null,
  usageCount?: number | null,
  validFrom?: string | null,
  validTo?: string | null,
  minOrderValue?: number | null,
  maxDiscountAmount?: number | null,
  customerRestrictions?: Array< string | null > | null,
  productRestrictions?: Array< string | null > | null,
  status: Status,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelOfferCodeConditionInput = {
  code?: ModelStringInput | null,
  description?: ModelStringInput | null,
  discountType?: ModelDiscountTypeInput | null,
  discountValue?: ModelFloatInput | null,
  maxUsage?: ModelIntInput | null,
  usageCount?: ModelIntInput | null,
  validFrom?: ModelStringInput | null,
  validTo?: ModelStringInput | null,
  minOrderValue?: ModelFloatInput | null,
  maxDiscountAmount?: ModelFloatInput | null,
  customerRestrictions?: ModelStringInput | null,
  productRestrictions?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOfferCodeConditionInput | null > | null,
  or?: Array< ModelOfferCodeConditionInput | null > | null,
  not?: ModelOfferCodeConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModelDiscountTypeInput = {
  eq?: DiscountType | null,
  ne?: DiscountType | null,
};

export type UpdateOfferCodeInput = {
  id: string,
  code?: string | null,
  description?: string | null,
  discountType?: DiscountType | null,
  discountValue?: number | null,
  maxUsage?: number | null,
  usageCount?: number | null,
  validFrom?: string | null,
  validTo?: string | null,
  minOrderValue?: number | null,
  maxDiscountAmount?: number | null,
  customerRestrictions?: Array< string | null > | null,
  productRestrictions?: Array< string | null > | null,
  status?: Status | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteOfferCodeInput = {
  id: string,
};

export type CreatePageHtmlInput = {
  id?: string | null,
  site: string,
  page: string,
  html: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelPageHtmlConditionInput = {
  site?: ModelStringInput | null,
  page?: ModelStringInput | null,
  html?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPageHtmlConditionInput | null > | null,
  or?: Array< ModelPageHtmlConditionInput | null > | null,
  not?: ModelPageHtmlConditionInput | null,
  owner?: ModelStringInput | null,
};

export type PageHtml = {
  __typename: "PageHtml",
  id: string,
  site: string,
  page: string,
  html: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
};

export type UpdatePageHtmlInput = {
  id: string,
  site?: string | null,
  page?: string | null,
  html?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeletePageHtmlInput = {
  id: string,
};

export type ProductInput = {
  userId: string,
  companyId?: string | null,
  attributeList?: Array< attributeListInput | null > | null,
  attribute?: string | null,
  type: string,
  categoryIds?: Array< string | null > | null,
  title?: string | null,
  sku?: string | null,
  description?: string | null,
  productCondition?: string | null,
  productUsed?: productUsedInput | null,
  quantity?: number | null,
  location?: LocationInput | null,
  files?: Array< fileInput | null > | null,
  productVariants?: Array< variantInput | null > | null,
};

export type attributeListInput = {
  name: string,
  options?: Array< string | null > | null,
};

export type productUsedInput = {
  year?: number | null,
  month?: number | null,
  day?: number | null,
};

export type fileInput = {
  id?: string | null,
  position?: number | null,
  key?: string | null,
  file_type?: string | null,
};

export type variantInput = {
  title?: string | null,
  attribute: string,
  description?: string | null,
  price: number,
  sku: string,
  list_price: number,
  files?: Array< fileInput | null > | null,
  quantity?: number | null,
  defaultPosition?: boolean | null,
  productCondition?: string | null,
  designStyle?: string | null,
  designPropertyType?: string | null,
};

export type Resp = {
  __typename: "Resp",
  resp_status?: boolean | null,
  resp_data?: string | null,
  resp_code?: string | null,
  resp_message?: string | null,
};

export type editProductInput = {
  productId: string,
  productVariantId: string,
  productDetails?: productDetailsInput | null,
  productVariantDetails?: productVariantDetailsInput | null,
};

export type productDetailsInput = {
  attributeList?: Array< attributeListInput | null > | null,
  attribute?: string | null,
  title?: string | null,
  description?: string | null,
  categoryIds: Array< string | null >,
};

export type productVariantDetailsInput = {
  attribute?: string | null,
  title?: string | null,
  description?: string | null,
  productCondition?: string | null,
  productUsed?: productUsedInput | null,
  quantity: number,
  location?: LocationInput | null,
};

export type editProductVariantFInput = {
  id?: string | null,
  attribute: string,
  title: string,
  description: string,
  price?: number | null,
  list_price?: number | null,
  sku: string,
  quantity?: number | null,
  files?: Array< fileInput | null > | null,
  location?: LocationInput | null,
  defaultPosition?: boolean | null,
  previousDefaultProductId?: string | null,
};

export type editProductWithAllVariantsInput = {
  productId: string,
  title: string,
  attributes: Array< attributeListInput | null >,
  description: string,
  categoryIds: Array< string | null >,
  productVariantsList: Array< productVariantDetailsInputTwo | null >,
};

export type productVariantDetailsInputTwo = {
  id?: string | null,
  attribute: string,
  title: string,
  description: string,
  price?: number | null,
  list_price?: number | null,
  sku: string,
  quantity?: number | null,
  files?: Array< fileInput | null > | null,
  location?: LocationInput | null,
  defaultPosition?: boolean | null,
  productCondition?: string | null,
  designStyle?: string | null,
  designPropertyType?: string | null,
};

export type bulkUploadProductInput = {
  productList?: Array< ProductInput | null > | null,
};

export type estoreOrderProductIssueRefundInput = {
  orderProductId: string,
  quantity: number,
  refund_amount: number,
  reason: string,
};

export type estoreCheckOutInput = {
  type: string,
  currency: string,
  cartIds?: Array< string | null > | null,
  userAddressId: string,
  user_email: string,
  userId: string,
  mobile_no: string,
  delivery_fee?: number | null,
  offerCodeId?: string | null,
  discount?: number | null,
  companyDeliveryFee?: string | null,
};

export type estoreClearCartInput = {
  userId: string,
  cartIds: Array< string | null >,
  status_code: Status,
};

export type estoreUpdateOrderStatusInput = {
  orderProductId: string,
  status: OrderStatusType,
  deliveryStatus: DeliverStatusType,
  cancellationReason?: string | null,
  customerApp?: boolean | null,
};

export type updateReadCountFInput = {
  id: string,
  unreadCount?: number | null,
};

export type initiateChatInput = {
  userId: string,
  participantId: string,
  productParams?: productParams | null,
  companyId?: string | null,
};

export type productParams = {
  type: ProductTypeSearch,
  title: string,
  description: string,
  quantity?: number | null,
  image: string,
  userId: string,
  productVariantId?: string | null,
  propertyId?: string | null,
};

export type likeUnlikeFInput = {
  productId?: string | null,
  type?: string | null,
  propertyId?: string | null,
  feedId?: string | null,
  postId?: string | null,
  ownerId?: string | null,
  likeState: boolean,
};

export type ViewProductFInput = {
  productId?: string | null,
  feedId?: string | null,
  postId?: string | null,
  type: ViewProductType,
};

export type commentAddInput = {
  comment: string,
  productId?: string | null,
  feedId?: string | null,
  postId?: string | null,
  ownerId?: string | null,
  parentCommentId?: string | null,
};

export type commentDeleteInput = {
  commentId: string,
};

export type bookingStatusChangeInput = {
  bookingId: string,
  bookingStatus?: BookingStatus | null,
};

export type bookmarkSaveUnSaveInput = {
  productId?: string | null,
  feedId?: string | null,
  postId?: string | null,
  propertyId?: string | null,
  type?: string | null,
  bookmarkState: boolean,
};

export type addProductReviewInput = {
  productVariantId: string,
  ratingObj?: RatingObj | null,
  estoreOrderProductId?: string | null,
  bookingId?: string | null,
  review?: string | null,
  reviewMediaList?: Array< ReviewMediaListInput | null > | null,
};

export type RatingObj = {
  one?: number | null,
  two?: number | null,
  three?: number | null,
  four?: number | null,
  five?: number | null,
};

export type ReviewMediaListInput = {
  key: string,
  file_type: string,
};

export type editProductReviewInput = {
  productReviewId: string,
  ratingObj?: RatingObj | null,
  review?: string | null,
  reviewMediaList?: Array< ReviewMediaListInput | null > | null,
};

export type requestForMerchantInput = {
  type?: Array< ComapnyType | null > | null,
  email?: string | null,
  picture?: string | null,
  name?: string | null,
  wlcmMsg?: string | null,
  bio?: string | null,
  address?: string | null,
  ownerType?: OwnerType | null,
  contactName?: string | null,
  contactEmail?: string | null,
  contactNo?: string | null,
  conatactDocImage?: DocumentImageInput | null,
  companyDocImage?: string | null,
  bankName?: string | null,
  bankCode?: string | null,
  beneficiaryName?: string | null,
  bankAccNo?: string | null,
  bankDocumnet?: string | null,
  status?: CompanyStatus | null,
  id?: string | null,
  companyPrivateId?: string | null,
  uen?: string | null,
  gstNo?: string | null,
};

export type updateFcmTokenInput = {
  type: fcmTokenField,
  fcmToken: string,
  removeFlag?: boolean | null,
};

export enum fcmTokenField {
  fcmToken = "fcmToken",
  merchantFcmToken = "merchantFcmToken",
}


export type clearUserNotificationInput = {
  notificationId?: string | null,
};

export type addFeedInput = {
  title: string,
  description: string,
  type?: string | null,
  userIds?: Array< string | null > | null,
  productVariantIds: Array< string | null >,
  mediaList: Array< fileInput | null >,
  companyList?: Array< companyListInput | null > | null,
  location?: LocationInput | null,
  companyId?: string | null,
};

export type companyListInput = {
  companyId?: string | null,
  type?: string | null,
  merchantId?: string | null,
};

export type editFeedInput = {
  feedId: string,
  title: string,
  description: string,
  type?: string | null,
  userIds?: Array< string | null > | null,
  productVariantIds: Array< string | null >,
  mediaList: Array< fileInput | null >,
  companyList?: Array< companyListInput | null > | null,
  location?: LocationInput | null,
  companyId?: string | null,
  removeTagIds?: Array< string | null > | null,
  removeMediaIds?: Array< string | null > | null,
  removeCompanyIds?: Array< string | null > | null,
};

export type addPostInput = {
  title: string,
  type?: PostType | null,
  description: string,
  mediaList?: Array< fileInput | null > | null,
  location?: LocationInput | null,
};

export type editPostInput = {
  postId: string,
  title: string,
  description: string,
  mediaList?: Array< fileInput | null > | null,
  location?: LocationInput | null,
  removeMediaIds?: Array< string | null > | null,
};

export type stripePaymentIntentsListInput = {
  limit: number,
  starting_after?: string | null,
};

export type stripeConnectInput = {
  userId: string,
};

export type addStripeAccountInput = {
  id: string,
  stripeAccountId: string,
};

export type generateTextAiInput = {
  title: string,
  type: string,
  language?: string | null,
};

export type notificationsSendInput = {
  topic?: string | null,
  title: string,
  description?: string | null,
  deeplink?: string | null,
  image?: string | null,
  userIds?: Array< string | null > | null,
  settings_data?: string | null,
};

export type followAddFInput = {
  companyId?: string | null,
  followerId?: string | null,
  followerState: boolean,
};

export type createPropertyInput = {
  title: string,
  description: string,
  type?: string | null,
  price?: number | null,
  bed?: number | null,
  bath?: number | null,
  sqft?: number | null,
  psf?: number | null,
  location: LocationInput,
  detailsJson?: Array< detailsJsonInput | null > | null,
  companyId?: string | null,
  mediaList?: Array< fileInput | null > | null,
  unitFeatures?: Array< string | null > | null,
  facilities?: Array< string | null > | null,
};

export type detailsJsonInput = {
  key?: string | null,
  value?: string | null,
};

export type editPropertyInput = {
  propertyId: string,
  title?: string | null,
  description?: string | null,
  type?: string | null,
  price?: number | null,
  bed?: number | null,
  bath?: number | null,
  sqft?: number | null,
  psf?: number | null,
  location: LocationInput,
  detailsJson?: Array< detailsJsonInput | null > | null,
  mediaList?: Array< fileInput | null > | null,
  unitFeatures?: Array< string | null > | null,
  facilities?: Array< string | null > | null,
  removeMediaIds?: Array< string | null > | null,
};

export type offerAddInput = {
  id?: string | null,
  code?: string | null,
  description?: string | null,
  discountType?: DiscountType | null,
  discountValue?: number | null,
  maxUsage?: number | null,
  usageCount?: number | null,
  validFrom?: string | null,
  validTo?: string | null,
  minOrderValue?: number | null,
  maxDiscountAmount?: number | null,
  customerRestrictions?: Array< string | null > | null,
  productRestrictions?: Array< string | null > | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  mobile_no?: ModelStringInput | null,
  name?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  country?: ModelStringInput | null,
  mobile_verified?: ModelBooleanInput | null,
  bio?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  followers?: ModelIntInput | null,
  followings?: ModelIntInput | null,
  online?: ModelBooleanInput | null,
  fcmToken?: ModelStringInput | null,
  merchantFcmToken?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserAddressFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  mobile_no?: ModelStringInput | null,
  address_one?: ModelStringInput | null,
  address_two?: ModelStringInput | null,
  pincode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  default?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserAddressFilterInput | null > | null,
  or?: Array< ModelUserAddressFilterInput | null > | null,
  not?: ModelUserAddressFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCompanyFilterInput = {
  id?: ModelIDInput | null,
  ownerType?: ModelOwnerTypeInput | null,
  uen?: ModelStringInput | null,
  gstNo?: ModelStringInput | null,
  type?: ModelComapnyTypeListInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  registration_name?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  wlcmMsg?: ModelStringInput | null,
  address?: ModelStringInput | null,
  category?: ModelStringInput | null,
  status?: ModelCompanyStatusInput | null,
  stripeAccountId?: ModelStringInput | null,
  followers?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection",
  items:  Array<Company | null >,
  nextToken?: string | null,
};

export type ModelCompanyPrivateInfoFilterInput = {
  id?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  companyDocImage?: ModelStringInput | null,
  contactName?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  contactNo?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  bankCode?: ModelStringInput | null,
  beneficiaryName?: ModelStringInput | null,
  bankAccNo?: ModelStringInput | null,
  bankDocumnet?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCompanyPrivateInfoFilterInput | null > | null,
  or?: Array< ModelCompanyPrivateInfoFilterInput | null > | null,
  not?: ModelCompanyPrivateInfoFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCompanyPrivateInfoConnection = {
  __typename: "ModelCompanyPrivateInfoConnection",
  items:  Array<CompanyPrivateInfo | null >,
  nextToken?: string | null,
};

export type ModelCompanyUserFilterInput = {
  id?: ModelIDInput | null,
  role?: ModelRoleInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  status?: ModelCompanyUserStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCompanyUserFilterInput | null > | null,
  or?: Array< ModelCompanyUserFilterInput | null > | null,
  not?: ModelCompanyUserFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCompanyReviewFilterInput = {
  id?: ModelIDInput | null,
  stars?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCompanyReviewFilterInput | null > | null,
  or?: Array< ModelCompanyReviewFilterInput | null > | null,
  not?: ModelCompanyReviewFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelProductCategoryTypeInput | null,
  description?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  parent_categoryId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelProductTypeInput | null,
  filterType?: ModelStringInput | null,
  allowToComment?: ModelIntInput | null,
  feature?: ModelIntInput | null,
  brand?: ModelStringInput | null,
  total_sold?: ModelIntInput | null,
  likeCount?: ModelIntInput | null,
  viewCount?: ModelIntInput | null,
  commentCount?: ModelIntInput | null,
  shareCount?: ModelIntInput | null,
  bookmarkCount?: ModelIntInput | null,
  totalReviewCount?: ModelIntInput | null,
  orderCount?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  metaKeywords?: ModelStringInput | null,
  sku?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductByProductTypeCompositeKeyConditionInput = {
  eq?: ModelProductByProductTypeCompositeKeyInput | null,
  le?: ModelProductByProductTypeCompositeKeyInput | null,
  lt?: ModelProductByProductTypeCompositeKeyInput | null,
  ge?: ModelProductByProductTypeCompositeKeyInput | null,
  gt?: ModelProductByProductTypeCompositeKeyInput | null,
  between?: Array< ModelProductByProductTypeCompositeKeyInput | null > | null,
  beginsWith?: ModelProductByProductTypeCompositeKeyInput | null,
};

export type ModelProductByProductTypeCompositeKeyInput = {
  createdAt?: string | null,
  status?: Status | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelProductByProductFilterTypeCompositeKeyConditionInput = {
  eq?: ModelProductByProductFilterTypeCompositeKeyInput | null,
  le?: ModelProductByProductFilterTypeCompositeKeyInput | null,
  lt?: ModelProductByProductFilterTypeCompositeKeyInput | null,
  ge?: ModelProductByProductFilterTypeCompositeKeyInput | null,
  gt?: ModelProductByProductFilterTypeCompositeKeyInput | null,
  between?: Array< ModelProductByProductFilterTypeCompositeKeyInput | null > | null,
  beginsWith?: ModelProductByProductFilterTypeCompositeKeyInput | null,
};

export type ModelProductByProductFilterTypeCompositeKeyInput = {
  createdAt?: string | null,
  status?: Status | null,
};

export type ModelProductByProductFeatureCompositeKeyConditionInput = {
  eq?: ModelProductByProductFeatureCompositeKeyInput | null,
  le?: ModelProductByProductFeatureCompositeKeyInput | null,
  lt?: ModelProductByProductFeatureCompositeKeyInput | null,
  ge?: ModelProductByProductFeatureCompositeKeyInput | null,
  gt?: ModelProductByProductFeatureCompositeKeyInput | null,
  between?: Array< ModelProductByProductFeatureCompositeKeyInput | null > | null,
  beginsWith?: ModelProductByProductFeatureCompositeKeyInput | null,
};

export type ModelProductByProductFeatureCompositeKeyInput = {
  createdAt?: string | null,
  status?: Status | null,
};

export type ModelFeedFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  likeCount?: ModelIntInput | null,
  commentCount?: ModelIntInput | null,
  shareCount?: ModelIntInput | null,
  viewCount?: ModelIntInput | null,
  bookmarkCount?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFeedFilterInput | null > | null,
  or?: Array< ModelFeedFilterInput | null > | null,
  not?: ModelFeedFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelFeedConnection = {
  __typename: "ModelFeedConnection",
  items:  Array<Feed | null >,
  nextToken?: string | null,
};

export type ModelFeedCollaborationFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  feedId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  merchantId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFeedCollaborationFilterInput | null > | null,
  or?: Array< ModelFeedCollaborationFilterInput | null > | null,
  not?: ModelFeedCollaborationFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelFeedTagsFilterInput = {
  id?: ModelIDInput | null,
  feedId?: ModelIDInput | null,
  productVariantId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFeedTagsFilterInput | null > | null,
  or?: Array< ModelFeedTagsFilterInput | null > | null,
  not?: ModelFeedTagsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelFeedMediaFilterInput = {
  id?: ModelIDInput | null,
  file_type?: ModelStringInput | null,
  key?: ModelStringInput | null,
  feedId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  position?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFeedMediaFilterInput | null > | null,
  or?: Array< ModelFeedMediaFilterInput | null > | null,
  not?: ModelFeedMediaFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelLikeFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  productId?: ModelIDInput | null,
  feedId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  likeState?: ModelBooleanInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLikeFilterInput | null > | null,
  or?: Array< ModelLikeFilterInput | null > | null,
  not?: ModelLikeFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelViewsFilterInput = {
  id?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  feedId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  viewCount?: ModelIntInput | null,
  type?: ModelViewProductTypeInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelViewsFilterInput | null > | null,
  or?: Array< ModelViewsFilterInput | null > | null,
  not?: ModelViewsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelViewsConnection = {
  __typename: "ModelViewsConnection",
  items:  Array<Views | null >,
  nextToken?: string | null,
};

export type ModelViewsByUserAndTypeAndSortCompositeKeyConditionInput = {
  eq?: ModelViewsByUserAndTypeAndSortCompositeKeyInput | null,
  le?: ModelViewsByUserAndTypeAndSortCompositeKeyInput | null,
  lt?: ModelViewsByUserAndTypeAndSortCompositeKeyInput | null,
  ge?: ModelViewsByUserAndTypeAndSortCompositeKeyInput | null,
  gt?: ModelViewsByUserAndTypeAndSortCompositeKeyInput | null,
  between?: Array< ModelViewsByUserAndTypeAndSortCompositeKeyInput | null > | null,
  beginsWith?: ModelViewsByUserAndTypeAndSortCompositeKeyInput | null,
};

export type ModelViewsByUserAndTypeAndSortCompositeKeyInput = {
  type?: ViewProductType | null,
  updatedAt?: string | null,
};

export type ModelProductBookmarksFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  productId?: ModelIDInput | null,
  feedId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  bookmarkState?: ModelBooleanInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductBookmarksFilterInput | null > | null,
  or?: Array< ModelProductBookmarksFilterInput | null > | null,
  not?: ModelProductBookmarksFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelCommentsFilterInput = {
  id?: ModelIDInput | null,
  comment?: ModelStringInput | null,
  productId?: ModelIDInput | null,
  feedId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  parentCommentId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCommentsFilterInput | null > | null,
  or?: Array< ModelCommentsFilterInput | null > | null,
  not?: ModelCommentsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductVariantFilterInput = {
  id?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  attribute?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  list_price?: ModelFloatInput | null,
  quantity?: ModelFloatInput | null,
  deliveryMethod?: ModelStringInput | null,
  productCondition?: ModelStringInput | null,
  designStyle?: ModelStringInput | null,
  designPropertyType?: ModelStringInput | null,
  orderCount?: ModelIntInput | null,
  videoLink?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  sku?: ModelStringInput | null,
  defaultPosition?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductVariantFilterInput | null > | null,
  or?: Array< ModelProductVariantFilterInput | null > | null,
  not?: ModelProductVariantFilterInput | null,
  productProductVariantsId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductMediaFilterInput = {
  id?: ModelIDInput | null,
  file_type?: ModelStringInput | null,
  key?: ModelStringInput | null,
  product_variant_id?: ModelIDInput | null,
  position?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductMediaFilterInput | null > | null,
  or?: Array< ModelProductMediaFilterInput | null > | null,
  not?: ModelProductMediaFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductCategoriesFilterInput = {
  id?: ModelIDInput | null,
  categoryId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductCategoriesFilterInput | null > | null,
  or?: Array< ModelProductCategoriesFilterInput | null > | null,
  not?: ModelProductCategoriesFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelProductReviewFilterInput = {
  id?: ModelIDInput | null,
  stars?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  attribute?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  totalImagesCount?: ModelIntInput | null,
  estoreOrderProductId?: ModelIDInput | null,
  bookingId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductReviewFilterInput | null > | null,
  or?: Array< ModelProductReviewFilterInput | null > | null,
  not?: ModelProductReviewFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelReviewMediaFilterInput = {
  id?: ModelIDInput | null,
  file_type?: ModelStringInput | null,
  key?: ModelStringInput | null,
  reviewId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReviewMediaFilterInput | null > | null,
  or?: Array< ModelReviewMediaFilterInput | null > | null,
  not?: ModelReviewMediaFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelEstoreOrdersFilterInput = {
  id?: ModelIDInput | null,
  orderId?: ModelStringInput | null,
  transaction_id?: ModelStringInput | null,
  order_price?: ModelFloatInput | null,
  discount?: ModelFloatInput | null,
  sell_price?: ModelFloatInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  mobile_no?: ModelStringInput | null,
  address_one?: ModelStringInput | null,
  address_two?: ModelStringInput | null,
  pincode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  offerCodeId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  delivery_fee?: ModelFloatInput | null,
  status?: ModelOrderStatusTypeInput | null,
  paymentStatus?: ModelPaymentStatusTypeInput | null,
  statusDescription?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEstoreOrdersFilterInput | null > | null,
  or?: Array< ModelEstoreOrdersFilterInput | null > | null,
  not?: ModelEstoreOrdersFilterInput | null,
};

export type ModelEstoreOrderProductFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  list_price?: ModelFloatInput | null,
  quantity?: ModelIntInput | null,
  invoice?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  productVariantId?: ModelIDInput | null,
  attribute?: ModelStringInput | null,
  status?: ModelOrderStatusTypeInput | null,
  paymentStatus?: ModelPaymentStatusTypeInput | null,
  deliveryStatus?: ModelDeliverStatusTypeInput | null,
  statusDescription?: ModelStringInput | null,
  statusHistory?: ModelStringInput | null,
  cancellationReason?: ModelStringInput | null,
  estore_order_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEstoreOrderProductFilterInput | null > | null,
  or?: Array< ModelEstoreOrderProductFilterInput | null > | null,
  not?: ModelEstoreOrderProductFilterInput | null,
};

export type ModelEstoreOrdersRefundFilterInput = {
  id?: ModelIDInput | null,
  orderProductId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  refund_price?: ModelFloatInput | null,
  reason?: ModelStringInput | null,
  status?: ModelPaymentStatusTypeInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEstoreOrdersRefundFilterInput | null > | null,
  or?: Array< ModelEstoreOrdersRefundFilterInput | null > | null,
  not?: ModelEstoreOrdersRefundFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelEstoreOrdersRefundConnection = {
  __typename: "ModelEstoreOrdersRefundConnection",
  items:  Array<EstoreOrdersRefund | null >,
  nextToken?: string | null,
};

export type ModelTransactionLogsFilterInput = {
  id?: ModelIDInput | null,
  orderId?: ModelStringInput | null,
  type?: ModelStringInput | null,
  response?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTransactionLogsFilterInput | null > | null,
  or?: Array< ModelTransactionLogsFilterInput | null > | null,
  not?: ModelTransactionLogsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelTransactionLogsConnection = {
  __typename: "ModelTransactionLogsConnection",
  items:  Array<TransactionLogs | null >,
  nextToken?: string | null,
};

export type ModelEstoreCartFilterInput = {
  id?: ModelIDInput | null,
  quantity?: ModelIntInput | null,
  userId?: ModelIDInput | null,
  productVariantId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEstoreCartFilterInput | null > | null,
  or?: Array< ModelEstoreCartFilterInput | null > | null,
  not?: ModelEstoreCartFilterInput | null,
};

export type ModelEstoreCartConnection = {
  __typename: "ModelEstoreCartConnection",
  items:  Array<EstoreCart | null >,
  nextToken?: string | null,
};

export type ModelBookingFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelProductTypeInput | null,
  quantity?: ModelIntInput | null,
  productVariantId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  fulfillerId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  status?: ModelBookingStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBookingFilterInput | null > | null,
  or?: Array< ModelBookingFilterInput | null > | null,
  not?: ModelBookingFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelChatFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  group?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  deletedBy?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatFilterInput | null > | null,
  or?: Array< ModelChatFilterInput | null > | null,
  not?: ModelChatFilterInput | null,
};

export type ModelChatParticipantsFilterInput = {
  id?: ModelIDInput | null,
  chatId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  isActive?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatParticipantsFilterInput | null > | null,
  or?: Array< ModelChatParticipantsFilterInput | null > | null,
  not?: ModelChatParticipantsFilterInput | null,
};

export type ModelChatMessagesFilterInput = {
  id?: ModelIDInput | null,
  message?: ModelStringInput | null,
  attachment?: ModelStringInput | null,
  attachment_type?: ModelStringInput | null,
  bookingId?: ModelIDInput | null,
  propertyId?: ModelIDInput | null,
  chatId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatMessagesFilterInput | null > | null,
  or?: Array< ModelChatMessagesFilterInput | null > | null,
  not?: ModelChatMessagesFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelChatMessageUnreadCountFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  chatId?: ModelIDInput | null,
  unreadCount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatMessageUnreadCountFilterInput | null > | null,
  or?: Array< ModelChatMessageUnreadCountFilterInput | null > | null,
  not?: ModelChatMessageUnreadCountFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelNotificationsFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  senderId?: ModelIDInput | null,
  read?: ModelIntInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  data?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNotificationsFilterInput | null > | null,
  or?: Array< ModelNotificationsFilterInput | null > | null,
  not?: ModelNotificationsFilterInput | null,
};

export type ModelNotificationsConnection = {
  __typename: "ModelNotificationsConnection",
  items:  Array<Notifications | null >,
  nextToken?: string | null,
};

export type ModelPostFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelPostTypeInput | null,
  userId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  likeCount?: ModelIntInput | null,
  commentCount?: ModelIntInput | null,
  shareCount?: ModelIntInput | null,
  viewCount?: ModelIntInput | null,
  bookmarkCount?: ModelIntInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPostFilterInput | null > | null,
  or?: Array< ModelPostFilterInput | null > | null,
  not?: ModelPostFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPostConnection = {
  __typename: "ModelPostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
};

export type ModelRecommendationFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  postId?: ModelIDInput | null,
  feedId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRecommendationFilterInput | null > | null,
  or?: Array< ModelRecommendationFilterInput | null > | null,
  not?: ModelRecommendationFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelRecommendationConnection = {
  __typename: "ModelRecommendationConnection",
  items:  Array<Recommendation | null >,
  nextToken?: string | null,
};

export type ModelRecommendationViewsFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRecommendationViewsFilterInput | null > | null,
  or?: Array< ModelRecommendationViewsFilterInput | null > | null,
  not?: ModelRecommendationViewsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelRecommendationViewsConnection = {
  __typename: "ModelRecommendationViewsConnection",
  items:  Array<RecommendationViews | null >,
  nextToken?: string | null,
};

export type ModelFollowFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  followerId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFollowFilterInput | null > | null,
  or?: Array< ModelFollowFilterInput | null > | null,
  not?: ModelFollowFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPropertyFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  type?: ModelPropertyTypeInput | null,
  description?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  bed?: ModelFloatInput | null,
  bath?: ModelFloatInput | null,
  sqft?: ModelFloatInput | null,
  psf?: ModelFloatInput | null,
  listingId?: ModelIntInput | null,
  category?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  bookmarkCount?: ModelIntInput | null,
  likeCount?: ModelIntInput | null,
  viewCount?: ModelIntInput | null,
  unitFeatures?: ModelStringInput | null,
  facilities?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPropertyFilterInput | null > | null,
  or?: Array< ModelPropertyFilterInput | null > | null,
  not?: ModelPropertyFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPropertyConnection = {
  __typename: "ModelPropertyConnection",
  items:  Array<Property | null >,
  nextToken?: string | null,
};

export type ModelAdminConfigFilterInput = {
  id?: ModelStringInput | null,
  type?: ModelStringInput | null,
  value?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAdminConfigFilterInput | null > | null,
  or?: Array< ModelAdminConfigFilterInput | null > | null,
  not?: ModelAdminConfigFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelAdminConfigConnection = {
  __typename: "ModelAdminConfigConnection",
  items:  Array<AdminConfig | null >,
  nextToken?: string | null,
};

export type ModelOfferCodeFilterInput = {
  id?: ModelIDInput | null,
  code?: ModelStringInput | null,
  description?: ModelStringInput | null,
  discountType?: ModelDiscountTypeInput | null,
  discountValue?: ModelFloatInput | null,
  maxUsage?: ModelIntInput | null,
  usageCount?: ModelIntInput | null,
  validFrom?: ModelStringInput | null,
  validTo?: ModelStringInput | null,
  minOrderValue?: ModelFloatInput | null,
  maxDiscountAmount?: ModelFloatInput | null,
  customerRestrictions?: ModelStringInput | null,
  productRestrictions?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOfferCodeFilterInput | null > | null,
  or?: Array< ModelOfferCodeFilterInput | null > | null,
  not?: ModelOfferCodeFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelOfferCodeConnection = {
  __typename: "ModelOfferCodeConnection",
  items:  Array<OfferCode | null >,
  nextToken?: string | null,
};

export type ModelPageHtmlFilterInput = {
  id?: ModelIDInput | null,
  site?: ModelStringInput | null,
  page?: ModelStringInput | null,
  html?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPageHtmlFilterInput | null > | null,
  or?: Array< ModelPageHtmlFilterInput | null > | null,
  not?: ModelPageHtmlFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPageHtmlConnection = {
  __typename: "ModelPageHtmlConnection",
  items:  Array<PageHtml | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  username?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  mobile_no?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  mobile_verified?: ModelSubscriptionBooleanInput | null,
  bio?: ModelSubscriptionStringInput | null,
  picture?: ModelSubscriptionStringInput | null,
  followers?: ModelSubscriptionIntInput | null,
  followings?: ModelSubscriptionIntInput | null,
  online?: ModelSubscriptionBooleanInput | null,
  fcmToken?: ModelSubscriptionStringInput | null,
  merchantFcmToken?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionUserAddressFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  mobile_no?: ModelSubscriptionStringInput | null,
  address_one?: ModelSubscriptionStringInput | null,
  address_two?: ModelSubscriptionStringInput | null,
  pincode?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  default?: ModelSubscriptionIntInput | null,
  userId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserAddressFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserAddressFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionCompanyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ownerType?: ModelSubscriptionStringInput | null,
  uen?: ModelSubscriptionStringInput | null,
  gstNo?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  registration_name?: ModelSubscriptionStringInput | null,
  picture?: ModelSubscriptionStringInput | null,
  bio?: ModelSubscriptionStringInput | null,
  wlcmMsg?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  stripeAccountId?: ModelSubscriptionStringInput | null,
  followers?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCompanyFilterInput | null > | null,
  or?: Array< ModelSubscriptionCompanyFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionCompanyPrivateInfoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  companyDocImage?: ModelSubscriptionStringInput | null,
  contactName?: ModelSubscriptionStringInput | null,
  contactEmail?: ModelSubscriptionStringInput | null,
  contactNo?: ModelSubscriptionStringInput | null,
  bankName?: ModelSubscriptionStringInput | null,
  bankCode?: ModelSubscriptionStringInput | null,
  beneficiaryName?: ModelSubscriptionStringInput | null,
  bankAccNo?: ModelSubscriptionStringInput | null,
  bankDocumnet?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCompanyPrivateInfoFilterInput | null > | null,
  or?: Array< ModelSubscriptionCompanyPrivateInfoFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionCompanyUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  role?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCompanyUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionCompanyUserFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionCompanyReviewFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  stars?: ModelSubscriptionIntInput | null,
  comment?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCompanyReviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionCompanyReviewFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  picture?: ModelSubscriptionStringInput | null,
  parent_categoryId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  filterType?: ModelSubscriptionStringInput | null,
  allowToComment?: ModelSubscriptionIntInput | null,
  feature?: ModelSubscriptionIntInput | null,
  brand?: ModelSubscriptionStringInput | null,
  total_sold?: ModelSubscriptionIntInput | null,
  likeCount?: ModelSubscriptionIntInput | null,
  viewCount?: ModelSubscriptionIntInput | null,
  commentCount?: ModelSubscriptionIntInput | null,
  shareCount?: ModelSubscriptionIntInput | null,
  bookmarkCount?: ModelSubscriptionIntInput | null,
  totalReviewCount?: ModelSubscriptionIntInput | null,
  orderCount?: ModelSubscriptionIntInput | null,
  userId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  metaKeywords?: ModelSubscriptionStringInput | null,
  sku?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductFilterInput | null > | null,
  productProductVariantsId?: ModelSubscriptionIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionFeedFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  likeCount?: ModelSubscriptionIntInput | null,
  commentCount?: ModelSubscriptionIntInput | null,
  shareCount?: ModelSubscriptionIntInput | null,
  viewCount?: ModelSubscriptionIntInput | null,
  bookmarkCount?: ModelSubscriptionIntInput | null,
  userId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFeedFilterInput | null > | null,
  or?: Array< ModelSubscriptionFeedFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionFeedCollaborationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  feedId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  merchantId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFeedCollaborationFilterInput | null > | null,
  or?: Array< ModelSubscriptionFeedCollaborationFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionFeedTagsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  feedId?: ModelSubscriptionIDInput | null,
  productVariantId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFeedTagsFilterInput | null > | null,
  or?: Array< ModelSubscriptionFeedTagsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionFeedMediaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  file_type?: ModelSubscriptionStringInput | null,
  key?: ModelSubscriptionStringInput | null,
  feedId?: ModelSubscriptionIDInput | null,
  postId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  position?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFeedMediaFilterInput | null > | null,
  or?: Array< ModelSubscriptionFeedMediaFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionLikeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  productId?: ModelSubscriptionIDInput | null,
  feedId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  postId?: ModelSubscriptionIDInput | null,
  likeState?: ModelSubscriptionBooleanInput | null,
  userId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLikeFilterInput | null > | null,
  or?: Array< ModelSubscriptionLikeFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionViewsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  productId?: ModelSubscriptionIDInput | null,
  feedId?: ModelSubscriptionIDInput | null,
  postId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  viewCount?: ModelSubscriptionIntInput | null,
  type?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionViewsFilterInput | null > | null,
  or?: Array< ModelSubscriptionViewsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionProductBookmarksFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  productId?: ModelSubscriptionIDInput | null,
  feedId?: ModelSubscriptionIDInput | null,
  postId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  bookmarkState?: ModelSubscriptionBooleanInput | null,
  userId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductBookmarksFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductBookmarksFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionCommentsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  comment?: ModelSubscriptionStringInput | null,
  productId?: ModelSubscriptionIDInput | null,
  feedId?: ModelSubscriptionIDInput | null,
  postId?: ModelSubscriptionIDInput | null,
  parentCommentId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCommentsFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommentsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionProductVariantFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  productId?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  attribute?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  list_price?: ModelSubscriptionFloatInput | null,
  quantity?: ModelSubscriptionFloatInput | null,
  deliveryMethod?: ModelSubscriptionStringInput | null,
  productCondition?: ModelSubscriptionStringInput | null,
  designStyle?: ModelSubscriptionStringInput | null,
  designPropertyType?: ModelSubscriptionStringInput | null,
  orderCount?: ModelSubscriptionIntInput | null,
  videoLink?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  sku?: ModelSubscriptionStringInput | null,
  defaultPosition?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductVariantFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductVariantFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionProductMediaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  file_type?: ModelSubscriptionStringInput | null,
  key?: ModelSubscriptionStringInput | null,
  product_variant_id?: ModelSubscriptionIDInput | null,
  position?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductMediaFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductMediaFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionProductCategoriesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  categoryId?: ModelSubscriptionIDInput | null,
  productId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductCategoriesFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductCategoriesFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionProductReviewFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  stars?: ModelSubscriptionIntInput | null,
  comment?: ModelSubscriptionStringInput | null,
  attribute?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  productId?: ModelSubscriptionIDInput | null,
  totalImagesCount?: ModelSubscriptionIntInput | null,
  estoreOrderProductId?: ModelSubscriptionIDInput | null,
  bookingId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductReviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductReviewFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionReviewMediaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  file_type?: ModelSubscriptionStringInput | null,
  key?: ModelSubscriptionStringInput | null,
  reviewId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReviewMediaFilterInput | null > | null,
  or?: Array< ModelSubscriptionReviewMediaFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionEstoreOrdersFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  orderId?: ModelSubscriptionStringInput | null,
  transaction_id?: ModelSubscriptionStringInput | null,
  order_price?: ModelSubscriptionFloatInput | null,
  discount?: ModelSubscriptionFloatInput | null,
  sell_price?: ModelSubscriptionFloatInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  mobile_no?: ModelSubscriptionStringInput | null,
  address_one?: ModelSubscriptionStringInput | null,
  address_two?: ModelSubscriptionStringInput | null,
  pincode?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  offerCodeId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  delivery_fee?: ModelSubscriptionFloatInput | null,
  status?: ModelSubscriptionStringInput | null,
  paymentStatus?: ModelSubscriptionStringInput | null,
  statusDescription?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEstoreOrdersFilterInput | null > | null,
  or?: Array< ModelSubscriptionEstoreOrdersFilterInput | null > | null,
};

export type ModelSubscriptionEstoreOrderProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  list_price?: ModelSubscriptionFloatInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  invoice?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  productVariantId?: ModelSubscriptionIDInput | null,
  attribute?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  paymentStatus?: ModelSubscriptionStringInput | null,
  deliveryStatus?: ModelSubscriptionStringInput | null,
  statusDescription?: ModelSubscriptionStringInput | null,
  statusHistory?: ModelSubscriptionStringInput | null,
  cancellationReason?: ModelSubscriptionStringInput | null,
  estore_order_id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEstoreOrderProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionEstoreOrderProductFilterInput | null > | null,
};

export type ModelSubscriptionEstoreOrdersRefundFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  orderProductId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  refund_price?: ModelSubscriptionFloatInput | null,
  reason?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEstoreOrdersRefundFilterInput | null > | null,
  or?: Array< ModelSubscriptionEstoreOrdersRefundFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionTransactionLogsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  orderId?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  response?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTransactionLogsFilterInput | null > | null,
  or?: Array< ModelSubscriptionTransactionLogsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionEstoreCartFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  userId?: ModelSubscriptionIDInput | null,
  productVariantId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEstoreCartFilterInput | null > | null,
  or?: Array< ModelSubscriptionEstoreCartFilterInput | null > | null,
};

export type ModelSubscriptionBookingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  productVariantId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  fulfillerId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBookingFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookingFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionChatFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  group?: ModelSubscriptionBooleanInput | null,
  image?: ModelSubscriptionStringInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  deletedBy?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChatFilterInput | null > | null,
  or?: Array< ModelSubscriptionChatFilterInput | null > | null,
};

export type ModelSubscriptionChatParticipantsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  chatId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  isActive?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChatParticipantsFilterInput | null > | null,
  or?: Array< ModelSubscriptionChatParticipantsFilterInput | null > | null,
};

export type ModelSubscriptionChatMessagesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  message?: ModelSubscriptionStringInput | null,
  attachment?: ModelSubscriptionStringInput | null,
  attachment_type?: ModelSubscriptionStringInput | null,
  bookingId?: ModelSubscriptionIDInput | null,
  propertyId?: ModelSubscriptionIDInput | null,
  chatId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChatMessagesFilterInput | null > | null,
  or?: Array< ModelSubscriptionChatMessagesFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionChatMessageUnreadCountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  chatId?: ModelSubscriptionIDInput | null,
  unreadCount?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChatMessageUnreadCountFilterInput | null > | null,
  or?: Array< ModelSubscriptionChatMessageUnreadCountFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionNotificationsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  senderId?: ModelSubscriptionIDInput | null,
  read?: ModelSubscriptionIntInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  data?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationsFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationsFilterInput | null > | null,
};

export type ModelSubscriptionPostFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  likeCount?: ModelSubscriptionIntInput | null,
  commentCount?: ModelSubscriptionIntInput | null,
  shareCount?: ModelSubscriptionIntInput | null,
  viewCount?: ModelSubscriptionIntInput | null,
  bookmarkCount?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPostFilterInput | null > | null,
  or?: Array< ModelSubscriptionPostFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionRecommendationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  productId?: ModelSubscriptionIDInput | null,
  postId?: ModelSubscriptionIDInput | null,
  feedId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRecommendationFilterInput | null > | null,
  or?: Array< ModelSubscriptionRecommendationFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionRecommendationViewsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRecommendationViewsFilterInput | null > | null,
  or?: Array< ModelSubscriptionRecommendationViewsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionFollowFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  followerId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFollowFilterInput | null > | null,
  or?: Array< ModelSubscriptionFollowFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionPropertyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  bed?: ModelSubscriptionFloatInput | null,
  bath?: ModelSubscriptionFloatInput | null,
  sqft?: ModelSubscriptionFloatInput | null,
  psf?: ModelSubscriptionFloatInput | null,
  listingId?: ModelSubscriptionIntInput | null,
  category?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  bookmarkCount?: ModelSubscriptionIntInput | null,
  likeCount?: ModelSubscriptionIntInput | null,
  viewCount?: ModelSubscriptionIntInput | null,
  unitFeatures?: ModelSubscriptionStringInput | null,
  facilities?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPropertyFilterInput | null > | null,
  or?: Array< ModelSubscriptionPropertyFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionAdminConfigFilterInput = {
  id?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  value?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminConfigFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminConfigFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionOfferCodeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  code?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  discountType?: ModelSubscriptionStringInput | null,
  discountValue?: ModelSubscriptionFloatInput | null,
  maxUsage?: ModelSubscriptionIntInput | null,
  usageCount?: ModelSubscriptionIntInput | null,
  validFrom?: ModelSubscriptionStringInput | null,
  validTo?: ModelSubscriptionStringInput | null,
  minOrderValue?: ModelSubscriptionFloatInput | null,
  maxDiscountAmount?: ModelSubscriptionFloatInput | null,
  customerRestrictions?: ModelSubscriptionStringInput | null,
  productRestrictions?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOfferCodeFilterInput | null > | null,
  or?: Array< ModelSubscriptionOfferCodeFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionPageHtmlFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  site?: ModelSubscriptionStringInput | null,
  page?: ModelSubscriptionStringInput | null,
  html?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPageHtmlFilterInput | null > | null,
  or?: Array< ModelSubscriptionPageHtmlFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type SearchProductFMutationVariables = {
  input?: searchProductInput | null,
};

export type SearchProductFMutation = {
  searchProductF?:  {
    __typename: "PublicResp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type OpenaiSearchProductFMutationVariables = {
  input?: openAiSerchInput | null,
};

export type OpenaiSearchProductFMutation = {
  openaiSearchProductF?:  {
    __typename: "PublicResp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type UpdateShareCountFMutationVariables = {
  input?: updateShareCountInput | null,
};

export type UpdateShareCountFMutation = {
  updateShareCountF?:  {
    __typename: "PublicResp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type OpenAISuggestionGeneratorMutationVariables = {
  input?: openAISuggestionGeneratorInput | null,
};

export type OpenAISuggestionGeneratorMutation = {
  openAISuggestionGenerator?:  {
    __typename: "PublicResp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type GiveNextRelatedProductMutationVariables = {
  input?: giveNextRelatedProductInput | null,
};

export type GiveNextRelatedProductMutation = {
  giveNextRelatedProduct?:  {
    __typename: "PublicResp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    username: string,
    email: string,
    mobile_no?: string | null,
    name?: string | null,
    gender?: string | null,
    country: string,
    mobile_verified?: boolean | null,
    bio?: string | null,
    picture?: string | null,
    userAddressList?:  {
      __typename: "ModelUserAddressConnection",
      nextToken?: string | null,
    } | null,
    companyUser?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    userProductList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    productReview?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    estoreOrderList?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    followers?: number | null,
    followings?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    online?: boolean | null,
    fcmToken?: string | null,
    merchantFcmToken?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type DeleteCompanyMutation = {
  deleteCompany?:  {
    __typename: "Company",
    id: string,
    ownerType?: OwnerType | null,
    uen?: string | null,
    gstNo?: string | null,
    type?: Array< ComapnyType | null > | null,
    name?: string | null,
    email?: string | null,
    registration_name?: string | null,
    picture?: string | null,
    bio?: string | null,
    wlcmMsg?: string | null,
    address?: string | null,
    category?: string | null,
    status?: CompanyStatus | null,
    companyUserList?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    productList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    chats?:  {
      __typename: "ModelChatConnection",
      nextToken?: string | null,
    } | null,
    stripeAccountId?: string | null,
    followers?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCompanyPrivateInfoMutationVariables = {
  input: DeleteCompanyPrivateInfoInput,
  condition?: ModelCompanyPrivateInfoConditionInput | null,
};

export type DeleteCompanyPrivateInfoMutation = {
  deleteCompanyPrivateInfo?:  {
    __typename: "CompanyPrivateInfo",
    id: string,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyDocImage?: string | null,
    contactName?: string | null,
    contactEmail?: string | null,
    contactNo?: string | null,
    conatactDocImage?:  {
      __typename: "DocumentImage",
      front?: string | null,
      back?: string | null,
    } | null,
    bankName?: string | null,
    bankCode?: string | null,
    beneficiaryName?: string | null,
    bankAccNo?: string | null,
    bankDocumnet?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    type: ProductCategoryType,
    description?: string | null,
    picture?: string | null,
    parent_categoryId?: string | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    productCategories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCommentsMutationVariables = {
  input: DeleteCommentsInput,
  condition?: ModelCommentsConditionInput | null,
};

export type DeleteCommentsMutation = {
  deleteComments?:  {
    __typename: "Comments",
    id: string,
    comment: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    parentCommentId?: string | null,
    commentReplyList?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    userId: string,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    username: string,
    email: string,
    mobile_no?: string | null,
    name?: string | null,
    gender?: string | null,
    country: string,
    mobile_verified?: boolean | null,
    bio?: string | null,
    picture?: string | null,
    userAddressList?:  {
      __typename: "ModelUserAddressConnection",
      nextToken?: string | null,
    } | null,
    companyUser?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    userProductList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    productReview?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    estoreOrderList?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    followers?: number | null,
    followings?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    online?: boolean | null,
    fcmToken?: string | null,
    merchantFcmToken?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    username: string,
    email: string,
    mobile_no?: string | null,
    name?: string | null,
    gender?: string | null,
    country: string,
    mobile_verified?: boolean | null,
    bio?: string | null,
    picture?: string | null,
    userAddressList?:  {
      __typename: "ModelUserAddressConnection",
      nextToken?: string | null,
    } | null,
    companyUser?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    userProductList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    productReview?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    estoreOrderList?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    followers?: number | null,
    followings?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    online?: boolean | null,
    fcmToken?: string | null,
    merchantFcmToken?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUserAddressMutationVariables = {
  input: CreateUserAddressInput,
  condition?: ModelUserAddressConditionInput | null,
};

export type CreateUserAddressMutation = {
  createUserAddress?:  {
    __typename: "UserAddress",
    id: string,
    name: string,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    default?: number | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserAddressMutationVariables = {
  input: UpdateUserAddressInput,
  condition?: ModelUserAddressConditionInput | null,
};

export type UpdateUserAddressMutation = {
  updateUserAddress?:  {
    __typename: "UserAddress",
    id: string,
    name: string,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    default?: number | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserAddressMutationVariables = {
  input: DeleteUserAddressInput,
  condition?: ModelUserAddressConditionInput | null,
};

export type DeleteUserAddressMutation = {
  deleteUserAddress?:  {
    __typename: "UserAddress",
    id: string,
    name: string,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    default?: number | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type CreateCompanyMutation = {
  createCompany?:  {
    __typename: "Company",
    id: string,
    ownerType?: OwnerType | null,
    uen?: string | null,
    gstNo?: string | null,
    type?: Array< ComapnyType | null > | null,
    name?: string | null,
    email?: string | null,
    registration_name?: string | null,
    picture?: string | null,
    bio?: string | null,
    wlcmMsg?: string | null,
    address?: string | null,
    category?: string | null,
    status?: CompanyStatus | null,
    companyUserList?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    productList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    chats?:  {
      __typename: "ModelChatConnection",
      nextToken?: string | null,
    } | null,
    stripeAccountId?: string | null,
    followers?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type UpdateCompanyMutation = {
  updateCompany?:  {
    __typename: "Company",
    id: string,
    ownerType?: OwnerType | null,
    uen?: string | null,
    gstNo?: string | null,
    type?: Array< ComapnyType | null > | null,
    name?: string | null,
    email?: string | null,
    registration_name?: string | null,
    picture?: string | null,
    bio?: string | null,
    wlcmMsg?: string | null,
    address?: string | null,
    category?: string | null,
    status?: CompanyStatus | null,
    companyUserList?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    productList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    chats?:  {
      __typename: "ModelChatConnection",
      nextToken?: string | null,
    } | null,
    stripeAccountId?: string | null,
    followers?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCompanyPrivateInfoMutationVariables = {
  input: CreateCompanyPrivateInfoInput,
  condition?: ModelCompanyPrivateInfoConditionInput | null,
};

export type CreateCompanyPrivateInfoMutation = {
  createCompanyPrivateInfo?:  {
    __typename: "CompanyPrivateInfo",
    id: string,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyDocImage?: string | null,
    contactName?: string | null,
    contactEmail?: string | null,
    contactNo?: string | null,
    conatactDocImage?:  {
      __typename: "DocumentImage",
      front?: string | null,
      back?: string | null,
    } | null,
    bankName?: string | null,
    bankCode?: string | null,
    beneficiaryName?: string | null,
    bankAccNo?: string | null,
    bankDocumnet?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCompanyPrivateInfoMutationVariables = {
  input: UpdateCompanyPrivateInfoInput,
  condition?: ModelCompanyPrivateInfoConditionInput | null,
};

export type UpdateCompanyPrivateInfoMutation = {
  updateCompanyPrivateInfo?:  {
    __typename: "CompanyPrivateInfo",
    id: string,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyDocImage?: string | null,
    contactName?: string | null,
    contactEmail?: string | null,
    contactNo?: string | null,
    conatactDocImage?:  {
      __typename: "DocumentImage",
      front?: string | null,
      back?: string | null,
    } | null,
    bankName?: string | null,
    bankCode?: string | null,
    beneficiaryName?: string | null,
    bankAccNo?: string | null,
    bankDocumnet?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCompanyUserMutationVariables = {
  input: CreateCompanyUserInput,
  condition?: ModelCompanyUserConditionInput | null,
};

export type CreateCompanyUserMutation = {
  createCompanyUser?:  {
    __typename: "CompanyUser",
    id: string,
    role: Role,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    modules?:  Array< {
      __typename: "ModuleList",
      moduleName?: string | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
      read?: boolean | null,
    } | null > | null,
    status?: CompanyUserStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCompanyUserMutationVariables = {
  input: UpdateCompanyUserInput,
  condition?: ModelCompanyUserConditionInput | null,
};

export type UpdateCompanyUserMutation = {
  updateCompanyUser?:  {
    __typename: "CompanyUser",
    id: string,
    role: Role,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    modules?:  Array< {
      __typename: "ModuleList",
      moduleName?: string | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
      read?: boolean | null,
    } | null > | null,
    status?: CompanyUserStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCompanyUserMutationVariables = {
  input: DeleteCompanyUserInput,
  condition?: ModelCompanyUserConditionInput | null,
};

export type DeleteCompanyUserMutation = {
  deleteCompanyUser?:  {
    __typename: "CompanyUser",
    id: string,
    role: Role,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    modules?:  Array< {
      __typename: "ModuleList",
      moduleName?: string | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
      read?: boolean | null,
    } | null > | null,
    status?: CompanyUserStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCompanyReviewMutationVariables = {
  input: CreateCompanyReviewInput,
  condition?: ModelCompanyReviewConditionInput | null,
};

export type CreateCompanyReviewMutation = {
  createCompanyReview?:  {
    __typename: "CompanyReview",
    id: string,
    stars?: number | null,
    comment?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCompanyReviewMutationVariables = {
  input: UpdateCompanyReviewInput,
  condition?: ModelCompanyReviewConditionInput | null,
};

export type UpdateCompanyReviewMutation = {
  updateCompanyReview?:  {
    __typename: "CompanyReview",
    id: string,
    stars?: number | null,
    comment?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCompanyReviewMutationVariables = {
  input: DeleteCompanyReviewInput,
  condition?: ModelCompanyReviewConditionInput | null,
};

export type DeleteCompanyReviewMutation = {
  deleteCompanyReview?:  {
    __typename: "CompanyReview",
    id: string,
    stars?: number | null,
    comment?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    type: ProductCategoryType,
    description?: string | null,
    picture?: string | null,
    parent_categoryId?: string | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    productCategories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    type: ProductCategoryType,
    description?: string | null,
    picture?: string | null,
    parent_categoryId?: string | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    productCategories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    type: ProductType,
    filterType: string,
    attributes?:  Array< {
      __typename: "ProductAttribute",
      name: string,
      options?: Array< string | null > | null,
    } | null > | null,
    allowToComment?: number | null,
    feature?: number | null,
    brand?: string | null,
    total_sold?: number | null,
    rating?:  {
      __typename: "RatingType",
      one?: number | null,
      two?: number | null,
      three?: number | null,
      four?: number | null,
      five?: number | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    commentCount?: number | null,
    shareCount?: number | null,
    bookmarkCount?: number | null,
    totalReviewCount?: number | null,
    orderCount?: number | null,
    productVariants?:  {
      __typename: "ModelProductVariantConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    metaKeywords?: string | null,
    sku?: string | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    type: ProductType,
    filterType: string,
    attributes?:  Array< {
      __typename: "ProductAttribute",
      name: string,
      options?: Array< string | null > | null,
    } | null > | null,
    allowToComment?: number | null,
    feature?: number | null,
    brand?: string | null,
    total_sold?: number | null,
    rating?:  {
      __typename: "RatingType",
      one?: number | null,
      two?: number | null,
      three?: number | null,
      four?: number | null,
      five?: number | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    commentCount?: number | null,
    shareCount?: number | null,
    bookmarkCount?: number | null,
    totalReviewCount?: number | null,
    orderCount?: number | null,
    productVariants?:  {
      __typename: "ModelProductVariantConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    metaKeywords?: string | null,
    sku?: string | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    type: ProductType,
    filterType: string,
    attributes?:  Array< {
      __typename: "ProductAttribute",
      name: string,
      options?: Array< string | null > | null,
    } | null > | null,
    allowToComment?: number | null,
    feature?: number | null,
    brand?: string | null,
    total_sold?: number | null,
    rating?:  {
      __typename: "RatingType",
      one?: number | null,
      two?: number | null,
      three?: number | null,
      four?: number | null,
      five?: number | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    commentCount?: number | null,
    shareCount?: number | null,
    bookmarkCount?: number | null,
    totalReviewCount?: number | null,
    orderCount?: number | null,
    productVariants?:  {
      __typename: "ModelProductVariantConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    metaKeywords?: string | null,
    sku?: string | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateFeedMutationVariables = {
  input: CreateFeedInput,
  condition?: ModelFeedConditionInput | null,
};

export type CreateFeedMutation = {
  createFeed?:  {
    __typename: "Feed",
    id: string,
    title: string,
    description: string,
    type: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelFeedTagsConnection",
      nextToken?: string | null,
    } | null,
    collaborations?:  {
      __typename: "ModelFeedCollaborationConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateFeedMutationVariables = {
  input: UpdateFeedInput,
  condition?: ModelFeedConditionInput | null,
};

export type UpdateFeedMutation = {
  updateFeed?:  {
    __typename: "Feed",
    id: string,
    title: string,
    description: string,
    type: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelFeedTagsConnection",
      nextToken?: string | null,
    } | null,
    collaborations?:  {
      __typename: "ModelFeedCollaborationConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteFeedMutationVariables = {
  input: DeleteFeedInput,
  condition?: ModelFeedConditionInput | null,
};

export type DeleteFeedMutation = {
  deleteFeed?:  {
    __typename: "Feed",
    id: string,
    title: string,
    description: string,
    type: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelFeedTagsConnection",
      nextToken?: string | null,
    } | null,
    collaborations?:  {
      __typename: "ModelFeedCollaborationConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateFeedCollaborationMutationVariables = {
  input: CreateFeedCollaborationInput,
  condition?: ModelFeedCollaborationConditionInput | null,
};

export type CreateFeedCollaborationMutation = {
  createFeedCollaboration?:  {
    __typename: "FeedCollaboration",
    id: string,
    type?: string | null,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    merchantId?: string | null,
    merchant?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateFeedCollaborationMutationVariables = {
  input: UpdateFeedCollaborationInput,
  condition?: ModelFeedCollaborationConditionInput | null,
};

export type UpdateFeedCollaborationMutation = {
  updateFeedCollaboration?:  {
    __typename: "FeedCollaboration",
    id: string,
    type?: string | null,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    merchantId?: string | null,
    merchant?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteFeedCollaborationMutationVariables = {
  input: DeleteFeedCollaborationInput,
  condition?: ModelFeedCollaborationConditionInput | null,
};

export type DeleteFeedCollaborationMutation = {
  deleteFeedCollaboration?:  {
    __typename: "FeedCollaboration",
    id: string,
    type?: string | null,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    merchantId?: string | null,
    merchant?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateFeedTagsMutationVariables = {
  input: CreateFeedTagsInput,
  condition?: ModelFeedTagsConditionInput | null,
};

export type CreateFeedTagsMutation = {
  createFeedTags?:  {
    __typename: "FeedTags",
    id: string,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId?: string | null,
    companyId?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateFeedTagsMutationVariables = {
  input: UpdateFeedTagsInput,
  condition?: ModelFeedTagsConditionInput | null,
};

export type UpdateFeedTagsMutation = {
  updateFeedTags?:  {
    __typename: "FeedTags",
    id: string,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId?: string | null,
    companyId?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteFeedTagsMutationVariables = {
  input: DeleteFeedTagsInput,
  condition?: ModelFeedTagsConditionInput | null,
};

export type DeleteFeedTagsMutation = {
  deleteFeedTags?:  {
    __typename: "FeedTags",
    id: string,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId?: string | null,
    companyId?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateFeedMediaMutationVariables = {
  input: CreateFeedMediaInput,
  condition?: ModelFeedMediaConditionInput | null,
};

export type CreateFeedMediaMutation = {
  createFeedMedia?:  {
    __typename: "FeedMedia",
    id: string,
    file_type: string,
    key: string,
    feedId: string,
    Feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId: string,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId: string,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateFeedMediaMutationVariables = {
  input: UpdateFeedMediaInput,
  condition?: ModelFeedMediaConditionInput | null,
};

export type UpdateFeedMediaMutation = {
  updateFeedMedia?:  {
    __typename: "FeedMedia",
    id: string,
    file_type: string,
    key: string,
    feedId: string,
    Feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId: string,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId: string,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteFeedMediaMutationVariables = {
  input: DeleteFeedMediaInput,
  condition?: ModelFeedMediaConditionInput | null,
};

export type DeleteFeedMediaMutation = {
  deleteFeedMedia?:  {
    __typename: "FeedMedia",
    id: string,
    file_type: string,
    key: string,
    feedId: string,
    Feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId: string,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId: string,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateLikeMutationVariables = {
  input: CreateLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type CreateLikeMutation = {
  createLike?:  {
    __typename: "Like",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    likeState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateLikeMutationVariables = {
  input: UpdateLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type UpdateLikeMutation = {
  updateLike?:  {
    __typename: "Like",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    likeState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteLikeMutationVariables = {
  input: DeleteLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type DeleteLikeMutation = {
  deleteLike?:  {
    __typename: "Like",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    likeState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateViewsMutationVariables = {
  input: CreateViewsInput,
  condition?: ModelViewsConditionInput | null,
};

export type CreateViewsMutation = {
  createViews?:  {
    __typename: "Views",
    id: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    viewCount?: number | null,
    type: ViewProductType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateViewsMutationVariables = {
  input: UpdateViewsInput,
  condition?: ModelViewsConditionInput | null,
};

export type UpdateViewsMutation = {
  updateViews?:  {
    __typename: "Views",
    id: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    viewCount?: number | null,
    type: ViewProductType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteViewsMutationVariables = {
  input: DeleteViewsInput,
  condition?: ModelViewsConditionInput | null,
};

export type DeleteViewsMutation = {
  deleteViews?:  {
    __typename: "Views",
    id: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    viewCount?: number | null,
    type: ViewProductType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateProductBookmarksMutationVariables = {
  input: CreateProductBookmarksInput,
  condition?: ModelProductBookmarksConditionInput | null,
};

export type CreateProductBookmarksMutation = {
  createProductBookmarks?:  {
    __typename: "ProductBookmarks",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookmarkState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductBookmarksMutationVariables = {
  input: UpdateProductBookmarksInput,
  condition?: ModelProductBookmarksConditionInput | null,
};

export type UpdateProductBookmarksMutation = {
  updateProductBookmarks?:  {
    __typename: "ProductBookmarks",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookmarkState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductBookmarksMutationVariables = {
  input: DeleteProductBookmarksInput,
  condition?: ModelProductBookmarksConditionInput | null,
};

export type DeleteProductBookmarksMutation = {
  deleteProductBookmarks?:  {
    __typename: "ProductBookmarks",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookmarkState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCommentsMutationVariables = {
  input: CreateCommentsInput,
  condition?: ModelCommentsConditionInput | null,
};

export type CreateCommentsMutation = {
  createComments?:  {
    __typename: "Comments",
    id: string,
    comment: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    parentCommentId?: string | null,
    commentReplyList?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    userId: string,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCommentsMutationVariables = {
  input: UpdateCommentsInput,
  condition?: ModelCommentsConditionInput | null,
};

export type UpdateCommentsMutation = {
  updateComments?:  {
    __typename: "Comments",
    id: string,
    comment: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    parentCommentId?: string | null,
    commentReplyList?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    userId: string,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductVariantMutationVariables = {
  input: CreateProductVariantInput,
  condition?: ModelProductVariantConditionInput | null,
};

export type CreateProductVariantMutation = {
  createProductVariant?:  {
    __typename: "ProductVariant",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    title: string,
    description: string,
    attribute?: string | null,
    price?: number | null,
    list_price?: number | null,
    quantity?: number | null,
    deliveryMethod?: string | null,
    productCondition?: string | null,
    productUsed?:  {
      __typename: "ProductUsedType",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    designStyle?: string | null,
    designPropertyType?: string | null,
    orderCount?: number | null,
    videoLink?: string | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    status?: Status | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    sku?: string | null,
    defaultPosition?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    productProductVariantsId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateProductVariantMutationVariables = {
  input: UpdateProductVariantInput,
  condition?: ModelProductVariantConditionInput | null,
};

export type UpdateProductVariantMutation = {
  updateProductVariant?:  {
    __typename: "ProductVariant",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    title: string,
    description: string,
    attribute?: string | null,
    price?: number | null,
    list_price?: number | null,
    quantity?: number | null,
    deliveryMethod?: string | null,
    productCondition?: string | null,
    productUsed?:  {
      __typename: "ProductUsedType",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    designStyle?: string | null,
    designPropertyType?: string | null,
    orderCount?: number | null,
    videoLink?: string | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    status?: Status | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    sku?: string | null,
    defaultPosition?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    productProductVariantsId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteProductVariantMutationVariables = {
  input: DeleteProductVariantInput,
  condition?: ModelProductVariantConditionInput | null,
};

export type DeleteProductVariantMutation = {
  deleteProductVariant?:  {
    __typename: "ProductVariant",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    title: string,
    description: string,
    attribute?: string | null,
    price?: number | null,
    list_price?: number | null,
    quantity?: number | null,
    deliveryMethod?: string | null,
    productCondition?: string | null,
    productUsed?:  {
      __typename: "ProductUsedType",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    designStyle?: string | null,
    designPropertyType?: string | null,
    orderCount?: number | null,
    videoLink?: string | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    status?: Status | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    sku?: string | null,
    defaultPosition?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    productProductVariantsId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateProductMediaMutationVariables = {
  input: CreateProductMediaInput,
  condition?: ModelProductMediaConditionInput | null,
};

export type CreateProductMediaMutation = {
  createProductMedia?:  {
    __typename: "ProductMedia",
    id: string,
    file_type: string,
    key: string,
    product_variant_id: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductMediaMutationVariables = {
  input: UpdateProductMediaInput,
  condition?: ModelProductMediaConditionInput | null,
};

export type UpdateProductMediaMutation = {
  updateProductMedia?:  {
    __typename: "ProductMedia",
    id: string,
    file_type: string,
    key: string,
    product_variant_id: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductMediaMutationVariables = {
  input: DeleteProductMediaInput,
  condition?: ModelProductMediaConditionInput | null,
};

export type DeleteProductMediaMutation = {
  deleteProductMedia?:  {
    __typename: "ProductMedia",
    id: string,
    file_type: string,
    key: string,
    product_variant_id: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductCategoriesMutationVariables = {
  input: CreateProductCategoriesInput,
  condition?: ModelProductCategoriesConditionInput | null,
};

export type CreateProductCategoriesMutation = {
  createProductCategories?:  {
    __typename: "ProductCategories",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      type: ProductCategoryType,
      description?: string | null,
      picture?: string | null,
      parent_categoryId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductCategoriesMutationVariables = {
  input: UpdateProductCategoriesInput,
  condition?: ModelProductCategoriesConditionInput | null,
};

export type UpdateProductCategoriesMutation = {
  updateProductCategories?:  {
    __typename: "ProductCategories",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      type: ProductCategoryType,
      description?: string | null,
      picture?: string | null,
      parent_categoryId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductCategoriesMutationVariables = {
  input: DeleteProductCategoriesInput,
  condition?: ModelProductCategoriesConditionInput | null,
};

export type DeleteProductCategoriesMutation = {
  deleteProductCategories?:  {
    __typename: "ProductCategories",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      type: ProductCategoryType,
      description?: string | null,
      picture?: string | null,
      parent_categoryId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductReviewMutationVariables = {
  input: CreateProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type CreateProductReviewMutation = {
  createProductReview?:  {
    __typename: "ProductReview",
    id: string,
    stars: number,
    comment?: string | null,
    attribute?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    totalImagesCount?: number | null,
    estoreOrderProductId?: string | null,
    estoreOrderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    bookingId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    reviewMediaList?:  {
      __typename: "ModelReviewMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductReviewMutationVariables = {
  input: UpdateProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type UpdateProductReviewMutation = {
  updateProductReview?:  {
    __typename: "ProductReview",
    id: string,
    stars: number,
    comment?: string | null,
    attribute?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    totalImagesCount?: number | null,
    estoreOrderProductId?: string | null,
    estoreOrderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    bookingId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    reviewMediaList?:  {
      __typename: "ModelReviewMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductReviewMutationVariables = {
  input: DeleteProductReviewInput,
  condition?: ModelProductReviewConditionInput | null,
};

export type DeleteProductReviewMutation = {
  deleteProductReview?:  {
    __typename: "ProductReview",
    id: string,
    stars: number,
    comment?: string | null,
    attribute?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    totalImagesCount?: number | null,
    estoreOrderProductId?: string | null,
    estoreOrderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    bookingId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    reviewMediaList?:  {
      __typename: "ModelReviewMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateReviewMediaMutationVariables = {
  input: CreateReviewMediaInput,
  condition?: ModelReviewMediaConditionInput | null,
};

export type CreateReviewMediaMutation = {
  createReviewMedia?:  {
    __typename: "ReviewMedia",
    id: string,
    file_type: string,
    key: string,
    reviewId: string,
    productReview?:  {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateReviewMediaMutationVariables = {
  input: UpdateReviewMediaInput,
  condition?: ModelReviewMediaConditionInput | null,
};

export type UpdateReviewMediaMutation = {
  updateReviewMedia?:  {
    __typename: "ReviewMedia",
    id: string,
    file_type: string,
    key: string,
    reviewId: string,
    productReview?:  {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteReviewMediaMutationVariables = {
  input: DeleteReviewMediaInput,
  condition?: ModelReviewMediaConditionInput | null,
};

export type DeleteReviewMediaMutation = {
  deleteReviewMedia?:  {
    __typename: "ReviewMedia",
    id: string,
    file_type: string,
    key: string,
    reviewId: string,
    productReview?:  {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateEstoreOrdersMutationVariables = {
  input: CreateEstoreOrdersInput,
  condition?: ModelEstoreOrdersConditionInput | null,
};

export type CreateEstoreOrdersMutation = {
  createEstoreOrders?:  {
    __typename: "EstoreOrders",
    id: string,
    orderId: string,
    transaction_id?: string | null,
    order_price: number,
    discount?: number | null,
    sell_price?: number | null,
    name?: string | null,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city: string,
    state?: string | null,
    country: string,
    offerCodeId?: string | null,
    offerCode?:  {
      __typename: "OfferCode",
      id: string,
      code: string,
      description?: string | null,
      discountType: DiscountType,
      discountValue: number,
      maxUsage?: number | null,
      usageCount?: number | null,
      validFrom?: string | null,
      validTo?: string | null,
      minOrderValue?: number | null,
      maxDiscountAmount?: number | null,
      customerRestrictions?: Array< string | null > | null,
      productRestrictions?: Array< string | null > | null,
      status: Status,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    delivery_fee?: number | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    statusDescription?: string | null,
    estoreOrderProducts?:  {
      __typename: "ModelEstoreOrderProductConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateEstoreOrdersMutationVariables = {
  input: UpdateEstoreOrdersInput,
  condition?: ModelEstoreOrdersConditionInput | null,
};

export type UpdateEstoreOrdersMutation = {
  updateEstoreOrders?:  {
    __typename: "EstoreOrders",
    id: string,
    orderId: string,
    transaction_id?: string | null,
    order_price: number,
    discount?: number | null,
    sell_price?: number | null,
    name?: string | null,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city: string,
    state?: string | null,
    country: string,
    offerCodeId?: string | null,
    offerCode?:  {
      __typename: "OfferCode",
      id: string,
      code: string,
      description?: string | null,
      discountType: DiscountType,
      discountValue: number,
      maxUsage?: number | null,
      usageCount?: number | null,
      validFrom?: string | null,
      validTo?: string | null,
      minOrderValue?: number | null,
      maxDiscountAmount?: number | null,
      customerRestrictions?: Array< string | null > | null,
      productRestrictions?: Array< string | null > | null,
      status: Status,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    delivery_fee?: number | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    statusDescription?: string | null,
    estoreOrderProducts?:  {
      __typename: "ModelEstoreOrderProductConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteEstoreOrdersMutationVariables = {
  input: DeleteEstoreOrdersInput,
  condition?: ModelEstoreOrdersConditionInput | null,
};

export type DeleteEstoreOrdersMutation = {
  deleteEstoreOrders?:  {
    __typename: "EstoreOrders",
    id: string,
    orderId: string,
    transaction_id?: string | null,
    order_price: number,
    discount?: number | null,
    sell_price?: number | null,
    name?: string | null,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city: string,
    state?: string | null,
    country: string,
    offerCodeId?: string | null,
    offerCode?:  {
      __typename: "OfferCode",
      id: string,
      code: string,
      description?: string | null,
      discountType: DiscountType,
      discountValue: number,
      maxUsage?: number | null,
      usageCount?: number | null,
      validFrom?: string | null,
      validTo?: string | null,
      minOrderValue?: number | null,
      maxDiscountAmount?: number | null,
      customerRestrictions?: Array< string | null > | null,
      productRestrictions?: Array< string | null > | null,
      status: Status,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    delivery_fee?: number | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    statusDescription?: string | null,
    estoreOrderProducts?:  {
      __typename: "ModelEstoreOrderProductConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateEstoreOrderProductMutationVariables = {
  input: CreateEstoreOrderProductInput,
  condition?: ModelEstoreOrderProductConditionInput | null,
};

export type CreateEstoreOrderProductMutation = {
  createEstoreOrderProduct?:  {
    __typename: "EstoreOrderProduct",
    id: string,
    title: string,
    description: string,
    price: number,
    list_price: number,
    quantity: number,
    invoice?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    attribute?: string | null,
    add_ons?:  {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    deliveryStatus: DeliverStatusType,
    statusDescription?: string | null,
    statusHistory?: string | null,
    cancellationReason?: string | null,
    estore_order_id?: string | null,
    estoreOrder?:  {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateEstoreOrderProductMutationVariables = {
  input: UpdateEstoreOrderProductInput,
  condition?: ModelEstoreOrderProductConditionInput | null,
};

export type UpdateEstoreOrderProductMutation = {
  updateEstoreOrderProduct?:  {
    __typename: "EstoreOrderProduct",
    id: string,
    title: string,
    description: string,
    price: number,
    list_price: number,
    quantity: number,
    invoice?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    attribute?: string | null,
    add_ons?:  {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    deliveryStatus: DeliverStatusType,
    statusDescription?: string | null,
    statusHistory?: string | null,
    cancellationReason?: string | null,
    estore_order_id?: string | null,
    estoreOrder?:  {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteEstoreOrderProductMutationVariables = {
  input: DeleteEstoreOrderProductInput,
  condition?: ModelEstoreOrderProductConditionInput | null,
};

export type DeleteEstoreOrderProductMutation = {
  deleteEstoreOrderProduct?:  {
    __typename: "EstoreOrderProduct",
    id: string,
    title: string,
    description: string,
    price: number,
    list_price: number,
    quantity: number,
    invoice?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    attribute?: string | null,
    add_ons?:  {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    deliveryStatus: DeliverStatusType,
    statusDescription?: string | null,
    statusHistory?: string | null,
    cancellationReason?: string | null,
    estore_order_id?: string | null,
    estoreOrder?:  {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateEstoreOrdersRefundMutationVariables = {
  input: CreateEstoreOrdersRefundInput,
  condition?: ModelEstoreOrdersRefundConditionInput | null,
};

export type CreateEstoreOrdersRefundMutation = {
  createEstoreOrdersRefund?:  {
    __typename: "EstoreOrdersRefund",
    id: string,
    orderProductId: string,
    orderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    refund_price: number,
    reason: string,
    status: PaymentStatusType,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateEstoreOrdersRefundMutationVariables = {
  input: UpdateEstoreOrdersRefundInput,
  condition?: ModelEstoreOrdersRefundConditionInput | null,
};

export type UpdateEstoreOrdersRefundMutation = {
  updateEstoreOrdersRefund?:  {
    __typename: "EstoreOrdersRefund",
    id: string,
    orderProductId: string,
    orderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    refund_price: number,
    reason: string,
    status: PaymentStatusType,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteEstoreOrdersRefundMutationVariables = {
  input: DeleteEstoreOrdersRefundInput,
  condition?: ModelEstoreOrdersRefundConditionInput | null,
};

export type DeleteEstoreOrdersRefundMutation = {
  deleteEstoreOrdersRefund?:  {
    __typename: "EstoreOrdersRefund",
    id: string,
    orderProductId: string,
    orderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    refund_price: number,
    reason: string,
    status: PaymentStatusType,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateTransactionLogsMutationVariables = {
  input: CreateTransactionLogsInput,
  condition?: ModelTransactionLogsConditionInput | null,
};

export type CreateTransactionLogsMutation = {
  createTransactionLogs?:  {
    __typename: "TransactionLogs",
    id: string,
    orderId?: string | null,
    type: string,
    response: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateTransactionLogsMutationVariables = {
  input: UpdateTransactionLogsInput,
  condition?: ModelTransactionLogsConditionInput | null,
};

export type UpdateTransactionLogsMutation = {
  updateTransactionLogs?:  {
    __typename: "TransactionLogs",
    id: string,
    orderId?: string | null,
    type: string,
    response: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteTransactionLogsMutationVariables = {
  input: DeleteTransactionLogsInput,
  condition?: ModelTransactionLogsConditionInput | null,
};

export type DeleteTransactionLogsMutation = {
  deleteTransactionLogs?:  {
    __typename: "TransactionLogs",
    id: string,
    orderId?: string | null,
    type: string,
    response: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateEstoreCartMutationVariables = {
  input: CreateEstoreCartInput,
  condition?: ModelEstoreCartConditionInput | null,
};

export type CreateEstoreCartMutation = {
  createEstoreCart?:  {
    __typename: "EstoreCart",
    id: string,
    quantity: number,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status: Status,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateEstoreCartMutationVariables = {
  input: UpdateEstoreCartInput,
  condition?: ModelEstoreCartConditionInput | null,
};

export type UpdateEstoreCartMutation = {
  updateEstoreCart?:  {
    __typename: "EstoreCart",
    id: string,
    quantity: number,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status: Status,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteEstoreCartMutationVariables = {
  input: DeleteEstoreCartInput,
  condition?: ModelEstoreCartConditionInput | null,
};

export type DeleteEstoreCartMutation = {
  deleteEstoreCart?:  {
    __typename: "EstoreCart",
    id: string,
    quantity: number,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status: Status,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateBookingMutationVariables = {
  input: CreateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type CreateBookingMutation = {
  createBooking?:  {
    __typename: "Booking",
    id: string,
    type: ProductType,
    quantity?: number | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    fulfillerId: string,
    fulfiller?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    status: BookingStatus,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateBookingMutationVariables = {
  input: UpdateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type UpdateBookingMutation = {
  updateBooking?:  {
    __typename: "Booking",
    id: string,
    type: ProductType,
    quantity?: number | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    fulfillerId: string,
    fulfiller?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    status: BookingStatus,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteBookingMutationVariables = {
  input: DeleteBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type DeleteBookingMutation = {
  deleteBooking?:  {
    __typename: "Booking",
    id: string,
    type: ProductType,
    quantity?: number | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    fulfillerId: string,
    fulfiller?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    status: BookingStatus,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateChatMutationVariables = {
  input: CreateChatInput,
  condition?: ModelChatConditionInput | null,
};

export type CreateChatMutation = {
  createChat?:  {
    __typename: "Chat",
    id: string,
    name?: string | null,
    group?: boolean | null,
    image?: string | null,
    participants?:  {
      __typename: "ModelChatParticipantsConnection",
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessagesConnection",
      nextToken?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    chatMessageUnreadCount?:  {
      __typename: "ModelChatMessageUnreadCountConnection",
      nextToken?: string | null,
    } | null,
    deletedBy?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateChatMutationVariables = {
  input: UpdateChatInput,
  condition?: ModelChatConditionInput | null,
};

export type UpdateChatMutation = {
  updateChat?:  {
    __typename: "Chat",
    id: string,
    name?: string | null,
    group?: boolean | null,
    image?: string | null,
    participants?:  {
      __typename: "ModelChatParticipantsConnection",
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessagesConnection",
      nextToken?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    chatMessageUnreadCount?:  {
      __typename: "ModelChatMessageUnreadCountConnection",
      nextToken?: string | null,
    } | null,
    deletedBy?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteChatMutationVariables = {
  input: DeleteChatInput,
  condition?: ModelChatConditionInput | null,
};

export type DeleteChatMutation = {
  deleteChat?:  {
    __typename: "Chat",
    id: string,
    name?: string | null,
    group?: boolean | null,
    image?: string | null,
    participants?:  {
      __typename: "ModelChatParticipantsConnection",
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessagesConnection",
      nextToken?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    chatMessageUnreadCount?:  {
      __typename: "ModelChatMessageUnreadCountConnection",
      nextToken?: string | null,
    } | null,
    deletedBy?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateChatParticipantsMutationVariables = {
  input: CreateChatParticipantsInput,
  condition?: ModelChatParticipantsConditionInput | null,
};

export type CreateChatParticipantsMutation = {
  createChatParticipants?:  {
    __typename: "ChatParticipants",
    id: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    isActive?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateChatParticipantsMutationVariables = {
  input: UpdateChatParticipantsInput,
  condition?: ModelChatParticipantsConditionInput | null,
};

export type UpdateChatParticipantsMutation = {
  updateChatParticipants?:  {
    __typename: "ChatParticipants",
    id: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    isActive?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteChatParticipantsMutationVariables = {
  input: DeleteChatParticipantsInput,
  condition?: ModelChatParticipantsConditionInput | null,
};

export type DeleteChatParticipantsMutation = {
  deleteChatParticipants?:  {
    __typename: "ChatParticipants",
    id: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    isActive?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateChatMessagesMutationVariables = {
  input: CreateChatMessagesInput,
  condition?: ModelChatMessagesConditionInput | null,
};

export type CreateChatMessagesMutation = {
  createChatMessages?:  {
    __typename: "ChatMessages",
    id: string,
    message?: string | null,
    attachment?: string | null,
    attachment_type?: string | null,
    bookingId?: string | null,
    propertyId?: string | null,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    User?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateChatMessagesMutationVariables = {
  input: UpdateChatMessagesInput,
  condition?: ModelChatMessagesConditionInput | null,
};

export type UpdateChatMessagesMutation = {
  updateChatMessages?:  {
    __typename: "ChatMessages",
    id: string,
    message?: string | null,
    attachment?: string | null,
    attachment_type?: string | null,
    bookingId?: string | null,
    propertyId?: string | null,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    User?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteChatMessagesMutationVariables = {
  input: DeleteChatMessagesInput,
  condition?: ModelChatMessagesConditionInput | null,
};

export type DeleteChatMessagesMutation = {
  deleteChatMessages?:  {
    __typename: "ChatMessages",
    id: string,
    message?: string | null,
    attachment?: string | null,
    attachment_type?: string | null,
    bookingId?: string | null,
    propertyId?: string | null,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    User?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateChatMessageUnreadCountMutationVariables = {
  input: CreateChatMessageUnreadCountInput,
  condition?: ModelChatMessageUnreadCountConditionInput | null,
};

export type CreateChatMessageUnreadCountMutation = {
  createChatMessageUnreadCount?:  {
    __typename: "ChatMessageUnreadCount",
    id: string,
    userId: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    unreadCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateChatMessageUnreadCountMutationVariables = {
  input: UpdateChatMessageUnreadCountInput,
  condition?: ModelChatMessageUnreadCountConditionInput | null,
};

export type UpdateChatMessageUnreadCountMutation = {
  updateChatMessageUnreadCount?:  {
    __typename: "ChatMessageUnreadCount",
    id: string,
    userId: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    unreadCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteChatMessageUnreadCountMutationVariables = {
  input: DeleteChatMessageUnreadCountInput,
  condition?: ModelChatMessageUnreadCountConditionInput | null,
};

export type DeleteChatMessageUnreadCountMutation = {
  deleteChatMessageUnreadCount?:  {
    __typename: "ChatMessageUnreadCount",
    id: string,
    userId: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    unreadCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateNotificationsMutationVariables = {
  input: CreateNotificationsInput,
  condition?: ModelNotificationsConditionInput | null,
};

export type CreateNotificationsMutation = {
  createNotifications?:  {
    __typename: "Notifications",
    id: string,
    userId: string,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    read: number,
    title: string,
    description: string,
    imageUrl?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationsMutationVariables = {
  input: UpdateNotificationsInput,
  condition?: ModelNotificationsConditionInput | null,
};

export type UpdateNotificationsMutation = {
  updateNotifications?:  {
    __typename: "Notifications",
    id: string,
    userId: string,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    read: number,
    title: string,
    description: string,
    imageUrl?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationsMutationVariables = {
  input: DeleteNotificationsInput,
  condition?: ModelNotificationsConditionInput | null,
};

export type DeleteNotificationsMutation = {
  deleteNotifications?:  {
    __typename: "Notifications",
    id: string,
    userId: string,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    read: number,
    title: string,
    description: string,
    imageUrl?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreatePostMutationVariables = {
  input: CreatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type CreatePostMutation = {
  createPost?:  {
    __typename: "Post",
    id: string,
    type?: PostType | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    title: string,
    description: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePostMutationVariables = {
  input: UpdatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type UpdatePostMutation = {
  updatePost?:  {
    __typename: "Post",
    id: string,
    type?: PostType | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    title: string,
    description: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePostMutationVariables = {
  input: DeletePostInput,
  condition?: ModelPostConditionInput | null,
};

export type DeletePostMutation = {
  deletePost?:  {
    __typename: "Post",
    id: string,
    type?: PostType | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    title: string,
    description: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateRecommendationMutationVariables = {
  input: CreateRecommendationInput,
  condition?: ModelRecommendationConditionInput | null,
};

export type CreateRecommendationMutation = {
  createRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    postId?: string | null,
    feedId?: string | null,
    createdAt?: string | null,
    ttl: number,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateRecommendationMutationVariables = {
  input: UpdateRecommendationInput,
  condition?: ModelRecommendationConditionInput | null,
};

export type UpdateRecommendationMutation = {
  updateRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    postId?: string | null,
    feedId?: string | null,
    createdAt?: string | null,
    ttl: number,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteRecommendationMutationVariables = {
  input: DeleteRecommendationInput,
  condition?: ModelRecommendationConditionInput | null,
};

export type DeleteRecommendationMutation = {
  deleteRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    postId?: string | null,
    feedId?: string | null,
    createdAt?: string | null,
    ttl: number,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateRecommendationViewsMutationVariables = {
  input: CreateRecommendationViewsInput,
  condition?: ModelRecommendationViewsConditionInput | null,
};

export type CreateRecommendationViewsMutation = {
  createRecommendationViews?:  {
    __typename: "RecommendationViews",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateRecommendationViewsMutationVariables = {
  input: UpdateRecommendationViewsInput,
  condition?: ModelRecommendationViewsConditionInput | null,
};

export type UpdateRecommendationViewsMutation = {
  updateRecommendationViews?:  {
    __typename: "RecommendationViews",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteRecommendationViewsMutationVariables = {
  input: DeleteRecommendationViewsInput,
  condition?: ModelRecommendationViewsConditionInput | null,
};

export type DeleteRecommendationViewsMutation = {
  deleteRecommendationViews?:  {
    __typename: "RecommendationViews",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateFollowMutationVariables = {
  input: CreateFollowInput,
  condition?: ModelFollowConditionInput | null,
};

export type CreateFollowMutation = {
  createFollow?:  {
    __typename: "Follow",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    followerId?: string | null,
    follower?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateFollowMutationVariables = {
  input: UpdateFollowInput,
  condition?: ModelFollowConditionInput | null,
};

export type UpdateFollowMutation = {
  updateFollow?:  {
    __typename: "Follow",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    followerId?: string | null,
    follower?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteFollowMutationVariables = {
  input: DeleteFollowInput,
  condition?: ModelFollowConditionInput | null,
};

export type DeleteFollowMutation = {
  deleteFollow?:  {
    __typename: "Follow",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    followerId?: string | null,
    follower?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePropertyMutationVariables = {
  input: CreatePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type CreatePropertyMutation = {
  createProperty?:  {
    __typename: "Property",
    id: string,
    title: string,
    type: PropertyType,
    description: string,
    price: number,
    bed?: number | null,
    bath?: number | null,
    sqft?: number | null,
    psf?: number | null,
    listingId?: number | null,
    category?: string | null,
    detailsJson?:  Array< {
      __typename: "KeyValue",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    unitFeatures?: Array< string | null > | null,
    facilities?: Array< string | null > | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdatePropertyMutationVariables = {
  input: UpdatePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type UpdatePropertyMutation = {
  updateProperty?:  {
    __typename: "Property",
    id: string,
    title: string,
    type: PropertyType,
    description: string,
    price: number,
    bed?: number | null,
    bath?: number | null,
    sqft?: number | null,
    psf?: number | null,
    listingId?: number | null,
    category?: string | null,
    detailsJson?:  Array< {
      __typename: "KeyValue",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    unitFeatures?: Array< string | null > | null,
    facilities?: Array< string | null > | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeletePropertyMutationVariables = {
  input: DeletePropertyInput,
  condition?: ModelPropertyConditionInput | null,
};

export type DeletePropertyMutation = {
  deleteProperty?:  {
    __typename: "Property",
    id: string,
    title: string,
    type: PropertyType,
    description: string,
    price: number,
    bed?: number | null,
    bath?: number | null,
    sqft?: number | null,
    psf?: number | null,
    listingId?: number | null,
    category?: string | null,
    detailsJson?:  Array< {
      __typename: "KeyValue",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    unitFeatures?: Array< string | null > | null,
    facilities?: Array< string | null > | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateAdminConfigMutationVariables = {
  input: CreateAdminConfigInput,
  condition?: ModelAdminConfigConditionInput | null,
};

export type CreateAdminConfigMutation = {
  createAdminConfig?:  {
    __typename: "AdminConfig",
    id: string,
    type?: string | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateAdminConfigMutationVariables = {
  input: UpdateAdminConfigInput,
  condition?: ModelAdminConfigConditionInput | null,
};

export type UpdateAdminConfigMutation = {
  updateAdminConfig?:  {
    __typename: "AdminConfig",
    id: string,
    type?: string | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteAdminConfigMutationVariables = {
  input: DeleteAdminConfigInput,
  condition?: ModelAdminConfigConditionInput | null,
};

export type DeleteAdminConfigMutation = {
  deleteAdminConfig?:  {
    __typename: "AdminConfig",
    id: string,
    type?: string | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateOfferCodeMutationVariables = {
  input: CreateOfferCodeInput,
  condition?: ModelOfferCodeConditionInput | null,
};

export type CreateOfferCodeMutation = {
  createOfferCode?:  {
    __typename: "OfferCode",
    id: string,
    code: string,
    description?: string | null,
    discountType: DiscountType,
    discountValue: number,
    maxUsage?: number | null,
    usageCount?: number | null,
    validFrom?: string | null,
    validTo?: string | null,
    minOrderValue?: number | null,
    maxDiscountAmount?: number | null,
    customerRestrictions?: Array< string | null > | null,
    productRestrictions?: Array< string | null > | null,
    status: Status,
    estoreOrders?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateOfferCodeMutationVariables = {
  input: UpdateOfferCodeInput,
  condition?: ModelOfferCodeConditionInput | null,
};

export type UpdateOfferCodeMutation = {
  updateOfferCode?:  {
    __typename: "OfferCode",
    id: string,
    code: string,
    description?: string | null,
    discountType: DiscountType,
    discountValue: number,
    maxUsage?: number | null,
    usageCount?: number | null,
    validFrom?: string | null,
    validTo?: string | null,
    minOrderValue?: number | null,
    maxDiscountAmount?: number | null,
    customerRestrictions?: Array< string | null > | null,
    productRestrictions?: Array< string | null > | null,
    status: Status,
    estoreOrders?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteOfferCodeMutationVariables = {
  input: DeleteOfferCodeInput,
  condition?: ModelOfferCodeConditionInput | null,
};

export type DeleteOfferCodeMutation = {
  deleteOfferCode?:  {
    __typename: "OfferCode",
    id: string,
    code: string,
    description?: string | null,
    discountType: DiscountType,
    discountValue: number,
    maxUsage?: number | null,
    usageCount?: number | null,
    validFrom?: string | null,
    validTo?: string | null,
    minOrderValue?: number | null,
    maxDiscountAmount?: number | null,
    customerRestrictions?: Array< string | null > | null,
    productRestrictions?: Array< string | null > | null,
    status: Status,
    estoreOrders?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreatePageHtmlMutationVariables = {
  input: CreatePageHtmlInput,
  condition?: ModelPageHtmlConditionInput | null,
};

export type CreatePageHtmlMutation = {
  createPageHtml?:  {
    __typename: "PageHtml",
    id: string,
    site: string,
    page: string,
    html: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdatePageHtmlMutationVariables = {
  input: UpdatePageHtmlInput,
  condition?: ModelPageHtmlConditionInput | null,
};

export type UpdatePageHtmlMutation = {
  updatePageHtml?:  {
    __typename: "PageHtml",
    id: string,
    site: string,
    page: string,
    html: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type DeletePageHtmlMutationVariables = {
  input: DeletePageHtmlInput,
  condition?: ModelPageHtmlConditionInput | null,
};

export type DeletePageHtmlMutation = {
  deletePageHtml?:  {
    __typename: "PageHtml",
    id: string,
    site: string,
    page: string,
    html: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateProductFMutationVariables = {
  input?: ProductInput | null,
};

export type CreateProductFMutation = {
  createProductF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type EditProductFMutationVariables = {
  input?: editProductInput | null,
};

export type EditProductFMutation = {
  editProductF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type EditProductVariantFMutationVariables = {
  input?: editProductVariantFInput | null,
};

export type EditProductVariantFMutation = {
  editProductVariantF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type EditProductWithAllVariantsFMutationVariables = {
  input?: editProductWithAllVariantsInput | null,
};

export type EditProductWithAllVariantsFMutation = {
  editProductWithAllVariantsF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type BulkUploadProductFMutationVariables = {
  input?: bulkUploadProductInput | null,
};

export type BulkUploadProductFMutation = {
  bulkUploadProductF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type EstoreOrderProductIssueRefundMutationVariables = {
  input?: estoreOrderProductIssueRefundInput | null,
};

export type EstoreOrderProductIssueRefundMutation = {
  estoreOrderProductIssueRefund?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type EstoreCheckOutFMutationVariables = {
  input?: estoreCheckOutInput | null,
};

export type EstoreCheckOutFMutation = {
  estoreCheckOutF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type EstoreClearCartFMutationVariables = {
  input?: estoreClearCartInput | null,
};

export type EstoreClearCartFMutation = {
  estoreClearCartF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type EstoreUpdateOrderProductStatusFMutationVariables = {
  input?: estoreUpdateOrderStatusInput | null,
};

export type EstoreUpdateOrderProductStatusFMutation = {
  estoreUpdateOrderProductStatusF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type SearchProductByUserFMutationVariables = {
  input?: searchProductInput | null,
};

export type SearchProductByUserFMutation = {
  searchProductByUserF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type UpdateReadCountFMutationVariables = {
  input?: updateReadCountFInput | null,
};

export type UpdateReadCountFMutation = {
  updateReadCountF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type InitiateChatFMutationVariables = {
  input?: initiateChatInput | null,
};

export type InitiateChatFMutation = {
  initiateChatF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type LikeUnlikeFMutationVariables = {
  input?: likeUnlikeFInput | null,
};

export type LikeUnlikeFMutation = {
  likeUnlikeF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type ViewProductFMutationVariables = {
  input?: ViewProductFInput | null,
};

export type ViewProductFMutation = {
  viewProductF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type CommentAddFMutationVariables = {
  input?: commentAddInput | null,
};

export type CommentAddFMutation = {
  commentAddF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type CommentDeleteFMutationVariables = {
  input?: commentDeleteInput | null,
};

export type CommentDeleteFMutation = {
  commentDeleteF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type BookingStatusChangeFMutationVariables = {
  input?: bookingStatusChangeInput | null,
};

export type BookingStatusChangeFMutation = {
  bookingStatusChangeF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type BookmarkSaveUnSaveFMutationVariables = {
  input?: bookmarkSaveUnSaveInput | null,
};

export type BookmarkSaveUnSaveFMutation = {
  bookmarkSaveUnSaveF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type AddProductReviewFMutationVariables = {
  input?: addProductReviewInput | null,
};

export type AddProductReviewFMutation = {
  addProductReviewF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type EditProductReviewFMutationVariables = {
  input?: editProductReviewInput | null,
};

export type EditProductReviewFMutation = {
  editProductReviewF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type RequestForMerchantFMutationVariables = {
  input?: requestForMerchantInput | null,
};

export type RequestForMerchantFMutation = {
  requestForMerchantF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type UpdateFcmTokenFMutationVariables = {
  input?: updateFcmTokenInput | null,
};

export type UpdateFcmTokenFMutation = {
  updateFcmTokenF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type ClearUserNotificationFMutationVariables = {
  input?: clearUserNotificationInput | null,
};

export type ClearUserNotificationFMutation = {
  clearUserNotificationF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type AddFeedFMutationVariables = {
  input?: addFeedInput | null,
};

export type AddFeedFMutation = {
  addFeedF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type EditFeedFMutationVariables = {
  input?: editFeedInput | null,
};

export type EditFeedFMutation = {
  editFeedF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type CreatePostFMutationVariables = {
  input?: addPostInput | null,
};

export type CreatePostFMutation = {
  createPostF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type EditPostFMutationVariables = {
  input?: editPostInput | null,
};

export type EditPostFMutation = {
  editPostF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type StripePaymentIntentsListMutationVariables = {
  input?: stripePaymentIntentsListInput | null,
};

export type StripePaymentIntentsListMutation = {
  stripePaymentIntentsList?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type StripeConnectMutationVariables = {
  input?: stripeConnectInput | null,
};

export type StripeConnectMutation = {
  stripeConnect?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type AddStripeAccountMutationVariables = {
  input?: addStripeAccountInput | null,
};

export type AddStripeAccountMutation = {
  addStripeAccount?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type GenerateTextAiMutationVariables = {
  input?: generateTextAiInput | null,
};

export type GenerateTextAiMutation = {
  generateTextAi?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type SendNotificationToUserIdsMutationVariables = {
  input?: notificationsSendInput | null,
};

export type SendNotificationToUserIdsMutation = {
  sendNotificationToUserIds?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type SendTopicNotificationMutationVariables = {
  input?: notificationsSendInput | null,
};

export type SendTopicNotificationMutation = {
  sendTopicNotification?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type FollowAddFMutationVariables = {
  input?: followAddFInput | null,
};

export type FollowAddFMutation = {
  followAddF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type CreatePropertyFMutationVariables = {
  input?: createPropertyInput | null,
};

export type CreatePropertyFMutation = {
  createPropertyF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type EditPropertyFMutationVariables = {
  input?: editPropertyInput | null,
};

export type EditPropertyFMutation = {
  editPropertyF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type OfferAddFMutationVariables = {
  input?: offerAddInput | null,
};

export type OfferAddFMutation = {
  offerAddF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    username: string,
    email: string,
    mobile_no?: string | null,
    name?: string | null,
    gender?: string | null,
    country: string,
    mobile_verified?: boolean | null,
    bio?: string | null,
    picture?: string | null,
    userAddressList?:  {
      __typename: "ModelUserAddressConnection",
      nextToken?: string | null,
    } | null,
    companyUser?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    userProductList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    productReview?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    estoreOrderList?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    followers?: number | null,
    followings?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    online?: boolean | null,
    fcmToken?: string | null,
    merchantFcmToken?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByUsernameAndCreatedAtQueryVariables = {
  username: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByUsernameAndCreatedAtQuery = {
  usersByUsernameAndCreatedAt?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByEmailAndCreatedAtQueryVariables = {
  email: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByEmailAndCreatedAtQuery = {
  usersByEmailAndCreatedAt?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByCountryAndNameQueryVariables = {
  country: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByCountryAndNameQuery = {
  usersByCountryAndName?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByCountryAndEmailQueryVariables = {
  country: string,
  email?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByCountryAndEmailQuery = {
  usersByCountryAndEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserAddressQueryVariables = {
  id: string,
};

export type GetUserAddressQuery = {
  getUserAddress?:  {
    __typename: "UserAddress",
    id: string,
    name: string,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    default?: number | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserAddressesQueryVariables = {
  filter?: ModelUserAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserAddressesQuery = {
  listUserAddresses?:  {
    __typename: "ModelUserAddressConnection",
    items:  Array< {
      __typename: "UserAddress",
      id: string,
      name: string,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      default?: number | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserAddressesByDefaultAndUserIdQueryVariables = {
  default: number,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserAddressesByDefaultAndUserIdQuery = {
  userAddressesByDefaultAndUserId?:  {
    __typename: "ModelUserAddressConnection",
    items:  Array< {
      __typename: "UserAddress",
      id: string,
      name: string,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      default?: number | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserAddressesByUserIdAndIdQueryVariables = {
  userId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserAddressesByUserIdAndIdQuery = {
  userAddressesByUserIdAndId?:  {
    __typename: "ModelUserAddressConnection",
    items:  Array< {
      __typename: "UserAddress",
      id: string,
      name: string,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      default?: number | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  id: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    ownerType?: OwnerType | null,
    uen?: string | null,
    gstNo?: string | null,
    type?: Array< ComapnyType | null > | null,
    name?: string | null,
    email?: string | null,
    registration_name?: string | null,
    picture?: string | null,
    bio?: string | null,
    wlcmMsg?: string | null,
    address?: string | null,
    category?: string | null,
    status?: CompanyStatus | null,
    companyUserList?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    productList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    chats?:  {
      __typename: "ModelChatConnection",
      nextToken?: string | null,
    } | null,
    stripeAccountId?: string | null,
    followers?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCompaniesQueryVariables = {
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompaniesQuery = {
  listCompanies?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompaniesByUenAndCreatedAtQueryVariables = {
  uen: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompaniesByUenAndCreatedAtQuery = {
  companiesByUenAndCreatedAt?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompaniesByGstNoAndCreatedAtQueryVariables = {
  gstNo: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompaniesByGstNoAndCreatedAtQuery = {
  companiesByGstNoAndCreatedAt?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompaniesByStatusAndCreatedAtQueryVariables = {
  status: CompanyStatus,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompaniesByStatusAndCreatedAtQuery = {
  companiesByStatusAndCreatedAt?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyPrivateInfoQueryVariables = {
  id: string,
};

export type GetCompanyPrivateInfoQuery = {
  getCompanyPrivateInfo?:  {
    __typename: "CompanyPrivateInfo",
    id: string,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyDocImage?: string | null,
    contactName?: string | null,
    contactEmail?: string | null,
    contactNo?: string | null,
    conatactDocImage?:  {
      __typename: "DocumentImage",
      front?: string | null,
      back?: string | null,
    } | null,
    bankName?: string | null,
    bankCode?: string | null,
    beneficiaryName?: string | null,
    bankAccNo?: string | null,
    bankDocumnet?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCompanyPrivateInfosQueryVariables = {
  filter?: ModelCompanyPrivateInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompanyPrivateInfosQuery = {
  listCompanyPrivateInfos?:  {
    __typename: "ModelCompanyPrivateInfoConnection",
    items:  Array< {
      __typename: "CompanyPrivateInfo",
      id: string,
      companyId: string,
      companyDocImage?: string | null,
      contactName?: string | null,
      contactEmail?: string | null,
      contactNo?: string | null,
      bankName?: string | null,
      bankCode?: string | null,
      beneficiaryName?: string | null,
      bankAccNo?: string | null,
      bankDocumnet?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyPrivateInfosByCompanyIdAndIdQueryVariables = {
  companyId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyPrivateInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyPrivateInfosByCompanyIdAndIdQuery = {
  companyPrivateInfosByCompanyIdAndId?:  {
    __typename: "ModelCompanyPrivateInfoConnection",
    items:  Array< {
      __typename: "CompanyPrivateInfo",
      id: string,
      companyId: string,
      companyDocImage?: string | null,
      contactName?: string | null,
      contactEmail?: string | null,
      contactNo?: string | null,
      bankName?: string | null,
      bankCode?: string | null,
      beneficiaryName?: string | null,
      bankAccNo?: string | null,
      bankDocumnet?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyUserQueryVariables = {
  id: string,
};

export type GetCompanyUserQuery = {
  getCompanyUser?:  {
    __typename: "CompanyUser",
    id: string,
    role: Role,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    modules?:  Array< {
      __typename: "ModuleList",
      moduleName?: string | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
      read?: boolean | null,
    } | null > | null,
    status?: CompanyUserStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCompanyUsersQueryVariables = {
  filter?: ModelCompanyUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompanyUsersQuery = {
  listCompanyUsers?:  {
    __typename: "ModelCompanyUserConnection",
    items:  Array< {
      __typename: "CompanyUser",
      id: string,
      role: Role,
      userId: string,
      companyId: string,
      status?: CompanyUserStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyUsersByUserIdAndCompanyIdQueryVariables = {
  userId: string,
  companyId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyUsersByUserIdAndCompanyIdQuery = {
  companyUsersByUserIdAndCompanyId?:  {
    __typename: "ModelCompanyUserConnection",
    items:  Array< {
      __typename: "CompanyUser",
      id: string,
      role: Role,
      userId: string,
      companyId: string,
      status?: CompanyUserStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyUsersByCompanyIdAndUserIdQueryVariables = {
  companyId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyUsersByCompanyIdAndUserIdQuery = {
  companyUsersByCompanyIdAndUserId?:  {
    __typename: "ModelCompanyUserConnection",
    items:  Array< {
      __typename: "CompanyUser",
      id: string,
      role: Role,
      userId: string,
      companyId: string,
      status?: CompanyUserStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyReviewQueryVariables = {
  id: string,
};

export type GetCompanyReviewQuery = {
  getCompanyReview?:  {
    __typename: "CompanyReview",
    id: string,
    stars?: number | null,
    comment?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCompanyReviewsQueryVariables = {
  filter?: ModelCompanyReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompanyReviewsQuery = {
  listCompanyReviews?:  {
    __typename: "ModelCompanyReviewConnection",
    items:  Array< {
      __typename: "CompanyReview",
      id: string,
      stars?: number | null,
      comment?: string | null,
      userId: string,
      companyId: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyReviewsByUserIdAndIdQueryVariables = {
  userId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyReviewsByUserIdAndIdQuery = {
  companyReviewsByUserIdAndId?:  {
    __typename: "ModelCompanyReviewConnection",
    items:  Array< {
      __typename: "CompanyReview",
      id: string,
      stars?: number | null,
      comment?: string | null,
      userId: string,
      companyId: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyReviewsByCompanyIdAndIdQueryVariables = {
  companyId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyReviewsByCompanyIdAndIdQuery = {
  companyReviewsByCompanyIdAndId?:  {
    __typename: "ModelCompanyReviewConnection",
    items:  Array< {
      __typename: "CompanyReview",
      id: string,
      stars?: number | null,
      comment?: string | null,
      userId: string,
      companyId: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    type: ProductCategoryType,
    description?: string | null,
    picture?: string | null,
    parent_categoryId?: string | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    productCategories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      type: ProductCategoryType,
      description?: string | null,
      picture?: string | null,
      parent_categoryId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoryListByTypeQueryVariables = {
  type: ProductCategoryType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryListByTypeQuery = {
  categoryListByType?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      type: ProductCategoryType,
      description?: string | null,
      picture?: string | null,
      parent_categoryId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoriesByParent_categoryIdQueryVariables = {
  parent_categoryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesByParent_categoryIdQuery = {
  categoriesByParent_categoryId?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      type: ProductCategoryType,
      description?: string | null,
      picture?: string | null,
      parent_categoryId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    type: ProductType,
    filterType: string,
    attributes?:  Array< {
      __typename: "ProductAttribute",
      name: string,
      options?: Array< string | null > | null,
    } | null > | null,
    allowToComment?: number | null,
    feature?: number | null,
    brand?: string | null,
    total_sold?: number | null,
    rating?:  {
      __typename: "RatingType",
      one?: number | null,
      two?: number | null,
      three?: number | null,
      four?: number | null,
      five?: number | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    commentCount?: number | null,
    shareCount?: number | null,
    bookmarkCount?: number | null,
    totalReviewCount?: number | null,
    orderCount?: number | null,
    productVariants?:  {
      __typename: "ModelProductVariantConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    metaKeywords?: string | null,
    sku?: string | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductListByTypeQueryVariables = {
  type: ProductType,
  createdAtStatus?: ModelProductByProductTypeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductListByTypeQuery = {
  productListByType?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MostViewedProductListQueryVariables = {
  type: ProductType,
  viewCount?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MostViewedProductListQuery = {
  mostViewedProductList?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MostOrderedProductsQueryVariables = {
  type: ProductType,
  orderCount?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MostOrderedProductsQuery = {
  mostOrderedProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductListByFilterTypeQueryVariables = {
  filterType: string,
  createdAtStatus?: ModelProductByProductFilterTypeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductListByFilterTypeQuery = {
  productListByFilterType?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductFeatureListQueryVariables = {
  feature: number,
  createdAtStatus?: ModelProductByProductFeatureCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductFeatureListQuery = {
  productFeatureList?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductsByUserIdAndIdQueryVariables = {
  userId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductsByUserIdAndIdQuery = {
  productsByUserIdAndId?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductsByUserIdAndTypeQueryVariables = {
  userId: string,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductsByUserIdAndTypeQuery = {
  productsByUserIdAndType?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductsByUserIdAndStatusQueryVariables = {
  userId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductsByUserIdAndStatusQuery = {
  productsByUserIdAndStatus?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductsByCompanyIdAndIdQueryVariables = {
  companyId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductsByCompanyIdAndIdQuery = {
  productsByCompanyIdAndId?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductListByCompanyIdAndTypeQueryVariables = {
  companyId: string,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductListByCompanyIdAndTypeQuery = {
  productListByCompanyIdAndType?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductListBySkuQueryVariables = {
  sku: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductListBySkuQuery = {
  productListBySku?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductsByStatusAndCompanyIdQueryVariables = {
  status: Status,
  companyId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductsByStatusAndCompanyIdQuery = {
  productsByStatusAndCompanyId?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductListByStatusAndTypeQueryVariables = {
  status: Status,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductListByStatusAndTypeQuery = {
  productListByStatusAndType?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFeedQueryVariables = {
  id: string,
};

export type GetFeedQuery = {
  getFeed?:  {
    __typename: "Feed",
    id: string,
    title: string,
    description: string,
    type: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelFeedTagsConnection",
      nextToken?: string | null,
    } | null,
    collaborations?:  {
      __typename: "ModelFeedCollaborationConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListFeedsQueryVariables = {
  filter?: ModelFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeedsQuery = {
  listFeeds?:  {
    __typename: "ModelFeedConnection",
    items:  Array< {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedsByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedsByUserIdAndCreatedAtQuery = {
  feedsByUserIdAndCreatedAt?:  {
    __typename: "ModelFeedConnection",
    items:  Array< {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedsByCompanyIdAndCreatedAtQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedsByCompanyIdAndCreatedAtQuery = {
  feedsByCompanyIdAndCreatedAt?:  {
    __typename: "ModelFeedConnection",
    items:  Array< {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedByStatusQueryVariables = {
  status: Status,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedByStatusQuery = {
  FeedByStatus?:  {
    __typename: "ModelFeedConnection",
    items:  Array< {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedByStatusAndTypeQueryVariables = {
  status: Status,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedByStatusAndTypeQuery = {
  FeedByStatusAndType?:  {
    __typename: "ModelFeedConnection",
    items:  Array< {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFeedCollaborationQueryVariables = {
  id: string,
};

export type GetFeedCollaborationQuery = {
  getFeedCollaboration?:  {
    __typename: "FeedCollaboration",
    id: string,
    type?: string | null,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    merchantId?: string | null,
    merchant?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListFeedCollaborationsQueryVariables = {
  filter?: ModelFeedCollaborationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeedCollaborationsQuery = {
  listFeedCollaborations?:  {
    __typename: "ModelFeedCollaborationConnection",
    items:  Array< {
      __typename: "FeedCollaboration",
      id: string,
      type?: string | null,
      feedId: string,
      companyId?: string | null,
      merchantId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedCollaborationsByFeedIdAndCreatedAtQueryVariables = {
  feedId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedCollaborationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedCollaborationsByFeedIdAndCreatedAtQuery = {
  feedCollaborationsByFeedIdAndCreatedAt?:  {
    __typename: "ModelFeedCollaborationConnection",
    items:  Array< {
      __typename: "FeedCollaboration",
      id: string,
      type?: string | null,
      feedId: string,
      companyId?: string | null,
      merchantId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedCollaborationsByCompanyIdAndCreatedAtQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedCollaborationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedCollaborationsByCompanyIdAndCreatedAtQuery = {
  feedCollaborationsByCompanyIdAndCreatedAt?:  {
    __typename: "ModelFeedCollaborationConnection",
    items:  Array< {
      __typename: "FeedCollaboration",
      id: string,
      type?: string | null,
      feedId: string,
      companyId?: string | null,
      merchantId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedCollaborationsByMerchantIdAndCreatedAtQueryVariables = {
  merchantId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedCollaborationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedCollaborationsByMerchantIdAndCreatedAtQuery = {
  feedCollaborationsByMerchantIdAndCreatedAt?:  {
    __typename: "ModelFeedCollaborationConnection",
    items:  Array< {
      __typename: "FeedCollaboration",
      id: string,
      type?: string | null,
      feedId: string,
      companyId?: string | null,
      merchantId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFeedTagsQueryVariables = {
  id: string,
};

export type GetFeedTagsQuery = {
  getFeedTags?:  {
    __typename: "FeedTags",
    id: string,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId?: string | null,
    companyId?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListFeedTagsQueryVariables = {
  filter?: ModelFeedTagsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeedTagsQuery = {
  listFeedTags?:  {
    __typename: "ModelFeedTagsConnection",
    items:  Array< {
      __typename: "FeedTags",
      id: string,
      feedId: string,
      productVariantId: string,
      userId?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedTagsByFeedIdAndCreatedAtQueryVariables = {
  feedId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedTagsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedTagsByFeedIdAndCreatedAtQuery = {
  feedTagsByFeedIdAndCreatedAt?:  {
    __typename: "ModelFeedTagsConnection",
    items:  Array< {
      __typename: "FeedTags",
      id: string,
      feedId: string,
      productVariantId: string,
      userId?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedTagsByProductVariantIdAndCreatedAtQueryVariables = {
  productVariantId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedTagsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedTagsByProductVariantIdAndCreatedAtQuery = {
  feedTagsByProductVariantIdAndCreatedAt?:  {
    __typename: "ModelFeedTagsConnection",
    items:  Array< {
      __typename: "FeedTags",
      id: string,
      feedId: string,
      productVariantId: string,
      userId?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFeedMediaQueryVariables = {
  id: string,
};

export type GetFeedMediaQuery = {
  getFeedMedia?:  {
    __typename: "FeedMedia",
    id: string,
    file_type: string,
    key: string,
    feedId: string,
    Feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId: string,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId: string,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListFeedMediasQueryVariables = {
  filter?: ModelFeedMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeedMediasQuery = {
  listFeedMedias?:  {
    __typename: "ModelFeedMediaConnection",
    items:  Array< {
      __typename: "FeedMedia",
      id: string,
      file_type: string,
      key: string,
      feedId: string,
      postId: string,
      propertyId: string,
      position?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedMediasByFeedIdAndPositionQueryVariables = {
  feedId: string,
  position?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedMediasByFeedIdAndPositionQuery = {
  feedMediasByFeedIdAndPosition?:  {
    __typename: "ModelFeedMediaConnection",
    items:  Array< {
      __typename: "FeedMedia",
      id: string,
      file_type: string,
      key: string,
      feedId: string,
      postId: string,
      propertyId: string,
      position?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedMediasByPostIdAndPositionQueryVariables = {
  postId: string,
  position?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedMediasByPostIdAndPositionQuery = {
  feedMediasByPostIdAndPosition?:  {
    __typename: "ModelFeedMediaConnection",
    items:  Array< {
      __typename: "FeedMedia",
      id: string,
      file_type: string,
      key: string,
      feedId: string,
      postId: string,
      propertyId: string,
      position?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedMediasByPropertyIdAndPositionQueryVariables = {
  propertyId: string,
  position?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedMediasByPropertyIdAndPositionQuery = {
  feedMediasByPropertyIdAndPosition?:  {
    __typename: "ModelFeedMediaConnection",
    items:  Array< {
      __typename: "FeedMedia",
      id: string,
      file_type: string,
      key: string,
      feedId: string,
      postId: string,
      propertyId: string,
      position?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedMediasByPositionAndCreatedAtQueryVariables = {
  position: number,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFeedMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedMediasByPositionAndCreatedAtQuery = {
  feedMediasByPositionAndCreatedAt?:  {
    __typename: "ModelFeedMediaConnection",
    items:  Array< {
      __typename: "FeedMedia",
      id: string,
      file_type: string,
      key: string,
      feedId: string,
      postId: string,
      propertyId: string,
      position?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLikeQueryVariables = {
  id: string,
};

export type GetLikeQuery = {
  getLike?:  {
    __typename: "Like",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    likeState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListLikesQueryVariables = {
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLikesQuery = {
  listLikes?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      propertyId?: string | null,
      postId?: string | null,
      likeState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByTypeAndUserIdQueryVariables = {
  type: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByTypeAndUserIdQuery = {
  likesByTypeAndUserId?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      propertyId?: string | null,
      postId?: string | null,
      likeState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByProductIdAndUserIdQueryVariables = {
  productId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByProductIdAndUserIdQuery = {
  likesByProductIdAndUserId?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      propertyId?: string | null,
      postId?: string | null,
      likeState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByFeedIdAndUserIdQueryVariables = {
  feedId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByFeedIdAndUserIdQuery = {
  likesByFeedIdAndUserId?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      propertyId?: string | null,
      postId?: string | null,
      likeState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByPropertyIdAndUserIdQueryVariables = {
  propertyId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByPropertyIdAndUserIdQuery = {
  likesByPropertyIdAndUserId?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      propertyId?: string | null,
      postId?: string | null,
      likeState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByPostIdAndUserIdQueryVariables = {
  postId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByPostIdAndUserIdQuery = {
  likesByPostIdAndUserId?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      propertyId?: string | null,
      postId?: string | null,
      likeState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByUserIdAndCreatedAtQuery = {
  likesByUserIdAndCreatedAt?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      propertyId?: string | null,
      postId?: string | null,
      likeState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetViewsQueryVariables = {
  id: string,
};

export type GetViewsQuery = {
  getViews?:  {
    __typename: "Views",
    id: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    viewCount?: number | null,
    type: ViewProductType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListViewsQueryVariables = {
  filter?: ModelViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListViewsQuery = {
  listViews?:  {
    __typename: "ModelViewsConnection",
    items:  Array< {
      __typename: "Views",
      id: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      viewCount?: number | null,
      type: ViewProductType,
      userId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ViewsByProductIdAndUserIdQueryVariables = {
  productId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ViewsByProductIdAndUserIdQuery = {
  viewsByProductIdAndUserId?:  {
    __typename: "ModelViewsConnection",
    items:  Array< {
      __typename: "Views",
      id: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      viewCount?: number | null,
      type: ViewProductType,
      userId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ViewsByFeedIdAndUserIdQueryVariables = {
  feedId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ViewsByFeedIdAndUserIdQuery = {
  viewsByFeedIdAndUserId?:  {
    __typename: "ModelViewsConnection",
    items:  Array< {
      __typename: "Views",
      id: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      viewCount?: number | null,
      type: ViewProductType,
      userId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ViewsByPostIdAndUserIdQueryVariables = {
  postId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ViewsByPostIdAndUserIdQuery = {
  viewsByPostIdAndUserId?:  {
    __typename: "ModelViewsConnection",
    items:  Array< {
      __typename: "Views",
      id: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      viewCount?: number | null,
      type: ViewProductType,
      userId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ViewsByPropertyIdAndUserIdQueryVariables = {
  propertyId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ViewsByPropertyIdAndUserIdQuery = {
  viewsByPropertyIdAndUserId?:  {
    __typename: "ModelViewsConnection",
    items:  Array< {
      __typename: "Views",
      id: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      viewCount?: number | null,
      type: ViewProductType,
      userId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ViewsByTypeAndUserIdQueryVariables = {
  type: ViewProductType,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ViewsByTypeAndUserIdQuery = {
  viewsByTypeAndUserId?:  {
    __typename: "ModelViewsConnection",
    items:  Array< {
      __typename: "Views",
      id: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      viewCount?: number | null,
      type: ViewProductType,
      userId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ViewsByTypeAndViewCountQueryVariables = {
  type: ViewProductType,
  viewCount?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ViewsByTypeAndViewCountQuery = {
  viewsByTypeAndViewCount?:  {
    __typename: "ModelViewsConnection",
    items:  Array< {
      __typename: "Views",
      id: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      viewCount?: number | null,
      type: ViewProductType,
      userId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ViewsByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ViewsByUserIdAndCreatedAtQuery = {
  viewsByUserIdAndCreatedAt?:  {
    __typename: "ModelViewsConnection",
    items:  Array< {
      __typename: "Views",
      id: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      viewCount?: number | null,
      type: ViewProductType,
      userId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ViewsByUserIdAndTypeAndUpdatedAtQueryVariables = {
  userId: string,
  typeUpdatedAt?: ModelViewsByUserAndTypeAndSortCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ViewsByUserIdAndTypeAndUpdatedAtQuery = {
  viewsByUserIdAndTypeAndUpdatedAt?:  {
    __typename: "ModelViewsConnection",
    items:  Array< {
      __typename: "Views",
      id: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      viewCount?: number | null,
      type: ViewProductType,
      userId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductBookmarksQueryVariables = {
  id: string,
};

export type GetProductBookmarksQuery = {
  getProductBookmarks?:  {
    __typename: "ProductBookmarks",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookmarkState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductBookmarksQueryVariables = {
  filter?: ModelProductBookmarksFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductBookmarksQuery = {
  listProductBookmarks?:  {
    __typename: "ModelProductBookmarksConnection",
    items:  Array< {
      __typename: "ProductBookmarks",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      bookmarkState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductBookmarksByTypeAndUserIdQueryVariables = {
  type: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductBookmarksFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductBookmarksByTypeAndUserIdQuery = {
  productBookmarksByTypeAndUserId?:  {
    __typename: "ModelProductBookmarksConnection",
    items:  Array< {
      __typename: "ProductBookmarks",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      bookmarkState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductBookmarksByProductIdAndUserIdQueryVariables = {
  productId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductBookmarksFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductBookmarksByProductIdAndUserIdQuery = {
  productBookmarksByProductIdAndUserId?:  {
    __typename: "ModelProductBookmarksConnection",
    items:  Array< {
      __typename: "ProductBookmarks",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      bookmarkState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductBookmarksByFeedIdAndUserIdQueryVariables = {
  feedId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductBookmarksFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductBookmarksByFeedIdAndUserIdQuery = {
  productBookmarksByFeedIdAndUserId?:  {
    __typename: "ModelProductBookmarksConnection",
    items:  Array< {
      __typename: "ProductBookmarks",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      bookmarkState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductBookmarksByPostIdAndUserIdQueryVariables = {
  postId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductBookmarksFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductBookmarksByPostIdAndUserIdQuery = {
  productBookmarksByPostIdAndUserId?:  {
    __typename: "ModelProductBookmarksConnection",
    items:  Array< {
      __typename: "ProductBookmarks",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      bookmarkState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductBookmarksByPropertyIdAndUserIdQueryVariables = {
  propertyId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductBookmarksFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductBookmarksByPropertyIdAndUserIdQuery = {
  productBookmarksByPropertyIdAndUserId?:  {
    __typename: "ModelProductBookmarksConnection",
    items:  Array< {
      __typename: "ProductBookmarks",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      bookmarkState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductBookmarksByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductBookmarksFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductBookmarksByUserIdAndCreatedAtQuery = {
  productBookmarksByUserIdAndCreatedAt?:  {
    __typename: "ModelProductBookmarksConnection",
    items:  Array< {
      __typename: "ProductBookmarks",
      id: string,
      type?: string | null,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      propertyId?: string | null,
      bookmarkState?: boolean | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentsQueryVariables = {
  id: string,
};

export type GetCommentsQuery = {
  getComments?:  {
    __typename: "Comments",
    id: string,
    comment: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    parentCommentId?: string | null,
    commentReplyList?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    userId: string,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentsConnection",
    items:  Array< {
      __typename: "Comments",
      id: string,
      comment: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      parentCommentId?: string | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByProductIdAndCreatedAtQueryVariables = {
  productId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByProductIdAndCreatedAtQuery = {
  commentsByProductIdAndCreatedAt?:  {
    __typename: "ModelCommentsConnection",
    items:  Array< {
      __typename: "Comments",
      id: string,
      comment: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      parentCommentId?: string | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByFeedIdAndCreatedAtQueryVariables = {
  feedId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByFeedIdAndCreatedAtQuery = {
  commentsByFeedIdAndCreatedAt?:  {
    __typename: "ModelCommentsConnection",
    items:  Array< {
      __typename: "Comments",
      id: string,
      comment: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      parentCommentId?: string | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByPostIdAndCreatedAtQueryVariables = {
  postId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByPostIdAndCreatedAtQuery = {
  commentsByPostIdAndCreatedAt?:  {
    __typename: "ModelCommentsConnection",
    items:  Array< {
      __typename: "Comments",
      id: string,
      comment: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      parentCommentId?: string | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByParentCommentIdAndCreatedAtQueryVariables = {
  parentCommentId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByParentCommentIdAndCreatedAtQuery = {
  commentsByParentCommentIdAndCreatedAt?:  {
    __typename: "ModelCommentsConnection",
    items:  Array< {
      __typename: "Comments",
      id: string,
      comment: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      parentCommentId?: string | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByUserIdAndIdQueryVariables = {
  userId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByUserIdAndIdQuery = {
  commentsByUserIdAndId?:  {
    __typename: "ModelCommentsConnection",
    items:  Array< {
      __typename: "Comments",
      id: string,
      comment: string,
      productId?: string | null,
      feedId?: string | null,
      postId?: string | null,
      parentCommentId?: string | null,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductVariantQueryVariables = {
  id: string,
};

export type GetProductVariantQuery = {
  getProductVariant?:  {
    __typename: "ProductVariant",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    title: string,
    description: string,
    attribute?: string | null,
    price?: number | null,
    list_price?: number | null,
    quantity?: number | null,
    deliveryMethod?: string | null,
    productCondition?: string | null,
    productUsed?:  {
      __typename: "ProductUsedType",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    designStyle?: string | null,
    designPropertyType?: string | null,
    orderCount?: number | null,
    videoLink?: string | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    status?: Status | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    sku?: string | null,
    defaultPosition?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    productProductVariantsId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListProductVariantsQueryVariables = {
  filter?: ModelProductVariantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductVariantsQuery = {
  listProductVariants?:  {
    __typename: "ModelProductVariantConnection",
    items:  Array< {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductVariantsByProductIdAndIdQueryVariables = {
  productId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductVariantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductVariantsByProductIdAndIdQuery = {
  productVariantsByProductIdAndId?:  {
    __typename: "ModelProductVariantConnection",
    items:  Array< {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductVariantListBySkuQueryVariables = {
  sku: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductVariantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductVariantListBySkuQuery = {
  productVariantListBySku?:  {
    __typename: "ModelProductVariantConnection",
    items:  Array< {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductMediaQueryVariables = {
  id: string,
};

export type GetProductMediaQuery = {
  getProductMedia?:  {
    __typename: "ProductMedia",
    id: string,
    file_type: string,
    key: string,
    product_variant_id: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductMediasQueryVariables = {
  filter?: ModelProductMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductMediasQuery = {
  listProductMedias?:  {
    __typename: "ModelProductMediaConnection",
    items:  Array< {
      __typename: "ProductMedia",
      id: string,
      file_type: string,
      key: string,
      product_variant_id: string,
      position?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductMediasByProduct_variant_idAndPositionQueryVariables = {
  product_variant_id: string,
  position?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductMediasByProduct_variant_idAndPositionQuery = {
  productMediasByProduct_variant_idAndPosition?:  {
    __typename: "ModelProductMediaConnection",
    items:  Array< {
      __typename: "ProductMedia",
      id: string,
      file_type: string,
      key: string,
      product_variant_id: string,
      position?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductMediasByPositionAndCreatedAtQueryVariables = {
  position: number,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductMediasByPositionAndCreatedAtQuery = {
  productMediasByPositionAndCreatedAt?:  {
    __typename: "ModelProductMediaConnection",
    items:  Array< {
      __typename: "ProductMedia",
      id: string,
      file_type: string,
      key: string,
      product_variant_id: string,
      position?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductCategoriesQueryVariables = {
  id: string,
};

export type GetProductCategoriesQuery = {
  getProductCategories?:  {
    __typename: "ProductCategories",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      type: ProductCategoryType,
      description?: string | null,
      picture?: string | null,
      parent_categoryId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductCategoriesQueryVariables = {
  filter?: ModelProductCategoriesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductCategoriesQuery = {
  listProductCategories?:  {
    __typename: "ModelProductCategoriesConnection",
    items:  Array< {
      __typename: "ProductCategories",
      id: string,
      categoryId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductCategoriesByCategoryIdAndIdQueryVariables = {
  categoryId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductCategoriesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductCategoriesByCategoryIdAndIdQuery = {
  productCategoriesByCategoryIdAndId?:  {
    __typename: "ModelProductCategoriesConnection",
    items:  Array< {
      __typename: "ProductCategories",
      id: string,
      categoryId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductCategoriesByProductIdAndCategoryIdQueryVariables = {
  productId: string,
  categoryId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductCategoriesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductCategoriesByProductIdAndCategoryIdQuery = {
  productCategoriesByProductIdAndCategoryId?:  {
    __typename: "ModelProductCategoriesConnection",
    items:  Array< {
      __typename: "ProductCategories",
      id: string,
      categoryId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductReviewQueryVariables = {
  id: string,
};

export type GetProductReviewQuery = {
  getProductReview?:  {
    __typename: "ProductReview",
    id: string,
    stars: number,
    comment?: string | null,
    attribute?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    totalImagesCount?: number | null,
    estoreOrderProductId?: string | null,
    estoreOrderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    bookingId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    reviewMediaList?:  {
      __typename: "ModelReviewMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductReviewsQueryVariables = {
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductReviewsQuery = {
  listProductReviews?:  {
    __typename: "ModelProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductReviewsByUserIdAndIdQueryVariables = {
  userId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductReviewsByUserIdAndIdQuery = {
  productReviewsByUserIdAndId?:  {
    __typename: "ModelProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductReviewsByProductIdAndIdQueryVariables = {
  productId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductReviewsByProductIdAndIdQuery = {
  productReviewsByProductIdAndId?:  {
    __typename: "ModelProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductReviewsByProductIdAndCreatedAtQueryVariables = {
  productId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductReviewsByProductIdAndCreatedAtQuery = {
  productReviewsByProductIdAndCreatedAt?:  {
    __typename: "ModelProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductReviewsByProductIdAndTotalImagesCountQueryVariables = {
  productId: string,
  totalImagesCount?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductReviewsByProductIdAndTotalImagesCountQuery = {
  productReviewsByProductIdAndTotalImagesCount?:  {
    __typename: "ModelProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductReviewsByEstoreOrderProductIdAndUserIdQueryVariables = {
  estoreOrderProductId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductReviewsByEstoreOrderProductIdAndUserIdQuery = {
  productReviewsByEstoreOrderProductIdAndUserId?:  {
    __typename: "ModelProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductReviewsByBookingIdAndUserIdQueryVariables = {
  bookingId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductReviewsByBookingIdAndUserIdQuery = {
  productReviewsByBookingIdAndUserId?:  {
    __typename: "ModelProductReviewConnection",
    items:  Array< {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReviewMediaQueryVariables = {
  id: string,
};

export type GetReviewMediaQuery = {
  getReviewMedia?:  {
    __typename: "ReviewMedia",
    id: string,
    file_type: string,
    key: string,
    reviewId: string,
    productReview?:  {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListReviewMediasQueryVariables = {
  filter?: ModelReviewMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewMediasQuery = {
  listReviewMedias?:  {
    __typename: "ModelReviewMediaConnection",
    items:  Array< {
      __typename: "ReviewMedia",
      id: string,
      file_type: string,
      key: string,
      reviewId: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewMediasByReviewIdAndIdQueryVariables = {
  reviewId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewMediasByReviewIdAndIdQuery = {
  reviewMediasByReviewIdAndId?:  {
    __typename: "ModelReviewMediaConnection",
    items:  Array< {
      __typename: "ReviewMedia",
      id: string,
      file_type: string,
      key: string,
      reviewId: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEstoreOrdersQueryVariables = {
  id: string,
};

export type GetEstoreOrdersQuery = {
  getEstoreOrders?:  {
    __typename: "EstoreOrders",
    id: string,
    orderId: string,
    transaction_id?: string | null,
    order_price: number,
    discount?: number | null,
    sell_price?: number | null,
    name?: string | null,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city: string,
    state?: string | null,
    country: string,
    offerCodeId?: string | null,
    offerCode?:  {
      __typename: "OfferCode",
      id: string,
      code: string,
      description?: string | null,
      discountType: DiscountType,
      discountValue: number,
      maxUsage?: number | null,
      usageCount?: number | null,
      validFrom?: string | null,
      validTo?: string | null,
      minOrderValue?: number | null,
      maxDiscountAmount?: number | null,
      customerRestrictions?: Array< string | null > | null,
      productRestrictions?: Array< string | null > | null,
      status: Status,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    delivery_fee?: number | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    statusDescription?: string | null,
    estoreOrderProducts?:  {
      __typename: "ModelEstoreOrderProductConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListEstoreOrdersQueryVariables = {
  filter?: ModelEstoreOrdersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEstoreOrdersQuery = {
  listEstoreOrders?:  {
    __typename: "ModelEstoreOrdersConnection",
    items:  Array< {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrdersByOrderIdAndCreatedAtQueryVariables = {
  orderId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrdersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrdersByOrderIdAndCreatedAtQuery = {
  estoreOrdersByOrderIdAndCreatedAt?:  {
    __typename: "ModelEstoreOrdersConnection",
    items:  Array< {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrdersByOfferCodeIdAndCreatedAtQueryVariables = {
  offerCodeId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrdersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrdersByOfferCodeIdAndCreatedAtQuery = {
  estoreOrdersByOfferCodeIdAndCreatedAt?:  {
    __typename: "ModelEstoreOrdersConnection",
    items:  Array< {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrdersByOfferCodeIdAndUserIdQueryVariables = {
  offerCodeId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrdersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrdersByOfferCodeIdAndUserIdQuery = {
  estoreOrdersByOfferCodeIdAndUserId?:  {
    __typename: "ModelEstoreOrdersConnection",
    items:  Array< {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrdersByUserIdAndIdQueryVariables = {
  userId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrdersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrdersByUserIdAndIdQuery = {
  estoreOrdersByUserIdAndId?:  {
    __typename: "ModelEstoreOrdersConnection",
    items:  Array< {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrdersByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrdersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrdersByUserIdAndCreatedAtQuery = {
  estoreOrdersByUserIdAndCreatedAt?:  {
    __typename: "ModelEstoreOrdersConnection",
    items:  Array< {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrdersByStatusQueryVariables = {
  status: OrderStatusType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrdersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrdersByStatusQuery = {
  estoreOrdersByStatus?:  {
    __typename: "ModelEstoreOrdersConnection",
    items:  Array< {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrdersByPaymentStatusAndCreatedAtQueryVariables = {
  paymentStatus: PaymentStatusType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrdersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrdersByPaymentStatusAndCreatedAtQuery = {
  estoreOrdersByPaymentStatusAndCreatedAt?:  {
    __typename: "ModelEstoreOrdersConnection",
    items:  Array< {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEstoreOrderProductQueryVariables = {
  id: string,
};

export type GetEstoreOrderProductQuery = {
  getEstoreOrderProduct?:  {
    __typename: "EstoreOrderProduct",
    id: string,
    title: string,
    description: string,
    price: number,
    list_price: number,
    quantity: number,
    invoice?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    attribute?: string | null,
    add_ons?:  {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    deliveryStatus: DeliverStatusType,
    statusDescription?: string | null,
    statusHistory?: string | null,
    cancellationReason?: string | null,
    estore_order_id?: string | null,
    estoreOrder?:  {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListEstoreOrderProductsQueryVariables = {
  filter?: ModelEstoreOrderProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEstoreOrderProductsQuery = {
  listEstoreOrderProducts?:  {
    __typename: "ModelEstoreOrderProductConnection",
    items:  Array< {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrderProductsByUserIdAndIdQueryVariables = {
  userId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrderProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrderProductsByUserIdAndIdQuery = {
  estoreOrderProductsByUserIdAndId?:  {
    __typename: "ModelEstoreOrderProductConnection",
    items:  Array< {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrderProductsByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrderProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrderProductsByUserIdAndCreatedAtQuery = {
  estoreOrderProductsByUserIdAndCreatedAt?:  {
    __typename: "ModelEstoreOrderProductConnection",
    items:  Array< {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrderProductsByCompanyIdAndIdQueryVariables = {
  companyId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrderProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrderProductsByCompanyIdAndIdQuery = {
  estoreOrderProductsByCompanyIdAndId?:  {
    __typename: "ModelEstoreOrderProductConnection",
    items:  Array< {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrderProductsByProductVariantIdAndCreatedAtQueryVariables = {
  productVariantId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrderProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrderProductsByProductVariantIdAndCreatedAtQuery = {
  estoreOrderProductsByProductVariantIdAndCreatedAt?:  {
    __typename: "ModelEstoreOrderProductConnection",
    items:  Array< {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrderProductByStatusQueryVariables = {
  status: OrderStatusType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrderProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrderProductByStatusQuery = {
  estoreOrderProductByStatus?:  {
    __typename: "ModelEstoreOrderProductConnection",
    items:  Array< {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrderProductsByPaymentStatusAndCreatedAtQueryVariables = {
  paymentStatus: PaymentStatusType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrderProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrderProductsByPaymentStatusAndCreatedAtQuery = {
  estoreOrderProductsByPaymentStatusAndCreatedAt?:  {
    __typename: "ModelEstoreOrderProductConnection",
    items:  Array< {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrderProductsByDeliveryStatusAndCreatedAtQueryVariables = {
  deliveryStatus: DeliverStatusType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrderProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrderProductsByDeliveryStatusAndCreatedAtQuery = {
  estoreOrderProductsByDeliveryStatusAndCreatedAt?:  {
    __typename: "ModelEstoreOrderProductConnection",
    items:  Array< {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrderProductsByEstore_order_idAndIdQueryVariables = {
  estore_order_id: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrderProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrderProductsByEstore_order_idAndIdQuery = {
  estoreOrderProductsByEstore_order_idAndId?:  {
    __typename: "ModelEstoreOrderProductConnection",
    items:  Array< {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEstoreOrdersRefundQueryVariables = {
  id: string,
};

export type GetEstoreOrdersRefundQuery = {
  getEstoreOrdersRefund?:  {
    __typename: "EstoreOrdersRefund",
    id: string,
    orderProductId: string,
    orderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    refund_price: number,
    reason: string,
    status: PaymentStatusType,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListEstoreOrdersRefundsQueryVariables = {
  filter?: ModelEstoreOrdersRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEstoreOrdersRefundsQuery = {
  listEstoreOrdersRefunds?:  {
    __typename: "ModelEstoreOrdersRefundConnection",
    items:  Array< {
      __typename: "EstoreOrdersRefund",
      id: string,
      orderProductId: string,
      companyId: string,
      refund_price: number,
      reason: string,
      status: PaymentStatusType,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrdersRefundsByOrderProductIdAndIdQueryVariables = {
  orderProductId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrdersRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrdersRefundsByOrderProductIdAndIdQuery = {
  estoreOrdersRefundsByOrderProductIdAndId?:  {
    __typename: "ModelEstoreOrdersRefundConnection",
    items:  Array< {
      __typename: "EstoreOrdersRefund",
      id: string,
      orderProductId: string,
      companyId: string,
      refund_price: number,
      reason: string,
      status: PaymentStatusType,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrdersRefundsByCompanyIdAndCreatedAtQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrdersRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrdersRefundsByCompanyIdAndCreatedAtQuery = {
  estoreOrdersRefundsByCompanyIdAndCreatedAt?:  {
    __typename: "ModelEstoreOrdersRefundConnection",
    items:  Array< {
      __typename: "EstoreOrdersRefund",
      id: string,
      orderProductId: string,
      companyId: string,
      refund_price: number,
      reason: string,
      status: PaymentStatusType,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreOrdersRefundsByStatusAndCreatedAtQueryVariables = {
  status: PaymentStatusType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreOrdersRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreOrdersRefundsByStatusAndCreatedAtQuery = {
  estoreOrdersRefundsByStatusAndCreatedAt?:  {
    __typename: "ModelEstoreOrdersRefundConnection",
    items:  Array< {
      __typename: "EstoreOrdersRefund",
      id: string,
      orderProductId: string,
      companyId: string,
      refund_price: number,
      reason: string,
      status: PaymentStatusType,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTransactionLogsQueryVariables = {
  id: string,
};

export type GetTransactionLogsQuery = {
  getTransactionLogs?:  {
    __typename: "TransactionLogs",
    id: string,
    orderId?: string | null,
    type: string,
    response: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListTransactionLogsQueryVariables = {
  filter?: ModelTransactionLogsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionLogsQuery = {
  listTransactionLogs?:  {
    __typename: "ModelTransactionLogsConnection",
    items:  Array< {
      __typename: "TransactionLogs",
      id: string,
      orderId?: string | null,
      type: string,
      response: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEstoreCartQueryVariables = {
  id: string,
};

export type GetEstoreCartQuery = {
  getEstoreCart?:  {
    __typename: "EstoreCart",
    id: string,
    quantity: number,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status: Status,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListEstoreCartsQueryVariables = {
  filter?: ModelEstoreCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEstoreCartsQuery = {
  listEstoreCarts?:  {
    __typename: "ModelEstoreCartConnection",
    items:  Array< {
      __typename: "EstoreCart",
      id: string,
      quantity: number,
      userId: string,
      productVariantId: string,
      companyId: string,
      status: Status,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreCartsByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreCartsByUserIdAndCreatedAtQuery = {
  estoreCartsByUserIdAndCreatedAt?:  {
    __typename: "ModelEstoreCartConnection",
    items:  Array< {
      __typename: "EstoreCart",
      id: string,
      quantity: number,
      userId: string,
      productVariantId: string,
      companyId: string,
      status: Status,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreCartsByProductVariantIdAndIdQueryVariables = {
  productVariantId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreCartsByProductVariantIdAndIdQuery = {
  estoreCartsByProductVariantIdAndId?:  {
    __typename: "ModelEstoreCartConnection",
    items:  Array< {
      __typename: "EstoreCart",
      id: string,
      quantity: number,
      userId: string,
      productVariantId: string,
      companyId: string,
      status: Status,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreCartsByCompanyIdAndIdQueryVariables = {
  companyId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreCartsByCompanyIdAndIdQuery = {
  estoreCartsByCompanyIdAndId?:  {
    __typename: "ModelEstoreCartConnection",
    items:  Array< {
      __typename: "EstoreCart",
      id: string,
      quantity: number,
      userId: string,
      productVariantId: string,
      companyId: string,
      status: Status,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EstoreCartListByStatusQueryVariables = {
  status: Status,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstoreCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EstoreCartListByStatusQuery = {
  estoreCartListByStatus?:  {
    __typename: "ModelEstoreCartConnection",
    items:  Array< {
      __typename: "EstoreCart",
      id: string,
      quantity: number,
      userId: string,
      productVariantId: string,
      companyId: string,
      status: Status,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBookingQueryVariables = {
  id: string,
};

export type GetBookingQuery = {
  getBooking?:  {
    __typename: "Booking",
    id: string,
    type: ProductType,
    quantity?: number | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    fulfillerId: string,
    fulfiller?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    status: BookingStatus,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListBookingsQueryVariables = {
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookingsQuery = {
  listBookings?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookingListByTypeQueryVariables = {
  type: ProductType,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingListByTypeQuery = {
  bookingListByType?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookingsByProductVariantIdAndIdQueryVariables = {
  productVariantId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingsByProductVariantIdAndIdQuery = {
  bookingsByProductVariantIdAndId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookingsByUserIdAndIdQueryVariables = {
  userId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingsByUserIdAndIdQuery = {
  bookingsByUserIdAndId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookingsByFulfillerIdAndTypeQueryVariables = {
  fulfillerId: string,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingsByFulfillerIdAndTypeQuery = {
  bookingsByFulfillerIdAndType?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BookingsByCompanyIdQueryVariables = {
  companyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingsByCompanyIdQuery = {
  bookingsByCompanyId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChatQueryVariables = {
  id: string,
};

export type GetChatQuery = {
  getChat?:  {
    __typename: "Chat",
    id: string,
    name?: string | null,
    group?: boolean | null,
    image?: string | null,
    participants?:  {
      __typename: "ModelChatParticipantsConnection",
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessagesConnection",
      nextToken?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    chatMessageUnreadCount?:  {
      __typename: "ModelChatMessageUnreadCountConnection",
      nextToken?: string | null,
    } | null,
    deletedBy?: string | null,
    updatedAt: string,
  } | null,
};

export type ListChatsQueryVariables = {
  filter?: ModelChatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChatsQuery = {
  listChats?:  {
    __typename: "ModelChatConnection",
    items:  Array< {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatsByCompanyIdAndCreatedAtQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatsByCompanyIdAndCreatedAtQuery = {
  chatsByCompanyIdAndCreatedAt?:  {
    __typename: "ModelChatConnection",
    items:  Array< {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChatParticipantsQueryVariables = {
  id: string,
};

export type GetChatParticipantsQuery = {
  getChatParticipants?:  {
    __typename: "ChatParticipants",
    id: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    isActive?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListChatParticipantsQueryVariables = {
  filter?: ModelChatParticipantsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChatParticipantsQuery = {
  listChatParticipants?:  {
    __typename: "ModelChatParticipantsConnection",
    items:  Array< {
      __typename: "ChatParticipants",
      id: string,
      chatId: string,
      userId: string,
      isActive?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatParticipantsByChatIdAndUserIdQueryVariables = {
  chatId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatParticipantsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatParticipantsByChatIdAndUserIdQuery = {
  chatParticipantsByChatIdAndUserId?:  {
    __typename: "ModelChatParticipantsConnection",
    items:  Array< {
      __typename: "ChatParticipants",
      id: string,
      chatId: string,
      userId: string,
      isActive?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatParticipantsByUserIdAndIsActiveQueryVariables = {
  userId: string,
  isActive?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatParticipantsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatParticipantsByUserIdAndIsActiveQuery = {
  chatParticipantsByUserIdAndIsActive?:  {
    __typename: "ModelChatParticipantsConnection",
    items:  Array< {
      __typename: "ChatParticipants",
      id: string,
      chatId: string,
      userId: string,
      isActive?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChatMessagesQueryVariables = {
  id: string,
};

export type GetChatMessagesQuery = {
  getChatMessages?:  {
    __typename: "ChatMessages",
    id: string,
    message?: string | null,
    attachment?: string | null,
    attachment_type?: string | null,
    bookingId?: string | null,
    propertyId?: string | null,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    User?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListChatMessagesQueryVariables = {
  filter?: ModelChatMessagesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChatMessagesQuery = {
  listChatMessages?:  {
    __typename: "ModelChatMessagesConnection",
    items:  Array< {
      __typename: "ChatMessages",
      id: string,
      message?: string | null,
      attachment?: string | null,
      attachment_type?: string | null,
      bookingId?: string | null,
      propertyId?: string | null,
      chatId: string,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatMessagesByBookingIdAndCreatedAtQueryVariables = {
  bookingId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatMessagesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatMessagesByBookingIdAndCreatedAtQuery = {
  chatMessagesByBookingIdAndCreatedAt?:  {
    __typename: "ModelChatMessagesConnection",
    items:  Array< {
      __typename: "ChatMessages",
      id: string,
      message?: string | null,
      attachment?: string | null,
      attachment_type?: string | null,
      bookingId?: string | null,
      propertyId?: string | null,
      chatId: string,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatMessagesByPropertyIdAndCreatedAtQueryVariables = {
  propertyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatMessagesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatMessagesByPropertyIdAndCreatedAtQuery = {
  chatMessagesByPropertyIdAndCreatedAt?:  {
    __typename: "ModelChatMessagesConnection",
    items:  Array< {
      __typename: "ChatMessages",
      id: string,
      message?: string | null,
      attachment?: string | null,
      attachment_type?: string | null,
      bookingId?: string | null,
      propertyId?: string | null,
      chatId: string,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatMessagesByChatIdAndCreatedAtQueryVariables = {
  chatId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatMessagesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatMessagesByChatIdAndCreatedAtQuery = {
  chatMessagesByChatIdAndCreatedAt?:  {
    __typename: "ModelChatMessagesConnection",
    items:  Array< {
      __typename: "ChatMessages",
      id: string,
      message?: string | null,
      attachment?: string | null,
      attachment_type?: string | null,
      bookingId?: string | null,
      propertyId?: string | null,
      chatId: string,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatMessagesByUserIdAndIdQueryVariables = {
  userId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatMessagesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatMessagesByUserIdAndIdQuery = {
  chatMessagesByUserIdAndId?:  {
    __typename: "ModelChatMessagesConnection",
    items:  Array< {
      __typename: "ChatMessages",
      id: string,
      message?: string | null,
      attachment?: string | null,
      attachment_type?: string | null,
      bookingId?: string | null,
      propertyId?: string | null,
      chatId: string,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChatMessageUnreadCountQueryVariables = {
  id: string,
};

export type GetChatMessageUnreadCountQuery = {
  getChatMessageUnreadCount?:  {
    __typename: "ChatMessageUnreadCount",
    id: string,
    userId: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    unreadCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListChatMessageUnreadCountsQueryVariables = {
  filter?: ModelChatMessageUnreadCountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChatMessageUnreadCountsQuery = {
  listChatMessageUnreadCounts?:  {
    __typename: "ModelChatMessageUnreadCountConnection",
    items:  Array< {
      __typename: "ChatMessageUnreadCount",
      id: string,
      userId: string,
      chatId: string,
      unreadCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatMessageUnreadCountsByUserIdAndIdQueryVariables = {
  userId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatMessageUnreadCountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatMessageUnreadCountsByUserIdAndIdQuery = {
  chatMessageUnreadCountsByUserIdAndId?:  {
    __typename: "ModelChatMessageUnreadCountConnection",
    items:  Array< {
      __typename: "ChatMessageUnreadCount",
      id: string,
      userId: string,
      chatId: string,
      unreadCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatMessageUnreadCountsByChatIdAndUserIdQueryVariables = {
  chatId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatMessageUnreadCountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatMessageUnreadCountsByChatIdAndUserIdQuery = {
  chatMessageUnreadCountsByChatIdAndUserId?:  {
    __typename: "ModelChatMessageUnreadCountConnection",
    items:  Array< {
      __typename: "ChatMessageUnreadCount",
      id: string,
      userId: string,
      chatId: string,
      unreadCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationsQueryVariables = {
  id: string,
};

export type GetNotificationsQuery = {
  getNotifications?:  {
    __typename: "Notifications",
    id: string,
    userId: string,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    read: number,
    title: string,
    description: string,
    imageUrl?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationsConnection",
    items:  Array< {
      __typename: "Notifications",
      id: string,
      userId: string,
      senderId?: string | null,
      read: number,
      title: string,
      description: string,
      imageUrl?: string | null,
      data?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByUserIdAndIdQueryVariables = {
  userId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByUserIdAndIdQuery = {
  notificationsByUserIdAndId?:  {
    __typename: "ModelNotificationsConnection",
    items:  Array< {
      __typename: "Notifications",
      id: string,
      userId: string,
      senderId?: string | null,
      read: number,
      title: string,
      description: string,
      imageUrl?: string | null,
      data?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsBySenderIdAndIdQueryVariables = {
  senderId: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsBySenderIdAndIdQuery = {
  notificationsBySenderIdAndId?:  {
    __typename: "ModelNotificationsConnection",
    items:  Array< {
      __typename: "Notifications",
      id: string,
      userId: string,
      senderId?: string | null,
      read: number,
      title: string,
      description: string,
      imageUrl?: string | null,
      data?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByReadAndUserIdQueryVariables = {
  read: number,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByReadAndUserIdQuery = {
  notificationsByReadAndUserId?:  {
    __typename: "ModelNotificationsConnection",
    items:  Array< {
      __typename: "Notifications",
      id: string,
      userId: string,
      senderId?: string | null,
      read: number,
      title: string,
      description: string,
      imageUrl?: string | null,
      data?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPostQueryVariables = {
  id: string,
};

export type GetPostQuery = {
  getPost?:  {
    __typename: "Post",
    id: string,
    type?: PostType | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    title: string,
    description: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPostsQueryVariables = {
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostsQuery = {
  listPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostsByTypeAndCreatedAtQueryVariables = {
  type: PostType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostsByTypeAndCreatedAtQuery = {
  postsByTypeAndCreatedAt?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostsByTypeAndTitleQueryVariables = {
  type: PostType,
  title?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostsByTypeAndTitleQuery = {
  postsByTypeAndTitle?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostsByTypeAndStatusQueryVariables = {
  type: PostType,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostsByTypeAndStatusQuery = {
  postsByTypeAndStatus?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostsByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostsByUserIdAndCreatedAtQuery = {
  postsByUserIdAndCreatedAt?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostsByUserIdAndStatusQueryVariables = {
  userId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostsByUserIdAndStatusQuery = {
  postsByUserIdAndStatus?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostListByStatusQueryVariables = {
  status: Status,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostListByStatusQuery = {
  PostListByStatus?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostListByTitleAndStatusQueryVariables = {
  status: Status,
  title?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostListByTitleAndStatusQuery = {
  PostListByTitleAndStatus?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecommendationQueryVariables = {
  id: string,
};

export type GetRecommendationQuery = {
  getRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    postId?: string | null,
    feedId?: string | null,
    createdAt?: string | null,
    ttl: number,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListRecommendationsQueryVariables = {
  filter?: ModelRecommendationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRecommendationsQuery = {
  listRecommendations?:  {
    __typename: "ModelRecommendationConnection",
    items:  Array< {
      __typename: "Recommendation",
      id: string,
      userId?: string | null,
      productId?: string | null,
      postId?: string | null,
      feedId?: string | null,
      createdAt?: string | null,
      ttl: number,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RecommendationsByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRecommendationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RecommendationsByUserIdAndCreatedAtQuery = {
  recommendationsByUserIdAndCreatedAt?:  {
    __typename: "ModelRecommendationConnection",
    items:  Array< {
      __typename: "Recommendation",
      id: string,
      userId?: string | null,
      productId?: string | null,
      postId?: string | null,
      feedId?: string | null,
      createdAt?: string | null,
      ttl: number,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RecommendationsByProductIdAndCreatedAtQueryVariables = {
  productId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRecommendationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RecommendationsByProductIdAndCreatedAtQuery = {
  recommendationsByProductIdAndCreatedAt?:  {
    __typename: "ModelRecommendationConnection",
    items:  Array< {
      __typename: "Recommendation",
      id: string,
      userId?: string | null,
      productId?: string | null,
      postId?: string | null,
      feedId?: string | null,
      createdAt?: string | null,
      ttl: number,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecommendationViewsQueryVariables = {
  id: string,
};

export type GetRecommendationViewsQuery = {
  getRecommendationViews?:  {
    __typename: "RecommendationViews",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListRecommendationViewsQueryVariables = {
  filter?: ModelRecommendationViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRecommendationViewsQuery = {
  listRecommendationViews?:  {
    __typename: "ModelRecommendationViewsConnection",
    items:  Array< {
      __typename: "RecommendationViews",
      id: string,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RecommendationViewsByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRecommendationViewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RecommendationViewsByUserIdAndCreatedAtQuery = {
  recommendationViewsByUserIdAndCreatedAt?:  {
    __typename: "ModelRecommendationViewsConnection",
    items:  Array< {
      __typename: "RecommendationViews",
      id: string,
      userId: string,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFollowQueryVariables = {
  id: string,
};

export type GetFollowQuery = {
  getFollow?:  {
    __typename: "Follow",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    followerId?: string | null,
    follower?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListFollowsQueryVariables = {
  filter?: ModelFollowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFollowsQuery = {
  listFollows?:  {
    __typename: "ModelFollowConnection",
    items:  Array< {
      __typename: "Follow",
      id: string,
      userId?: string | null,
      companyId?: string | null,
      followerId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FollowsByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFollowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FollowsByUserIdAndCreatedAtQuery = {
  followsByUserIdAndCreatedAt?:  {
    __typename: "ModelFollowConnection",
    items:  Array< {
      __typename: "Follow",
      id: string,
      userId?: string | null,
      companyId?: string | null,
      followerId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FollowsByCompanyIdAndUserIdQueryVariables = {
  companyId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFollowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FollowsByCompanyIdAndUserIdQuery = {
  followsByCompanyIdAndUserId?:  {
    __typename: "ModelFollowConnection",
    items:  Array< {
      __typename: "Follow",
      id: string,
      userId?: string | null,
      companyId?: string | null,
      followerId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FollowsByCompanyIdAndCreatedAtQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFollowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FollowsByCompanyIdAndCreatedAtQuery = {
  followsByCompanyIdAndCreatedAt?:  {
    __typename: "ModelFollowConnection",
    items:  Array< {
      __typename: "Follow",
      id: string,
      userId?: string | null,
      companyId?: string | null,
      followerId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FollowsByFollowerIdAndUserIdQueryVariables = {
  followerId: string,
  userId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFollowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FollowsByFollowerIdAndUserIdQuery = {
  followsByFollowerIdAndUserId?:  {
    __typename: "ModelFollowConnection",
    items:  Array< {
      __typename: "Follow",
      id: string,
      userId?: string | null,
      companyId?: string | null,
      followerId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FollowsByFollowerIdAndCompanyIdQueryVariables = {
  followerId: string,
  companyId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFollowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FollowsByFollowerIdAndCompanyIdQuery = {
  followsByFollowerIdAndCompanyId?:  {
    __typename: "ModelFollowConnection",
    items:  Array< {
      __typename: "Follow",
      id: string,
      userId?: string | null,
      companyId?: string | null,
      followerId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FollowsByFollowerIdAndCreatedAtQueryVariables = {
  followerId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFollowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FollowsByFollowerIdAndCreatedAtQuery = {
  followsByFollowerIdAndCreatedAt?:  {
    __typename: "ModelFollowConnection",
    items:  Array< {
      __typename: "Follow",
      id: string,
      userId?: string | null,
      companyId?: string | null,
      followerId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPropertyQueryVariables = {
  id: string,
};

export type GetPropertyQuery = {
  getProperty?:  {
    __typename: "Property",
    id: string,
    title: string,
    type: PropertyType,
    description: string,
    price: number,
    bed?: number | null,
    bath?: number | null,
    sqft?: number | null,
    psf?: number | null,
    listingId?: number | null,
    category?: string | null,
    detailsJson?:  Array< {
      __typename: "KeyValue",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    unitFeatures?: Array< string | null > | null,
    facilities?: Array< string | null > | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListPropertiesQueryVariables = {
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPropertiesQuery = {
  listProperties?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PropertiesByTypeAndCreatedAtQueryVariables = {
  type: PropertyType,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PropertiesByTypeAndCreatedAtQuery = {
  propertiesByTypeAndCreatedAt?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PropertiesByCategoryAndCreatedAtQueryVariables = {
  category: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PropertiesByCategoryAndCreatedAtQuery = {
  propertiesByCategoryAndCreatedAt?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PropertiesByUserIdAndCreatedAtQueryVariables = {
  userId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PropertiesByUserIdAndCreatedAtQuery = {
  propertiesByUserIdAndCreatedAt?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PropertiesByCompanyIdAndCreatedAtQueryVariables = {
  companyId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPropertyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PropertiesByCompanyIdAndCreatedAtQuery = {
  propertiesByCompanyIdAndCreatedAt?:  {
    __typename: "ModelPropertyConnection",
    items:  Array< {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAdminConfigQueryVariables = {
  id: string,
};

export type GetAdminConfigQuery = {
  getAdminConfig?:  {
    __typename: "AdminConfig",
    id: string,
    type?: string | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListAdminConfigsQueryVariables = {
  filter?: ModelAdminConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminConfigsQuery = {
  listAdminConfigs?:  {
    __typename: "ModelAdminConfigConnection",
    items:  Array< {
      __typename: "AdminConfig",
      id: string,
      type?: string | null,
      value?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AdminConfigsByTypeQueryVariables = {
  type: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAdminConfigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdminConfigsByTypeQuery = {
  adminConfigsByType?:  {
    __typename: "ModelAdminConfigConnection",
    items:  Array< {
      __typename: "AdminConfig",
      id: string,
      type?: string | null,
      value?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOfferCodeQueryVariables = {
  id: string,
};

export type GetOfferCodeQuery = {
  getOfferCode?:  {
    __typename: "OfferCode",
    id: string,
    code: string,
    description?: string | null,
    discountType: DiscountType,
    discountValue: number,
    maxUsage?: number | null,
    usageCount?: number | null,
    validFrom?: string | null,
    validTo?: string | null,
    minOrderValue?: number | null,
    maxDiscountAmount?: number | null,
    customerRestrictions?: Array< string | null > | null,
    productRestrictions?: Array< string | null > | null,
    status: Status,
    estoreOrders?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListOfferCodesQueryVariables = {
  filter?: ModelOfferCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOfferCodesQuery = {
  listOfferCodes?:  {
    __typename: "ModelOfferCodeConnection",
    items:  Array< {
      __typename: "OfferCode",
      id: string,
      code: string,
      description?: string | null,
      discountType: DiscountType,
      discountValue: number,
      maxUsage?: number | null,
      usageCount?: number | null,
      validFrom?: string | null,
      validTo?: string | null,
      minOrderValue?: number | null,
      maxDiscountAmount?: number | null,
      customerRestrictions?: Array< string | null > | null,
      productRestrictions?: Array< string | null > | null,
      status: Status,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OfferCodesByCodeAndCreatedAtQueryVariables = {
  code: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOfferCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OfferCodesByCodeAndCreatedAtQuery = {
  offerCodesByCodeAndCreatedAt?:  {
    __typename: "ModelOfferCodeConnection",
    items:  Array< {
      __typename: "OfferCode",
      id: string,
      code: string,
      description?: string | null,
      discountType: DiscountType,
      discountValue: number,
      maxUsage?: number | null,
      usageCount?: number | null,
      validFrom?: string | null,
      validTo?: string | null,
      minOrderValue?: number | null,
      maxDiscountAmount?: number | null,
      customerRestrictions?: Array< string | null > | null,
      productRestrictions?: Array< string | null > | null,
      status: Status,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPageHtmlQueryVariables = {
  id: string,
};

export type GetPageHtmlQuery = {
  getPageHtml?:  {
    __typename: "PageHtml",
    id: string,
    site: string,
    page: string,
    html: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type ListPageHtmlsQueryVariables = {
  filter?: ModelPageHtmlFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPageHtmlsQuery = {
  listPageHtmls?:  {
    __typename: "ModelPageHtmlConnection",
    items:  Array< {
      __typename: "PageHtml",
      id: string,
      site: string,
      page: string,
      html: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PageHtmlsBySiteAndPageQueryVariables = {
  site: string,
  page?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPageHtmlFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PageHtmlsBySiteAndPageQuery = {
  pageHtmlsBySiteAndPage?:  {
    __typename: "ModelPageHtmlConnection",
    items:  Array< {
      __typename: "PageHtml",
      id: string,
      site: string,
      page: string,
      html: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FollowRemoveFQueryVariables = {
  id: string,
};

export type FollowRemoveFQuery = {
  followRemoveF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type DeleteMultipleProductFQueryVariables = {
  productVariantIds: Array< string | null >,
};

export type DeleteMultipleProductFQuery = {
  deleteMultipleProductF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type DeleteFeedFQueryVariables = {
  feedId: string,
};

export type DeleteFeedFQuery = {
  deleteFeedF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type DeleteProductByIdFQueryVariables = {
  productId: string,
};

export type DeleteProductByIdFQuery = {
  deleteProductByIdF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type DeletePostByIdFQueryVariables = {
  postId: string,
};

export type DeletePostByIdFQuery = {
  deletePostByIdF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type ChatGroupRemoveFQueryVariables = {
  chatId: string,
};

export type ChatGroupRemoveFQuery = {
  chatGroupRemoveF?:  {
    __typename: "Resp",
    resp_status?: boolean | null,
    resp_data?: string | null,
    resp_code?: string | null,
    resp_message?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    username: string,
    email: string,
    mobile_no?: string | null,
    name?: string | null,
    gender?: string | null,
    country: string,
    mobile_verified?: boolean | null,
    bio?: string | null,
    picture?: string | null,
    userAddressList?:  {
      __typename: "ModelUserAddressConnection",
      nextToken?: string | null,
    } | null,
    companyUser?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    userProductList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    productReview?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    estoreOrderList?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    followers?: number | null,
    followings?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    online?: boolean | null,
    fcmToken?: string | null,
    merchantFcmToken?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    username: string,
    email: string,
    mobile_no?: string | null,
    name?: string | null,
    gender?: string | null,
    country: string,
    mobile_verified?: boolean | null,
    bio?: string | null,
    picture?: string | null,
    userAddressList?:  {
      __typename: "ModelUserAddressConnection",
      nextToken?: string | null,
    } | null,
    companyUser?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    userProductList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    productReview?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    estoreOrderList?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    followers?: number | null,
    followings?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    online?: boolean | null,
    fcmToken?: string | null,
    merchantFcmToken?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    username: string,
    email: string,
    mobile_no?: string | null,
    name?: string | null,
    gender?: string | null,
    country: string,
    mobile_verified?: boolean | null,
    bio?: string | null,
    picture?: string | null,
    userAddressList?:  {
      __typename: "ModelUserAddressConnection",
      nextToken?: string | null,
    } | null,
    companyUser?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    userProductList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    productReview?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    estoreOrderList?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    followers?: number | null,
    followings?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    online?: boolean | null,
    fcmToken?: string | null,
    merchantFcmToken?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUserAddressSubscriptionVariables = {
  filter?: ModelSubscriptionUserAddressFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserAddressSubscription = {
  onCreateUserAddress?:  {
    __typename: "UserAddress",
    id: string,
    name: string,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    default?: number | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserAddressSubscriptionVariables = {
  filter?: ModelSubscriptionUserAddressFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserAddressSubscription = {
  onUpdateUserAddress?:  {
    __typename: "UserAddress",
    id: string,
    name: string,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    default?: number | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserAddressSubscriptionVariables = {
  filter?: ModelSubscriptionUserAddressFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserAddressSubscription = {
  onDeleteUserAddress?:  {
    __typename: "UserAddress",
    id: string,
    name: string,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    default?: number | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
  owner?: string | null,
};

export type OnCreateCompanySubscription = {
  onCreateCompany?:  {
    __typename: "Company",
    id: string,
    ownerType?: OwnerType | null,
    uen?: string | null,
    gstNo?: string | null,
    type?: Array< ComapnyType | null > | null,
    name?: string | null,
    email?: string | null,
    registration_name?: string | null,
    picture?: string | null,
    bio?: string | null,
    wlcmMsg?: string | null,
    address?: string | null,
    category?: string | null,
    status?: CompanyStatus | null,
    companyUserList?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    productList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    chats?:  {
      __typename: "ModelChatConnection",
      nextToken?: string | null,
    } | null,
    stripeAccountId?: string | null,
    followers?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany?:  {
    __typename: "Company",
    id: string,
    ownerType?: OwnerType | null,
    uen?: string | null,
    gstNo?: string | null,
    type?: Array< ComapnyType | null > | null,
    name?: string | null,
    email?: string | null,
    registration_name?: string | null,
    picture?: string | null,
    bio?: string | null,
    wlcmMsg?: string | null,
    address?: string | null,
    category?: string | null,
    status?: CompanyStatus | null,
    companyUserList?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    productList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    chats?:  {
      __typename: "ModelChatConnection",
      nextToken?: string | null,
    } | null,
    stripeAccountId?: string | null,
    followers?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany?:  {
    __typename: "Company",
    id: string,
    ownerType?: OwnerType | null,
    uen?: string | null,
    gstNo?: string | null,
    type?: Array< ComapnyType | null > | null,
    name?: string | null,
    email?: string | null,
    registration_name?: string | null,
    picture?: string | null,
    bio?: string | null,
    wlcmMsg?: string | null,
    address?: string | null,
    category?: string | null,
    status?: CompanyStatus | null,
    companyUserList?:  {
      __typename: "ModelCompanyUserConnection",
      nextToken?: string | null,
    } | null,
    companyReviews?:  {
      __typename: "ModelCompanyReviewConnection",
      nextToken?: string | null,
    } | null,
    productList?:  {
      __typename: "ModelProductConnection",
      nextToken?: string | null,
    } | null,
    bookings?:  {
      __typename: "ModelBookingConnection",
      nextToken?: string | null,
    } | null,
    chats?:  {
      __typename: "ModelChatConnection",
      nextToken?: string | null,
    } | null,
    stripeAccountId?: string | null,
    followers?: number | null,
    followersList?:  {
      __typename: "ModelFollowConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCompanyPrivateInfoSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyPrivateInfoFilterInput | null,
  owner?: string | null,
};

export type OnCreateCompanyPrivateInfoSubscription = {
  onCreateCompanyPrivateInfo?:  {
    __typename: "CompanyPrivateInfo",
    id: string,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyDocImage?: string | null,
    contactName?: string | null,
    contactEmail?: string | null,
    contactNo?: string | null,
    conatactDocImage?:  {
      __typename: "DocumentImage",
      front?: string | null,
      back?: string | null,
    } | null,
    bankName?: string | null,
    bankCode?: string | null,
    beneficiaryName?: string | null,
    bankAccNo?: string | null,
    bankDocumnet?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCompanyPrivateInfoSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyPrivateInfoFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCompanyPrivateInfoSubscription = {
  onUpdateCompanyPrivateInfo?:  {
    __typename: "CompanyPrivateInfo",
    id: string,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyDocImage?: string | null,
    contactName?: string | null,
    contactEmail?: string | null,
    contactNo?: string | null,
    conatactDocImage?:  {
      __typename: "DocumentImage",
      front?: string | null,
      back?: string | null,
    } | null,
    bankName?: string | null,
    bankCode?: string | null,
    beneficiaryName?: string | null,
    bankAccNo?: string | null,
    bankDocumnet?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCompanyPrivateInfoSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyPrivateInfoFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCompanyPrivateInfoSubscription = {
  onDeleteCompanyPrivateInfo?:  {
    __typename: "CompanyPrivateInfo",
    id: string,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyDocImage?: string | null,
    contactName?: string | null,
    contactEmail?: string | null,
    contactNo?: string | null,
    conatactDocImage?:  {
      __typename: "DocumentImage",
      front?: string | null,
      back?: string | null,
    } | null,
    bankName?: string | null,
    bankCode?: string | null,
    beneficiaryName?: string | null,
    bankAccNo?: string | null,
    bankDocumnet?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCompanyUserSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateCompanyUserSubscription = {
  onCreateCompanyUser?:  {
    __typename: "CompanyUser",
    id: string,
    role: Role,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    modules?:  Array< {
      __typename: "ModuleList",
      moduleName?: string | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
      read?: boolean | null,
    } | null > | null,
    status?: CompanyUserStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCompanyUserSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCompanyUserSubscription = {
  onUpdateCompanyUser?:  {
    __typename: "CompanyUser",
    id: string,
    role: Role,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    modules?:  Array< {
      __typename: "ModuleList",
      moduleName?: string | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
      read?: boolean | null,
    } | null > | null,
    status?: CompanyUserStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCompanyUserSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCompanyUserSubscription = {
  onDeleteCompanyUser?:  {
    __typename: "CompanyUser",
    id: string,
    role: Role,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    modules?:  Array< {
      __typename: "ModuleList",
      moduleName?: string | null,
      add?: boolean | null,
      edit?: boolean | null,
      delete?: boolean | null,
      read?: boolean | null,
    } | null > | null,
    status?: CompanyUserStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCompanyReviewSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyReviewFilterInput | null,
  owner?: string | null,
};

export type OnCreateCompanyReviewSubscription = {
  onCreateCompanyReview?:  {
    __typename: "CompanyReview",
    id: string,
    stars?: number | null,
    comment?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCompanyReviewSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyReviewFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCompanyReviewSubscription = {
  onUpdateCompanyReview?:  {
    __typename: "CompanyReview",
    id: string,
    stars?: number | null,
    comment?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCompanyReviewSubscriptionVariables = {
  filter?: ModelSubscriptionCompanyReviewFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCompanyReviewSubscription = {
  onDeleteCompanyReview?:  {
    __typename: "CompanyReview",
    id: string,
    stars?: number | null,
    comment?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
  owner?: string | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    type: ProductCategoryType,
    description?: string | null,
    picture?: string | null,
    parent_categoryId?: string | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    productCategories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    type: ProductCategoryType,
    description?: string | null,
    picture?: string | null,
    parent_categoryId?: string | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    productCategories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    type: ProductCategoryType,
    description?: string | null,
    picture?: string | null,
    parent_categoryId?: string | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    productCategories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    type: ProductType,
    filterType: string,
    attributes?:  Array< {
      __typename: "ProductAttribute",
      name: string,
      options?: Array< string | null > | null,
    } | null > | null,
    allowToComment?: number | null,
    feature?: number | null,
    brand?: string | null,
    total_sold?: number | null,
    rating?:  {
      __typename: "RatingType",
      one?: number | null,
      two?: number | null,
      three?: number | null,
      four?: number | null,
      five?: number | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    commentCount?: number | null,
    shareCount?: number | null,
    bookmarkCount?: number | null,
    totalReviewCount?: number | null,
    orderCount?: number | null,
    productVariants?:  {
      __typename: "ModelProductVariantConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    metaKeywords?: string | null,
    sku?: string | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    type: ProductType,
    filterType: string,
    attributes?:  Array< {
      __typename: "ProductAttribute",
      name: string,
      options?: Array< string | null > | null,
    } | null > | null,
    allowToComment?: number | null,
    feature?: number | null,
    brand?: string | null,
    total_sold?: number | null,
    rating?:  {
      __typename: "RatingType",
      one?: number | null,
      two?: number | null,
      three?: number | null,
      four?: number | null,
      five?: number | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    commentCount?: number | null,
    shareCount?: number | null,
    bookmarkCount?: number | null,
    totalReviewCount?: number | null,
    orderCount?: number | null,
    productVariants?:  {
      __typename: "ModelProductVariantConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    metaKeywords?: string | null,
    sku?: string | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    title: string,
    description: string,
    type: ProductType,
    filterType: string,
    attributes?:  Array< {
      __typename: "ProductAttribute",
      name: string,
      options?: Array< string | null > | null,
    } | null > | null,
    allowToComment?: number | null,
    feature?: number | null,
    brand?: string | null,
    total_sold?: number | null,
    rating?:  {
      __typename: "RatingType",
      one?: number | null,
      two?: number | null,
      three?: number | null,
      four?: number | null,
      five?: number | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    commentCount?: number | null,
    shareCount?: number | null,
    bookmarkCount?: number | null,
    totalReviewCount?: number | null,
    orderCount?: number | null,
    productVariants?:  {
      __typename: "ModelProductVariantConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelProductCategoriesConnection",
      nextToken?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    metaKeywords?: string | null,
    sku?: string | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateFeedSubscriptionVariables = {
  filter?: ModelSubscriptionFeedFilterInput | null,
  owner?: string | null,
};

export type OnCreateFeedSubscription = {
  onCreateFeed?:  {
    __typename: "Feed",
    id: string,
    title: string,
    description: string,
    type: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelFeedTagsConnection",
      nextToken?: string | null,
    } | null,
    collaborations?:  {
      __typename: "ModelFeedCollaborationConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateFeedSubscriptionVariables = {
  filter?: ModelSubscriptionFeedFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFeedSubscription = {
  onUpdateFeed?:  {
    __typename: "Feed",
    id: string,
    title: string,
    description: string,
    type: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelFeedTagsConnection",
      nextToken?: string | null,
    } | null,
    collaborations?:  {
      __typename: "ModelFeedCollaborationConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteFeedSubscriptionVariables = {
  filter?: ModelSubscriptionFeedFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFeedSubscription = {
  onDeleteFeed?:  {
    __typename: "Feed",
    id: string,
    title: string,
    description: string,
    type: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    tags?:  {
      __typename: "ModelFeedTagsConnection",
      nextToken?: string | null,
    } | null,
    collaborations?:  {
      __typename: "ModelFeedCollaborationConnection",
      nextToken?: string | null,
    } | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateFeedCollaborationSubscriptionVariables = {
  filter?: ModelSubscriptionFeedCollaborationFilterInput | null,
  owner?: string | null,
};

export type OnCreateFeedCollaborationSubscription = {
  onCreateFeedCollaboration?:  {
    __typename: "FeedCollaboration",
    id: string,
    type?: string | null,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    merchantId?: string | null,
    merchant?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateFeedCollaborationSubscriptionVariables = {
  filter?: ModelSubscriptionFeedCollaborationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFeedCollaborationSubscription = {
  onUpdateFeedCollaboration?:  {
    __typename: "FeedCollaboration",
    id: string,
    type?: string | null,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    merchantId?: string | null,
    merchant?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteFeedCollaborationSubscriptionVariables = {
  filter?: ModelSubscriptionFeedCollaborationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFeedCollaborationSubscription = {
  onDeleteFeedCollaboration?:  {
    __typename: "FeedCollaboration",
    id: string,
    type?: string | null,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    merchantId?: string | null,
    merchant?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateFeedTagsSubscriptionVariables = {
  filter?: ModelSubscriptionFeedTagsFilterInput | null,
  owner?: string | null,
};

export type OnCreateFeedTagsSubscription = {
  onCreateFeedTags?:  {
    __typename: "FeedTags",
    id: string,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId?: string | null,
    companyId?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateFeedTagsSubscriptionVariables = {
  filter?: ModelSubscriptionFeedTagsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFeedTagsSubscription = {
  onUpdateFeedTags?:  {
    __typename: "FeedTags",
    id: string,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId?: string | null,
    companyId?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteFeedTagsSubscriptionVariables = {
  filter?: ModelSubscriptionFeedTagsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFeedTagsSubscription = {
  onDeleteFeedTags?:  {
    __typename: "FeedTags",
    id: string,
    feedId: string,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId?: string | null,
    companyId?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateFeedMediaSubscriptionVariables = {
  filter?: ModelSubscriptionFeedMediaFilterInput | null,
  owner?: string | null,
};

export type OnCreateFeedMediaSubscription = {
  onCreateFeedMedia?:  {
    __typename: "FeedMedia",
    id: string,
    file_type: string,
    key: string,
    feedId: string,
    Feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId: string,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId: string,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateFeedMediaSubscriptionVariables = {
  filter?: ModelSubscriptionFeedMediaFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFeedMediaSubscription = {
  onUpdateFeedMedia?:  {
    __typename: "FeedMedia",
    id: string,
    file_type: string,
    key: string,
    feedId: string,
    Feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId: string,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId: string,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteFeedMediaSubscriptionVariables = {
  filter?: ModelSubscriptionFeedMediaFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFeedMediaSubscription = {
  onDeleteFeedMedia?:  {
    __typename: "FeedMedia",
    id: string,
    file_type: string,
    key: string,
    feedId: string,
    Feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId: string,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId: string,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateLikeSubscriptionVariables = {
  filter?: ModelSubscriptionLikeFilterInput | null,
  owner?: string | null,
};

export type OnCreateLikeSubscription = {
  onCreateLike?:  {
    __typename: "Like",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    likeState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateLikeSubscriptionVariables = {
  filter?: ModelSubscriptionLikeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateLikeSubscription = {
  onUpdateLike?:  {
    __typename: "Like",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    likeState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteLikeSubscriptionVariables = {
  filter?: ModelSubscriptionLikeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteLikeSubscription = {
  onDeleteLike?:  {
    __typename: "Like",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    likeState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateViewsSubscriptionVariables = {
  filter?: ModelSubscriptionViewsFilterInput | null,
  owner?: string | null,
};

export type OnCreateViewsSubscription = {
  onCreateViews?:  {
    __typename: "Views",
    id: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    viewCount?: number | null,
    type: ViewProductType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateViewsSubscriptionVariables = {
  filter?: ModelSubscriptionViewsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateViewsSubscription = {
  onUpdateViews?:  {
    __typename: "Views",
    id: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    viewCount?: number | null,
    type: ViewProductType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteViewsSubscriptionVariables = {
  filter?: ModelSubscriptionViewsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteViewsSubscription = {
  onDeleteViews?:  {
    __typename: "Views",
    id: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    viewCount?: number | null,
    type: ViewProductType,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateProductBookmarksSubscriptionVariables = {
  filter?: ModelSubscriptionProductBookmarksFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductBookmarksSubscription = {
  onCreateProductBookmarks?:  {
    __typename: "ProductBookmarks",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookmarkState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductBookmarksSubscriptionVariables = {
  filter?: ModelSubscriptionProductBookmarksFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductBookmarksSubscription = {
  onUpdateProductBookmarks?:  {
    __typename: "ProductBookmarks",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookmarkState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductBookmarksSubscriptionVariables = {
  filter?: ModelSubscriptionProductBookmarksFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductBookmarksSubscription = {
  onDeleteProductBookmarks?:  {
    __typename: "ProductBookmarks",
    id: string,
    type?: string | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    propertyId?: string | null,
    property?:  {
      __typename: "Property",
      id: string,
      title: string,
      type: PropertyType,
      description: string,
      price: number,
      bed?: number | null,
      bath?: number | null,
      sqft?: number | null,
      psf?: number | null,
      listingId?: number | null,
      category?: string | null,
      userId: string,
      companyId?: string | null,
      bookmarkCount?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      unitFeatures?: Array< string | null > | null,
      facilities?: Array< string | null > | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    bookmarkState?: boolean | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCommentsSubscriptionVariables = {
  filter?: ModelSubscriptionCommentsFilterInput | null,
  owner?: string | null,
};

export type OnCreateCommentsSubscription = {
  onCreateComments?:  {
    __typename: "Comments",
    id: string,
    comment: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    parentCommentId?: string | null,
    commentReplyList?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    userId: string,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCommentsSubscriptionVariables = {
  filter?: ModelSubscriptionCommentsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCommentsSubscription = {
  onUpdateComments?:  {
    __typename: "Comments",
    id: string,
    comment: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    parentCommentId?: string | null,
    commentReplyList?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    userId: string,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCommentsSubscriptionVariables = {
  filter?: ModelSubscriptionCommentsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCommentsSubscription = {
  onDeleteComments?:  {
    __typename: "Comments",
    id: string,
    comment: string,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    feedId?: string | null,
    feed?:  {
      __typename: "Feed",
      id: string,
      title: string,
      description: string,
      type: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      userId: string,
      companyId?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    postId?: string | null,
    post?:  {
      __typename: "Post",
      id: string,
      type?: PostType | null,
      userId: string,
      title: string,
      description: string,
      likeCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      viewCount?: number | null,
      bookmarkCount?: number | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    parentCommentId?: string | null,
    commentReplyList?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    userId: string,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductVariantSubscriptionVariables = {
  filter?: ModelSubscriptionProductVariantFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductVariantSubscription = {
  onCreateProductVariant?:  {
    __typename: "ProductVariant",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    title: string,
    description: string,
    attribute?: string | null,
    price?: number | null,
    list_price?: number | null,
    quantity?: number | null,
    deliveryMethod?: string | null,
    productCondition?: string | null,
    productUsed?:  {
      __typename: "ProductUsedType",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    designStyle?: string | null,
    designPropertyType?: string | null,
    orderCount?: number | null,
    videoLink?: string | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    status?: Status | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    sku?: string | null,
    defaultPosition?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    productProductVariantsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductVariantSubscriptionVariables = {
  filter?: ModelSubscriptionProductVariantFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductVariantSubscription = {
  onUpdateProductVariant?:  {
    __typename: "ProductVariant",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    title: string,
    description: string,
    attribute?: string | null,
    price?: number | null,
    list_price?: number | null,
    quantity?: number | null,
    deliveryMethod?: string | null,
    productCondition?: string | null,
    productUsed?:  {
      __typename: "ProductUsedType",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    designStyle?: string | null,
    designPropertyType?: string | null,
    orderCount?: number | null,
    videoLink?: string | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    status?: Status | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    sku?: string | null,
    defaultPosition?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    productProductVariantsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductVariantSubscriptionVariables = {
  filter?: ModelSubscriptionProductVariantFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductVariantSubscription = {
  onDeleteProductVariant?:  {
    __typename: "ProductVariant",
    id: string,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    title: string,
    description: string,
    attribute?: string | null,
    price?: number | null,
    list_price?: number | null,
    quantity?: number | null,
    deliveryMethod?: string | null,
    productCondition?: string | null,
    productUsed?:  {
      __typename: "ProductUsedType",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    designStyle?: string | null,
    designPropertyType?: string | null,
    orderCount?: number | null,
    videoLink?: string | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    status?: Status | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    sku?: string | null,
    defaultPosition?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    productProductVariantsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateProductMediaSubscriptionVariables = {
  filter?: ModelSubscriptionProductMediaFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductMediaSubscription = {
  onCreateProductMedia?:  {
    __typename: "ProductMedia",
    id: string,
    file_type: string,
    key: string,
    product_variant_id: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductMediaSubscriptionVariables = {
  filter?: ModelSubscriptionProductMediaFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductMediaSubscription = {
  onUpdateProductMedia?:  {
    __typename: "ProductMedia",
    id: string,
    file_type: string,
    key: string,
    product_variant_id: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductMediaSubscriptionVariables = {
  filter?: ModelSubscriptionProductMediaFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductMediaSubscription = {
  onDeleteProductMedia?:  {
    __typename: "ProductMedia",
    id: string,
    file_type: string,
    key: string,
    product_variant_id: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    position?: number | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductCategoriesSubscriptionVariables = {
  filter?: ModelSubscriptionProductCategoriesFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductCategoriesSubscription = {
  onCreateProductCategories?:  {
    __typename: "ProductCategories",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      type: ProductCategoryType,
      description?: string | null,
      picture?: string | null,
      parent_categoryId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductCategoriesSubscriptionVariables = {
  filter?: ModelSubscriptionProductCategoriesFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductCategoriesSubscription = {
  onUpdateProductCategories?:  {
    __typename: "ProductCategories",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      type: ProductCategoryType,
      description?: string | null,
      picture?: string | null,
      parent_categoryId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductCategoriesSubscriptionVariables = {
  filter?: ModelSubscriptionProductCategoriesFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductCategoriesSubscription = {
  onDeleteProductCategories?:  {
    __typename: "ProductCategories",
    id: string,
    categoryId: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      type: ProductCategoryType,
      description?: string | null,
      picture?: string | null,
      parent_categoryId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductReviewSubscriptionVariables = {
  filter?: ModelSubscriptionProductReviewFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductReviewSubscription = {
  onCreateProductReview?:  {
    __typename: "ProductReview",
    id: string,
    stars: number,
    comment?: string | null,
    attribute?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    totalImagesCount?: number | null,
    estoreOrderProductId?: string | null,
    estoreOrderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    bookingId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    reviewMediaList?:  {
      __typename: "ModelReviewMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductReviewSubscriptionVariables = {
  filter?: ModelSubscriptionProductReviewFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductReviewSubscription = {
  onUpdateProductReview?:  {
    __typename: "ProductReview",
    id: string,
    stars: number,
    comment?: string | null,
    attribute?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    totalImagesCount?: number | null,
    estoreOrderProductId?: string | null,
    estoreOrderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    bookingId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    reviewMediaList?:  {
      __typename: "ModelReviewMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductReviewSubscriptionVariables = {
  filter?: ModelSubscriptionProductReviewFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductReviewSubscription = {
  onDeleteProductReview?:  {
    __typename: "ProductReview",
    id: string,
    stars: number,
    comment?: string | null,
    attribute?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId: string,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    totalImagesCount?: number | null,
    estoreOrderProductId?: string | null,
    estoreOrderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    bookingId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      type: ProductType,
      quantity?: number | null,
      productVariantId: string,
      userId: string,
      fulfillerId: string,
      companyId?: string | null,
      status: BookingStatus,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    reviewMediaList?:  {
      __typename: "ModelReviewMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateReviewMediaSubscriptionVariables = {
  filter?: ModelSubscriptionReviewMediaFilterInput | null,
  owner?: string | null,
};

export type OnCreateReviewMediaSubscription = {
  onCreateReviewMedia?:  {
    __typename: "ReviewMedia",
    id: string,
    file_type: string,
    key: string,
    reviewId: string,
    productReview?:  {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateReviewMediaSubscriptionVariables = {
  filter?: ModelSubscriptionReviewMediaFilterInput | null,
  owner?: string | null,
};

export type OnUpdateReviewMediaSubscription = {
  onUpdateReviewMedia?:  {
    __typename: "ReviewMedia",
    id: string,
    file_type: string,
    key: string,
    reviewId: string,
    productReview?:  {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteReviewMediaSubscriptionVariables = {
  filter?: ModelSubscriptionReviewMediaFilterInput | null,
  owner?: string | null,
};

export type OnDeleteReviewMediaSubscription = {
  onDeleteReviewMedia?:  {
    __typename: "ReviewMedia",
    id: string,
    file_type: string,
    key: string,
    reviewId: string,
    productReview?:  {
      __typename: "ProductReview",
      id: string,
      stars: number,
      comment?: string | null,
      attribute?: string | null,
      userId: string,
      productId: string,
      totalImagesCount?: number | null,
      estoreOrderProductId?: string | null,
      bookingId?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateEstoreOrdersSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreOrdersFilterInput | null,
};

export type OnCreateEstoreOrdersSubscription = {
  onCreateEstoreOrders?:  {
    __typename: "EstoreOrders",
    id: string,
    orderId: string,
    transaction_id?: string | null,
    order_price: number,
    discount?: number | null,
    sell_price?: number | null,
    name?: string | null,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city: string,
    state?: string | null,
    country: string,
    offerCodeId?: string | null,
    offerCode?:  {
      __typename: "OfferCode",
      id: string,
      code: string,
      description?: string | null,
      discountType: DiscountType,
      discountValue: number,
      maxUsage?: number | null,
      usageCount?: number | null,
      validFrom?: string | null,
      validTo?: string | null,
      minOrderValue?: number | null,
      maxDiscountAmount?: number | null,
      customerRestrictions?: Array< string | null > | null,
      productRestrictions?: Array< string | null > | null,
      status: Status,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    delivery_fee?: number | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    statusDescription?: string | null,
    estoreOrderProducts?:  {
      __typename: "ModelEstoreOrderProductConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateEstoreOrdersSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreOrdersFilterInput | null,
};

export type OnUpdateEstoreOrdersSubscription = {
  onUpdateEstoreOrders?:  {
    __typename: "EstoreOrders",
    id: string,
    orderId: string,
    transaction_id?: string | null,
    order_price: number,
    discount?: number | null,
    sell_price?: number | null,
    name?: string | null,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city: string,
    state?: string | null,
    country: string,
    offerCodeId?: string | null,
    offerCode?:  {
      __typename: "OfferCode",
      id: string,
      code: string,
      description?: string | null,
      discountType: DiscountType,
      discountValue: number,
      maxUsage?: number | null,
      usageCount?: number | null,
      validFrom?: string | null,
      validTo?: string | null,
      minOrderValue?: number | null,
      maxDiscountAmount?: number | null,
      customerRestrictions?: Array< string | null > | null,
      productRestrictions?: Array< string | null > | null,
      status: Status,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    delivery_fee?: number | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    statusDescription?: string | null,
    estoreOrderProducts?:  {
      __typename: "ModelEstoreOrderProductConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteEstoreOrdersSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreOrdersFilterInput | null,
};

export type OnDeleteEstoreOrdersSubscription = {
  onDeleteEstoreOrders?:  {
    __typename: "EstoreOrders",
    id: string,
    orderId: string,
    transaction_id?: string | null,
    order_price: number,
    discount?: number | null,
    sell_price?: number | null,
    name?: string | null,
    email?: string | null,
    mobile_no: string,
    address_one: string,
    address_two?: string | null,
    pincode?: string | null,
    city: string,
    state?: string | null,
    country: string,
    offerCodeId?: string | null,
    offerCode?:  {
      __typename: "OfferCode",
      id: string,
      code: string,
      description?: string | null,
      discountType: DiscountType,
      discountValue: number,
      maxUsage?: number | null,
      usageCount?: number | null,
      validFrom?: string | null,
      validTo?: string | null,
      minOrderValue?: number | null,
      maxDiscountAmount?: number | null,
      customerRestrictions?: Array< string | null > | null,
      productRestrictions?: Array< string | null > | null,
      status: Status,
      createdAt?: string | null,
      updatedAt?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    delivery_fee?: number | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    statusDescription?: string | null,
    estoreOrderProducts?:  {
      __typename: "ModelEstoreOrderProductConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateEstoreOrderProductSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreOrderProductFilterInput | null,
};

export type OnCreateEstoreOrderProductSubscription = {
  onCreateEstoreOrderProduct?:  {
    __typename: "EstoreOrderProduct",
    id: string,
    title: string,
    description: string,
    price: number,
    list_price: number,
    quantity: number,
    invoice?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    attribute?: string | null,
    add_ons?:  {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    deliveryStatus: DeliverStatusType,
    statusDescription?: string | null,
    statusHistory?: string | null,
    cancellationReason?: string | null,
    estore_order_id?: string | null,
    estoreOrder?:  {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateEstoreOrderProductSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreOrderProductFilterInput | null,
};

export type OnUpdateEstoreOrderProductSubscription = {
  onUpdateEstoreOrderProduct?:  {
    __typename: "EstoreOrderProduct",
    id: string,
    title: string,
    description: string,
    price: number,
    list_price: number,
    quantity: number,
    invoice?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    attribute?: string | null,
    add_ons?:  {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    deliveryStatus: DeliverStatusType,
    statusDescription?: string | null,
    statusHistory?: string | null,
    cancellationReason?: string | null,
    estore_order_id?: string | null,
    estoreOrder?:  {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteEstoreOrderProductSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreOrderProductFilterInput | null,
};

export type OnDeleteEstoreOrderProductSubscription = {
  onDeleteEstoreOrderProduct?:  {
    __typename: "EstoreOrderProduct",
    id: string,
    title: string,
    description: string,
    price: number,
    list_price: number,
    quantity: number,
    invoice?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    productMediaList?:  {
      __typename: "ModelProductMediaConnection",
      nextToken?: string | null,
    } | null,
    attribute?: string | null,
    add_ons?:  {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null,
    status: OrderStatusType,
    paymentStatus: PaymentStatusType,
    deliveryStatus: DeliverStatusType,
    statusDescription?: string | null,
    statusHistory?: string | null,
    cancellationReason?: string | null,
    estore_order_id?: string | null,
    estoreOrder?:  {
      __typename: "EstoreOrders",
      id: string,
      orderId: string,
      transaction_id?: string | null,
      order_price: number,
      discount?: number | null,
      sell_price?: number | null,
      name?: string | null,
      email?: string | null,
      mobile_no: string,
      address_one: string,
      address_two?: string | null,
      pincode?: string | null,
      city: string,
      state?: string | null,
      country: string,
      offerCodeId?: string | null,
      userId: string,
      delivery_fee?: number | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      statusDescription?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateEstoreOrdersRefundSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreOrdersRefundFilterInput | null,
  owner?: string | null,
};

export type OnCreateEstoreOrdersRefundSubscription = {
  onCreateEstoreOrdersRefund?:  {
    __typename: "EstoreOrdersRefund",
    id: string,
    orderProductId: string,
    orderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    refund_price: number,
    reason: string,
    status: PaymentStatusType,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateEstoreOrdersRefundSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreOrdersRefundFilterInput | null,
  owner?: string | null,
};

export type OnUpdateEstoreOrdersRefundSubscription = {
  onUpdateEstoreOrdersRefund?:  {
    __typename: "EstoreOrdersRefund",
    id: string,
    orderProductId: string,
    orderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    refund_price: number,
    reason: string,
    status: PaymentStatusType,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteEstoreOrdersRefundSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreOrdersRefundFilterInput | null,
  owner?: string | null,
};

export type OnDeleteEstoreOrdersRefundSubscription = {
  onDeleteEstoreOrdersRefund?:  {
    __typename: "EstoreOrdersRefund",
    id: string,
    orderProductId: string,
    orderProduct?:  {
      __typename: "EstoreOrderProduct",
      id: string,
      title: string,
      description: string,
      price: number,
      list_price: number,
      quantity: number,
      invoice?: string | null,
      userId: string,
      companyId: string,
      productVariantId: string,
      attribute?: string | null,
      status: OrderStatusType,
      paymentStatus: PaymentStatusType,
      deliveryStatus: DeliverStatusType,
      statusDescription?: string | null,
      statusHistory?: string | null,
      cancellationReason?: string | null,
      estore_order_id?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    refund_price: number,
    reason: string,
    status: PaymentStatusType,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateTransactionLogsSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionLogsFilterInput | null,
  owner?: string | null,
};

export type OnCreateTransactionLogsSubscription = {
  onCreateTransactionLogs?:  {
    __typename: "TransactionLogs",
    id: string,
    orderId?: string | null,
    type: string,
    response: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateTransactionLogsSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionLogsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateTransactionLogsSubscription = {
  onUpdateTransactionLogs?:  {
    __typename: "TransactionLogs",
    id: string,
    orderId?: string | null,
    type: string,
    response: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteTransactionLogsSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionLogsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteTransactionLogsSubscription = {
  onDeleteTransactionLogs?:  {
    __typename: "TransactionLogs",
    id: string,
    orderId?: string | null,
    type: string,
    response: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateEstoreCartSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreCartFilterInput | null,
};

export type OnCreateEstoreCartSubscription = {
  onCreateEstoreCart?:  {
    __typename: "EstoreCart",
    id: string,
    quantity: number,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status: Status,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateEstoreCartSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreCartFilterInput | null,
};

export type OnUpdateEstoreCartSubscription = {
  onUpdateEstoreCart?:  {
    __typename: "EstoreCart",
    id: string,
    quantity: number,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status: Status,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteEstoreCartSubscriptionVariables = {
  filter?: ModelSubscriptionEstoreCartFilterInput | null,
};

export type OnDeleteEstoreCartSubscription = {
  onDeleteEstoreCart?:  {
    __typename: "EstoreCart",
    id: string,
    quantity: number,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    add_ons?:  Array< {
      __typename: "ProductAddOns",
      id: string,
      title: string,
      description: string,
      imageKey?: string | null,
      price?: number | null,
      list_price?: number | null,
    } | null > | null,
    companyId: string,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status: Status,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
  owner?: string | null,
};

export type OnCreateBookingSubscription = {
  onCreateBooking?:  {
    __typename: "Booking",
    id: string,
    type: ProductType,
    quantity?: number | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    fulfillerId: string,
    fulfiller?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    status: BookingStatus,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
  owner?: string | null,
};

export type OnUpdateBookingSubscription = {
  onUpdateBooking?:  {
    __typename: "Booking",
    id: string,
    type: ProductType,
    quantity?: number | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    fulfillerId: string,
    fulfiller?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    status: BookingStatus,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
  owner?: string | null,
};

export type OnDeleteBookingSubscription = {
  onDeleteBooking?:  {
    __typename: "Booking",
    id: string,
    type: ProductType,
    quantity?: number | null,
    productVariantId: string,
    productVariant?:  {
      __typename: "ProductVariant",
      id: string,
      productId: string,
      title: string,
      description: string,
      attribute?: string | null,
      price?: number | null,
      list_price?: number | null,
      quantity?: number | null,
      deliveryMethod?: string | null,
      productCondition?: string | null,
      designStyle?: string | null,
      designPropertyType?: string | null,
      orderCount?: number | null,
      videoLink?: string | null,
      status?: Status | null,
      sku?: string | null,
      defaultPosition?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      productProductVariantsId?: string | null,
      owner?: string | null,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    fulfillerId: string,
    fulfiller?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productReviews?:  {
      __typename: "ModelProductReviewConnection",
      nextToken?: string | null,
    } | null,
    status: BookingStatus,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateChatSubscriptionVariables = {
  filter?: ModelSubscriptionChatFilterInput | null,
};

export type OnCreateChatSubscription = {
  onCreateChat?:  {
    __typename: "Chat",
    id: string,
    name?: string | null,
    group?: boolean | null,
    image?: string | null,
    participants?:  {
      __typename: "ModelChatParticipantsConnection",
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessagesConnection",
      nextToken?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    chatMessageUnreadCount?:  {
      __typename: "ModelChatMessageUnreadCountConnection",
      nextToken?: string | null,
    } | null,
    deletedBy?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateChatSubscriptionVariables = {
  filter?: ModelSubscriptionChatFilterInput | null,
};

export type OnUpdateChatSubscription = {
  onUpdateChat?:  {
    __typename: "Chat",
    id: string,
    name?: string | null,
    group?: boolean | null,
    image?: string | null,
    participants?:  {
      __typename: "ModelChatParticipantsConnection",
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessagesConnection",
      nextToken?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    chatMessageUnreadCount?:  {
      __typename: "ModelChatMessageUnreadCountConnection",
      nextToken?: string | null,
    } | null,
    deletedBy?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteChatSubscriptionVariables = {
  filter?: ModelSubscriptionChatFilterInput | null,
};

export type OnDeleteChatSubscription = {
  onDeleteChat?:  {
    __typename: "Chat",
    id: string,
    name?: string | null,
    group?: boolean | null,
    image?: string | null,
    participants?:  {
      __typename: "ModelChatParticipantsConnection",
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessagesConnection",
      nextToken?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    chatMessageUnreadCount?:  {
      __typename: "ModelChatMessageUnreadCountConnection",
      nextToken?: string | null,
    } | null,
    deletedBy?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateChatParticipantsSubscriptionVariables = {
  filter?: ModelSubscriptionChatParticipantsFilterInput | null,
};

export type OnCreateChatParticipantsSubscription = {
  onCreateChatParticipants?:  {
    __typename: "ChatParticipants",
    id: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    isActive?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateChatParticipantsSubscriptionVariables = {
  filter?: ModelSubscriptionChatParticipantsFilterInput | null,
};

export type OnUpdateChatParticipantsSubscription = {
  onUpdateChatParticipants?:  {
    __typename: "ChatParticipants",
    id: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    isActive?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteChatParticipantsSubscriptionVariables = {
  filter?: ModelSubscriptionChatParticipantsFilterInput | null,
};

export type OnDeleteChatParticipantsSubscription = {
  onDeleteChatParticipants?:  {
    __typename: "ChatParticipants",
    id: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    isActive?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateChatMessagesSubscriptionVariables = {
  filter?: ModelSubscriptionChatMessagesFilterInput | null,
  owner?: string | null,
};

export type OnCreateChatMessagesSubscription = {
  onCreateChatMessages?:  {
    __typename: "ChatMessages",
    id: string,
    message?: string | null,
    attachment?: string | null,
    attachment_type?: string | null,
    bookingId?: string | null,
    propertyId?: string | null,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    User?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateChatMessagesSubscriptionVariables = {
  filter?: ModelSubscriptionChatMessagesFilterInput | null,
  owner?: string | null,
};

export type OnUpdateChatMessagesSubscription = {
  onUpdateChatMessages?:  {
    __typename: "ChatMessages",
    id: string,
    message?: string | null,
    attachment?: string | null,
    attachment_type?: string | null,
    bookingId?: string | null,
    propertyId?: string | null,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    User?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteChatMessagesSubscriptionVariables = {
  filter?: ModelSubscriptionChatMessagesFilterInput | null,
  owner?: string | null,
};

export type OnDeleteChatMessagesSubscription = {
  onDeleteChatMessages?:  {
    __typename: "ChatMessages",
    id: string,
    message?: string | null,
    attachment?: string | null,
    attachment_type?: string | null,
    bookingId?: string | null,
    propertyId?: string | null,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    userId: string,
    User?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateChatMessageUnreadCountSubscriptionVariables = {
  filter?: ModelSubscriptionChatMessageUnreadCountFilterInput | null,
  owner?: string | null,
};

export type OnCreateChatMessageUnreadCountSubscription = {
  onCreateChatMessageUnreadCount?:  {
    __typename: "ChatMessageUnreadCount",
    id: string,
    userId: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    unreadCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateChatMessageUnreadCountSubscriptionVariables = {
  filter?: ModelSubscriptionChatMessageUnreadCountFilterInput | null,
  owner?: string | null,
};

export type OnUpdateChatMessageUnreadCountSubscription = {
  onUpdateChatMessageUnreadCount?:  {
    __typename: "ChatMessageUnreadCount",
    id: string,
    userId: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    unreadCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteChatMessageUnreadCountSubscriptionVariables = {
  filter?: ModelSubscriptionChatMessageUnreadCountFilterInput | null,
  owner?: string | null,
};

export type OnDeleteChatMessageUnreadCountSubscription = {
  onDeleteChatMessageUnreadCount?:  {
    __typename: "ChatMessageUnreadCount",
    id: string,
    userId: string,
    chatId: string,
    chat?:  {
      __typename: "Chat",
      id: string,
      name?: string | null,
      group?: boolean | null,
      image?: string | null,
      companyId?: string | null,
      createdAt?: string | null,
      deletedBy?: string | null,
      updatedAt: string,
    } | null,
    unreadCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateNotificationsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationsFilterInput | null,
};

export type OnCreateNotificationsSubscription = {
  onCreateNotifications?:  {
    __typename: "Notifications",
    id: string,
    userId: string,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    read: number,
    title: string,
    description: string,
    imageUrl?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificationsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationsFilterInput | null,
};

export type OnUpdateNotificationsSubscription = {
  onUpdateNotifications?:  {
    __typename: "Notifications",
    id: string,
    userId: string,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    read: number,
    title: string,
    description: string,
    imageUrl?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificationsSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationsFilterInput | null,
};

export type OnDeleteNotificationsSubscription = {
  onDeleteNotifications?:  {
    __typename: "Notifications",
    id: string,
    userId: string,
    senderId?: string | null,
    sender?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    read: number,
    title: string,
    description: string,
    imageUrl?: string | null,
    data?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreatePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
  owner?: string | null,
};

export type OnCreatePostSubscription = {
  onCreatePost?:  {
    __typename: "Post",
    id: string,
    type?: PostType | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    title: string,
    description: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePostSubscription = {
  onUpdatePost?:  {
    __typename: "Post",
    id: string,
    type?: PostType | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    title: string,
    description: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
  owner?: string | null,
};

export type OnDeletePostSubscription = {
  onDeletePost?:  {
    __typename: "Post",
    id: string,
    type?: PostType | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    title: string,
    description: string,
    likeCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    commentCount?: number | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    shareCount?: number | null,
    viewCount?: number | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateRecommendationSubscriptionVariables = {
  filter?: ModelSubscriptionRecommendationFilterInput | null,
  owner?: string | null,
};

export type OnCreateRecommendationSubscription = {
  onCreateRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    postId?: string | null,
    feedId?: string | null,
    createdAt?: string | null,
    ttl: number,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateRecommendationSubscriptionVariables = {
  filter?: ModelSubscriptionRecommendationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateRecommendationSubscription = {
  onUpdateRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    postId?: string | null,
    feedId?: string | null,
    createdAt?: string | null,
    ttl: number,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteRecommendationSubscriptionVariables = {
  filter?: ModelSubscriptionRecommendationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteRecommendationSubscription = {
  onDeleteRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    productId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      title: string,
      description: string,
      type: ProductType,
      filterType: string,
      allowToComment?: number | null,
      feature?: number | null,
      brand?: string | null,
      total_sold?: number | null,
      likeCount?: number | null,
      viewCount?: number | null,
      commentCount?: number | null,
      shareCount?: number | null,
      bookmarkCount?: number | null,
      totalReviewCount?: number | null,
      orderCount?: number | null,
      userId: string,
      companyId?: string | null,
      metaKeywords?: string | null,
      sku?: string | null,
      status?: Status | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    postId?: string | null,
    feedId?: string | null,
    createdAt?: string | null,
    ttl: number,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateRecommendationViewsSubscriptionVariables = {
  filter?: ModelSubscriptionRecommendationViewsFilterInput | null,
  owner?: string | null,
};

export type OnCreateRecommendationViewsSubscription = {
  onCreateRecommendationViews?:  {
    __typename: "RecommendationViews",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateRecommendationViewsSubscriptionVariables = {
  filter?: ModelSubscriptionRecommendationViewsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateRecommendationViewsSubscription = {
  onUpdateRecommendationViews?:  {
    __typename: "RecommendationViews",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteRecommendationViewsSubscriptionVariables = {
  filter?: ModelSubscriptionRecommendationViewsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteRecommendationViewsSubscription = {
  onDeleteRecommendationViews?:  {
    __typename: "RecommendationViews",
    id: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateFollowSubscriptionVariables = {
  filter?: ModelSubscriptionFollowFilterInput | null,
  owner?: string | null,
};

export type OnCreateFollowSubscription = {
  onCreateFollow?:  {
    __typename: "Follow",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    followerId?: string | null,
    follower?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateFollowSubscriptionVariables = {
  filter?: ModelSubscriptionFollowFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFollowSubscription = {
  onUpdateFollow?:  {
    __typename: "Follow",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    followerId?: string | null,
    follower?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteFollowSubscriptionVariables = {
  filter?: ModelSubscriptionFollowFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFollowSubscription = {
  onDeleteFollow?:  {
    __typename: "Follow",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    followerId?: string | null,
    follower?:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
  owner?: string | null,
};

export type OnCreatePropertySubscription = {
  onCreateProperty?:  {
    __typename: "Property",
    id: string,
    title: string,
    type: PropertyType,
    description: string,
    price: number,
    bed?: number | null,
    bath?: number | null,
    sqft?: number | null,
    psf?: number | null,
    listingId?: number | null,
    category?: string | null,
    detailsJson?:  Array< {
      __typename: "KeyValue",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    unitFeatures?: Array< string | null > | null,
    facilities?: Array< string | null > | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdatePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePropertySubscription = {
  onUpdateProperty?:  {
    __typename: "Property",
    id: string,
    title: string,
    type: PropertyType,
    description: string,
    price: number,
    bed?: number | null,
    bath?: number | null,
    sqft?: number | null,
    psf?: number | null,
    listingId?: number | null,
    category?: string | null,
    detailsJson?:  Array< {
      __typename: "KeyValue",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    unitFeatures?: Array< string | null > | null,
    facilities?: Array< string | null > | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeletePropertySubscriptionVariables = {
  filter?: ModelSubscriptionPropertyFilterInput | null,
  owner?: string | null,
};

export type OnDeletePropertySubscription = {
  onDeleteProperty?:  {
    __typename: "Property",
    id: string,
    title: string,
    type: PropertyType,
    description: string,
    price: number,
    bed?: number | null,
    bath?: number | null,
    sqft?: number | null,
    psf?: number | null,
    listingId?: number | null,
    category?: string | null,
    detailsJson?:  Array< {
      __typename: "KeyValue",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      username: string,
      email: string,
      mobile_no?: string | null,
      name?: string | null,
      gender?: string | null,
      country: string,
      mobile_verified?: boolean | null,
      bio?: string | null,
      picture?: string | null,
      followers?: number | null,
      followings?: number | null,
      online?: boolean | null,
      fcmToken?: string | null,
      merchantFcmToken?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    },
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      ownerType?: OwnerType | null,
      uen?: string | null,
      gstNo?: string | null,
      type?: Array< ComapnyType | null > | null,
      name?: string | null,
      email?: string | null,
      registration_name?: string | null,
      picture?: string | null,
      bio?: string | null,
      wlcmMsg?: string | null,
      address?: string | null,
      category?: string | null,
      status?: CompanyStatus | null,
      stripeAccountId?: string | null,
      followers?: number | null,
      createdAt?: string | null,
      updatedAt: string,
      owner?: string | null,
    } | null,
    mediaList?:  {
      __typename: "ModelFeedMediaConnection",
      nextToken?: string | null,
    } | null,
    bookmarkCount?: number | null,
    bookmarks?:  {
      __typename: "ModelProductBookmarksConnection",
      nextToken?: string | null,
    } | null,
    likeCount?: number | null,
    viewCount?: number | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      nextToken?: string | null,
    } | null,
    unitFeatures?: Array< string | null > | null,
    facilities?: Array< string | null > | null,
    status?: Status | null,
    location?:  {
      __typename: "Location",
      lat: number,
      lng: number,
      address?: string | null,
      pincode?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateAdminConfigSubscriptionVariables = {
  filter?: ModelSubscriptionAdminConfigFilterInput | null,
  owner?: string | null,
};

export type OnCreateAdminConfigSubscription = {
  onCreateAdminConfig?:  {
    __typename: "AdminConfig",
    id: string,
    type?: string | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateAdminConfigSubscriptionVariables = {
  filter?: ModelSubscriptionAdminConfigFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAdminConfigSubscription = {
  onUpdateAdminConfig?:  {
    __typename: "AdminConfig",
    id: string,
    type?: string | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteAdminConfigSubscriptionVariables = {
  filter?: ModelSubscriptionAdminConfigFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAdminConfigSubscription = {
  onDeleteAdminConfig?:  {
    __typename: "AdminConfig",
    id: string,
    type?: string | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateOfferCodeSubscriptionVariables = {
  filter?: ModelSubscriptionOfferCodeFilterInput | null,
  owner?: string | null,
};

export type OnCreateOfferCodeSubscription = {
  onCreateOfferCode?:  {
    __typename: "OfferCode",
    id: string,
    code: string,
    description?: string | null,
    discountType: DiscountType,
    discountValue: number,
    maxUsage?: number | null,
    usageCount?: number | null,
    validFrom?: string | null,
    validTo?: string | null,
    minOrderValue?: number | null,
    maxDiscountAmount?: number | null,
    customerRestrictions?: Array< string | null > | null,
    productRestrictions?: Array< string | null > | null,
    status: Status,
    estoreOrders?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateOfferCodeSubscriptionVariables = {
  filter?: ModelSubscriptionOfferCodeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateOfferCodeSubscription = {
  onUpdateOfferCode?:  {
    __typename: "OfferCode",
    id: string,
    code: string,
    description?: string | null,
    discountType: DiscountType,
    discountValue: number,
    maxUsage?: number | null,
    usageCount?: number | null,
    validFrom?: string | null,
    validTo?: string | null,
    minOrderValue?: number | null,
    maxDiscountAmount?: number | null,
    customerRestrictions?: Array< string | null > | null,
    productRestrictions?: Array< string | null > | null,
    status: Status,
    estoreOrders?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteOfferCodeSubscriptionVariables = {
  filter?: ModelSubscriptionOfferCodeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteOfferCodeSubscription = {
  onDeleteOfferCode?:  {
    __typename: "OfferCode",
    id: string,
    code: string,
    description?: string | null,
    discountType: DiscountType,
    discountValue: number,
    maxUsage?: number | null,
    usageCount?: number | null,
    validFrom?: string | null,
    validTo?: string | null,
    minOrderValue?: number | null,
    maxDiscountAmount?: number | null,
    customerRestrictions?: Array< string | null > | null,
    productRestrictions?: Array< string | null > | null,
    status: Status,
    estoreOrders?:  {
      __typename: "ModelEstoreOrdersConnection",
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreatePageHtmlSubscriptionVariables = {
  filter?: ModelSubscriptionPageHtmlFilterInput | null,
  owner?: string | null,
};

export type OnCreatePageHtmlSubscription = {
  onCreatePageHtml?:  {
    __typename: "PageHtml",
    id: string,
    site: string,
    page: string,
    html: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdatePageHtmlSubscriptionVariables = {
  filter?: ModelSubscriptionPageHtmlFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePageHtmlSubscription = {
  onUpdatePageHtml?:  {
    __typename: "PageHtml",
    id: string,
    site: string,
    page: string,
    html: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeletePageHtmlSubscriptionVariables = {
  filter?: ModelSubscriptionPageHtmlFilterInput | null,
  owner?: string | null,
};

export type OnDeletePageHtmlSubscription = {
  onDeletePageHtml?:  {
    __typename: "PageHtml",
    id: string,
    site: string,
    page: string,
    html: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    owner?: string | null,
  } | null,
};

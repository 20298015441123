import { useState, useEffect } from 'react';
import { fileInput } from '../APIs/API';

const useVideoPlayer = () => {
  const [ playStates, setPlayStates ] = useState({} as any);

  const toggleVideoPlayPause = (media:fileInput) => {
    try {
      if (!media.id) {
        return
      }

      // Toggle the play/pause state
      let tempPlayStates = { ...playStates };
      const play = !tempPlayStates[media.id];

      // Get the video element
      const videoElement: any = document.querySelector(`#${media.id}`);
      // Toggle play/pause based on the updated state
      if (play) {
        videoElement?.play();
      } else {
        videoElement?.pause();
      }

      setPlayStates({ ...tempPlayStates, [media.id]: play });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSlideChange = () => {
    let tempPlayStates = { ...playStates };
    for (const videoId in tempPlayStates) {
      if (tempPlayStates[videoId]) {
        const videoElement: any = document.getElementById(videoId);
        if (videoElement) {
          tempPlayStates[videoId] = false;
          videoElement.pause();
        }
      }
    }
    setPlayStates(tempPlayStates);
  };

  useEffect(() => {
    const cleanup = () => {
      Object.keys(playStates).forEach((videoId) => {
        const videoElement:any = document.getElementById(videoId);
        if (videoElement) {
          videoElement.pause();
        }
      });
    };

    document.addEventListener('visibilitychange', cleanup);

    return () => {
      document.removeEventListener('visibilitychange', cleanup);
    };
  }, [ playStates ]);

  return { toggleVideoPlayPause, handleSlideChange, playStates };
};

export default useVideoPlayer;
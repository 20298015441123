import React, { lazy, Suspense } from 'react';

const LazyPageStoreList = lazy(() => import('./PageStoreList'));

const PageStoreList = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageStoreList {...props} />
  </Suspense>
);

export default PageStoreList;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const searchProductF = /* GraphQL */ `mutation SearchProductF($input: searchProductInput) {
  searchProductF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SearchProductFMutationVariables,
  APITypes.SearchProductFMutation
>;
export const openaiSearchProductF = /* GraphQL */ `mutation OpenaiSearchProductF($input: openAiSerchInput) {
  openaiSearchProductF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.OpenaiSearchProductFMutationVariables,
  APITypes.OpenaiSearchProductFMutation
>;
export const updateShareCountF = /* GraphQL */ `mutation UpdateShareCountF($input: updateShareCountInput) {
  updateShareCountF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShareCountFMutationVariables,
  APITypes.UpdateShareCountFMutation
>;
export const openAISuggestionGenerator = /* GraphQL */ `mutation OpenAISuggestionGenerator($input: openAISuggestionGeneratorInput) {
  openAISuggestionGenerator(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.OpenAISuggestionGeneratorMutationVariables,
  APITypes.OpenAISuggestionGeneratorMutation
>;
export const giveNextRelatedProduct = /* GraphQL */ `mutation GiveNextRelatedProduct($input: giveNextRelatedProductInput) {
  giveNextRelatedProduct(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GiveNextRelatedProductMutationVariables,
  APITypes.GiveNextRelatedProductMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    username
    email
    mobile_no
    name
    gender
    country
    mobile_verified
    bio
    picture
    userAddressList {
      nextToken
      __typename
    }
    companyUser {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    userProductList {
      nextToken
      __typename
    }
    productReview {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    estoreOrderList {
      nextToken
      __typename
    }
    followers
    followings
    followersList {
      nextToken
      __typename
    }
    online
    fcmToken
    merchantFcmToken
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const deleteCompany = /* GraphQL */ `mutation DeleteCompany(
  $input: DeleteCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  deleteCompany(input: $input, condition: $condition) {
    id
    ownerType
    uen
    gstNo
    type
    name
    email
    registration_name
    picture
    bio
    wlcmMsg
    address
    category
    status
    companyUserList {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    productList {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    chats {
      nextToken
      __typename
    }
    stripeAccountId
    followers
    followersList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyMutationVariables,
  APITypes.DeleteCompanyMutation
>;
export const deleteCompanyPrivateInfo = /* GraphQL */ `mutation DeleteCompanyPrivateInfo(
  $input: DeleteCompanyPrivateInfoInput!
  $condition: ModelCompanyPrivateInfoConditionInput
) {
  deleteCompanyPrivateInfo(input: $input, condition: $condition) {
    id
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    companyDocImage
    contactName
    contactEmail
    contactNo
    conatactDocImage {
      front
      back
      __typename
    }
    bankName
    bankCode
    beneficiaryName
    bankAccNo
    bankDocumnet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyPrivateInfoMutationVariables,
  APITypes.DeleteCompanyPrivateInfoMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    name
    type
    description
    picture
    parent_categoryId
    subCategories {
      nextToken
      __typename
    }
    productCategories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const deleteComments = /* GraphQL */ `mutation DeleteComments(
  $input: DeleteCommentsInput!
  $condition: ModelCommentsConditionInput
) {
  deleteComments(input: $input, condition: $condition) {
    id
    comment
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    parentCommentId
    commentReplyList {
      nextToken
      __typename
    }
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentsMutationVariables,
  APITypes.DeleteCommentsMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    username
    email
    mobile_no
    name
    gender
    country
    mobile_verified
    bio
    picture
    userAddressList {
      nextToken
      __typename
    }
    companyUser {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    userProductList {
      nextToken
      __typename
    }
    productReview {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    estoreOrderList {
      nextToken
      __typename
    }
    followers
    followings
    followersList {
      nextToken
      __typename
    }
    online
    fcmToken
    merchantFcmToken
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    username
    email
    mobile_no
    name
    gender
    country
    mobile_verified
    bio
    picture
    userAddressList {
      nextToken
      __typename
    }
    companyUser {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    userProductList {
      nextToken
      __typename
    }
    productReview {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    estoreOrderList {
      nextToken
      __typename
    }
    followers
    followings
    followersList {
      nextToken
      __typename
    }
    online
    fcmToken
    merchantFcmToken
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const createUserAddress = /* GraphQL */ `mutation CreateUserAddress(
  $input: CreateUserAddressInput!
  $condition: ModelUserAddressConditionInput
) {
  createUserAddress(input: $input, condition: $condition) {
    id
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    default
    userId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserAddressMutationVariables,
  APITypes.CreateUserAddressMutation
>;
export const updateUserAddress = /* GraphQL */ `mutation UpdateUserAddress(
  $input: UpdateUserAddressInput!
  $condition: ModelUserAddressConditionInput
) {
  updateUserAddress(input: $input, condition: $condition) {
    id
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    default
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserAddressMutationVariables,
  APITypes.UpdateUserAddressMutation
>;
export const deleteUserAddress = /* GraphQL */ `mutation DeleteUserAddress(
  $input: DeleteUserAddressInput!
  $condition: ModelUserAddressConditionInput
) {
  deleteUserAddress(input: $input, condition: $condition) {
    id
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    default
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserAddressMutationVariables,
  APITypes.DeleteUserAddressMutation
>;
export const createCompany = /* GraphQL */ `mutation CreateCompany(
  $input: CreateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  createCompany(input: $input, condition: $condition) {
    id
    ownerType
    uen
    gstNo
    type
    name
    email
    registration_name
    picture
    bio
    wlcmMsg
    address
    category
    status
    companyUserList {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    productList {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    chats {
      nextToken
      __typename
    }
    stripeAccountId
    followers
    followersList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyMutationVariables,
  APITypes.CreateCompanyMutation
>;
export const updateCompany = /* GraphQL */ `mutation UpdateCompany(
  $input: UpdateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  updateCompany(input: $input, condition: $condition) {
    id
    ownerType
    uen
    gstNo
    type
    name
    email
    registration_name
    picture
    bio
    wlcmMsg
    address
    category
    status
    companyUserList {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    productList {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    chats {
      nextToken
      __typename
    }
    stripeAccountId
    followers
    followersList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyMutationVariables,
  APITypes.UpdateCompanyMutation
>;
export const createCompanyPrivateInfo = /* GraphQL */ `mutation CreateCompanyPrivateInfo(
  $input: CreateCompanyPrivateInfoInput!
  $condition: ModelCompanyPrivateInfoConditionInput
) {
  createCompanyPrivateInfo(input: $input, condition: $condition) {
    id
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    companyDocImage
    contactName
    contactEmail
    contactNo
    conatactDocImage {
      front
      back
      __typename
    }
    bankName
    bankCode
    beneficiaryName
    bankAccNo
    bankDocumnet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyPrivateInfoMutationVariables,
  APITypes.CreateCompanyPrivateInfoMutation
>;
export const updateCompanyPrivateInfo = /* GraphQL */ `mutation UpdateCompanyPrivateInfo(
  $input: UpdateCompanyPrivateInfoInput!
  $condition: ModelCompanyPrivateInfoConditionInput
) {
  updateCompanyPrivateInfo(input: $input, condition: $condition) {
    id
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    companyDocImage
    contactName
    contactEmail
    contactNo
    conatactDocImage {
      front
      back
      __typename
    }
    bankName
    bankCode
    beneficiaryName
    bankAccNo
    bankDocumnet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyPrivateInfoMutationVariables,
  APITypes.UpdateCompanyPrivateInfoMutation
>;
export const createCompanyUser = /* GraphQL */ `mutation CreateCompanyUser(
  $input: CreateCompanyUserInput!
  $condition: ModelCompanyUserConditionInput
) {
  createCompanyUser(input: $input, condition: $condition) {
    id
    role
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    modules {
      moduleName
      add
      edit
      delete
      read
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyUserMutationVariables,
  APITypes.CreateCompanyUserMutation
>;
export const updateCompanyUser = /* GraphQL */ `mutation UpdateCompanyUser(
  $input: UpdateCompanyUserInput!
  $condition: ModelCompanyUserConditionInput
) {
  updateCompanyUser(input: $input, condition: $condition) {
    id
    role
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    modules {
      moduleName
      add
      edit
      delete
      read
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyUserMutationVariables,
  APITypes.UpdateCompanyUserMutation
>;
export const deleteCompanyUser = /* GraphQL */ `mutation DeleteCompanyUser(
  $input: DeleteCompanyUserInput!
  $condition: ModelCompanyUserConditionInput
) {
  deleteCompanyUser(input: $input, condition: $condition) {
    id
    role
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    modules {
      moduleName
      add
      edit
      delete
      read
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyUserMutationVariables,
  APITypes.DeleteCompanyUserMutation
>;
export const createCompanyReview = /* GraphQL */ `mutation CreateCompanyReview(
  $input: CreateCompanyReviewInput!
  $condition: ModelCompanyReviewConditionInput
) {
  createCompanyReview(input: $input, condition: $condition) {
    id
    stars
    comment
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyReviewMutationVariables,
  APITypes.CreateCompanyReviewMutation
>;
export const updateCompanyReview = /* GraphQL */ `mutation UpdateCompanyReview(
  $input: UpdateCompanyReviewInput!
  $condition: ModelCompanyReviewConditionInput
) {
  updateCompanyReview(input: $input, condition: $condition) {
    id
    stars
    comment
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyReviewMutationVariables,
  APITypes.UpdateCompanyReviewMutation
>;
export const deleteCompanyReview = /* GraphQL */ `mutation DeleteCompanyReview(
  $input: DeleteCompanyReviewInput!
  $condition: ModelCompanyReviewConditionInput
) {
  deleteCompanyReview(input: $input, condition: $condition) {
    id
    stars
    comment
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyReviewMutationVariables,
  APITypes.DeleteCompanyReviewMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    name
    type
    description
    picture
    parent_categoryId
    subCategories {
      nextToken
      __typename
    }
    productCategories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    name
    type
    description
    picture
    parent_categoryId
    subCategories {
      nextToken
      __typename
    }
    productCategories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const createProduct = /* GraphQL */ `mutation CreateProduct(
  $input: CreateProductInput!
  $condition: ModelProductConditionInput
) {
  createProduct(input: $input, condition: $condition) {
    id
    title
    description
    type
    filterType
    attributes {
      name
      options
      __typename
    }
    allowToComment
    feature
    brand
    total_sold
    rating {
      one
      two
      three
      four
      five
      __typename
    }
    likeCount
    viewCount
    commentCount
    shareCount
    bookmarkCount
    totalReviewCount
    orderCount
    productVariants {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    categories {
      nextToken
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    metaKeywords
    sku
    likes {
      nextToken
      __typename
    }
    bookmarks {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductMutationVariables,
  APITypes.CreateProductMutation
>;
export const updateProduct = /* GraphQL */ `mutation UpdateProduct(
  $input: UpdateProductInput!
  $condition: ModelProductConditionInput
) {
  updateProduct(input: $input, condition: $condition) {
    id
    title
    description
    type
    filterType
    attributes {
      name
      options
      __typename
    }
    allowToComment
    feature
    brand
    total_sold
    rating {
      one
      two
      three
      four
      five
      __typename
    }
    likeCount
    viewCount
    commentCount
    shareCount
    bookmarkCount
    totalReviewCount
    orderCount
    productVariants {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    categories {
      nextToken
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    metaKeywords
    sku
    likes {
      nextToken
      __typename
    }
    bookmarks {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductMutationVariables,
  APITypes.UpdateProductMutation
>;
export const deleteProduct = /* GraphQL */ `mutation DeleteProduct(
  $input: DeleteProductInput!
  $condition: ModelProductConditionInput
) {
  deleteProduct(input: $input, condition: $condition) {
    id
    title
    description
    type
    filterType
    attributes {
      name
      options
      __typename
    }
    allowToComment
    feature
    brand
    total_sold
    rating {
      one
      two
      three
      four
      five
      __typename
    }
    likeCount
    viewCount
    commentCount
    shareCount
    bookmarkCount
    totalReviewCount
    orderCount
    productVariants {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    categories {
      nextToken
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    metaKeywords
    sku
    likes {
      nextToken
      __typename
    }
    bookmarks {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductMutationVariables,
  APITypes.DeleteProductMutation
>;
export const createFeed = /* GraphQL */ `mutation CreateFeed(
  $input: CreateFeedInput!
  $condition: ModelFeedConditionInput
) {
  createFeed(input: $input, condition: $condition) {
    id
    title
    description
    type
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    tags {
      nextToken
      __typename
    }
    collaborations {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFeedMutationVariables,
  APITypes.CreateFeedMutation
>;
export const updateFeed = /* GraphQL */ `mutation UpdateFeed(
  $input: UpdateFeedInput!
  $condition: ModelFeedConditionInput
) {
  updateFeed(input: $input, condition: $condition) {
    id
    title
    description
    type
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    tags {
      nextToken
      __typename
    }
    collaborations {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFeedMutationVariables,
  APITypes.UpdateFeedMutation
>;
export const deleteFeed = /* GraphQL */ `mutation DeleteFeed(
  $input: DeleteFeedInput!
  $condition: ModelFeedConditionInput
) {
  deleteFeed(input: $input, condition: $condition) {
    id
    title
    description
    type
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    tags {
      nextToken
      __typename
    }
    collaborations {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFeedMutationVariables,
  APITypes.DeleteFeedMutation
>;
export const createFeedCollaboration = /* GraphQL */ `mutation CreateFeedCollaboration(
  $input: CreateFeedCollaborationInput!
  $condition: ModelFeedCollaborationConditionInput
) {
  createFeedCollaboration(input: $input, condition: $condition) {
    id
    type
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    merchantId
    merchant {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFeedCollaborationMutationVariables,
  APITypes.CreateFeedCollaborationMutation
>;
export const updateFeedCollaboration = /* GraphQL */ `mutation UpdateFeedCollaboration(
  $input: UpdateFeedCollaborationInput!
  $condition: ModelFeedCollaborationConditionInput
) {
  updateFeedCollaboration(input: $input, condition: $condition) {
    id
    type
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    merchantId
    merchant {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFeedCollaborationMutationVariables,
  APITypes.UpdateFeedCollaborationMutation
>;
export const deleteFeedCollaboration = /* GraphQL */ `mutation DeleteFeedCollaboration(
  $input: DeleteFeedCollaborationInput!
  $condition: ModelFeedCollaborationConditionInput
) {
  deleteFeedCollaboration(input: $input, condition: $condition) {
    id
    type
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    merchantId
    merchant {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFeedCollaborationMutationVariables,
  APITypes.DeleteFeedCollaborationMutation
>;
export const createFeedTags = /* GraphQL */ `mutation CreateFeedTags(
  $input: CreateFeedTagsInput!
  $condition: ModelFeedTagsConditionInput
) {
  createFeedTags(input: $input, condition: $condition) {
    id
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    companyId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFeedTagsMutationVariables,
  APITypes.CreateFeedTagsMutation
>;
export const updateFeedTags = /* GraphQL */ `mutation UpdateFeedTags(
  $input: UpdateFeedTagsInput!
  $condition: ModelFeedTagsConditionInput
) {
  updateFeedTags(input: $input, condition: $condition) {
    id
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    companyId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFeedTagsMutationVariables,
  APITypes.UpdateFeedTagsMutation
>;
export const deleteFeedTags = /* GraphQL */ `mutation DeleteFeedTags(
  $input: DeleteFeedTagsInput!
  $condition: ModelFeedTagsConditionInput
) {
  deleteFeedTags(input: $input, condition: $condition) {
    id
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    companyId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFeedTagsMutationVariables,
  APITypes.DeleteFeedTagsMutation
>;
export const createFeedMedia = /* GraphQL */ `mutation CreateFeedMedia(
  $input: CreateFeedMediaInput!
  $condition: ModelFeedMediaConditionInput
) {
  createFeedMedia(input: $input, condition: $condition) {
    id
    file_type
    key
    feedId
    Feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFeedMediaMutationVariables,
  APITypes.CreateFeedMediaMutation
>;
export const updateFeedMedia = /* GraphQL */ `mutation UpdateFeedMedia(
  $input: UpdateFeedMediaInput!
  $condition: ModelFeedMediaConditionInput
) {
  updateFeedMedia(input: $input, condition: $condition) {
    id
    file_type
    key
    feedId
    Feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFeedMediaMutationVariables,
  APITypes.UpdateFeedMediaMutation
>;
export const deleteFeedMedia = /* GraphQL */ `mutation DeleteFeedMedia(
  $input: DeleteFeedMediaInput!
  $condition: ModelFeedMediaConditionInput
) {
  deleteFeedMedia(input: $input, condition: $condition) {
    id
    file_type
    key
    feedId
    Feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFeedMediaMutationVariables,
  APITypes.DeleteFeedMediaMutation
>;
export const createLike = /* GraphQL */ `mutation CreateLike(
  $input: CreateLikeInput!
  $condition: ModelLikeConditionInput
) {
  createLike(input: $input, condition: $condition) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    likeState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLikeMutationVariables,
  APITypes.CreateLikeMutation
>;
export const updateLike = /* GraphQL */ `mutation UpdateLike(
  $input: UpdateLikeInput!
  $condition: ModelLikeConditionInput
) {
  updateLike(input: $input, condition: $condition) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    likeState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLikeMutationVariables,
  APITypes.UpdateLikeMutation
>;
export const deleteLike = /* GraphQL */ `mutation DeleteLike(
  $input: DeleteLikeInput!
  $condition: ModelLikeConditionInput
) {
  deleteLike(input: $input, condition: $condition) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    likeState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLikeMutationVariables,
  APITypes.DeleteLikeMutation
>;
export const createViews = /* GraphQL */ `mutation CreateViews(
  $input: CreateViewsInput!
  $condition: ModelViewsConditionInput
) {
  createViews(input: $input, condition: $condition) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    viewCount
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateViewsMutationVariables,
  APITypes.CreateViewsMutation
>;
export const updateViews = /* GraphQL */ `mutation UpdateViews(
  $input: UpdateViewsInput!
  $condition: ModelViewsConditionInput
) {
  updateViews(input: $input, condition: $condition) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    viewCount
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateViewsMutationVariables,
  APITypes.UpdateViewsMutation
>;
export const deleteViews = /* GraphQL */ `mutation DeleteViews(
  $input: DeleteViewsInput!
  $condition: ModelViewsConditionInput
) {
  deleteViews(input: $input, condition: $condition) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    viewCount
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteViewsMutationVariables,
  APITypes.DeleteViewsMutation
>;
export const createProductBookmarks = /* GraphQL */ `mutation CreateProductBookmarks(
  $input: CreateProductBookmarksInput!
  $condition: ModelProductBookmarksConditionInput
) {
  createProductBookmarks(input: $input, condition: $condition) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    bookmarkState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductBookmarksMutationVariables,
  APITypes.CreateProductBookmarksMutation
>;
export const updateProductBookmarks = /* GraphQL */ `mutation UpdateProductBookmarks(
  $input: UpdateProductBookmarksInput!
  $condition: ModelProductBookmarksConditionInput
) {
  updateProductBookmarks(input: $input, condition: $condition) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    bookmarkState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductBookmarksMutationVariables,
  APITypes.UpdateProductBookmarksMutation
>;
export const deleteProductBookmarks = /* GraphQL */ `mutation DeleteProductBookmarks(
  $input: DeleteProductBookmarksInput!
  $condition: ModelProductBookmarksConditionInput
) {
  deleteProductBookmarks(input: $input, condition: $condition) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    bookmarkState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductBookmarksMutationVariables,
  APITypes.DeleteProductBookmarksMutation
>;
export const createComments = /* GraphQL */ `mutation CreateComments(
  $input: CreateCommentsInput!
  $condition: ModelCommentsConditionInput
) {
  createComments(input: $input, condition: $condition) {
    id
    comment
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    parentCommentId
    commentReplyList {
      nextToken
      __typename
    }
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentsMutationVariables,
  APITypes.CreateCommentsMutation
>;
export const updateComments = /* GraphQL */ `mutation UpdateComments(
  $input: UpdateCommentsInput!
  $condition: ModelCommentsConditionInput
) {
  updateComments(input: $input, condition: $condition) {
    id
    comment
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    parentCommentId
    commentReplyList {
      nextToken
      __typename
    }
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentsMutationVariables,
  APITypes.UpdateCommentsMutation
>;
export const createProductVariant = /* GraphQL */ `mutation CreateProductVariant(
  $input: CreateProductVariantInput!
  $condition: ModelProductVariantConditionInput
) {
  createProductVariant(input: $input, condition: $condition) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    attribute
    price
    list_price
    quantity
    deliveryMethod
    productCondition
    productUsed {
      day
      month
      year
      __typename
    }
    designStyle
    designPropertyType
    orderCount
    videoLink
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    status
    productMediaList {
      nextToken
      __typename
    }
    sku
    defaultPosition
    createdAt
    updatedAt
    productProductVariantsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductVariantMutationVariables,
  APITypes.CreateProductVariantMutation
>;
export const updateProductVariant = /* GraphQL */ `mutation UpdateProductVariant(
  $input: UpdateProductVariantInput!
  $condition: ModelProductVariantConditionInput
) {
  updateProductVariant(input: $input, condition: $condition) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    attribute
    price
    list_price
    quantity
    deliveryMethod
    productCondition
    productUsed {
      day
      month
      year
      __typename
    }
    designStyle
    designPropertyType
    orderCount
    videoLink
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    status
    productMediaList {
      nextToken
      __typename
    }
    sku
    defaultPosition
    createdAt
    updatedAt
    productProductVariantsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductVariantMutationVariables,
  APITypes.UpdateProductVariantMutation
>;
export const deleteProductVariant = /* GraphQL */ `mutation DeleteProductVariant(
  $input: DeleteProductVariantInput!
  $condition: ModelProductVariantConditionInput
) {
  deleteProductVariant(input: $input, condition: $condition) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    attribute
    price
    list_price
    quantity
    deliveryMethod
    productCondition
    productUsed {
      day
      month
      year
      __typename
    }
    designStyle
    designPropertyType
    orderCount
    videoLink
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    status
    productMediaList {
      nextToken
      __typename
    }
    sku
    defaultPosition
    createdAt
    updatedAt
    productProductVariantsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductVariantMutationVariables,
  APITypes.DeleteProductVariantMutation
>;
export const createProductMedia = /* GraphQL */ `mutation CreateProductMedia(
  $input: CreateProductMediaInput!
  $condition: ModelProductMediaConditionInput
) {
  createProductMedia(input: $input, condition: $condition) {
    id
    file_type
    key
    product_variant_id
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductMediaMutationVariables,
  APITypes.CreateProductMediaMutation
>;
export const updateProductMedia = /* GraphQL */ `mutation UpdateProductMedia(
  $input: UpdateProductMediaInput!
  $condition: ModelProductMediaConditionInput
) {
  updateProductMedia(input: $input, condition: $condition) {
    id
    file_type
    key
    product_variant_id
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductMediaMutationVariables,
  APITypes.UpdateProductMediaMutation
>;
export const deleteProductMedia = /* GraphQL */ `mutation DeleteProductMedia(
  $input: DeleteProductMediaInput!
  $condition: ModelProductMediaConditionInput
) {
  deleteProductMedia(input: $input, condition: $condition) {
    id
    file_type
    key
    product_variant_id
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductMediaMutationVariables,
  APITypes.DeleteProductMediaMutation
>;
export const createProductCategories = /* GraphQL */ `mutation CreateProductCategories(
  $input: CreateProductCategoriesInput!
  $condition: ModelProductCategoriesConditionInput
) {
  createProductCategories(input: $input, condition: $condition) {
    id
    categoryId
    category {
      id
      name
      type
      description
      picture
      parent_categoryId
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductCategoriesMutationVariables,
  APITypes.CreateProductCategoriesMutation
>;
export const updateProductCategories = /* GraphQL */ `mutation UpdateProductCategories(
  $input: UpdateProductCategoriesInput!
  $condition: ModelProductCategoriesConditionInput
) {
  updateProductCategories(input: $input, condition: $condition) {
    id
    categoryId
    category {
      id
      name
      type
      description
      picture
      parent_categoryId
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductCategoriesMutationVariables,
  APITypes.UpdateProductCategoriesMutation
>;
export const deleteProductCategories = /* GraphQL */ `mutation DeleteProductCategories(
  $input: DeleteProductCategoriesInput!
  $condition: ModelProductCategoriesConditionInput
) {
  deleteProductCategories(input: $input, condition: $condition) {
    id
    categoryId
    category {
      id
      name
      type
      description
      picture
      parent_categoryId
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductCategoriesMutationVariables,
  APITypes.DeleteProductCategoriesMutation
>;
export const createProductReview = /* GraphQL */ `mutation CreateProductReview(
  $input: CreateProductReviewInput!
  $condition: ModelProductReviewConditionInput
) {
  createProductReview(input: $input, condition: $condition) {
    id
    stars
    comment
    attribute
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    totalImagesCount
    estoreOrderProductId
    estoreOrderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    bookingId
    booking {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    reviewMediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductReviewMutationVariables,
  APITypes.CreateProductReviewMutation
>;
export const updateProductReview = /* GraphQL */ `mutation UpdateProductReview(
  $input: UpdateProductReviewInput!
  $condition: ModelProductReviewConditionInput
) {
  updateProductReview(input: $input, condition: $condition) {
    id
    stars
    comment
    attribute
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    totalImagesCount
    estoreOrderProductId
    estoreOrderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    bookingId
    booking {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    reviewMediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductReviewMutationVariables,
  APITypes.UpdateProductReviewMutation
>;
export const deleteProductReview = /* GraphQL */ `mutation DeleteProductReview(
  $input: DeleteProductReviewInput!
  $condition: ModelProductReviewConditionInput
) {
  deleteProductReview(input: $input, condition: $condition) {
    id
    stars
    comment
    attribute
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    totalImagesCount
    estoreOrderProductId
    estoreOrderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    bookingId
    booking {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    reviewMediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductReviewMutationVariables,
  APITypes.DeleteProductReviewMutation
>;
export const createReviewMedia = /* GraphQL */ `mutation CreateReviewMedia(
  $input: CreateReviewMediaInput!
  $condition: ModelReviewMediaConditionInput
) {
  createReviewMedia(input: $input, condition: $condition) {
    id
    file_type
    key
    reviewId
    productReview {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMediaMutationVariables,
  APITypes.CreateReviewMediaMutation
>;
export const updateReviewMedia = /* GraphQL */ `mutation UpdateReviewMedia(
  $input: UpdateReviewMediaInput!
  $condition: ModelReviewMediaConditionInput
) {
  updateReviewMedia(input: $input, condition: $condition) {
    id
    file_type
    key
    reviewId
    productReview {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMediaMutationVariables,
  APITypes.UpdateReviewMediaMutation
>;
export const deleteReviewMedia = /* GraphQL */ `mutation DeleteReviewMedia(
  $input: DeleteReviewMediaInput!
  $condition: ModelReviewMediaConditionInput
) {
  deleteReviewMedia(input: $input, condition: $condition) {
    id
    file_type
    key
    reviewId
    productReview {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMediaMutationVariables,
  APITypes.DeleteReviewMediaMutation
>;
export const createEstoreOrders = /* GraphQL */ `mutation CreateEstoreOrders(
  $input: CreateEstoreOrdersInput!
  $condition: ModelEstoreOrdersConditionInput
) {
  createEstoreOrders(input: $input, condition: $condition) {
    id
    orderId
    transaction_id
    order_price
    discount
    sell_price
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    offerCodeId
    offerCode {
      id
      code
      description
      discountType
      discountValue
      maxUsage
      usageCount
      validFrom
      validTo
      minOrderValue
      maxDiscountAmount
      customerRestrictions
      productRestrictions
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    delivery_fee
    status
    paymentStatus
    statusDescription
    estoreOrderProducts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEstoreOrdersMutationVariables,
  APITypes.CreateEstoreOrdersMutation
>;
export const updateEstoreOrders = /* GraphQL */ `mutation UpdateEstoreOrders(
  $input: UpdateEstoreOrdersInput!
  $condition: ModelEstoreOrdersConditionInput
) {
  updateEstoreOrders(input: $input, condition: $condition) {
    id
    orderId
    transaction_id
    order_price
    discount
    sell_price
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    offerCodeId
    offerCode {
      id
      code
      description
      discountType
      discountValue
      maxUsage
      usageCount
      validFrom
      validTo
      minOrderValue
      maxDiscountAmount
      customerRestrictions
      productRestrictions
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    delivery_fee
    status
    paymentStatus
    statusDescription
    estoreOrderProducts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEstoreOrdersMutationVariables,
  APITypes.UpdateEstoreOrdersMutation
>;
export const deleteEstoreOrders = /* GraphQL */ `mutation DeleteEstoreOrders(
  $input: DeleteEstoreOrdersInput!
  $condition: ModelEstoreOrdersConditionInput
) {
  deleteEstoreOrders(input: $input, condition: $condition) {
    id
    orderId
    transaction_id
    order_price
    discount
    sell_price
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    offerCodeId
    offerCode {
      id
      code
      description
      discountType
      discountValue
      maxUsage
      usageCount
      validFrom
      validTo
      minOrderValue
      maxDiscountAmount
      customerRestrictions
      productRestrictions
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    delivery_fee
    status
    paymentStatus
    statusDescription
    estoreOrderProducts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEstoreOrdersMutationVariables,
  APITypes.DeleteEstoreOrdersMutation
>;
export const createEstoreOrderProduct = /* GraphQL */ `mutation CreateEstoreOrderProduct(
  $input: CreateEstoreOrderProductInput!
  $condition: ModelEstoreOrderProductConditionInput
) {
  createEstoreOrderProduct(input: $input, condition: $condition) {
    id
    title
    description
    price
    list_price
    quantity
    invoice
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    productMediaList {
      nextToken
      __typename
    }
    attribute
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    status
    paymentStatus
    deliveryStatus
    statusDescription
    statusHistory
    cancellationReason
    estore_order_id
    estoreOrder {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEstoreOrderProductMutationVariables,
  APITypes.CreateEstoreOrderProductMutation
>;
export const updateEstoreOrderProduct = /* GraphQL */ `mutation UpdateEstoreOrderProduct(
  $input: UpdateEstoreOrderProductInput!
  $condition: ModelEstoreOrderProductConditionInput
) {
  updateEstoreOrderProduct(input: $input, condition: $condition) {
    id
    title
    description
    price
    list_price
    quantity
    invoice
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    productMediaList {
      nextToken
      __typename
    }
    attribute
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    status
    paymentStatus
    deliveryStatus
    statusDescription
    statusHistory
    cancellationReason
    estore_order_id
    estoreOrder {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEstoreOrderProductMutationVariables,
  APITypes.UpdateEstoreOrderProductMutation
>;
export const deleteEstoreOrderProduct = /* GraphQL */ `mutation DeleteEstoreOrderProduct(
  $input: DeleteEstoreOrderProductInput!
  $condition: ModelEstoreOrderProductConditionInput
) {
  deleteEstoreOrderProduct(input: $input, condition: $condition) {
    id
    title
    description
    price
    list_price
    quantity
    invoice
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    productMediaList {
      nextToken
      __typename
    }
    attribute
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    status
    paymentStatus
    deliveryStatus
    statusDescription
    statusHistory
    cancellationReason
    estore_order_id
    estoreOrder {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEstoreOrderProductMutationVariables,
  APITypes.DeleteEstoreOrderProductMutation
>;
export const createEstoreOrdersRefund = /* GraphQL */ `mutation CreateEstoreOrdersRefund(
  $input: CreateEstoreOrdersRefundInput!
  $condition: ModelEstoreOrdersRefundConditionInput
) {
  createEstoreOrdersRefund(input: $input, condition: $condition) {
    id
    orderProductId
    orderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    refund_price
    reason
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEstoreOrdersRefundMutationVariables,
  APITypes.CreateEstoreOrdersRefundMutation
>;
export const updateEstoreOrdersRefund = /* GraphQL */ `mutation UpdateEstoreOrdersRefund(
  $input: UpdateEstoreOrdersRefundInput!
  $condition: ModelEstoreOrdersRefundConditionInput
) {
  updateEstoreOrdersRefund(input: $input, condition: $condition) {
    id
    orderProductId
    orderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    refund_price
    reason
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEstoreOrdersRefundMutationVariables,
  APITypes.UpdateEstoreOrdersRefundMutation
>;
export const deleteEstoreOrdersRefund = /* GraphQL */ `mutation DeleteEstoreOrdersRefund(
  $input: DeleteEstoreOrdersRefundInput!
  $condition: ModelEstoreOrdersRefundConditionInput
) {
  deleteEstoreOrdersRefund(input: $input, condition: $condition) {
    id
    orderProductId
    orderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    refund_price
    reason
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEstoreOrdersRefundMutationVariables,
  APITypes.DeleteEstoreOrdersRefundMutation
>;
export const createTransactionLogs = /* GraphQL */ `mutation CreateTransactionLogs(
  $input: CreateTransactionLogsInput!
  $condition: ModelTransactionLogsConditionInput
) {
  createTransactionLogs(input: $input, condition: $condition) {
    id
    orderId
    type
    response
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionLogsMutationVariables,
  APITypes.CreateTransactionLogsMutation
>;
export const updateTransactionLogs = /* GraphQL */ `mutation UpdateTransactionLogs(
  $input: UpdateTransactionLogsInput!
  $condition: ModelTransactionLogsConditionInput
) {
  updateTransactionLogs(input: $input, condition: $condition) {
    id
    orderId
    type
    response
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTransactionLogsMutationVariables,
  APITypes.UpdateTransactionLogsMutation
>;
export const deleteTransactionLogs = /* GraphQL */ `mutation DeleteTransactionLogs(
  $input: DeleteTransactionLogsInput!
  $condition: ModelTransactionLogsConditionInput
) {
  deleteTransactionLogs(input: $input, condition: $condition) {
    id
    orderId
    type
    response
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTransactionLogsMutationVariables,
  APITypes.DeleteTransactionLogsMutation
>;
export const createEstoreCart = /* GraphQL */ `mutation CreateEstoreCart(
  $input: CreateEstoreCartInput!
  $condition: ModelEstoreCartConditionInput
) {
  createEstoreCart(input: $input, condition: $condition) {
    id
    quantity
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEstoreCartMutationVariables,
  APITypes.CreateEstoreCartMutation
>;
export const updateEstoreCart = /* GraphQL */ `mutation UpdateEstoreCart(
  $input: UpdateEstoreCartInput!
  $condition: ModelEstoreCartConditionInput
) {
  updateEstoreCart(input: $input, condition: $condition) {
    id
    quantity
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEstoreCartMutationVariables,
  APITypes.UpdateEstoreCartMutation
>;
export const deleteEstoreCart = /* GraphQL */ `mutation DeleteEstoreCart(
  $input: DeleteEstoreCartInput!
  $condition: ModelEstoreCartConditionInput
) {
  deleteEstoreCart(input: $input, condition: $condition) {
    id
    quantity
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEstoreCartMutationVariables,
  APITypes.DeleteEstoreCartMutation
>;
export const createBooking = /* GraphQL */ `mutation CreateBooking(
  $input: CreateBookingInput!
  $condition: ModelBookingConditionInput
) {
  createBooking(input: $input, condition: $condition) {
    id
    type
    quantity
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    fulfillerId
    fulfiller {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookingMutationVariables,
  APITypes.CreateBookingMutation
>;
export const updateBooking = /* GraphQL */ `mutation UpdateBooking(
  $input: UpdateBookingInput!
  $condition: ModelBookingConditionInput
) {
  updateBooking(input: $input, condition: $condition) {
    id
    type
    quantity
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    fulfillerId
    fulfiller {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookingMutationVariables,
  APITypes.UpdateBookingMutation
>;
export const deleteBooking = /* GraphQL */ `mutation DeleteBooking(
  $input: DeleteBookingInput!
  $condition: ModelBookingConditionInput
) {
  deleteBooking(input: $input, condition: $condition) {
    id
    type
    quantity
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    fulfillerId
    fulfiller {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBookingMutationVariables,
  APITypes.DeleteBookingMutation
>;
export const createChat = /* GraphQL */ `mutation CreateChat(
  $input: CreateChatInput!
  $condition: ModelChatConditionInput
) {
  createChat(input: $input, condition: $condition) {
    id
    name
    group
    image
    participants {
      nextToken
      __typename
    }
    chatMessages {
      nextToken
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    chatMessageUnreadCount {
      nextToken
      __typename
    }
    deletedBy
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChatMutationVariables,
  APITypes.CreateChatMutation
>;
export const updateChat = /* GraphQL */ `mutation UpdateChat(
  $input: UpdateChatInput!
  $condition: ModelChatConditionInput
) {
  updateChat(input: $input, condition: $condition) {
    id
    name
    group
    image
    participants {
      nextToken
      __typename
    }
    chatMessages {
      nextToken
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    chatMessageUnreadCount {
      nextToken
      __typename
    }
    deletedBy
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChatMutationVariables,
  APITypes.UpdateChatMutation
>;
export const deleteChat = /* GraphQL */ `mutation DeleteChat(
  $input: DeleteChatInput!
  $condition: ModelChatConditionInput
) {
  deleteChat(input: $input, condition: $condition) {
    id
    name
    group
    image
    participants {
      nextToken
      __typename
    }
    chatMessages {
      nextToken
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    chatMessageUnreadCount {
      nextToken
      __typename
    }
    deletedBy
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChatMutationVariables,
  APITypes.DeleteChatMutation
>;
export const createChatParticipants = /* GraphQL */ `mutation CreateChatParticipants(
  $input: CreateChatParticipantsInput!
  $condition: ModelChatParticipantsConditionInput
) {
  createChatParticipants(input: $input, condition: $condition) {
    id
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChatParticipantsMutationVariables,
  APITypes.CreateChatParticipantsMutation
>;
export const updateChatParticipants = /* GraphQL */ `mutation UpdateChatParticipants(
  $input: UpdateChatParticipantsInput!
  $condition: ModelChatParticipantsConditionInput
) {
  updateChatParticipants(input: $input, condition: $condition) {
    id
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChatParticipantsMutationVariables,
  APITypes.UpdateChatParticipantsMutation
>;
export const deleteChatParticipants = /* GraphQL */ `mutation DeleteChatParticipants(
  $input: DeleteChatParticipantsInput!
  $condition: ModelChatParticipantsConditionInput
) {
  deleteChatParticipants(input: $input, condition: $condition) {
    id
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChatParticipantsMutationVariables,
  APITypes.DeleteChatParticipantsMutation
>;
export const createChatMessages = /* GraphQL */ `mutation CreateChatMessages(
  $input: CreateChatMessagesInput!
  $condition: ModelChatMessagesConditionInput
) {
  createChatMessages(input: $input, condition: $condition) {
    id
    message
    attachment
    attachment_type
    bookingId
    propertyId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    User {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChatMessagesMutationVariables,
  APITypes.CreateChatMessagesMutation
>;
export const updateChatMessages = /* GraphQL */ `mutation UpdateChatMessages(
  $input: UpdateChatMessagesInput!
  $condition: ModelChatMessagesConditionInput
) {
  updateChatMessages(input: $input, condition: $condition) {
    id
    message
    attachment
    attachment_type
    bookingId
    propertyId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    User {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChatMessagesMutationVariables,
  APITypes.UpdateChatMessagesMutation
>;
export const deleteChatMessages = /* GraphQL */ `mutation DeleteChatMessages(
  $input: DeleteChatMessagesInput!
  $condition: ModelChatMessagesConditionInput
) {
  deleteChatMessages(input: $input, condition: $condition) {
    id
    message
    attachment
    attachment_type
    bookingId
    propertyId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    User {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChatMessagesMutationVariables,
  APITypes.DeleteChatMessagesMutation
>;
export const createChatMessageUnreadCount = /* GraphQL */ `mutation CreateChatMessageUnreadCount(
  $input: CreateChatMessageUnreadCountInput!
  $condition: ModelChatMessageUnreadCountConditionInput
) {
  createChatMessageUnreadCount(input: $input, condition: $condition) {
    id
    userId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    unreadCount
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChatMessageUnreadCountMutationVariables,
  APITypes.CreateChatMessageUnreadCountMutation
>;
export const updateChatMessageUnreadCount = /* GraphQL */ `mutation UpdateChatMessageUnreadCount(
  $input: UpdateChatMessageUnreadCountInput!
  $condition: ModelChatMessageUnreadCountConditionInput
) {
  updateChatMessageUnreadCount(input: $input, condition: $condition) {
    id
    userId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    unreadCount
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChatMessageUnreadCountMutationVariables,
  APITypes.UpdateChatMessageUnreadCountMutation
>;
export const deleteChatMessageUnreadCount = /* GraphQL */ `mutation DeleteChatMessageUnreadCount(
  $input: DeleteChatMessageUnreadCountInput!
  $condition: ModelChatMessageUnreadCountConditionInput
) {
  deleteChatMessageUnreadCount(input: $input, condition: $condition) {
    id
    userId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    unreadCount
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChatMessageUnreadCountMutationVariables,
  APITypes.DeleteChatMessageUnreadCountMutation
>;
export const createNotifications = /* GraphQL */ `mutation CreateNotifications(
  $input: CreateNotificationsInput!
  $condition: ModelNotificationsConditionInput
) {
  createNotifications(input: $input, condition: $condition) {
    id
    userId
    senderId
    sender {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    read
    title
    description
    imageUrl
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationsMutationVariables,
  APITypes.CreateNotificationsMutation
>;
export const updateNotifications = /* GraphQL */ `mutation UpdateNotifications(
  $input: UpdateNotificationsInput!
  $condition: ModelNotificationsConditionInput
) {
  updateNotifications(input: $input, condition: $condition) {
    id
    userId
    senderId
    sender {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    read
    title
    description
    imageUrl
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationsMutationVariables,
  APITypes.UpdateNotificationsMutation
>;
export const deleteNotifications = /* GraphQL */ `mutation DeleteNotifications(
  $input: DeleteNotificationsInput!
  $condition: ModelNotificationsConditionInput
) {
  deleteNotifications(input: $input, condition: $condition) {
    id
    userId
    senderId
    sender {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    read
    title
    description
    imageUrl
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationsMutationVariables,
  APITypes.DeleteNotificationsMutation
>;
export const createPost = /* GraphQL */ `mutation CreatePost(
  $input: CreatePostInput!
  $condition: ModelPostConditionInput
) {
  createPost(input: $input, condition: $condition) {
    id
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    comments {
      nextToken
      __typename
    }
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePostMutationVariables,
  APITypes.CreatePostMutation
>;
export const updatePost = /* GraphQL */ `mutation UpdatePost(
  $input: UpdatePostInput!
  $condition: ModelPostConditionInput
) {
  updatePost(input: $input, condition: $condition) {
    id
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    comments {
      nextToken
      __typename
    }
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePostMutationVariables,
  APITypes.UpdatePostMutation
>;
export const deletePost = /* GraphQL */ `mutation DeletePost(
  $input: DeletePostInput!
  $condition: ModelPostConditionInput
) {
  deletePost(input: $input, condition: $condition) {
    id
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    comments {
      nextToken
      __typename
    }
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePostMutationVariables,
  APITypes.DeletePostMutation
>;
export const createRecommendation = /* GraphQL */ `mutation CreateRecommendation(
  $input: CreateRecommendationInput!
  $condition: ModelRecommendationConditionInput
) {
  createRecommendation(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    feedId
    createdAt
    ttl
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRecommendationMutationVariables,
  APITypes.CreateRecommendationMutation
>;
export const updateRecommendation = /* GraphQL */ `mutation UpdateRecommendation(
  $input: UpdateRecommendationInput!
  $condition: ModelRecommendationConditionInput
) {
  updateRecommendation(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    feedId
    createdAt
    ttl
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRecommendationMutationVariables,
  APITypes.UpdateRecommendationMutation
>;
export const deleteRecommendation = /* GraphQL */ `mutation DeleteRecommendation(
  $input: DeleteRecommendationInput!
  $condition: ModelRecommendationConditionInput
) {
  deleteRecommendation(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    feedId
    createdAt
    ttl
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRecommendationMutationVariables,
  APITypes.DeleteRecommendationMutation
>;
export const createRecommendationViews = /* GraphQL */ `mutation CreateRecommendationViews(
  $input: CreateRecommendationViewsInput!
  $condition: ModelRecommendationViewsConditionInput
) {
  createRecommendationViews(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRecommendationViewsMutationVariables,
  APITypes.CreateRecommendationViewsMutation
>;
export const updateRecommendationViews = /* GraphQL */ `mutation UpdateRecommendationViews(
  $input: UpdateRecommendationViewsInput!
  $condition: ModelRecommendationViewsConditionInput
) {
  updateRecommendationViews(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRecommendationViewsMutationVariables,
  APITypes.UpdateRecommendationViewsMutation
>;
export const deleteRecommendationViews = /* GraphQL */ `mutation DeleteRecommendationViews(
  $input: DeleteRecommendationViewsInput!
  $condition: ModelRecommendationViewsConditionInput
) {
  deleteRecommendationViews(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRecommendationViewsMutationVariables,
  APITypes.DeleteRecommendationViewsMutation
>;
export const createFollow = /* GraphQL */ `mutation CreateFollow(
  $input: CreateFollowInput!
  $condition: ModelFollowConditionInput
) {
  createFollow(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    followerId
    follower {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFollowMutationVariables,
  APITypes.CreateFollowMutation
>;
export const updateFollow = /* GraphQL */ `mutation UpdateFollow(
  $input: UpdateFollowInput!
  $condition: ModelFollowConditionInput
) {
  updateFollow(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    followerId
    follower {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFollowMutationVariables,
  APITypes.UpdateFollowMutation
>;
export const deleteFollow = /* GraphQL */ `mutation DeleteFollow(
  $input: DeleteFollowInput!
  $condition: ModelFollowConditionInput
) {
  deleteFollow(input: $input, condition: $condition) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    followerId
    follower {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFollowMutationVariables,
  APITypes.DeleteFollowMutation
>;
export const createProperty = /* GraphQL */ `mutation CreateProperty(
  $input: CreatePropertyInput!
  $condition: ModelPropertyConditionInput
) {
  createProperty(input: $input, condition: $condition) {
    id
    title
    type
    description
    price
    bed
    bath
    sqft
    psf
    listingId
    category
    detailsJson {
      key
      value
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    likeCount
    viewCount
    likes {
      nextToken
      __typename
    }
    unitFeatures
    facilities
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePropertyMutationVariables,
  APITypes.CreatePropertyMutation
>;
export const updateProperty = /* GraphQL */ `mutation UpdateProperty(
  $input: UpdatePropertyInput!
  $condition: ModelPropertyConditionInput
) {
  updateProperty(input: $input, condition: $condition) {
    id
    title
    type
    description
    price
    bed
    bath
    sqft
    psf
    listingId
    category
    detailsJson {
      key
      value
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    likeCount
    viewCount
    likes {
      nextToken
      __typename
    }
    unitFeatures
    facilities
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePropertyMutationVariables,
  APITypes.UpdatePropertyMutation
>;
export const deleteProperty = /* GraphQL */ `mutation DeleteProperty(
  $input: DeletePropertyInput!
  $condition: ModelPropertyConditionInput
) {
  deleteProperty(input: $input, condition: $condition) {
    id
    title
    type
    description
    price
    bed
    bath
    sqft
    psf
    listingId
    category
    detailsJson {
      key
      value
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      stripeAccountId
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    likeCount
    viewCount
    likes {
      nextToken
      __typename
    }
    unitFeatures
    facilities
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePropertyMutationVariables,
  APITypes.DeletePropertyMutation
>;
export const createAdminConfig = /* GraphQL */ `mutation CreateAdminConfig(
  $input: CreateAdminConfigInput!
  $condition: ModelAdminConfigConditionInput
) {
  createAdminConfig(input: $input, condition: $condition) {
    id
    type
    value
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminConfigMutationVariables,
  APITypes.CreateAdminConfigMutation
>;
export const updateAdminConfig = /* GraphQL */ `mutation UpdateAdminConfig(
  $input: UpdateAdminConfigInput!
  $condition: ModelAdminConfigConditionInput
) {
  updateAdminConfig(input: $input, condition: $condition) {
    id
    type
    value
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminConfigMutationVariables,
  APITypes.UpdateAdminConfigMutation
>;
export const deleteAdminConfig = /* GraphQL */ `mutation DeleteAdminConfig(
  $input: DeleteAdminConfigInput!
  $condition: ModelAdminConfigConditionInput
) {
  deleteAdminConfig(input: $input, condition: $condition) {
    id
    type
    value
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminConfigMutationVariables,
  APITypes.DeleteAdminConfigMutation
>;
export const createOfferCode = /* GraphQL */ `mutation CreateOfferCode(
  $input: CreateOfferCodeInput!
  $condition: ModelOfferCodeConditionInput
) {
  createOfferCode(input: $input, condition: $condition) {
    id
    code
    description
    discountType
    discountValue
    maxUsage
    usageCount
    validFrom
    validTo
    minOrderValue
    maxDiscountAmount
    customerRestrictions
    productRestrictions
    status
    estoreOrders {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOfferCodeMutationVariables,
  APITypes.CreateOfferCodeMutation
>;
export const updateOfferCode = /* GraphQL */ `mutation UpdateOfferCode(
  $input: UpdateOfferCodeInput!
  $condition: ModelOfferCodeConditionInput
) {
  updateOfferCode(input: $input, condition: $condition) {
    id
    code
    description
    discountType
    discountValue
    maxUsage
    usageCount
    validFrom
    validTo
    minOrderValue
    maxDiscountAmount
    customerRestrictions
    productRestrictions
    status
    estoreOrders {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOfferCodeMutationVariables,
  APITypes.UpdateOfferCodeMutation
>;
export const deleteOfferCode = /* GraphQL */ `mutation DeleteOfferCode(
  $input: DeleteOfferCodeInput!
  $condition: ModelOfferCodeConditionInput
) {
  deleteOfferCode(input: $input, condition: $condition) {
    id
    code
    description
    discountType
    discountValue
    maxUsage
    usageCount
    validFrom
    validTo
    minOrderValue
    maxDiscountAmount
    customerRestrictions
    productRestrictions
    status
    estoreOrders {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOfferCodeMutationVariables,
  APITypes.DeleteOfferCodeMutation
>;
export const createPageHtml = /* GraphQL */ `mutation CreatePageHtml(
  $input: CreatePageHtmlInput!
  $condition: ModelPageHtmlConditionInput
) {
  createPageHtml(input: $input, condition: $condition) {
    id
    site
    page
    html
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePageHtmlMutationVariables,
  APITypes.CreatePageHtmlMutation
>;
export const updatePageHtml = /* GraphQL */ `mutation UpdatePageHtml(
  $input: UpdatePageHtmlInput!
  $condition: ModelPageHtmlConditionInput
) {
  updatePageHtml(input: $input, condition: $condition) {
    id
    site
    page
    html
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePageHtmlMutationVariables,
  APITypes.UpdatePageHtmlMutation
>;
export const deletePageHtml = /* GraphQL */ `mutation DeletePageHtml(
  $input: DeletePageHtmlInput!
  $condition: ModelPageHtmlConditionInput
) {
  deletePageHtml(input: $input, condition: $condition) {
    id
    site
    page
    html
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePageHtmlMutationVariables,
  APITypes.DeletePageHtmlMutation
>;
export const createProductF = /* GraphQL */ `mutation CreateProductF($input: ProductInput) {
  createProductF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductFMutationVariables,
  APITypes.CreateProductFMutation
>;
export const editProductF = /* GraphQL */ `mutation EditProductF($input: editProductInput) {
  editProductF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EditProductFMutationVariables,
  APITypes.EditProductFMutation
>;
export const editProductVariantF = /* GraphQL */ `mutation EditProductVariantF($input: editProductVariantFInput) {
  editProductVariantF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EditProductVariantFMutationVariables,
  APITypes.EditProductVariantFMutation
>;
export const editProductWithAllVariantsF = /* GraphQL */ `mutation EditProductWithAllVariantsF($input: editProductWithAllVariantsInput) {
  editProductWithAllVariantsF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EditProductWithAllVariantsFMutationVariables,
  APITypes.EditProductWithAllVariantsFMutation
>;
export const bulkUploadProductF = /* GraphQL */ `mutation BulkUploadProductF($input: bulkUploadProductInput) {
  bulkUploadProductF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BulkUploadProductFMutationVariables,
  APITypes.BulkUploadProductFMutation
>;
export const estoreOrderProductIssueRefund = /* GraphQL */ `mutation EstoreOrderProductIssueRefund(
  $input: estoreOrderProductIssueRefundInput
) {
  estoreOrderProductIssueRefund(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EstoreOrderProductIssueRefundMutationVariables,
  APITypes.EstoreOrderProductIssueRefundMutation
>;
export const estoreCheckOutF = /* GraphQL */ `mutation EstoreCheckOutF($input: estoreCheckOutInput) {
  estoreCheckOutF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EstoreCheckOutFMutationVariables,
  APITypes.EstoreCheckOutFMutation
>;
export const estoreClearCartF = /* GraphQL */ `mutation EstoreClearCartF($input: estoreClearCartInput) {
  estoreClearCartF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EstoreClearCartFMutationVariables,
  APITypes.EstoreClearCartFMutation
>;
export const estoreUpdateOrderProductStatusF = /* GraphQL */ `mutation EstoreUpdateOrderProductStatusF($input: estoreUpdateOrderStatusInput) {
  estoreUpdateOrderProductStatusF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EstoreUpdateOrderProductStatusFMutationVariables,
  APITypes.EstoreUpdateOrderProductStatusFMutation
>;
export const searchProductByUserF = /* GraphQL */ `mutation SearchProductByUserF($input: searchProductInput) {
  searchProductByUserF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SearchProductByUserFMutationVariables,
  APITypes.SearchProductByUserFMutation
>;
export const updateReadCountF = /* GraphQL */ `mutation UpdateReadCountF($input: updateReadCountFInput) {
  updateReadCountF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReadCountFMutationVariables,
  APITypes.UpdateReadCountFMutation
>;
export const initiateChatF = /* GraphQL */ `mutation InitiateChatF($input: initiateChatInput) {
  initiateChatF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.InitiateChatFMutationVariables,
  APITypes.InitiateChatFMutation
>;
export const likeUnlikeF = /* GraphQL */ `mutation LikeUnlikeF($input: likeUnlikeFInput) {
  likeUnlikeF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.LikeUnlikeFMutationVariables,
  APITypes.LikeUnlikeFMutation
>;
export const viewProductF = /* GraphQL */ `mutation ViewProductF($input: ViewProductFInput) {
  viewProductF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ViewProductFMutationVariables,
  APITypes.ViewProductFMutation
>;
export const commentAddF = /* GraphQL */ `mutation CommentAddF($input: commentAddInput) {
  commentAddF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CommentAddFMutationVariables,
  APITypes.CommentAddFMutation
>;
export const commentDeleteF = /* GraphQL */ `mutation CommentDeleteF($input: commentDeleteInput) {
  commentDeleteF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CommentDeleteFMutationVariables,
  APITypes.CommentDeleteFMutation
>;
export const bookingStatusChangeF = /* GraphQL */ `mutation BookingStatusChangeF($input: bookingStatusChangeInput) {
  bookingStatusChangeF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BookingStatusChangeFMutationVariables,
  APITypes.BookingStatusChangeFMutation
>;
export const bookmarkSaveUnSaveF = /* GraphQL */ `mutation BookmarkSaveUnSaveF($input: bookmarkSaveUnSaveInput) {
  bookmarkSaveUnSaveF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BookmarkSaveUnSaveFMutationVariables,
  APITypes.BookmarkSaveUnSaveFMutation
>;
export const addProductReviewF = /* GraphQL */ `mutation AddProductReviewF($input: addProductReviewInput) {
  addProductReviewF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddProductReviewFMutationVariables,
  APITypes.AddProductReviewFMutation
>;
export const editProductReviewF = /* GraphQL */ `mutation EditProductReviewF($input: editProductReviewInput) {
  editProductReviewF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EditProductReviewFMutationVariables,
  APITypes.EditProductReviewFMutation
>;
export const requestForMerchantF = /* GraphQL */ `mutation RequestForMerchantF($input: requestForMerchantInput) {
  requestForMerchantF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RequestForMerchantFMutationVariables,
  APITypes.RequestForMerchantFMutation
>;
export const updateFcmTokenF = /* GraphQL */ `mutation UpdateFcmTokenF($input: updateFcmTokenInput) {
  updateFcmTokenF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFcmTokenFMutationVariables,
  APITypes.UpdateFcmTokenFMutation
>;
export const clearUserNotificationF = /* GraphQL */ `mutation ClearUserNotificationF($input: clearUserNotificationInput) {
  clearUserNotificationF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ClearUserNotificationFMutationVariables,
  APITypes.ClearUserNotificationFMutation
>;
export const addFeedF = /* GraphQL */ `mutation AddFeedF($input: addFeedInput) {
  addFeedF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddFeedFMutationVariables,
  APITypes.AddFeedFMutation
>;
export const editFeedF = /* GraphQL */ `mutation EditFeedF($input: editFeedInput) {
  editFeedF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EditFeedFMutationVariables,
  APITypes.EditFeedFMutation
>;
export const createPostF = /* GraphQL */ `mutation CreatePostF($input: addPostInput) {
  createPostF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePostFMutationVariables,
  APITypes.CreatePostFMutation
>;
export const editPostF = /* GraphQL */ `mutation EditPostF($input: editPostInput) {
  editPostF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EditPostFMutationVariables,
  APITypes.EditPostFMutation
>;
export const stripePaymentIntentsList = /* GraphQL */ `mutation StripePaymentIntentsList($input: stripePaymentIntentsListInput) {
  stripePaymentIntentsList(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StripePaymentIntentsListMutationVariables,
  APITypes.StripePaymentIntentsListMutation
>;
export const stripeConnect = /* GraphQL */ `mutation StripeConnect($input: stripeConnectInput) {
  stripeConnect(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StripeConnectMutationVariables,
  APITypes.StripeConnectMutation
>;
export const generateTextAi = /* GraphQL */ `mutation GenerateTextAi($input: generateTextAiInput) {
  generateTextAi(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateTextAiMutationVariables,
  APITypes.GenerateTextAiMutation
>;
export const sendNotificationToUserIds = /* GraphQL */ `mutation SendNotificationToUserIds($input: notificationsSendInput) {
  sendNotificationToUserIds(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendNotificationToUserIdsMutationVariables,
  APITypes.SendNotificationToUserIdsMutation
>;
export const sendTopicNotification = /* GraphQL */ `mutation SendTopicNotification($input: notificationsSendInput) {
  sendTopicNotification(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendTopicNotificationMutationVariables,
  APITypes.SendTopicNotificationMutation
>;
export const followAddF = /* GraphQL */ `mutation FollowAddF($input: followAddFInput) {
  followAddF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.FollowAddFMutationVariables,
  APITypes.FollowAddFMutation
>;
export const createPropertyF = /* GraphQL */ `mutation CreatePropertyF($input: createPropertyInput) {
  createPropertyF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePropertyFMutationVariables,
  APITypes.CreatePropertyFMutation
>;
export const editPropertyF = /* GraphQL */ `mutation EditPropertyF($input: editPropertyInput) {
  editPropertyF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EditPropertyFMutationVariables,
  APITypes.EditPropertyFMutation
>;
export const offerAddF = /* GraphQL */ `mutation OfferAddF($input: offerAddInput) {
  offerAddF(input: $input) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.OfferAddFMutationVariables,
  APITypes.OfferAddFMutation
>;

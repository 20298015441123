/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    username
    email
    mobile_no
    name
    gender
    country
    mobile_verified
    bio
    picture
    userAddressList {
      nextToken
      __typename
    }
    companyUser {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    userProductList {
      nextToken
      __typename
    }
    productReview {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    estoreOrderList {
      nextToken
      __typename
    }
    followers
    followings
    followersList {
      nextToken
      __typename
    }
    online
    fcmToken
    merchantFcmToken
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const usersByUsernameAndCreatedAt = /* GraphQL */ `query UsersByUsernameAndCreatedAt(
  $username: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByUsernameAndCreatedAt(
    username: $username
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByUsernameAndCreatedAtQueryVariables,
  APITypes.UsersByUsernameAndCreatedAtQuery
>;
export const usersByEmailAndCreatedAt = /* GraphQL */ `query UsersByEmailAndCreatedAt(
  $email: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByEmailAndCreatedAt(
    email: $email
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByEmailAndCreatedAtQueryVariables,
  APITypes.UsersByEmailAndCreatedAtQuery
>;
export const usersByCountryAndName = /* GraphQL */ `query UsersByCountryAndName(
  $country: String!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByCountryAndName(
    country: $country
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByCountryAndNameQueryVariables,
  APITypes.UsersByCountryAndNameQuery
>;
export const usersByCountryAndEmail = /* GraphQL */ `query UsersByCountryAndEmail(
  $country: String!
  $email: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByCountryAndEmail(
    country: $country
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByCountryAndEmailQueryVariables,
  APITypes.UsersByCountryAndEmailQuery
>;
export const getUserAddress = /* GraphQL */ `query GetUserAddress($id: ID!) {
  getUserAddress(id: $id) {
    id
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    default
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserAddressQueryVariables,
  APITypes.GetUserAddressQuery
>;
export const listUserAddresses = /* GraphQL */ `query ListUserAddresses(
  $filter: ModelUserAddressFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      default
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserAddressesQueryVariables,
  APITypes.ListUserAddressesQuery
>;
export const userAddressesByDefaultAndUserId = /* GraphQL */ `query UserAddressesByDefaultAndUserId(
  $default: Int!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserAddressFilterInput
  $limit: Int
  $nextToken: String
) {
  userAddressesByDefaultAndUserId(
    default: $default
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      default
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserAddressesByDefaultAndUserIdQueryVariables,
  APITypes.UserAddressesByDefaultAndUserIdQuery
>;
export const userAddressesByUserIdAndId = /* GraphQL */ `query UserAddressesByUserIdAndId(
  $userId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserAddressFilterInput
  $limit: Int
  $nextToken: String
) {
  userAddressesByUserIdAndId(
    userId: $userId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      default
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserAddressesByUserIdAndIdQueryVariables,
  APITypes.UserAddressesByUserIdAndIdQuery
>;
export const getCompany = /* GraphQL */ `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    ownerType
    uen
    gstNo
    type
    name
    email
    registration_name
    picture
    bio
    wlcmMsg
    address
    category
    status
    companyUserList {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    productList {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    chats {
      nextToken
      __typename
    }
    followers
    followersList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyQueryVariables,
  APITypes.GetCompanyQuery
>;
export const listCompanies = /* GraphQL */ `query ListCompanies(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompaniesQueryVariables,
  APITypes.ListCompaniesQuery
>;
export const companiesByUenAndCreatedAt = /* GraphQL */ `query CompaniesByUenAndCreatedAt(
  $uen: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  companiesByUenAndCreatedAt(
    uen: $uen
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompaniesByUenAndCreatedAtQueryVariables,
  APITypes.CompaniesByUenAndCreatedAtQuery
>;
export const companiesByGstNoAndCreatedAt = /* GraphQL */ `query CompaniesByGstNoAndCreatedAt(
  $gstNo: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  companiesByGstNoAndCreatedAt(
    gstNo: $gstNo
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompaniesByGstNoAndCreatedAtQueryVariables,
  APITypes.CompaniesByGstNoAndCreatedAtQuery
>;
export const companiesByStatusAndCreatedAt = /* GraphQL */ `query CompaniesByStatusAndCreatedAt(
  $status: CompanyStatus!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  companiesByStatusAndCreatedAt(
    status: $status
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompaniesByStatusAndCreatedAtQueryVariables,
  APITypes.CompaniesByStatusAndCreatedAtQuery
>;
export const getCompanyPrivateInfo = /* GraphQL */ `query GetCompanyPrivateInfo($id: ID!) {
  getCompanyPrivateInfo(id: $id) {
    id
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    companyDocImage
    contactName
    contactEmail
    contactNo
    conatactDocImage {
      front
      back
      __typename
    }
    bankName
    bankCode
    beneficiaryName
    bankAccNo
    bankDocumnet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyPrivateInfoQueryVariables,
  APITypes.GetCompanyPrivateInfoQuery
>;
export const listCompanyPrivateInfos = /* GraphQL */ `query ListCompanyPrivateInfos(
  $filter: ModelCompanyPrivateInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyPrivateInfos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      companyDocImage
      contactName
      contactEmail
      contactNo
      bankName
      bankCode
      beneficiaryName
      bankAccNo
      bankDocumnet
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyPrivateInfosQueryVariables,
  APITypes.ListCompanyPrivateInfosQuery
>;
export const companyPrivateInfosByCompanyIdAndId = /* GraphQL */ `query CompanyPrivateInfosByCompanyIdAndId(
  $companyId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyPrivateInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  companyPrivateInfosByCompanyIdAndId(
    companyId: $companyId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyId
      companyDocImage
      contactName
      contactEmail
      contactNo
      bankName
      bankCode
      beneficiaryName
      bankAccNo
      bankDocumnet
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyPrivateInfosByCompanyIdAndIdQueryVariables,
  APITypes.CompanyPrivateInfosByCompanyIdAndIdQuery
>;
export const getCompanyUser = /* GraphQL */ `query GetCompanyUser($id: ID!) {
  getCompanyUser(id: $id) {
    id
    role
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    modules {
      moduleName
      add
      edit
      delete
      read
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyUserQueryVariables,
  APITypes.GetCompanyUserQuery
>;
export const listCompanyUsers = /* GraphQL */ `query ListCompanyUsers(
  $filter: ModelCompanyUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      role
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyUsersQueryVariables,
  APITypes.ListCompanyUsersQuery
>;
export const companyUsersByUserIdAndCompanyId = /* GraphQL */ `query CompanyUsersByUserIdAndCompanyId(
  $userId: ID!
  $companyId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyUserFilterInput
  $limit: Int
  $nextToken: String
) {
  companyUsersByUserIdAndCompanyId(
    userId: $userId
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      role
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyUsersByUserIdAndCompanyIdQueryVariables,
  APITypes.CompanyUsersByUserIdAndCompanyIdQuery
>;
export const companyUsersByCompanyIdAndUserId = /* GraphQL */ `query CompanyUsersByCompanyIdAndUserId(
  $companyId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyUserFilterInput
  $limit: Int
  $nextToken: String
) {
  companyUsersByCompanyIdAndUserId(
    companyId: $companyId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      role
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyUsersByCompanyIdAndUserIdQueryVariables,
  APITypes.CompanyUsersByCompanyIdAndUserIdQuery
>;
export const getCompanyReview = /* GraphQL */ `query GetCompanyReview($id: ID!) {
  getCompanyReview(id: $id) {
    id
    stars
    comment
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyReviewQueryVariables,
  APITypes.GetCompanyReviewQuery
>;
export const listCompanyReviews = /* GraphQL */ `query ListCompanyReviews(
  $filter: ModelCompanyReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanyReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      stars
      comment
      userId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanyReviewsQueryVariables,
  APITypes.ListCompanyReviewsQuery
>;
export const companyReviewsByUserIdAndId = /* GraphQL */ `query CompanyReviewsByUserIdAndId(
  $userId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  companyReviewsByUserIdAndId(
    userId: $userId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stars
      comment
      userId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyReviewsByUserIdAndIdQueryVariables,
  APITypes.CompanyReviewsByUserIdAndIdQuery
>;
export const companyReviewsByCompanyIdAndId = /* GraphQL */ `query CompanyReviewsByCompanyIdAndId(
  $companyId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  companyReviewsByCompanyIdAndId(
    companyId: $companyId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stars
      comment
      userId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyReviewsByCompanyIdAndIdQueryVariables,
  APITypes.CompanyReviewsByCompanyIdAndIdQuery
>;
export const getCategory = /* GraphQL */ `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    name
    type
    description
    picture
    parent_categoryId
    subCategories {
      nextToken
      __typename
    }
    productCategories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryQueryVariables,
  APITypes.GetCategoryQuery
>;
export const listCategories = /* GraphQL */ `query ListCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      type
      description
      picture
      parent_categoryId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoriesQueryVariables,
  APITypes.ListCategoriesQuery
>;
export const categoryListByType = /* GraphQL */ `query CategoryListByType(
  $type: ProductCategoryType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoryListByType(
    type: $type
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      description
      picture
      parent_categoryId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoryListByTypeQueryVariables,
  APITypes.CategoryListByTypeQuery
>;
export const categoriesByParent_categoryId = /* GraphQL */ `query CategoriesByParent_categoryId(
  $parent_categoryId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoriesByParent_categoryId(
    parent_categoryId: $parent_categoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      type
      description
      picture
      parent_categoryId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoriesByParent_categoryIdQueryVariables,
  APITypes.CategoriesByParent_categoryIdQuery
>;
export const getProduct = /* GraphQL */ `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    title
    description
    type
    filterType
    attributes {
      name
      options
      __typename
    }
    allowToComment
    feature
    brand
    total_sold
    rating {
      one
      two
      three
      four
      five
      __typename
    }
    likeCount
    viewCount
    commentCount
    shareCount
    bookmarkCount
    totalReviewCount
    orderCount
    productVariants {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    categories {
      nextToken
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    metaKeywords
    sku
    likes {
      nextToken
      __typename
    }
    bookmarks {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductQueryVariables,
  APITypes.GetProductQuery
>;
export const listProducts = /* GraphQL */ `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsQueryVariables,
  APITypes.ListProductsQuery
>;
export const productListByType = /* GraphQL */ `query ProductListByType(
  $type: ProductType!
  $createdAtStatus: ModelProductByProductTypeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productListByType(
    type: $type
    createdAtStatus: $createdAtStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductListByTypeQueryVariables,
  APITypes.ProductListByTypeQuery
>;
export const mostViewedProductList = /* GraphQL */ `query MostViewedProductList(
  $type: ProductType!
  $viewCount: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  mostViewedProductList(
    type: $type
    viewCount: $viewCount
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MostViewedProductListQueryVariables,
  APITypes.MostViewedProductListQuery
>;
export const mostOrderedProducts = /* GraphQL */ `query MostOrderedProducts(
  $type: ProductType!
  $orderCount: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  mostOrderedProducts(
    type: $type
    orderCount: $orderCount
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MostOrderedProductsQueryVariables,
  APITypes.MostOrderedProductsQuery
>;
export const productListByFilterType = /* GraphQL */ `query ProductListByFilterType(
  $filterType: String!
  $createdAtStatus: ModelProductByProductFilterTypeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productListByFilterType(
    filterType: $filterType
    createdAtStatus: $createdAtStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductListByFilterTypeQueryVariables,
  APITypes.ProductListByFilterTypeQuery
>;
export const productFeatureList = /* GraphQL */ `query ProductFeatureList(
  $feature: Int!
  $createdAtStatus: ModelProductByProductFeatureCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productFeatureList(
    feature: $feature
    createdAtStatus: $createdAtStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductFeatureListQueryVariables,
  APITypes.ProductFeatureListQuery
>;
export const productsByUserIdAndId = /* GraphQL */ `query ProductsByUserIdAndId(
  $userId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productsByUserIdAndId(
    userId: $userId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductsByUserIdAndIdQueryVariables,
  APITypes.ProductsByUserIdAndIdQuery
>;
export const productsByUserIdAndType = /* GraphQL */ `query ProductsByUserIdAndType(
  $userId: ID!
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productsByUserIdAndType(
    userId: $userId
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductsByUserIdAndTypeQueryVariables,
  APITypes.ProductsByUserIdAndTypeQuery
>;
export const productsByUserIdAndStatus = /* GraphQL */ `query ProductsByUserIdAndStatus(
  $userId: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productsByUserIdAndStatus(
    userId: $userId
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductsByUserIdAndStatusQueryVariables,
  APITypes.ProductsByUserIdAndStatusQuery
>;
export const productsByCompanyIdAndId = /* GraphQL */ `query ProductsByCompanyIdAndId(
  $companyId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productsByCompanyIdAndId(
    companyId: $companyId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductsByCompanyIdAndIdQueryVariables,
  APITypes.ProductsByCompanyIdAndIdQuery
>;
export const productListByCompanyIdAndType = /* GraphQL */ `query ProductListByCompanyIdAndType(
  $companyId: ID!
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productListByCompanyIdAndType(
    companyId: $companyId
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductListByCompanyIdAndTypeQueryVariables,
  APITypes.ProductListByCompanyIdAndTypeQuery
>;
export const productListBySku = /* GraphQL */ `query ProductListBySku(
  $sku: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productListBySku(
    sku: $sku
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductListBySkuQueryVariables,
  APITypes.ProductListBySkuQuery
>;
export const productsByStatusAndCompanyId = /* GraphQL */ `query ProductsByStatusAndCompanyId(
  $status: Status!
  $companyId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productsByStatusAndCompanyId(
    status: $status
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductsByStatusAndCompanyIdQueryVariables,
  APITypes.ProductsByStatusAndCompanyIdQuery
>;
export const productListByStatusAndType = /* GraphQL */ `query ProductListByStatusAndType(
  $status: Status!
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  productListByStatusAndType(
    status: $status
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductListByStatusAndTypeQueryVariables,
  APITypes.ProductListByStatusAndTypeQuery
>;
export const getFeed = /* GraphQL */ `query GetFeed($id: ID!) {
  getFeed(id: $id) {
    id
    title
    description
    type
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    tags {
      nextToken
      __typename
    }
    collaborations {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFeedQueryVariables, APITypes.GetFeedQuery>;
export const listFeeds = /* GraphQL */ `query ListFeeds(
  $filter: ModelFeedFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFeedsQueryVariables, APITypes.ListFeedsQuery>;
export const feedsByUserIdAndCreatedAt = /* GraphQL */ `query FeedsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedFilterInput
  $limit: Int
  $nextToken: String
) {
  feedsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedsByUserIdAndCreatedAtQueryVariables,
  APITypes.FeedsByUserIdAndCreatedAtQuery
>;
export const feedsByCompanyIdAndCreatedAt = /* GraphQL */ `query FeedsByCompanyIdAndCreatedAt(
  $companyId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedFilterInput
  $limit: Int
  $nextToken: String
) {
  feedsByCompanyIdAndCreatedAt(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedsByCompanyIdAndCreatedAtQueryVariables,
  APITypes.FeedsByCompanyIdAndCreatedAtQuery
>;
export const FeedByStatus = /* GraphQL */ `query FeedByStatus(
  $status: Status!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedFilterInput
  $limit: Int
  $nextToken: String
) {
  FeedByStatus(
    status: $status
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedByStatusQueryVariables,
  APITypes.FeedByStatusQuery
>;
export const FeedByStatusAndType = /* GraphQL */ `query FeedByStatusAndType(
  $status: Status!
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedFilterInput
  $limit: Int
  $nextToken: String
) {
  FeedByStatusAndType(
    status: $status
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedByStatusAndTypeQueryVariables,
  APITypes.FeedByStatusAndTypeQuery
>;
export const getFeedCollaboration = /* GraphQL */ `query GetFeedCollaboration($id: ID!) {
  getFeedCollaboration(id: $id) {
    id
    type
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    merchantId
    merchant {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeedCollaborationQueryVariables,
  APITypes.GetFeedCollaborationQuery
>;
export const listFeedCollaborations = /* GraphQL */ `query ListFeedCollaborations(
  $filter: ModelFeedCollaborationFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeedCollaborations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      feedId
      companyId
      merchantId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeedCollaborationsQueryVariables,
  APITypes.ListFeedCollaborationsQuery
>;
export const feedCollaborationsByFeedIdAndCreatedAt = /* GraphQL */ `query FeedCollaborationsByFeedIdAndCreatedAt(
  $feedId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedCollaborationFilterInput
  $limit: Int
  $nextToken: String
) {
  feedCollaborationsByFeedIdAndCreatedAt(
    feedId: $feedId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      feedId
      companyId
      merchantId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedCollaborationsByFeedIdAndCreatedAtQueryVariables,
  APITypes.FeedCollaborationsByFeedIdAndCreatedAtQuery
>;
export const feedCollaborationsByCompanyIdAndCreatedAt = /* GraphQL */ `query FeedCollaborationsByCompanyIdAndCreatedAt(
  $companyId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedCollaborationFilterInput
  $limit: Int
  $nextToken: String
) {
  feedCollaborationsByCompanyIdAndCreatedAt(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      feedId
      companyId
      merchantId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedCollaborationsByCompanyIdAndCreatedAtQueryVariables,
  APITypes.FeedCollaborationsByCompanyIdAndCreatedAtQuery
>;
export const feedCollaborationsByMerchantIdAndCreatedAt = /* GraphQL */ `query FeedCollaborationsByMerchantIdAndCreatedAt(
  $merchantId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedCollaborationFilterInput
  $limit: Int
  $nextToken: String
) {
  feedCollaborationsByMerchantIdAndCreatedAt(
    merchantId: $merchantId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      feedId
      companyId
      merchantId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedCollaborationsByMerchantIdAndCreatedAtQueryVariables,
  APITypes.FeedCollaborationsByMerchantIdAndCreatedAtQuery
>;
export const getFeedTags = /* GraphQL */ `query GetFeedTags($id: ID!) {
  getFeedTags(id: $id) {
    id
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    companyId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeedTagsQueryVariables,
  APITypes.GetFeedTagsQuery
>;
export const listFeedTags = /* GraphQL */ `query ListFeedTags(
  $filter: ModelFeedTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeedTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      feedId
      productVariantId
      userId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeedTagsQueryVariables,
  APITypes.ListFeedTagsQuery
>;
export const feedTagsByFeedIdAndCreatedAt = /* GraphQL */ `query FeedTagsByFeedIdAndCreatedAt(
  $feedId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  feedTagsByFeedIdAndCreatedAt(
    feedId: $feedId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      feedId
      productVariantId
      userId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedTagsByFeedIdAndCreatedAtQueryVariables,
  APITypes.FeedTagsByFeedIdAndCreatedAtQuery
>;
export const feedTagsByProductVariantIdAndCreatedAt = /* GraphQL */ `query FeedTagsByProductVariantIdAndCreatedAt(
  $productVariantId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  feedTagsByProductVariantIdAndCreatedAt(
    productVariantId: $productVariantId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      feedId
      productVariantId
      userId
      companyId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedTagsByProductVariantIdAndCreatedAtQueryVariables,
  APITypes.FeedTagsByProductVariantIdAndCreatedAtQuery
>;
export const getFeedMedia = /* GraphQL */ `query GetFeedMedia($id: ID!) {
  getFeedMedia(id: $id) {
    id
    file_type
    key
    feedId
    Feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeedMediaQueryVariables,
  APITypes.GetFeedMediaQuery
>;
export const listFeedMedias = /* GraphQL */ `query ListFeedMedias(
  $filter: ModelFeedMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeedMedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      file_type
      key
      feedId
      postId
      propertyId
      position
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeedMediasQueryVariables,
  APITypes.ListFeedMediasQuery
>;
export const feedMediasByFeedIdAndPosition = /* GraphQL */ `query FeedMediasByFeedIdAndPosition(
  $feedId: ID!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  feedMediasByFeedIdAndPosition(
    feedId: $feedId
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      file_type
      key
      feedId
      postId
      propertyId
      position
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedMediasByFeedIdAndPositionQueryVariables,
  APITypes.FeedMediasByFeedIdAndPositionQuery
>;
export const feedMediasByPostIdAndPosition = /* GraphQL */ `query FeedMediasByPostIdAndPosition(
  $postId: ID!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  feedMediasByPostIdAndPosition(
    postId: $postId
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      file_type
      key
      feedId
      postId
      propertyId
      position
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedMediasByPostIdAndPositionQueryVariables,
  APITypes.FeedMediasByPostIdAndPositionQuery
>;
export const feedMediasByPropertyIdAndPosition = /* GraphQL */ `query FeedMediasByPropertyIdAndPosition(
  $propertyId: ID!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  feedMediasByPropertyIdAndPosition(
    propertyId: $propertyId
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      file_type
      key
      feedId
      postId
      propertyId
      position
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedMediasByPropertyIdAndPositionQueryVariables,
  APITypes.FeedMediasByPropertyIdAndPositionQuery
>;
export const feedMediasByPositionAndCreatedAt = /* GraphQL */ `query FeedMediasByPositionAndCreatedAt(
  $position: Int!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFeedMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  feedMediasByPositionAndCreatedAt(
    position: $position
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      file_type
      key
      feedId
      postId
      propertyId
      position
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FeedMediasByPositionAndCreatedAtQueryVariables,
  APITypes.FeedMediasByPositionAndCreatedAtQuery
>;
export const getLike = /* GraphQL */ `query GetLike($id: ID!) {
  getLike(id: $id) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    likeState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLikeQueryVariables, APITypes.GetLikeQuery>;
export const listLikes = /* GraphQL */ `query ListLikes(
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      productId
      feedId
      propertyId
      postId
      likeState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListLikesQueryVariables, APITypes.ListLikesQuery>;
export const likesByTypeAndUserId = /* GraphQL */ `query LikesByTypeAndUserId(
  $type: String!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  likesByTypeAndUserId(
    type: $type
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      propertyId
      postId
      likeState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LikesByTypeAndUserIdQueryVariables,
  APITypes.LikesByTypeAndUserIdQuery
>;
export const likesByProductIdAndUserId = /* GraphQL */ `query LikesByProductIdAndUserId(
  $productId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  likesByProductIdAndUserId(
    productId: $productId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      propertyId
      postId
      likeState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LikesByProductIdAndUserIdQueryVariables,
  APITypes.LikesByProductIdAndUserIdQuery
>;
export const likesByFeedIdAndUserId = /* GraphQL */ `query LikesByFeedIdAndUserId(
  $feedId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  likesByFeedIdAndUserId(
    feedId: $feedId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      propertyId
      postId
      likeState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LikesByFeedIdAndUserIdQueryVariables,
  APITypes.LikesByFeedIdAndUserIdQuery
>;
export const likesByPropertyIdAndUserId = /* GraphQL */ `query LikesByPropertyIdAndUserId(
  $propertyId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  likesByPropertyIdAndUserId(
    propertyId: $propertyId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      propertyId
      postId
      likeState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LikesByPropertyIdAndUserIdQueryVariables,
  APITypes.LikesByPropertyIdAndUserIdQuery
>;
export const likesByPostIdAndUserId = /* GraphQL */ `query LikesByPostIdAndUserId(
  $postId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  likesByPostIdAndUserId(
    postId: $postId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      propertyId
      postId
      likeState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LikesByPostIdAndUserIdQueryVariables,
  APITypes.LikesByPostIdAndUserIdQuery
>;
export const likesByUserIdAndCreatedAt = /* GraphQL */ `query LikesByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  likesByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      propertyId
      postId
      likeState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LikesByUserIdAndCreatedAtQueryVariables,
  APITypes.LikesByUserIdAndCreatedAtQuery
>;
export const getViews = /* GraphQL */ `query GetViews($id: ID!) {
  getViews(id: $id) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    viewCount
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetViewsQueryVariables, APITypes.GetViewsQuery>;
export const listViews = /* GraphQL */ `query ListViews(
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  listViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      productId
      feedId
      postId
      propertyId
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListViewsQueryVariables, APITypes.ListViewsQuery>;
export const viewsByProductIdAndUserId = /* GraphQL */ `query ViewsByProductIdAndUserId(
  $productId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  viewsByProductIdAndUserId(
    productId: $productId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      feedId
      postId
      propertyId
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ViewsByProductIdAndUserIdQueryVariables,
  APITypes.ViewsByProductIdAndUserIdQuery
>;
export const viewsByFeedIdAndUserId = /* GraphQL */ `query ViewsByFeedIdAndUserId(
  $feedId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  viewsByFeedIdAndUserId(
    feedId: $feedId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      feedId
      postId
      propertyId
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ViewsByFeedIdAndUserIdQueryVariables,
  APITypes.ViewsByFeedIdAndUserIdQuery
>;
export const viewsByPostIdAndUserId = /* GraphQL */ `query ViewsByPostIdAndUserId(
  $postId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  viewsByPostIdAndUserId(
    postId: $postId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      feedId
      postId
      propertyId
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ViewsByPostIdAndUserIdQueryVariables,
  APITypes.ViewsByPostIdAndUserIdQuery
>;
export const viewsByPropertyIdAndUserId = /* GraphQL */ `query ViewsByPropertyIdAndUserId(
  $propertyId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  viewsByPropertyIdAndUserId(
    propertyId: $propertyId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      feedId
      postId
      propertyId
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ViewsByPropertyIdAndUserIdQueryVariables,
  APITypes.ViewsByPropertyIdAndUserIdQuery
>;
export const viewsByTypeAndUserId = /* GraphQL */ `query ViewsByTypeAndUserId(
  $type: ViewProductType!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  viewsByTypeAndUserId(
    type: $type
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      feedId
      postId
      propertyId
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ViewsByTypeAndUserIdQueryVariables,
  APITypes.ViewsByTypeAndUserIdQuery
>;
export const viewsByTypeAndViewCount = /* GraphQL */ `query ViewsByTypeAndViewCount(
  $type: ViewProductType!
  $viewCount: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  viewsByTypeAndViewCount(
    type: $type
    viewCount: $viewCount
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      feedId
      postId
      propertyId
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ViewsByTypeAndViewCountQueryVariables,
  APITypes.ViewsByTypeAndViewCountQuery
>;
export const viewsByUserIdAndCreatedAt = /* GraphQL */ `query ViewsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  viewsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      feedId
      postId
      propertyId
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ViewsByUserIdAndCreatedAtQueryVariables,
  APITypes.ViewsByUserIdAndCreatedAtQuery
>;
export const viewsByUserIdAndTypeAndUpdatedAt = /* GraphQL */ `query ViewsByUserIdAndTypeAndUpdatedAt(
  $userId: ID!
  $typeUpdatedAt: ModelViewsByUserAndTypeAndSortCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  viewsByUserIdAndTypeAndUpdatedAt(
    userId: $userId
    typeUpdatedAt: $typeUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      feedId
      postId
      propertyId
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ViewsByUserIdAndTypeAndUpdatedAtQueryVariables,
  APITypes.ViewsByUserIdAndTypeAndUpdatedAtQuery
>;
export const getProductBookmarks = /* GraphQL */ `query GetProductBookmarks($id: ID!) {
  getProductBookmarks(id: $id) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    bookmarkState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductBookmarksQueryVariables,
  APITypes.GetProductBookmarksQuery
>;
export const listProductBookmarks = /* GraphQL */ `query ListProductBookmarks(
  $filter: ModelProductBookmarksFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductBookmarks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      productId
      feedId
      postId
      propertyId
      bookmarkState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductBookmarksQueryVariables,
  APITypes.ListProductBookmarksQuery
>;
export const productBookmarksByTypeAndUserId = /* GraphQL */ `query ProductBookmarksByTypeAndUserId(
  $type: String!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductBookmarksFilterInput
  $limit: Int
  $nextToken: String
) {
  productBookmarksByTypeAndUserId(
    type: $type
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      postId
      propertyId
      bookmarkState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductBookmarksByTypeAndUserIdQueryVariables,
  APITypes.ProductBookmarksByTypeAndUserIdQuery
>;
export const productBookmarksByProductIdAndUserId = /* GraphQL */ `query ProductBookmarksByProductIdAndUserId(
  $productId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductBookmarksFilterInput
  $limit: Int
  $nextToken: String
) {
  productBookmarksByProductIdAndUserId(
    productId: $productId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      postId
      propertyId
      bookmarkState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductBookmarksByProductIdAndUserIdQueryVariables,
  APITypes.ProductBookmarksByProductIdAndUserIdQuery
>;
export const productBookmarksByFeedIdAndUserId = /* GraphQL */ `query ProductBookmarksByFeedIdAndUserId(
  $feedId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductBookmarksFilterInput
  $limit: Int
  $nextToken: String
) {
  productBookmarksByFeedIdAndUserId(
    feedId: $feedId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      postId
      propertyId
      bookmarkState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductBookmarksByFeedIdAndUserIdQueryVariables,
  APITypes.ProductBookmarksByFeedIdAndUserIdQuery
>;
export const productBookmarksByPostIdAndUserId = /* GraphQL */ `query ProductBookmarksByPostIdAndUserId(
  $postId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductBookmarksFilterInput
  $limit: Int
  $nextToken: String
) {
  productBookmarksByPostIdAndUserId(
    postId: $postId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      postId
      propertyId
      bookmarkState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductBookmarksByPostIdAndUserIdQueryVariables,
  APITypes.ProductBookmarksByPostIdAndUserIdQuery
>;
export const productBookmarksByPropertyIdAndUserId = /* GraphQL */ `query ProductBookmarksByPropertyIdAndUserId(
  $propertyId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductBookmarksFilterInput
  $limit: Int
  $nextToken: String
) {
  productBookmarksByPropertyIdAndUserId(
    propertyId: $propertyId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      postId
      propertyId
      bookmarkState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductBookmarksByPropertyIdAndUserIdQueryVariables,
  APITypes.ProductBookmarksByPropertyIdAndUserIdQuery
>;
export const productBookmarksByUserIdAndCreatedAt = /* GraphQL */ `query ProductBookmarksByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductBookmarksFilterInput
  $limit: Int
  $nextToken: String
) {
  productBookmarksByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      productId
      feedId
      postId
      propertyId
      bookmarkState
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductBookmarksByUserIdAndCreatedAtQueryVariables,
  APITypes.ProductBookmarksByUserIdAndCreatedAtQuery
>;
export const getComments = /* GraphQL */ `query GetComments($id: ID!) {
  getComments(id: $id) {
    id
    comment
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    parentCommentId
    commentReplyList {
      nextToken
      __typename
    }
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentsQueryVariables,
  APITypes.GetCommentsQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $filter: ModelCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      comment
      productId
      feedId
      postId
      parentCommentId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const commentsByProductIdAndCreatedAt = /* GraphQL */ `query CommentsByProductIdAndCreatedAt(
  $productId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByProductIdAndCreatedAt(
    productId: $productId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      comment
      productId
      feedId
      postId
      parentCommentId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByProductIdAndCreatedAtQueryVariables,
  APITypes.CommentsByProductIdAndCreatedAtQuery
>;
export const commentsByFeedIdAndCreatedAt = /* GraphQL */ `query CommentsByFeedIdAndCreatedAt(
  $feedId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByFeedIdAndCreatedAt(
    feedId: $feedId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      comment
      productId
      feedId
      postId
      parentCommentId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByFeedIdAndCreatedAtQueryVariables,
  APITypes.CommentsByFeedIdAndCreatedAtQuery
>;
export const commentsByPostIdAndCreatedAt = /* GraphQL */ `query CommentsByPostIdAndCreatedAt(
  $postId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByPostIdAndCreatedAt(
    postId: $postId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      comment
      productId
      feedId
      postId
      parentCommentId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByPostIdAndCreatedAtQueryVariables,
  APITypes.CommentsByPostIdAndCreatedAtQuery
>;
export const commentsByParentCommentIdAndCreatedAt = /* GraphQL */ `query CommentsByParentCommentIdAndCreatedAt(
  $parentCommentId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByParentCommentIdAndCreatedAt(
    parentCommentId: $parentCommentId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      comment
      productId
      feedId
      postId
      parentCommentId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByParentCommentIdAndCreatedAtQueryVariables,
  APITypes.CommentsByParentCommentIdAndCreatedAtQuery
>;
export const commentsByUserIdAndId = /* GraphQL */ `query CommentsByUserIdAndId(
  $userId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByUserIdAndId(
    userId: $userId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      comment
      productId
      feedId
      postId
      parentCommentId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByUserIdAndIdQueryVariables,
  APITypes.CommentsByUserIdAndIdQuery
>;
export const getProductVariant = /* GraphQL */ `query GetProductVariant($id: ID!) {
  getProductVariant(id: $id) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    attribute
    price
    list_price
    quantity
    deliveryMethod
    productCondition
    productUsed {
      day
      month
      year
      __typename
    }
    designStyle
    designPropertyType
    orderCount
    videoLink
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    status
    productMediaList {
      nextToken
      __typename
    }
    sku
    defaultPosition
    createdAt
    updatedAt
    productProductVariantsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductVariantQueryVariables,
  APITypes.GetProductVariantQuery
>;
export const listProductVariants = /* GraphQL */ `query ListProductVariants(
  $filter: ModelProductVariantFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductVariants(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductVariantsQueryVariables,
  APITypes.ListProductVariantsQuery
>;
export const productVariantsByProductIdAndId = /* GraphQL */ `query ProductVariantsByProductIdAndId(
  $productId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductVariantFilterInput
  $limit: Int
  $nextToken: String
) {
  productVariantsByProductIdAndId(
    productId: $productId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductVariantsByProductIdAndIdQueryVariables,
  APITypes.ProductVariantsByProductIdAndIdQuery
>;
export const productVariantListBySku = /* GraphQL */ `query ProductVariantListBySku(
  $sku: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductVariantFilterInput
  $limit: Int
  $nextToken: String
) {
  productVariantListBySku(
    sku: $sku
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductVariantListBySkuQueryVariables,
  APITypes.ProductVariantListBySkuQuery
>;
export const getProductMedia = /* GraphQL */ `query GetProductMedia($id: ID!) {
  getProductMedia(id: $id) {
    id
    file_type
    key
    product_variant_id
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductMediaQueryVariables,
  APITypes.GetProductMediaQuery
>;
export const listProductMedias = /* GraphQL */ `query ListProductMedias(
  $filter: ModelProductMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductMedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      file_type
      key
      product_variant_id
      position
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductMediasQueryVariables,
  APITypes.ListProductMediasQuery
>;
export const productMediasByProduct_variant_idAndPosition = /* GraphQL */ `query ProductMediasByProduct_variant_idAndPosition(
  $product_variant_id: ID!
  $position: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  productMediasByProduct_variant_idAndPosition(
    product_variant_id: $product_variant_id
    position: $position
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      file_type
      key
      product_variant_id
      position
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductMediasByProduct_variant_idAndPositionQueryVariables,
  APITypes.ProductMediasByProduct_variant_idAndPositionQuery
>;
export const productMediasByPositionAndCreatedAt = /* GraphQL */ `query ProductMediasByPositionAndCreatedAt(
  $position: Int!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  productMediasByPositionAndCreatedAt(
    position: $position
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      file_type
      key
      product_variant_id
      position
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductMediasByPositionAndCreatedAtQueryVariables,
  APITypes.ProductMediasByPositionAndCreatedAtQuery
>;
export const getProductCategories = /* GraphQL */ `query GetProductCategories($id: ID!) {
  getProductCategories(id: $id) {
    id
    categoryId
    category {
      id
      name
      type
      description
      picture
      parent_categoryId
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductCategoriesQueryVariables,
  APITypes.GetProductCategoriesQuery
>;
export const listProductCategories = /* GraphQL */ `query ListProductCategories(
  $filter: ModelProductCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      categoryId
      productId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductCategoriesQueryVariables,
  APITypes.ListProductCategoriesQuery
>;
export const productCategoriesByCategoryIdAndId = /* GraphQL */ `query ProductCategoriesByCategoryIdAndId(
  $categoryId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  productCategoriesByCategoryIdAndId(
    categoryId: $categoryId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      categoryId
      productId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductCategoriesByCategoryIdAndIdQueryVariables,
  APITypes.ProductCategoriesByCategoryIdAndIdQuery
>;
export const productCategoriesByProductIdAndCategoryId = /* GraphQL */ `query ProductCategoriesByProductIdAndCategoryId(
  $productId: ID!
  $categoryId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  productCategoriesByProductIdAndCategoryId(
    productId: $productId
    categoryId: $categoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      categoryId
      productId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductCategoriesByProductIdAndCategoryIdQueryVariables,
  APITypes.ProductCategoriesByProductIdAndCategoryIdQuery
>;
export const getProductReview = /* GraphQL */ `query GetProductReview($id: ID!) {
  getProductReview(id: $id) {
    id
    stars
    comment
    attribute
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    totalImagesCount
    estoreOrderProductId
    estoreOrderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    bookingId
    booking {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    reviewMediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductReviewQueryVariables,
  APITypes.GetProductReviewQuery
>;
export const listProductReviews = /* GraphQL */ `query ListProductReviews(
  $filter: ModelProductReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductReviewsQueryVariables,
  APITypes.ListProductReviewsQuery
>;
export const productReviewsByUserIdAndId = /* GraphQL */ `query ProductReviewsByUserIdAndId(
  $userId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  productReviewsByUserIdAndId(
    userId: $userId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductReviewsByUserIdAndIdQueryVariables,
  APITypes.ProductReviewsByUserIdAndIdQuery
>;
export const productReviewsByProductIdAndId = /* GraphQL */ `query ProductReviewsByProductIdAndId(
  $productId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  productReviewsByProductIdAndId(
    productId: $productId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductReviewsByProductIdAndIdQueryVariables,
  APITypes.ProductReviewsByProductIdAndIdQuery
>;
export const productReviewsByProductIdAndCreatedAt = /* GraphQL */ `query ProductReviewsByProductIdAndCreatedAt(
  $productId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  productReviewsByProductIdAndCreatedAt(
    productId: $productId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductReviewsByProductIdAndCreatedAtQueryVariables,
  APITypes.ProductReviewsByProductIdAndCreatedAtQuery
>;
export const productReviewsByProductIdAndTotalImagesCount = /* GraphQL */ `query ProductReviewsByProductIdAndTotalImagesCount(
  $productId: ID!
  $totalImagesCount: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  productReviewsByProductIdAndTotalImagesCount(
    productId: $productId
    totalImagesCount: $totalImagesCount
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductReviewsByProductIdAndTotalImagesCountQueryVariables,
  APITypes.ProductReviewsByProductIdAndTotalImagesCountQuery
>;
export const productReviewsByEstoreOrderProductIdAndUserId = /* GraphQL */ `query ProductReviewsByEstoreOrderProductIdAndUserId(
  $estoreOrderProductId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  productReviewsByEstoreOrderProductIdAndUserId(
    estoreOrderProductId: $estoreOrderProductId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductReviewsByEstoreOrderProductIdAndUserIdQueryVariables,
  APITypes.ProductReviewsByEstoreOrderProductIdAndUserIdQuery
>;
export const productReviewsByBookingIdAndUserId = /* GraphQL */ `query ProductReviewsByBookingIdAndUserId(
  $bookingId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  productReviewsByBookingIdAndUserId(
    bookingId: $bookingId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProductReviewsByBookingIdAndUserIdQueryVariables,
  APITypes.ProductReviewsByBookingIdAndUserIdQuery
>;
export const getReviewMedia = /* GraphQL */ `query GetReviewMedia($id: ID!) {
  getReviewMedia(id: $id) {
    id
    file_type
    key
    reviewId
    productReview {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReviewMediaQueryVariables,
  APITypes.GetReviewMediaQuery
>;
export const listReviewMedias = /* GraphQL */ `query ListReviewMedias(
  $filter: ModelReviewMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewMedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      file_type
      key
      reviewId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewMediasQueryVariables,
  APITypes.ListReviewMediasQuery
>;
export const reviewMediasByReviewIdAndId = /* GraphQL */ `query ReviewMediasByReviewIdAndId(
  $reviewId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewMediasByReviewIdAndId(
    reviewId: $reviewId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      file_type
      key
      reviewId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewMediasByReviewIdAndIdQueryVariables,
  APITypes.ReviewMediasByReviewIdAndIdQuery
>;
export const getEstoreOrders = /* GraphQL */ `query GetEstoreOrders($id: ID!) {
  getEstoreOrders(id: $id) {
    id
    orderId
    transaction_id
    order_price
    discount
    sell_price
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    offerCodeId
    offerCode {
      id
      code
      description
      discountType
      discountValue
      maxUsage
      usageCount
      validFrom
      validTo
      minOrderValue
      maxDiscountAmount
      customerRestrictions
      productRestrictions
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    delivery_fee
    status
    paymentStatus
    statusDescription
    estoreOrderProducts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEstoreOrdersQueryVariables,
  APITypes.GetEstoreOrdersQuery
>;
export const listEstoreOrders = /* GraphQL */ `query ListEstoreOrders(
  $filter: ModelEstoreOrdersFilterInput
  $limit: Int
  $nextToken: String
) {
  listEstoreOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEstoreOrdersQueryVariables,
  APITypes.ListEstoreOrdersQuery
>;
export const estoreOrdersByOrderIdAndCreatedAt = /* GraphQL */ `query EstoreOrdersByOrderIdAndCreatedAt(
  $orderId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrdersFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrdersByOrderIdAndCreatedAt(
    orderId: $orderId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrdersByOrderIdAndCreatedAtQueryVariables,
  APITypes.EstoreOrdersByOrderIdAndCreatedAtQuery
>;
export const estoreOrdersByOfferCodeIdAndCreatedAt = /* GraphQL */ `query EstoreOrdersByOfferCodeIdAndCreatedAt(
  $offerCodeId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrdersFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrdersByOfferCodeIdAndCreatedAt(
    offerCodeId: $offerCodeId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrdersByOfferCodeIdAndCreatedAtQueryVariables,
  APITypes.EstoreOrdersByOfferCodeIdAndCreatedAtQuery
>;
export const estoreOrdersByOfferCodeIdAndUserId = /* GraphQL */ `query EstoreOrdersByOfferCodeIdAndUserId(
  $offerCodeId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrdersFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrdersByOfferCodeIdAndUserId(
    offerCodeId: $offerCodeId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrdersByOfferCodeIdAndUserIdQueryVariables,
  APITypes.EstoreOrdersByOfferCodeIdAndUserIdQuery
>;
export const estoreOrdersByUserIdAndId = /* GraphQL */ `query EstoreOrdersByUserIdAndId(
  $userId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrdersFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrdersByUserIdAndId(
    userId: $userId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrdersByUserIdAndIdQueryVariables,
  APITypes.EstoreOrdersByUserIdAndIdQuery
>;
export const estoreOrdersByUserIdAndCreatedAt = /* GraphQL */ `query EstoreOrdersByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrdersFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrdersByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrdersByUserIdAndCreatedAtQueryVariables,
  APITypes.EstoreOrdersByUserIdAndCreatedAtQuery
>;
export const estoreOrdersByStatus = /* GraphQL */ `query EstoreOrdersByStatus(
  $status: OrderStatusType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrdersFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrdersByStatus(
    status: $status
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrdersByStatusQueryVariables,
  APITypes.EstoreOrdersByStatusQuery
>;
export const estoreOrdersByPaymentStatusAndCreatedAt = /* GraphQL */ `query EstoreOrdersByPaymentStatusAndCreatedAt(
  $paymentStatus: PaymentStatusType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrdersFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrdersByPaymentStatusAndCreatedAt(
    paymentStatus: $paymentStatus
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrdersByPaymentStatusAndCreatedAtQueryVariables,
  APITypes.EstoreOrdersByPaymentStatusAndCreatedAtQuery
>;
export const getEstoreOrderProduct = /* GraphQL */ `query GetEstoreOrderProduct($id: ID!) {
  getEstoreOrderProduct(id: $id) {
    id
    title
    description
    price
    list_price
    quantity
    invoice
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    productMediaList {
      nextToken
      __typename
    }
    attribute
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    status
    paymentStatus
    deliveryStatus
    statusDescription
    statusHistory
    cancellationReason
    estore_order_id
    estoreOrder {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEstoreOrderProductQueryVariables,
  APITypes.GetEstoreOrderProductQuery
>;
export const listEstoreOrderProducts = /* GraphQL */ `query ListEstoreOrderProducts(
  $filter: ModelEstoreOrderProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listEstoreOrderProducts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEstoreOrderProductsQueryVariables,
  APITypes.ListEstoreOrderProductsQuery
>;
export const estoreOrderProductsByUserIdAndId = /* GraphQL */ `query EstoreOrderProductsByUserIdAndId(
  $userId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrderProductFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrderProductsByUserIdAndId(
    userId: $userId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrderProductsByUserIdAndIdQueryVariables,
  APITypes.EstoreOrderProductsByUserIdAndIdQuery
>;
export const estoreOrderProductsByUserIdAndCreatedAt = /* GraphQL */ `query EstoreOrderProductsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrderProductFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrderProductsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrderProductsByUserIdAndCreatedAtQueryVariables,
  APITypes.EstoreOrderProductsByUserIdAndCreatedAtQuery
>;
export const estoreOrderProductsByCompanyIdAndId = /* GraphQL */ `query EstoreOrderProductsByCompanyIdAndId(
  $companyId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrderProductFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrderProductsByCompanyIdAndId(
    companyId: $companyId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrderProductsByCompanyIdAndIdQueryVariables,
  APITypes.EstoreOrderProductsByCompanyIdAndIdQuery
>;
export const estoreOrderProductsByProductVariantIdAndCreatedAt = /* GraphQL */ `query EstoreOrderProductsByProductVariantIdAndCreatedAt(
  $productVariantId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrderProductFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrderProductsByProductVariantIdAndCreatedAt(
    productVariantId: $productVariantId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrderProductsByProductVariantIdAndCreatedAtQueryVariables,
  APITypes.EstoreOrderProductsByProductVariantIdAndCreatedAtQuery
>;
export const estoreOrderProductByStatus = /* GraphQL */ `query EstoreOrderProductByStatus(
  $status: OrderStatusType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrderProductFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrderProductByStatus(
    status: $status
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrderProductByStatusQueryVariables,
  APITypes.EstoreOrderProductByStatusQuery
>;
export const estoreOrderProductsByPaymentStatusAndCreatedAt = /* GraphQL */ `query EstoreOrderProductsByPaymentStatusAndCreatedAt(
  $paymentStatus: PaymentStatusType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrderProductFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrderProductsByPaymentStatusAndCreatedAt(
    paymentStatus: $paymentStatus
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrderProductsByPaymentStatusAndCreatedAtQueryVariables,
  APITypes.EstoreOrderProductsByPaymentStatusAndCreatedAtQuery
>;
export const estoreOrderProductsByDeliveryStatusAndCreatedAt = /* GraphQL */ `query EstoreOrderProductsByDeliveryStatusAndCreatedAt(
  $deliveryStatus: DeliverStatusType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrderProductFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrderProductsByDeliveryStatusAndCreatedAt(
    deliveryStatus: $deliveryStatus
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrderProductsByDeliveryStatusAndCreatedAtQueryVariables,
  APITypes.EstoreOrderProductsByDeliveryStatusAndCreatedAtQuery
>;
export const estoreOrderProductsByEstore_order_idAndId = /* GraphQL */ `query EstoreOrderProductsByEstore_order_idAndId(
  $estore_order_id: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrderProductFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrderProductsByEstore_order_idAndId(
    estore_order_id: $estore_order_id
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrderProductsByEstore_order_idAndIdQueryVariables,
  APITypes.EstoreOrderProductsByEstore_order_idAndIdQuery
>;
export const getEstoreOrdersRefund = /* GraphQL */ `query GetEstoreOrdersRefund($id: ID!) {
  getEstoreOrdersRefund(id: $id) {
    id
    orderProductId
    orderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    refund_price
    reason
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEstoreOrdersRefundQueryVariables,
  APITypes.GetEstoreOrdersRefundQuery
>;
export const listEstoreOrdersRefunds = /* GraphQL */ `query ListEstoreOrdersRefunds(
  $filter: ModelEstoreOrdersRefundFilterInput
  $limit: Int
  $nextToken: String
) {
  listEstoreOrdersRefunds(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderProductId
      companyId
      refund_price
      reason
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEstoreOrdersRefundsQueryVariables,
  APITypes.ListEstoreOrdersRefundsQuery
>;
export const estoreOrdersRefundsByOrderProductIdAndId = /* GraphQL */ `query EstoreOrdersRefundsByOrderProductIdAndId(
  $orderProductId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrdersRefundFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrdersRefundsByOrderProductIdAndId(
    orderProductId: $orderProductId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderProductId
      companyId
      refund_price
      reason
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrdersRefundsByOrderProductIdAndIdQueryVariables,
  APITypes.EstoreOrdersRefundsByOrderProductIdAndIdQuery
>;
export const estoreOrdersRefundsByCompanyIdAndCreatedAt = /* GraphQL */ `query EstoreOrdersRefundsByCompanyIdAndCreatedAt(
  $companyId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrdersRefundFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrdersRefundsByCompanyIdAndCreatedAt(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderProductId
      companyId
      refund_price
      reason
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrdersRefundsByCompanyIdAndCreatedAtQueryVariables,
  APITypes.EstoreOrdersRefundsByCompanyIdAndCreatedAtQuery
>;
export const estoreOrdersRefundsByStatusAndCreatedAt = /* GraphQL */ `query EstoreOrdersRefundsByStatusAndCreatedAt(
  $status: PaymentStatusType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrdersRefundFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrdersRefundsByStatusAndCreatedAt(
    status: $status
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderProductId
      companyId
      refund_price
      reason
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreOrdersRefundsByStatusAndCreatedAtQueryVariables,
  APITypes.EstoreOrdersRefundsByStatusAndCreatedAtQuery
>;
export const getEstoreCart = /* GraphQL */ `query GetEstoreCart($id: ID!) {
  getEstoreCart(id: $id) {
    id
    quantity
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEstoreCartQueryVariables,
  APITypes.GetEstoreCartQuery
>;
export const listEstoreCarts = /* GraphQL */ `query ListEstoreCarts(
  $filter: ModelEstoreCartFilterInput
  $limit: Int
  $nextToken: String
) {
  listEstoreCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      quantity
      userId
      productVariantId
      companyId
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEstoreCartsQueryVariables,
  APITypes.ListEstoreCartsQuery
>;
export const estoreCartsByUserIdAndCreatedAt = /* GraphQL */ `query EstoreCartsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreCartFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreCartsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      quantity
      userId
      productVariantId
      companyId
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreCartsByUserIdAndCreatedAtQueryVariables,
  APITypes.EstoreCartsByUserIdAndCreatedAtQuery
>;
export const estoreCartsByProductVariantIdAndId = /* GraphQL */ `query EstoreCartsByProductVariantIdAndId(
  $productVariantId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreCartFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreCartsByProductVariantIdAndId(
    productVariantId: $productVariantId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      quantity
      userId
      productVariantId
      companyId
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreCartsByProductVariantIdAndIdQueryVariables,
  APITypes.EstoreCartsByProductVariantIdAndIdQuery
>;
export const estoreCartsByCompanyIdAndId = /* GraphQL */ `query EstoreCartsByCompanyIdAndId(
  $companyId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreCartFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreCartsByCompanyIdAndId(
    companyId: $companyId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      quantity
      userId
      productVariantId
      companyId
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreCartsByCompanyIdAndIdQueryVariables,
  APITypes.EstoreCartsByCompanyIdAndIdQuery
>;
export const estoreCartListByStatus = /* GraphQL */ `query EstoreCartListByStatus(
  $status: Status!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreCartFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreCartListByStatus(
    status: $status
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      quantity
      userId
      productVariantId
      companyId
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EstoreCartListByStatusQueryVariables,
  APITypes.EstoreCartListByStatusQuery
>;
export const getBooking = /* GraphQL */ `query GetBooking($id: ID!) {
  getBooking(id: $id) {
    id
    type
    quantity
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    fulfillerId
    fulfiller {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookingQueryVariables,
  APITypes.GetBookingQuery
>;
export const listBookings = /* GraphQL */ `query ListBookings(
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookingsQueryVariables,
  APITypes.ListBookingsQuery
>;
export const bookingListByType = /* GraphQL */ `query BookingListByType(
  $type: ProductType!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingListByType(
    type: $type
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingListByTypeQueryVariables,
  APITypes.BookingListByTypeQuery
>;
export const bookingsByProductVariantIdAndId = /* GraphQL */ `query BookingsByProductVariantIdAndId(
  $productVariantId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingsByProductVariantIdAndId(
    productVariantId: $productVariantId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingsByProductVariantIdAndIdQueryVariables,
  APITypes.BookingsByProductVariantIdAndIdQuery
>;
export const bookingsByUserIdAndId = /* GraphQL */ `query BookingsByUserIdAndId(
  $userId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingsByUserIdAndId(
    userId: $userId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingsByUserIdAndIdQueryVariables,
  APITypes.BookingsByUserIdAndIdQuery
>;
export const bookingsByFulfillerIdAndType = /* GraphQL */ `query BookingsByFulfillerIdAndType(
  $fulfillerId: ID!
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingsByFulfillerIdAndType(
    fulfillerId: $fulfillerId
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingsByFulfillerIdAndTypeQueryVariables,
  APITypes.BookingsByFulfillerIdAndTypeQuery
>;
export const bookingsByCompanyId = /* GraphQL */ `query BookingsByCompanyId(
  $companyId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingsByCompanyId(
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingsByCompanyIdQueryVariables,
  APITypes.BookingsByCompanyIdQuery
>;
export const getChat = /* GraphQL */ `query GetChat($id: ID!) {
  getChat(id: $id) {
    id
    name
    group
    image
    participants {
      nextToken
      __typename
    }
    chatMessages {
      nextToken
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    chatMessageUnreadCount {
      nextToken
      __typename
    }
    deletedBy
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetChatQueryVariables, APITypes.GetChatQuery>;
export const listChats = /* GraphQL */ `query ListChats(
  $filter: ModelChatFilterInput
  $limit: Int
  $nextToken: String
) {
  listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListChatsQueryVariables, APITypes.ListChatsQuery>;
export const chatsByCompanyIdAndCreatedAt = /* GraphQL */ `query ChatsByCompanyIdAndCreatedAt(
  $companyId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChatFilterInput
  $limit: Int
  $nextToken: String
) {
  chatsByCompanyIdAndCreatedAt(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatsByCompanyIdAndCreatedAtQueryVariables,
  APITypes.ChatsByCompanyIdAndCreatedAtQuery
>;
export const getChatParticipants = /* GraphQL */ `query GetChatParticipants($id: ID!) {
  getChatParticipants(id: $id) {
    id
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChatParticipantsQueryVariables,
  APITypes.GetChatParticipantsQuery
>;
export const listChatParticipants = /* GraphQL */ `query ListChatParticipants(
  $filter: ModelChatParticipantsFilterInput
  $limit: Int
  $nextToken: String
) {
  listChatParticipants(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      chatId
      userId
      isActive
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChatParticipantsQueryVariables,
  APITypes.ListChatParticipantsQuery
>;
export const chatParticipantsByChatIdAndUserId = /* GraphQL */ `query ChatParticipantsByChatIdAndUserId(
  $chatId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChatParticipantsFilterInput
  $limit: Int
  $nextToken: String
) {
  chatParticipantsByChatIdAndUserId(
    chatId: $chatId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      chatId
      userId
      isActive
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatParticipantsByChatIdAndUserIdQueryVariables,
  APITypes.ChatParticipantsByChatIdAndUserIdQuery
>;
export const chatParticipantsByUserIdAndIsActive = /* GraphQL */ `query ChatParticipantsByUserIdAndIsActive(
  $userId: ID!
  $isActive: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChatParticipantsFilterInput
  $limit: Int
  $nextToken: String
) {
  chatParticipantsByUserIdAndIsActive(
    userId: $userId
    isActive: $isActive
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      chatId
      userId
      isActive
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatParticipantsByUserIdAndIsActiveQueryVariables,
  APITypes.ChatParticipantsByUserIdAndIsActiveQuery
>;
export const getChatMessages = /* GraphQL */ `query GetChatMessages($id: ID!) {
  getChatMessages(id: $id) {
    id
    message
    attachment
    attachment_type
    bookingId
    propertyId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    User {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChatMessagesQueryVariables,
  APITypes.GetChatMessagesQuery
>;
export const listChatMessages = /* GraphQL */ `query ListChatMessages(
  $filter: ModelChatMessagesFilterInput
  $limit: Int
  $nextToken: String
) {
  listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      message
      attachment
      attachment_type
      bookingId
      propertyId
      chatId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChatMessagesQueryVariables,
  APITypes.ListChatMessagesQuery
>;
export const chatMessagesByBookingIdAndCreatedAt = /* GraphQL */ `query ChatMessagesByBookingIdAndCreatedAt(
  $bookingId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChatMessagesFilterInput
  $limit: Int
  $nextToken: String
) {
  chatMessagesByBookingIdAndCreatedAt(
    bookingId: $bookingId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      attachment
      attachment_type
      bookingId
      propertyId
      chatId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatMessagesByBookingIdAndCreatedAtQueryVariables,
  APITypes.ChatMessagesByBookingIdAndCreatedAtQuery
>;
export const chatMessagesByPropertyIdAndCreatedAt = /* GraphQL */ `query ChatMessagesByPropertyIdAndCreatedAt(
  $propertyId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChatMessagesFilterInput
  $limit: Int
  $nextToken: String
) {
  chatMessagesByPropertyIdAndCreatedAt(
    propertyId: $propertyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      attachment
      attachment_type
      bookingId
      propertyId
      chatId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatMessagesByPropertyIdAndCreatedAtQueryVariables,
  APITypes.ChatMessagesByPropertyIdAndCreatedAtQuery
>;
export const chatMessagesByChatIdAndCreatedAt = /* GraphQL */ `query ChatMessagesByChatIdAndCreatedAt(
  $chatId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChatMessagesFilterInput
  $limit: Int
  $nextToken: String
) {
  chatMessagesByChatIdAndCreatedAt(
    chatId: $chatId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      attachment
      attachment_type
      bookingId
      propertyId
      chatId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatMessagesByChatIdAndCreatedAtQueryVariables,
  APITypes.ChatMessagesByChatIdAndCreatedAtQuery
>;
export const chatMessagesByUserIdAndId = /* GraphQL */ `query ChatMessagesByUserIdAndId(
  $userId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChatMessagesFilterInput
  $limit: Int
  $nextToken: String
) {
  chatMessagesByUserIdAndId(
    userId: $userId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      message
      attachment
      attachment_type
      bookingId
      propertyId
      chatId
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatMessagesByUserIdAndIdQueryVariables,
  APITypes.ChatMessagesByUserIdAndIdQuery
>;
export const getChatMessageUnreadCount = /* GraphQL */ `query GetChatMessageUnreadCount($id: ID!) {
  getChatMessageUnreadCount(id: $id) {
    id
    userId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    unreadCount
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChatMessageUnreadCountQueryVariables,
  APITypes.GetChatMessageUnreadCountQuery
>;
export const listChatMessageUnreadCounts = /* GraphQL */ `query ListChatMessageUnreadCounts(
  $filter: ModelChatMessageUnreadCountFilterInput
  $limit: Int
  $nextToken: String
) {
  listChatMessageUnreadCounts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      chatId
      unreadCount
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChatMessageUnreadCountsQueryVariables,
  APITypes.ListChatMessageUnreadCountsQuery
>;
export const chatMessageUnreadCountsByUserIdAndId = /* GraphQL */ `query ChatMessageUnreadCountsByUserIdAndId(
  $userId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChatMessageUnreadCountFilterInput
  $limit: Int
  $nextToken: String
) {
  chatMessageUnreadCountsByUserIdAndId(
    userId: $userId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      chatId
      unreadCount
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatMessageUnreadCountsByUserIdAndIdQueryVariables,
  APITypes.ChatMessageUnreadCountsByUserIdAndIdQuery
>;
export const chatMessageUnreadCountsByChatIdAndUserId = /* GraphQL */ `query ChatMessageUnreadCountsByChatIdAndUserId(
  $chatId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChatMessageUnreadCountFilterInput
  $limit: Int
  $nextToken: String
) {
  chatMessageUnreadCountsByChatIdAndUserId(
    chatId: $chatId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      chatId
      unreadCount
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatMessageUnreadCountsByChatIdAndUserIdQueryVariables,
  APITypes.ChatMessageUnreadCountsByChatIdAndUserIdQuery
>;
export const getNotifications = /* GraphQL */ `query GetNotifications($id: ID!) {
  getNotifications(id: $id) {
    id
    userId
    senderId
    sender {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    read
    title
    description
    imageUrl
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationsQueryVariables,
  APITypes.GetNotificationsQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationsFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      senderId
      read
      title
      description
      imageUrl
      data
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const notificationsByUserIdAndId = /* GraphQL */ `query NotificationsByUserIdAndId(
  $userId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationsFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByUserIdAndId(
    userId: $userId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      senderId
      read
      title
      description
      imageUrl
      data
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationsByUserIdAndIdQueryVariables,
  APITypes.NotificationsByUserIdAndIdQuery
>;
export const notificationsBySenderIdAndId = /* GraphQL */ `query NotificationsBySenderIdAndId(
  $senderId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationsFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsBySenderIdAndId(
    senderId: $senderId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      senderId
      read
      title
      description
      imageUrl
      data
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationsBySenderIdAndIdQueryVariables,
  APITypes.NotificationsBySenderIdAndIdQuery
>;
export const notificationsByReadAndUserId = /* GraphQL */ `query NotificationsByReadAndUserId(
  $read: Int!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationsFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByReadAndUserId(
    read: $read
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      senderId
      read
      title
      description
      imageUrl
      data
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationsByReadAndUserIdQueryVariables,
  APITypes.NotificationsByReadAndUserIdQuery
>;
export const getPost = /* GraphQL */ `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    comments {
      nextToken
      __typename
    }
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPostQueryVariables, APITypes.GetPostQuery>;
export const listPosts = /* GraphQL */ `query ListPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPostsQueryVariables, APITypes.ListPostsQuery>;
export const postsByTypeAndCreatedAt = /* GraphQL */ `query PostsByTypeAndCreatedAt(
  $type: PostType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postsByTypeAndCreatedAt(
    type: $type
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PostsByTypeAndCreatedAtQueryVariables,
  APITypes.PostsByTypeAndCreatedAtQuery
>;
export const postsByTypeAndTitle = /* GraphQL */ `query PostsByTypeAndTitle(
  $type: PostType!
  $title: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postsByTypeAndTitle(
    type: $type
    title: $title
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PostsByTypeAndTitleQueryVariables,
  APITypes.PostsByTypeAndTitleQuery
>;
export const postsByTypeAndStatus = /* GraphQL */ `query PostsByTypeAndStatus(
  $type: PostType!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postsByTypeAndStatus(
    type: $type
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PostsByTypeAndStatusQueryVariables,
  APITypes.PostsByTypeAndStatusQuery
>;
export const postsByUserIdAndCreatedAt = /* GraphQL */ `query PostsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PostsByUserIdAndCreatedAtQueryVariables,
  APITypes.PostsByUserIdAndCreatedAtQuery
>;
export const postsByUserIdAndStatus = /* GraphQL */ `query PostsByUserIdAndStatus(
  $userId: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  postsByUserIdAndStatus(
    userId: $userId
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PostsByUserIdAndStatusQueryVariables,
  APITypes.PostsByUserIdAndStatusQuery
>;
export const PostListByStatus = /* GraphQL */ `query PostListByStatus(
  $status: Status!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  PostListByStatus(
    status: $status
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PostListByStatusQueryVariables,
  APITypes.PostListByStatusQuery
>;
export const PostListByTitleAndStatus = /* GraphQL */ `query PostListByTitleAndStatus(
  $status: Status!
  $title: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  PostListByTitleAndStatus(
    status: $status
    title: $title
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PostListByTitleAndStatusQueryVariables,
  APITypes.PostListByTitleAndStatusQuery
>;
export const getRecommendation = /* GraphQL */ `query GetRecommendation($id: ID!) {
  getRecommendation(id: $id) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    feedId
    createdAt
    ttl
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRecommendationQueryVariables,
  APITypes.GetRecommendationQuery
>;
export const listRecommendations = /* GraphQL */ `query ListRecommendations(
  $filter: ModelRecommendationFilterInput
  $limit: Int
  $nextToken: String
) {
  listRecommendations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      productId
      postId
      feedId
      createdAt
      ttl
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRecommendationsQueryVariables,
  APITypes.ListRecommendationsQuery
>;
export const recommendationsByUserIdAndCreatedAt = /* GraphQL */ `query RecommendationsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRecommendationFilterInput
  $limit: Int
  $nextToken: String
) {
  recommendationsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      productId
      postId
      feedId
      createdAt
      ttl
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RecommendationsByUserIdAndCreatedAtQueryVariables,
  APITypes.RecommendationsByUserIdAndCreatedAtQuery
>;
export const recommendationsByProductIdAndCreatedAt = /* GraphQL */ `query RecommendationsByProductIdAndCreatedAt(
  $productId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRecommendationFilterInput
  $limit: Int
  $nextToken: String
) {
  recommendationsByProductIdAndCreatedAt(
    productId: $productId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      productId
      postId
      feedId
      createdAt
      ttl
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RecommendationsByProductIdAndCreatedAtQueryVariables,
  APITypes.RecommendationsByProductIdAndCreatedAtQuery
>;
export const getRecommendationViews = /* GraphQL */ `query GetRecommendationViews($id: ID!) {
  getRecommendationViews(id: $id) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRecommendationViewsQueryVariables,
  APITypes.GetRecommendationViewsQuery
>;
export const listRecommendationViews = /* GraphQL */ `query ListRecommendationViews(
  $filter: ModelRecommendationViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  listRecommendationViews(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRecommendationViewsQueryVariables,
  APITypes.ListRecommendationViewsQuery
>;
export const recommendationViewsByUserIdAndCreatedAt = /* GraphQL */ `query RecommendationViewsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRecommendationViewsFilterInput
  $limit: Int
  $nextToken: String
) {
  recommendationViewsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RecommendationViewsByUserIdAndCreatedAtQueryVariables,
  APITypes.RecommendationViewsByUserIdAndCreatedAtQuery
>;
export const getFollow = /* GraphQL */ `query GetFollow($id: ID!) {
  getFollow(id: $id) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    followerId
    follower {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFollowQueryVariables, APITypes.GetFollowQuery>;
export const listFollows = /* GraphQL */ `query ListFollows(
  $filter: ModelFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  listFollows(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      companyId
      followerId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFollowsQueryVariables,
  APITypes.ListFollowsQuery
>;
export const followsByUserIdAndCreatedAt = /* GraphQL */ `query FollowsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  followsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      companyId
      followerId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FollowsByUserIdAndCreatedAtQueryVariables,
  APITypes.FollowsByUserIdAndCreatedAtQuery
>;
export const followsByCompanyIdAndUserId = /* GraphQL */ `query FollowsByCompanyIdAndUserId(
  $companyId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  followsByCompanyIdAndUserId(
    companyId: $companyId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      companyId
      followerId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FollowsByCompanyIdAndUserIdQueryVariables,
  APITypes.FollowsByCompanyIdAndUserIdQuery
>;
export const followsByCompanyIdAndCreatedAt = /* GraphQL */ `query FollowsByCompanyIdAndCreatedAt(
  $companyId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  followsByCompanyIdAndCreatedAt(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      companyId
      followerId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FollowsByCompanyIdAndCreatedAtQueryVariables,
  APITypes.FollowsByCompanyIdAndCreatedAtQuery
>;
export const followsByFollowerIdAndUserId = /* GraphQL */ `query FollowsByFollowerIdAndUserId(
  $followerId: ID!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  followsByFollowerIdAndUserId(
    followerId: $followerId
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      companyId
      followerId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FollowsByFollowerIdAndUserIdQueryVariables,
  APITypes.FollowsByFollowerIdAndUserIdQuery
>;
export const followsByFollowerIdAndCompanyId = /* GraphQL */ `query FollowsByFollowerIdAndCompanyId(
  $followerId: ID!
  $companyId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  followsByFollowerIdAndCompanyId(
    followerId: $followerId
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      companyId
      followerId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FollowsByFollowerIdAndCompanyIdQueryVariables,
  APITypes.FollowsByFollowerIdAndCompanyIdQuery
>;
export const followsByFollowerIdAndCreatedAt = /* GraphQL */ `query FollowsByFollowerIdAndCreatedAt(
  $followerId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFollowFilterInput
  $limit: Int
  $nextToken: String
) {
  followsByFollowerIdAndCreatedAt(
    followerId: $followerId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      companyId
      followerId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FollowsByFollowerIdAndCreatedAtQueryVariables,
  APITypes.FollowsByFollowerIdAndCreatedAtQuery
>;
export const getProperty = /* GraphQL */ `query GetProperty($id: ID!) {
  getProperty(id: $id) {
    id
    title
    type
    description
    price
    bed
    bath
    sqft
    psf
    listingId
    category
    detailsJson {
      key
      value
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    likeCount
    viewCount
    likes {
      nextToken
      __typename
    }
    unitFeatures
    facilities
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPropertyQueryVariables,
  APITypes.GetPropertyQuery
>;
export const listProperties = /* GraphQL */ `query ListProperties(
  $filter: ModelPropertyFilterInput
  $limit: Int
  $nextToken: String
) {
  listProperties(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPropertiesQueryVariables,
  APITypes.ListPropertiesQuery
>;
export const propertiesByTypeAndCreatedAt = /* GraphQL */ `query PropertiesByTypeAndCreatedAt(
  $type: PropertyType!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPropertyFilterInput
  $limit: Int
  $nextToken: String
) {
  propertiesByTypeAndCreatedAt(
    type: $type
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PropertiesByTypeAndCreatedAtQueryVariables,
  APITypes.PropertiesByTypeAndCreatedAtQuery
>;
export const propertiesByCategoryAndCreatedAt = /* GraphQL */ `query PropertiesByCategoryAndCreatedAt(
  $category: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPropertyFilterInput
  $limit: Int
  $nextToken: String
) {
  propertiesByCategoryAndCreatedAt(
    category: $category
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PropertiesByCategoryAndCreatedAtQueryVariables,
  APITypes.PropertiesByCategoryAndCreatedAtQuery
>;
export const propertiesByUserIdAndCreatedAt = /* GraphQL */ `query PropertiesByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPropertyFilterInput
  $limit: Int
  $nextToken: String
) {
  propertiesByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PropertiesByUserIdAndCreatedAtQueryVariables,
  APITypes.PropertiesByUserIdAndCreatedAtQuery
>;
export const propertiesByCompanyIdAndCreatedAt = /* GraphQL */ `query PropertiesByCompanyIdAndCreatedAt(
  $companyId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPropertyFilterInput
  $limit: Int
  $nextToken: String
) {
  propertiesByCompanyIdAndCreatedAt(
    companyId: $companyId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PropertiesByCompanyIdAndCreatedAtQueryVariables,
  APITypes.PropertiesByCompanyIdAndCreatedAtQuery
>;
export const getAdminConfig = /* GraphQL */ `query GetAdminConfig($id: ID!) {
  getAdminConfig(id: $id) {
    id
    type
    value
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminConfigQueryVariables,
  APITypes.GetAdminConfigQuery
>;
export const listAdminConfigs = /* GraphQL */ `query ListAdminConfigs(
  $filter: ModelAdminConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      value
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminConfigsQueryVariables,
  APITypes.ListAdminConfigsQuery
>;
export const adminConfigsByType = /* GraphQL */ `query AdminConfigsByType(
  $type: String!
  $sortDirection: ModelSortDirection
  $filter: ModelAdminConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  adminConfigsByType(
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      value
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminConfigsByTypeQueryVariables,
  APITypes.AdminConfigsByTypeQuery
>;
export const getOfferCode = /* GraphQL */ `query GetOfferCode($id: ID!) {
  getOfferCode(id: $id) {
    id
    code
    description
    discountType
    discountValue
    maxUsage
    usageCount
    validFrom
    validTo
    minOrderValue
    maxDiscountAmount
    customerRestrictions
    productRestrictions
    status
    estoreOrders {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOfferCodeQueryVariables,
  APITypes.GetOfferCodeQuery
>;
export const listOfferCodes = /* GraphQL */ `query ListOfferCodes(
  $filter: ModelOfferCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listOfferCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      description
      discountType
      discountValue
      maxUsage
      usageCount
      validFrom
      validTo
      minOrderValue
      maxDiscountAmount
      customerRestrictions
      productRestrictions
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOfferCodesQueryVariables,
  APITypes.ListOfferCodesQuery
>;
export const offerCodesByCodeAndCreatedAt = /* GraphQL */ `query OfferCodesByCodeAndCreatedAt(
  $code: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOfferCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  offerCodesByCodeAndCreatedAt(
    code: $code
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      code
      description
      discountType
      discountValue
      maxUsage
      usageCount
      validFrom
      validTo
      minOrderValue
      maxDiscountAmount
      customerRestrictions
      productRestrictions
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OfferCodesByCodeAndCreatedAtQueryVariables,
  APITypes.OfferCodesByCodeAndCreatedAtQuery
>;
export const getPageHtml = /* GraphQL */ `query GetPageHtml($id: ID!) {
  getPageHtml(id: $id) {
    id
    site
    page
    html
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPageHtmlQueryVariables,
  APITypes.GetPageHtmlQuery
>;
export const listPageHtmls = /* GraphQL */ `query ListPageHtmls(
  $filter: ModelPageHtmlFilterInput
  $limit: Int
  $nextToken: String
) {
  listPageHtmls(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      site
      page
      html
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPageHtmlsQueryVariables,
  APITypes.ListPageHtmlsQuery
>;
export const pageHtmlsBySiteAndPage = /* GraphQL */ `query PageHtmlsBySiteAndPage(
  $site: String!
  $page: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPageHtmlFilterInput
  $limit: Int
  $nextToken: String
) {
  pageHtmlsBySiteAndPage(
    site: $site
    page: $page
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      site
      page
      html
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PageHtmlsBySiteAndPageQueryVariables,
  APITypes.PageHtmlsBySiteAndPageQuery
>;
export const followRemoveF = /* GraphQL */ `query FollowRemoveF($id: ID!) {
  followRemoveF(id: $id) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FollowRemoveFQueryVariables,
  APITypes.FollowRemoveFQuery
>;
export const deleteMultipleProductF = /* GraphQL */ `query DeleteMultipleProductF($productVariantIds: [ID]!) {
  deleteMultipleProductF(productVariantIds: $productVariantIds) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DeleteMultipleProductFQueryVariables,
  APITypes.DeleteMultipleProductFQuery
>;
export const deleteFeedF = /* GraphQL */ `query DeleteFeedF($feedId: ID!) {
  deleteFeedF(feedId: $feedId) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DeleteFeedFQueryVariables,
  APITypes.DeleteFeedFQuery
>;
export const deleteProductByIdF = /* GraphQL */ `query DeleteProductByIdF($productId: ID!) {
  deleteProductByIdF(productId: $productId) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DeleteProductByIdFQueryVariables,
  APITypes.DeleteProductByIdFQuery
>;
export const deletePostByIdF = /* GraphQL */ `query DeletePostByIdF($postId: ID!) {
  deletePostByIdF(postId: $postId) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DeletePostByIdFQueryVariables,
  APITypes.DeletePostByIdFQuery
>;
export const chatGroupRemoveF = /* GraphQL */ `query ChatGroupRemoveF($chatId: ID!) {
  chatGroupRemoveF(chatId: $chatId) {
    resp_status
    resp_data
    resp_code
    resp_message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChatGroupRemoveFQueryVariables,
  APITypes.ChatGroupRemoveFQuery
>;

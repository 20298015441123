import React, { FC } from 'react';
import styles from './ComponentSelectProductType.module.scss';
import { IonCol, IonIcon, IonItem, IonLabel, IonRow, IonThumbnail } from '@ionic/react';
import { Link, useHistory } from 'react-router-dom';
import { AllProductTypes } from '../../shared/errorMsg';
import { closeCircle } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

interface ComponentSelectProductTypeProps {
  closeModal?: any
}

const ComponentSelectProductType: FC<ComponentSelectProductTypeProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  /**
   * goToNav
   */
  function goToNav(path: string) {
    history.push(path)
    props.closeModal()
  }
  return (
    <IonRow className={styles.ComponentSelectProductType} data-testid="ComponentSelectProductType">
      <h4 className={styles.title}>{t('ComponentSelectProductType.createAListing')}
        <IonIcon icon={closeCircle} className={styles.closeIcon} onClick={(e)=> props.closeModal()}></IonIcon>
      </h4>
      <IonCol size='12'>
        <IonItem onClick={(e)=> goToNav(`/addProduct/${AllProductTypes.Request}`)}>
          <IonThumbnail slot="end">
            <svg xmlns="http://www.w3.org/2000/svg" width="83" height="66" viewBox="0 0 83 66">
              <path d="M13.74 60.3602L41.21 61.3302C42.11 61.3302 42.85 61.1802 43.6 60.8802L67.34 51.3302C68.38 50.8802 69.13 50.2902 69.88 49.3902L72.12 46.4002C74.81 42.8202 78.54 37.8902 81.08 34.6102C81.53 34.0102 81.68 33.4202 81.68 32.6702C81.68 31.7702 81.38 31.0302 80.78 30.4302C80.18 29.8302 79.29 29.5302 78.54 29.5302C77.64 29.5302 76.9 29.8302 76.3 30.5702L65.7 41.7702C64.95 42.5202 64.06 43.1102 63.01 43.2602L46.74 46.8402C43.9 47.4402 40.92 45.6502 40.17 42.8102C39.42 39.9702 41.07 36.9902 43.9 36.0902L52.11 33.7002C52.56 33.5502 53.01 33.2502 53.3 32.8002C53.6 32.3502 53.75 31.9002 53.75 31.4602C53.75 30.1202 52.71 29.0702 51.36 28.9202L40.01 28.4702C37.47 28.3202 34.93 29.0702 32.7 30.2602L22.25 36.2302C20.01 37.4202 18.37 38.1702 15.83 38.0202L13.74 38.0402V60.3602V60.3602Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M1.19995 64.9207L13.74 64.5507V30.4307H1.19995V64.9207Z" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M75.5899 4.19988C72.5799 1.18988 67.7099 1.18988 64.6999 4.19988L63.2199 5.67988L61.7399 4.19988C58.7299 1.18988 53.8599 1.18988 50.8499 4.19988C47.8399 7.20988 47.8399 12.0799 50.8499 15.0899L52.3299 16.5699L63.2199 27.4599L74.1099 16.5699L75.5899 15.0899C78.5999 12.0899 78.5999 7.20988 75.5899 4.19988ZM75.5899 4.19988C75.5999 4.19988 75.5999 4.19988 75.5899 4.19988Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </IonThumbnail>
          <IonLabel>
            <h2>{t('ComponentSelectProductType.request')}</h2>
            <p>{t('ComponentSelectProductType.reqDesc')}</p>
          </IonLabel>
        </IonItem>
        <IonItem onClick={(e)=> goToNav(`/addProduct/${AllProductTypes.Donation}`)}>
          <IonThumbnail slot="end">
            <svg xmlns="http://www.w3.org/2000/svg" width="83" height="66" viewBox="0 0 83 66" fill="none">
              <path d="M62.77 35.4502V69.6202H8.08997V35.4502" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M69.6 18.3604H1.26001V35.4403H69.6V18.3604Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M35.4301 69.6204V18.3604" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M35.43 18.3603H20.05C15.33 18.3603 11.51 14.5403 11.51 9.82027C11.51 5.10027 15.33 1.28027 20.05 1.28027C32.01 1.28027 35.43 18.3603 35.43 18.3603Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M35.4301 18.3603H50.8101C55.5301 18.3603 59.3501 14.5403 59.3501 9.82027C59.3501 5.10027 55.5301 1.28027 50.8101 1.28027C38.8501 1.28027 35.4301 18.3603 35.4301 18.3603Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </IonThumbnail>
          <IonLabel>
            <h2>{t('ComponentSelectProductType.donation')}</h2>
            <p>{t('ComponentSelectProductType.donateDesc')}</p>
          </IonLabel>
        </IonItem>
      </IonCol>
      <IonCol size='12'>
      </IonCol>
    </IonRow>
  );
}

export default ComponentSelectProductType;

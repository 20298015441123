import React, { lazy, Suspense } from 'react';

const LazyPageStore = lazy(() => import('./PageStore'));

const PageStore = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageStore {...props} />
  </Suspense>
);

export default PageStore;

import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import styles from './ComponentExploreDetail.module.scss';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../store/UserContext';
import { AppContext } from '../../store/AppContext';
import { AllProductTypes, FILE_PATH } from '../../shared/errorMsg';
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import { bookmarkSaveUnSaveF, giveNextRelatedProduct, likeUnlikeF } from '../../APIs/graphql/mutations';
import { IonAvatar, IonBadge, IonButton, IonCol, IonContent, IonFabButton, IonGrid, IonIcon, IonImg, IonLabel, IonModal, IonPage, IonRow, IonSkeletonText, IonText, IonToolbar, NavContext, useIonViewDidEnter } from '@ionic/react';
import { bookmark, bookmarkOutline, chatbubbleOutline, chevronBack, chevronUp, code, heart, heartOutline, locationOutline, pause, play, shareSocial } from 'ionicons/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import ComponentComments from '../ComponentComments/ComponentComments';
import ComponentReview from '../../components/ComponentReview/ComponentReview';
import ComponentMap from '../../components/ComponentMap/ComponentMap';
import useVideoPlayer from '../../customHooks/useVideoPlayer';
import { ProductTypeSearch, ViewProductType, bookmarkSaveUnSaveInput, giveNextRelatedProductInput, likeUnlikeFInput } from '../../APIs/API';
import moment from 'moment';
import ComponentYoutubeList from '../ComponentYoutubeList/ComponentYoutubeList';
import { viewAction } from '../../shared/UtilityContext';

interface ComponentExploreDetailProps {
  moduleDetails: any,
  type: any,
  file_path: string
}

const ComponentExploreDetail: FC<ComponentExploreDetailProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { s3BucketUrl, loginConfirmBox, initiateChat, presentToastError, onImageError, socialShare, showFilePreview } = useContext(AppContext);
  const { user, isAuthenticated } = useContext(UserContext);
  const mapModal = useRef<HTMLIonModalElement>(null);
  const [ viewFull, setViewFull ] = useState(false);
  const [ activeIndex, setActiveIndex ] = useState(0);
  const { handleSlideChange, playStates, toggleVideoPlayPause } = useVideoPlayer();
  const [ relatedProductList, setRelatedProductList ] = useState([ props.moduleDetails ] as any);
  const [ prLocation, setPrLocation ] = useState({} as any);
  const [ loading, setLoading ] = useState(false);

  const PARENT_MODULE_ID = props.type == ViewProductType.Post ? props.moduleDetails.id : props.moduleDetails.id;
  const MODULE_KEY_NAME = props.type == ViewProductType.Post ? 'postId' : 'productVariantId';

  const [ renderCount, setRenderCount ] = useState(0);
  const { navigate } = useContext(NavContext);
  const myRef = useRef(null);

  // This callback will be executed when the view enters
  useIonViewDidEnter(() => {
    // Update state to trigger a rerender
    setRenderCount(prevCount => prevCount + 1);
  });

  /** Intilized called only once */
  useEffect(() => {
    load();
    return () => {
      const div:any = myRef.current
      div?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /**
   * load
   */
  async function load() {
    // viewAction({ productId: productResp.data.getProductVariant.productId, type: type });
    // relatedProduct(props.moduleDetails.productId)
    productVariantId = PARENT_MODULE_ID;
    dataLoadObj[productVariantId] = true

    if (MODULE_KEY_NAME == 'productVariantId') {
      viewAction({ productId:  props.moduleDetails.productId, type: props.moduleDetails.product.type })
    }
    else {
      viewAction({ [MODULE_KEY_NAME]: PARENT_MODULE_ID, type: props.type });
    }

    const resp =  await relatedProduct(productVariantId);
    if (resp && resp.id) {
      productVariantId = resp.productVariantId || resp.id;
      tempList.push(resp);
      setRelatedProductList(tempList)
      if (myRef && myRef.current) {
        const div:any = myRef.current
        div.addEventListener('scroll', handleScroll)
      }
      // dataLoadObj[resp.productVariantId] = true
    }
  }

  /**
   * handleScroll
   * custom scrool added because infinite loader don't work with snap
   * @param e
   */
  let dataLoadObj = {} as any;
  let productVariantId = '';
  let isApiBusy = false;
  let tempList = [ props.moduleDetails ] as any;
  let allLoaded = false;
  async function handleScroll(e:any) {
    const element = e.target;
    if (allLoaded) {
      return
    }

    const threshold = element.scrollHeight - element.clientHeight * 2;
    if (element.scrollTop >= threshold) {
      // User has reached the bottom of the scrollable area
      // Trigger the loading of more data here
      // Call your function to load more data or fetch the next page
      // return
      if (!productVariantId.length || isApiBusy) {
        return
      }

      isApiBusy = true
      const resp = await relatedProduct(productVariantId)
      console.log(resp)
      if (resp && resp.id) {
        dataLoadObj[resp.id] = true
        // productVariantId = resp.productVariantId
        tempList.push(resp);
        setRelatedProductList([ ...tempList ])
      }
      else {
        allLoaded = true
      }
      isApiBusy = false
    }
  }


  async function relatedProduct(moduleId: string) {
    try {
      setLoading(true)
      if (!moduleId) {
        return ''
      }

      const resp:any = await API.graphql({ query: giveNextRelatedProduct, variables: { input: { [ MODULE_KEY_NAME ]: moduleId, existingProductVariantIds: Object.keys(dataLoadObj)  } as giveNextRelatedProductInput } });
      if (resp && resp.data.giveNextRelatedProduct.resp_status) {
        // set structure according to current where { id:, productId: ,,, product:{} }
        const moduleData = JSON.parse(resp.data.giveNextRelatedProduct.resp_data);
        let moduleItem = { ...moduleData };

        if (props.type != ViewProductType.Post) {
          let productVaraintDetail = { ...moduleItem.productVariants.items[0] }
          delete moduleItem.productVariants;
          productVaraintDetail.product = { ...moduleItem };
          moduleItem = productVaraintDetail;
        }

        // viewAction({ [MODULE_KEY_NAME]: moduleItem.id, type: moduleItem.type });
        const moduleId = moduleItem.productVariantId || moduleItem.id;
        if (MODULE_KEY_NAME == 'productVariantId') {
          // viewAction({ productId:  })
        }
        else {
          viewAction({ [MODULE_KEY_NAME]: moduleId, type: props.type });
        }

        return moduleItem;
      }
    }
    catch (error) {
      console.error('Exception in relatedProduct()', error);
      return {}
    }
    finally {
      setLoading(false)
    }
  }

  /**
   * likeUnlikeFeed
   */
  async function likeUnlikeFeed(product:any, index:number = -1) {
    try {
      if (!isAuthenticated) {
        loginConfirmBox()
        return
      }

      const MODULE_KEY_NAME = props.type == ViewProductType.Post ? 'postId' : 'productId';
      let likeState = true
      if (product.likes.items?.length && product.likes.items[0].likeState) {
        product.likes.items = []
        product.likeCount = product.likeCount - 1
        likeState = false
      }
      else {
        product.likes.items = [ { id: user.username, likeState: true } ]
        product.likeCount = product.likeCount + 1
        likeState = true
      }

      let tempDataListObj = [ ...relatedProductList ];
      if (props.type == ViewProductType.Post) {
        tempDataListObj[index] = product;
      }
      else {
        tempDataListObj[index].product = product;
      }

      console.log(tempDataListObj)
      setRelatedProductList(tempDataListObj)

      const params: GraphQLOptions = {
        query: likeUnlikeF,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: {
          input: {
            likeState: likeState,
            [MODULE_KEY_NAME]: product.id,
            type: props.type,
            ownerId: product.userId
          } as likeUnlikeFInput
        }
      }
      await API.graphql(params);
    }
    catch (error) {
      console.error('Exception in likeUnlikeFeed()', error)
    }
  }

  /**
   * bookmarkToggle
   */
  async function bookmarkToggle(productVaraintDetail:any, index: number) {
    try {
      if (!isAuthenticated) {
        loginConfirmBox()
        return
      }

      const MODULE_KEY_NAME = props.type == ViewProductType.Post ? 'postId' : 'productId';
      const product = productVaraintDetail;
      let bookmarkState = true
      if (product.bookmarks.items?.length && product.bookmarks.items[0].bookmarkState) {
        product.bookmarks.items = []
        product.bookmarkCount = product.bookmarkCount - 1
        bookmarkState = false
      }
      else {
        product.bookmarks.items = [ { id: user.username, bookmarkState: true } ]
        product.bookmarkCount = product.bookmarkCount + 1
        bookmarkState = true
      }

      let tempDataListObj = [ ...relatedProductList ];
      if (props.type == ViewProductType.Post) {
        tempDataListObj[index] = product;
      }
      else {
        tempDataListObj[index].product = product;
      }
      setRelatedProductList(tempDataListObj)

      const params: GraphQLOptions = {
        query: bookmarkSaveUnSaveF,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: {
          input: {
            bookmarkState: bookmarkState,
            [MODULE_KEY_NAME]: product.id,
            type: 'Explore'
          } as bookmarkSaveUnSaveInput
        }
      }
      await API.graphql(params);
    }
    catch (error) {
      console.error('Exception in bookmarkToogle()', error)
    }
  }

  /**
   * addRequest
   */
  async function addRequest(productVaraintDetail: any, imageKey: string, userId: string) {
    try {
      if (productVaraintDetail.product.userId == user?.username) {
        history.push(`/editProduct/${productVaraintDetail.product.type}/${productVaraintDetail.id}`)
        return
      }

      let productParams = {
        productVariantId: productVaraintDetail.id,
        title: productVaraintDetail.title,
        description: productVaraintDetail.description,
        type: props.type,
        image: imageKey,
        userId: userId
      }
      const resp:any = await initiateChat(productParams.userId, productParams);
      if (resp && resp.id) {
        navigate('/chatDetail/' + resp.id, 'root')
      }
    }
    catch (error:any) {
      console.error('Exception in addRequest()', error)
    }
  }

  /**
   * chatInitiate
   * @param participantId
   */
  async function chatInitiate(participantId: string) {
    try {
      const resp:any = await initiateChat(participantId, null);
      if (resp && resp.id) {
        navigate('/chatDetail/' + resp.id, 'root')
      }
    }
    catch (error:any) {
      presentToastError(error.message)
      console.error('Exception in chatInitiate()', error);
    }
  }

  /**
   * shareFeed
   */
  async function shareFeed(productVaraintDetail: any, pvIndex: any) {
    try {
      const MODULE_KEY_NAME = props.type == ViewProductType.Post ? 'postId' : 'productId';
      const resp = await socialShare(productVaraintDetail.id, 'Home360', '', `${window.location.pathname}`, 'Share the url', MODULE_KEY_NAME);
      // if (resp) {
      //   let tempDataListObj = { ...productVaraintDetail  };
      //   tempDataListObj.product.shareCount = tempDataListObj.product.shareCount + 1
      //   // setmoduleDetails(tempDataListObj)
      // }
    }
    catch (error) {
      console.error('Exception in shareFeed');
    }
  }

  /**
   * goToCommentList
   * @param product
   */
  function goToCommentList(product:any) {
    history.push(`/commentList/${product.id}?ownerId=${product.userId}&type=${props.type}`)
  }

  /**
   * gotToPage
   */
  function gotToPage(url:string) {
    navigate(url, 'root')
  }

  function createNewReqDonation(productVaraintDetail:any) {
    history.push(`/addProduct/${props.type == AllProductTypes.Request ? AllProductTypes.Donation : AllProductTypes.Request}?productParams=${JSON.stringify(productVaraintDetail)}`)
  }

  /**
   * viewLocation
   * @param productVaraintDetail || post
   */
  function viewLocation(productVaraintDetail: any){
    setPrLocation(productVaraintDetail.location);
    mapModal.current?.present()
  }

  /**
  * productContentView
  */
  function productContentView(productVaraintDetail: any, index: number) {
    let likeModule = (props.type == ViewProductType.Post) ? productVaraintDetail : productVaraintDetail.product;
    const mediaList = productVaraintDetail?.productMediaList?.items || productVaraintDetail?.mediaList?.items;
    return (
      <>
        <div className={styles.PageContentDetailHeaderDiv}>
          <IonToolbar>
            <div className={styles.headerDiv}>
            </div>

            <IonFabButton className={styles.left + ' transparentButton'} onClick={()=> history.goBack()}>
              <IonIcon icon={chevronBack}></IonIcon>
            </IonFabButton>

            <IonFabButton className={styles.right + ' transparentButton'} style={{ right: 89 + 'px' }} onClick={()=> likeUnlikeFeed(likeModule, index)}>
              <IonIcon icon={likeModule?.likes?.items[0]?.likeState ? heart : heartOutline}></IonIcon>
            </IonFabButton>

            <IonFabButton className={styles.right + ' transparentButton'} style={{ right: 51 + 'px' }} onClick={()=> bookmarkToggle(likeModule, index)}>
              <IonIcon icon={!likeModule?.bookmarks?.items[0]?.bookmarkState ? bookmarkOutline : bookmark} className={!likeModule?.bookmarks?.items[0]?.bookmarkState ? '' : styles.selected }></IonIcon>
            </IonFabButton>

            <IonFabButton className={styles.right + ' transparentButton'} onClick={()=> shareFeed(productVaraintDetail, index)}>
              <IonIcon icon={shareSocial}></IonIcon>
            </IonFabButton>
          </IonToolbar>
        </div>
        <>
          <IonRow className={styles.headerRow}>
            {mediaList && mediaList.length ? (
              <Swiper className={styles.swiperContainer + ' feedDetailSlide'} autoHeight={ false } pagination= { true } spaceBetween={0.2} modules={[ Pagination ]} onSlideChange={(e)=> { setActiveIndex(e.activeIndex); handleSlideChange() } }>
                {mediaList.map((media: any, index: any) => (
                  <SwiperSlide key={media.id + index} onClick={()=> showFilePreview(mediaList, index, props.file_path)}>
                    <div style={{ background: media.file_type.substr(0, 5) == 'image' ? `url(${s3BucketUrl + '/' + props.file_path + '/' + media.key})` : '' }} className='backImg'></div>
                    {
                      media.file_type.substr(0, 5) == 'image' ?
                        <img src={s3BucketUrl + '/' + props.file_path + '/' + media.key} onError={(e:any)=> onImageError(e)} />
                        :
                        <div className='videoContainer' onClick={()=> toggleVideoPlayPause(media)}>
                          <IonFabButton className={styles.videoFabBtn}>
                            <IonIcon icon={ playStates[media.id] ? pause : play }></IonIcon>
                          </IonFabButton>
                          <video src={s3BucketUrl + '/' + props.file_path + '/' + media.key} id={media.id} />
                        </div>
                    }
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <IonSkeletonText animated style={{ width: '100%', height: '200px' }} />
            )}
          </IonRow>

          <IonGrid>
            <IonRow className={styles.productDetailRow}>
              <IonCol size='12' className={styles.swiperCol}>
                <div className="custom swiper-pagination-bullets swiper-pagination-horizontal">
                  {
                    Array.from({ length: mediaList.length }, (_, index) => index).map((value, index:number)=>
                      <span key={index + 'slide'} className={'swiper-pagination-bullet ' + (index == activeIndex ? 'swiper-pagination-bullet-active' : '') }></span>
                    )
                  }
                </div>
              </IonCol>
              {/* Show Categoroes in case of request and donation or title if design or service */}
              <IonCol size='12' className={styles.productCatCol}>
                <IonLabel className={styles.locationLabel + ' oneLineOnly'} onClick={()=> viewLocation(productVaraintDetail)}>
                  <IonIcon icon={locationOutline}></IonIcon>
                  {
                    productVaraintDetail.location.address
                  }

                  {
                    productVaraintDetail.viewCount ?
                      <IonBadge className='greyTextSmall pullRight primaryDarkBtn'>
                        {t('PageProductDetails.views')} {productVaraintDetail.viewCount}
                      </IonBadge>
                      : ''
                  }
                </IonLabel>

                <h6 className={styles.productCatTitle} key={'sd'}>
                  { productVaraintDetail.title }
                  <br/>
                  <span className={styles.posted}>{t('PageProductDetails.posted')} {moment(productVaraintDetail.createdAt).format('DD MMM YYYY')}</span>
                </h6>
              </IonCol>
              { props.type == ProductTypeSearch.Design ? <hr /> : ''}
              {
                productVaraintDetail.size ?
                  <IonCol size='6' className={styles.specificationCol}>
                    <h5 className={styles.title}>Size</h5>
                    <IonText className={styles.desc}>{productVaraintDetail.size}</IonText>
                  </IonCol>
                  : ''
              }

              {
                productVaraintDetail.productCondition ?
                  <IonCol size='6' className={styles.specificationCol}>
                    <h5 className={styles.title}>Condition</h5>
                    <IonText className={styles.desc}>{ productVaraintDetail.productCondition }</IonText>
                  </IonCol>
                  : ''
              }

              {
                productVaraintDetail.price ?
                  <IonCol size='6' className={styles.specificationCol}>
                    <h5 className={styles.title}>Price</h5>
                    <IonText className={styles.desc}>${productVaraintDetail.price}</IonText>
                  </IonCol>
                  : ''
              }

              {
                productVaraintDetail.designPropertyType ?
                  <IonCol size='6' className={styles.specificationCol}>
                    <h5 className={styles.title}>Design Property type</h5>
                    <IonText className={styles.desc}>{productVaraintDetail.designPropertyType}</IonText>
                  </IonCol>
                  : ''
              }

              {
                productVaraintDetail.designStyle ?
                  <IonCol size='6' className={styles.specificationCol}>
                    <h5 className={styles.title}>Design Style</h5>
                    <IonText className={styles.desc}>{productVaraintDetail.designStyle}</IonText>
                  </IonCol>
                  : ''
              }
            </IonRow>
            <IonRow className={styles.descriptionRow}>
              { props.type == ProductTypeSearch.Design ? <hr /> : ''}
              <IonCol size='12' className={styles.descriptionCol}>
                {
                  productVaraintDetail.description ?
                    <>
                      <span className={'greyTextSmall htmlDescription' + (!viewFull ? ' fiveLinesOnly' : '') } dangerouslySetInnerHTML={{ __html: replaceOembedWithIframe(productVaraintDetail.description) }}>
                      </span>
                      {
                        productVaraintDetail.description?.length > 400  ?
                          <a onClick={()=> setViewFull(!viewFull)} className={styles.anchorViewBtn}>
                            { viewFull ? t('PageProductDetails.less') : t('PageProductDetails.more')}
                          </a> : ''
                      }
                    </>
                    :
                    <></>
                }
              </IonCol>

              {
                props.type != ViewProductType.Post ?
                  <IonCol size='12' className={styles.submitColBtn}>
                    {
                      productVaraintDetail.quantity == 0 && (props.type == AllProductTypes.Request || props.type == AllProductTypes.Donation) ?
                        <h4 className={styles.notAvailable}>
                          {/* this <span className='lowerTransform'>{props.type}</span> */}
                          Sorry!, it is currently unavailable. <a onClick={()=> createNewReqDonation(productVaraintDetail)} className={styles.anchorViewBtn}>
                          Create a new {props.type == AllProductTypes.Request ? AllProductTypes.Donation : AllProductTypes.Request}
                          </a>
                        </h4> :
                        <IonButton expand='block' onClick={()=> addRequest(productVaraintDetail, mediaList[0].key, likeModule?.userId)} className='primaryDarkBtn'>
                          {
                            props.type == AllProductTypes.Request ? ( productVaraintDetail?.product?.userId == user?.username ? 'Edit Request' : 'Donate') :
                              props.type == AllProductTypes.Donation ? ( productVaraintDetail?.product?.userId == user?.username ? 'Edit Donation' : 'Request') :
                                props.type == AllProductTypes.Design ? 'Get a quote' :
                                  props.type == AllProductTypes.Service ? 'Enquire' : ''
                          }
                        </IonButton>
                    }
                  </IonCol> : ''
              }

              {/* {
                (type == AllProductTypes.Design || type == AllProductTypes.Service) &&
                productVaraintDetail.product && likeModule?.categories? && likeModule?.categories?.items &&
                likeModule?.categories?.items.map((category: any, index: any) =>
                  <IonCol size='3' className={styles.servicesCol} key={'service' + index}>
                    <IonAvatar>
                      <IonImg src='https://w7.pngwing.com/pngs/99/557/png-transparent-computer-icons-avatar-avatar-angle-heroes-recruiter.png'></IonImg>
                    </IonAvatar>
                    <IonText className='twoLineOnly capitalizeTransform'>{category.category.name}</IonText>
                  </IonCol>
                )
              } */}
            </IonRow>

            { /* Service Layout */}
            <IonRow className={styles.ownerRow}>
              <IonCol size='2' sizeMd='1' className={styles.imgCol}>
                <IonAvatar className={styles.designerImg}>
                  {
                    likeModule?.company?.name ?
                      <IonImg src={likeModule?.company?.picture ? (s3BucketUrl + '/' + FILE_PATH.COMPANY + '/' + likeModule?.company?.picture ) : 'assets/svg/avatar.svg' } onError={(e)=> onImageError(e)}></IonImg>
                      : <IonImg src={likeModule?.user?.picture ? (s3BucketUrl + '/' + FILE_PATH.PUBLIC + '/' + likeModule?.user?.picture ) : 'assets/svg/avatar.svg' } onError={(e)=> onImageError(e)}></IonImg>
                  }
                </IonAvatar>
              </IonCol>
              <IonCol size='8' sizeMd='9' className={styles.nameCol}>
                <IonText style={{ 'top': props.type != ViewProductType.Store ? '12px' : '' }} className={styles.userDetailTex} onClick={()=> gotToPage(likeModule?.company?.name ? `/company/${likeModule?.companyId}` : `/profile/${likeModule?.userId}` )}>
                  <span className={styles.companyName}>
                    { likeModule?.company?.name ? likeModule?.company?.name : likeModule?.user.name }
                  </span>
                  {/* {
                    props.type != ViewProductType.Post ?
                      <span className={styles.companyEmail}>
                        {likeModule?.user.email}
                      </span> : ''
                  } */}
                </IonText>
              </IonCol>
              <IonCol size='2' className={styles.chatCol}>
                {/* Chat ICON */}
                {
                  likeModule?.userId != user?.username ? <IonIcon icon={chatbubbleOutline} onClick={(e)=> chatInitiate(likeModule?.userId) }></IonIcon> : ''
                }
              </IonCol>
            </IonRow>

            <IonRow className={styles.commentListRow}>
              {(props.type == AllProductTypes.Request || props.type == AllProductTypes.Donation || props.type == ViewProductType.Post) ?
                <>
                  <IonCol size='12' className={styles.headingCol}>
                    <h5 className={styles.headingComment} onClick={(e) => goToCommentList(likeModule)}>
                      {t('CmpFeedDetail.comments')}
                      <span> {likeModule?.commentCount ? `(${likeModule?.commentCount})` : ''}</span>
                      <IonIcon icon={code}></IonIcon>
                    </h5>
                  </IonCol>
                  <IonCol size='12' onClick={(e) => goToCommentList(likeModule)}>
                    {
                      productVaraintDetail.productId &&  <ComponentComments key={productVaraintDetail.productId + renderCount} productId={productVaraintDetail.productId} limit={5}></ComponentComments>
                    }
                    {
                      ! productVaraintDetail.productId && productVaraintDetail.id ? <ComponentComments key={productVaraintDetail.id + renderCount} postId={productVaraintDetail.id} limit={5}></ComponentComments> : ''
                    }
                  </IonCol>
                </>
                :
                <>
                  <IonCol size='12' className={styles.headingCol} onClick={(e) => history.push(`/reviewList/${likeModule.id}`)}>
                    <h5 className={styles.headingComment}>
                      {t('CmpFeedDetail.reviews')}
                      <span> {productVaraintDetail?.product?.totalReviewCount ? `(${productVaraintDetail.product.totalReviewCount})` : ''}</span>
                      <IonIcon icon={code}></IonIcon>
                    </h5>
                  </IonCol>
                  <IonCol size='12'>
                    {
                      productVaraintDetail.productId ? <ComponentReview key={productVaraintDetail.productId} productId={productVaraintDetail.productId} rating={productVaraintDetail.product?.rating}></ComponentReview> : ''
                    }
                  </IonCol>
                </>
              }
            </IonRow>
          </IonGrid>
        </>
      </>
    )
  }

  /**
  * productContentView
  */
  function productSkeltonView() {
    return (
      <IonPage>
        <IonContent>
          <div className={styles.feeds} id='feedContainerId'>
            <div className={styles.feedCard} id='feedCardDiv'>
              <div className={styles.PageContentDetailHeaderDiv}>
                <IonToolbar>
                  <div className={styles.headerDiv}>
                  </div>

                  <IonFabButton className={styles.left + ' transparentButton'}>
                    <IonIcon icon={chevronBack}></IonIcon>
                  </IonFabButton>

                  <IonFabButton className={styles.right + ' transparentButton'} style={{ right: 89 + 'px' }}>
                    <IonIcon icon={heartOutline}></IonIcon>
                  </IonFabButton>

                  <IonFabButton className={styles.right + ' transparentButton'} style={{ right: 51 + 'px' }}>
                    <IonIcon icon={bookmarkOutline}></IonIcon>
                  </IonFabButton>

                  <IonFabButton className={styles.right + ' transparentButton'}>
                    <IonIcon icon={shareSocial}></IonIcon>
                  </IonFabButton>
                </IonToolbar>
              </div>
              <>
                <IonRow className={styles.headerRow}>
                  <IonSkeletonText animated style={{ width: '100%', height: '200px' }} />
                </IonRow>

                <IonGrid>
                  <IonRow className={styles.productDetailRow}>
                    {/* Show Categoroes in case of request and donation or title if design or service */}
                    <IonCol size="12" className={styles.productCatCol}>
                      <IonLabel className={styles.locationLabel} onClick={()=> mapModal.current?.present()}>
                        <IonIcon icon={locationOutline}></IonIcon>
                        <IonSkeletonText animated></IonSkeletonText>
                      </IonLabel>

                      <h6 className={styles.productCatTitle} key={'sd'}>
                        <IonSkeletonText animated />
                      </h6>
                    </IonCol>

                    <IonCol size='12' className={styles.descriptionCol}>
                      <IonSkeletonText animated />
                    </IonCol>
                    <IonCol size='12' className={styles.descriptionCol}>
                      <IonSkeletonText animated />
                    </IonCol>
                    <IonCol size='12' className={styles.descriptionCol}>
                      <IonSkeletonText animated />
                    </IonCol>
                    <IonCol size='12' className={styles.descriptionCol}>
                      <IonSkeletonText animated />
                    </IonCol>
                    <IonCol size='12' className={styles.descriptionCol}>
                      <IonSkeletonText animated />
                    </IonCol>

                    <IonCol size='12' className={styles.submitColBtn}>
                      <IonButton expand='block' className='primaryDarkBtn'>
                        <IonSkeletonText animated/>
                      </IonButton>
                    </IonCol>
                  </IonRow>

                  { /* Service Layout */}
                  <IonRow className={styles.ownerRow}>
                    <IonCol size='2' sizeMd='1' className={styles.imgCol}>
                      <IonAvatar className={styles.designerImg}>
                        <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
                      </IonAvatar>
                    </IonCol>
                    <IonCol size='8' sizeMd='9' className={styles.nameCol}>
                      <IonText className={styles.userDetailTex}>
                        <span className={styles.companyName}>
                          <IonSkeletonText animated />
                        </span>
                        <span className={styles.companyEmail}>
                          <IonSkeletonText animated />
                        </span>
                      </IonText>
                    </IonCol>
                    <IonCol size='2' className={styles.chatCol}>
                      <IonIcon icon={chatbubbleOutline}></IonIcon>
                    </IonCol>
                  </IonRow>

                  <IonRow className={styles.commentListRow}>
                    <IonCol size='12' className={styles.headingCol}>
                      <h5 className={styles.headingComment}>
                        {t('CmpFeedDetail.comments')}
                        <IonIcon icon={code}></IonIcon>
                      </h5>
                    </IonCol>
                    <IonCol size='12'>
                      <IonSkeletonText animated />
                    </IonCol>
                    <IonCol size='12'>
                      <IonSkeletonText animated />
                    </IonCol>
                    <IonCol size='12'>
                      <IonSkeletonText animated />
                    </IonCol>
                    <IonCol size='12'>
                      <IonSkeletonText animated />
                    </IonCol>
                    <IonCol size='12'>
                      <IonSkeletonText animated />
                    </IonCol>
                    <IonCol size='12'>
                      <IonSkeletonText animated />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </>
            </div>
          </div>
        </IonContent>
      </IonPage>
    )
  }

  const replaceOembedWithIframe = (htmlContent:string) => {
    /* eslint-disable no-useless-escape */
    const regex = /<oembed url="https:\/\/(?:www\.youtube\.com\/(?:watch\?v=|embed\/|v\/|user\/\S+|shorts\/|)([^\/\?\&"]+)|youtu\.be\/([^\/\?\&"]+))(?:\?si=[^"]*)?"[^>]*><\/oembed>/g;
    return htmlContent.replace(regex, (match, videoId) => {
      const iframe = `
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/${videoId}"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style="width: 100%; height: auto;"
        ></iframe>`;
      return iframe.trim();
    });
  };
  return (
    <>
      <IonContent className={styles.PageFeedDetail} forceOverscroll={false}>
        <div ref={myRef} className={styles.feeds + (isApiBusy ? '' : ' ' +  styles.scrollSnapClass )} id='feedContainerId'>
          {
            relatedProductList.map((item:any, index: number)=>
              <div className={styles.abc } key={item.id}>
                <div key={index} id={item.productId} className={styles.feedCard}>
                  {productContentView(item, index)}
                  <div className={styles.swipeUp}>
                    <span>
                      <IonIcon icon={chevronUp}></IonIcon>
                    </span>

                    { (loading || (index + 1 < relatedProductList.length) ) ? t('RPageHome.swipeUpExplore') : t('RPageHome.theEnd') }
                  </div>
                </div>
              </div>
            )
          }

          {
            loading ? <div className={styles.abc}>
              { productSkeltonView() }
            </div> : ''
          }

          {
            props.type == ViewProductType.Post ?
              <div className={styles.abc}>
                <div className={styles.feedCard}>
                  <ComponentYoutubeList limit={15} query={props.moduleDetails.title}></ComponentYoutubeList>
                </div>
              </div> : ''
          }
        </div>

      </IonContent>
      <IonModal ref={mapModal}>
        <ComponentMap onLocationChange={()=> {}} emitCloseModal={()=> mapModal.current?.dismiss()} propLocation={prLocation}></ComponentMap>
      </IonModal>
    </>
  );
}

export default ComponentExploreDetail;

import React, { lazy, Suspense } from 'react';

const LazyPageOrderDetail = lazy(() => import('./PageOrderSuccess'));

const PageOrderSuccess = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageOrderDetail {...props} />
  </Suspense>
);

export default PageOrderSuccess;

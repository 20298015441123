import { useState, useEffect } from 'react';

export function useLazyLoading(fetchData: (nextToken: any) => void, initialKey: string, direction = 'bottom') {
  const [ dataListObj, setDataListObj ] = useState({ [initialKey]: { items: [], nextToken: null, isAllLoaded: false } } as any);
  const [ isApiBusy, setIsApiBusy ] = useState(false);
  const [ event, setEvent ] = useState(null as any);
  const [ pageNo, setPageNo ] = useState(0);

  useEffect(() => {
    fetchDataWithToken();
  }, [ event, initialKey ]);

  /**
   * fetchDataWithToken
   * @returns
   */
  async function fetchDataWithToken() {
    try {
      let tempListObj = { ...dataListObj };

      if (!tempListObj[initialKey]) {
        tempListObj[initialKey] = {
          items: [],
          token: null,
          isAllLoaded: false
        }
      }

      if (isApiBusy || (tempListObj[initialKey]?.isAllLoaded && !tempListObj[initialKey]?.otherListToken)) {
        return
      }

      setIsApiBusy(true);
      const resp:any = await fetchData(tempListObj[initialKey].nextToken);
      const { key, items, nextToken: newNextToken } = resp;
      if (direction == 'bottom') {
        tempListObj[key].items = [ ...tempListObj[key].items, ...items ];
      }
      else {
        tempListObj[key].items = [ ...items.reverse(), ...tempListObj[key].items ];
      }

      tempListObj[key].nextToken = newNextToken;

      if (!newNextToken) {
        tempListObj[key].isAllLoaded = true;
      }

      setDataListObj(tempListObj)
      setIsApiBusy(false);
    }
    catch (error) {
      console.error('Exception in fetchDataWithToken()', error)
      setIsApiBusy(false);
    }
    finally {
      if (event && event.target && event.target.complete) {
        event.target.complete()
      }
    }
  }

  /**
   * loadMoreProduct
   * @param event
  */
  function loadMoreProduct(event: any) {
    event.id = pageNo
    setPageNo(pageNo + 1)
    setEvent(event)
  }

  return { dataListObj, isApiBusy, setDataListObj, loadMoreProduct };
}
import { useEffect } from 'react';

function useInfiniteScroll(containerId: string, dataListObj: any, INITITAL_KEY: string, loadMoreProduct: any) {
  useEffect(() => {
    const handleScroll = (e: any) => {
      const element = e.target;
      const threshold = element.scrollHeight - element.clientHeight * 2;
      if (element.scrollTop >= threshold - 200 && !dataListObj?.[INITITAL_KEY]?.isAllLoaded) {
        loadMoreProduct(e);
      }
    };

    const containerElement = document.getElementById(containerId);
    if (containerElement) {
      containerElement.addEventListener('scroll', handleScroll);

      return () => {
        containerElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [ containerId, dataListObj, INITITAL_KEY, loadMoreProduct ]);
}

export default useInfiniteScroll;

import React, { lazy, Suspense } from 'react';

const LazyPageSupport = lazy(() => import('./PageSupport'));

const PageSupport = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageSupport {...props} />
  </Suspense>
);

export default PageSupport;

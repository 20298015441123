/* tslint:disable */
/* eslint-disable */
export const ProductFeedListByStatus = /* GraphQL */ `
  query ProductListByStatus(
    $status: Status!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $productVariantFilter: ModelProductVariantFilterInput
    $productVariantLimit: Int
    $productVariantNextToken: String
  ) {
    productListByStatusAndType(
      status: $status
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken)
    {
      items {
        id
        description
        title
        type
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        updatedAt
        createdAt
        userId
        company {
          name
        }
        user {
          id
          name
          picture
        }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            createdAt
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            productCondition
            productId
            productProductVariantsId
            productUsed {
              day
              month
              year
            }
            quantity
            status
            title
            updatedAt
            videoLink
            productMediaList(sortDirection: ASC){
              items {
                id
                key
                owner
                file_type
                product_variant_id
                updatedAt
                createdAt
              }
            }
          }
        }
      }
      nextToken
    }
}`;

export const ProductFeedListByStatusAfterAuth = /* GraphQL */ `
  query ProductListByStatusAndType(
    $status: Status!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $productVariantFilter: ModelProductVariantFilterInput
    $productVariantLimit: Int
    $productVariantNextToken: String
    $userId: ModelIDKeyConditionInput
  ) {
    productListByStatusAndType(
      status: $status
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken)
    {
      items {
        id
        description
        title
        type
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        bookmarks (userId: $userId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $userId) {
          items {
            id
            likeState
          }
        }
        company {
          name
        }
        updatedAt
        createdAt
        userId
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            createdAt
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            productCondition
            productId
            productProductVariantsId
            productUsed {
              day
              month
              year
            }
            quantity
            status
            title
            updatedAt
            videoLink
            productMediaList(sortDirection: ASC) {
              items {
                createdAt
                file_type
                id
                key
                owner
                product_variant_id
                updatedAt
              }
            }
          }
        }
      }
      nextToken
    }
}`;
export const viewsByUserIdAndCreatedAtCustom = /* GraphQL */ `query ViewsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
  $productVariantFilter: ModelProductVariantFilterInput
  $productVariantLimit: Int
  $productVariantNextToken: String
) {
  viewsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      product {
        id
        type
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        rating {
          one
          two
          three
          four
          five
        }
        bookmarks (userId: { eq: $userId}) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: { eq: $userId }) {
          items {
            id
            likeState
          }
        }
        company {
          name
        }
        userId
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            id
            title
            description
            productMediaList(sortDirection: ASC ) {
              items {
                createdAt
                file_type
                id
                key
                position
                owner
                product_variant_id
                updatedAt
              }
            }
          }
        }
      }
      feedId
      postId
      post{
        id
        title
        description
        userId
        type
        user {
          id
          name
          picture
        }
        mediaList (sortDirection: ASC) {
          items {
            id
            key
            file_type
          }
        }
      }
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` ;

export const viewsByTypeUserIdAndCreatedAtCustom = /* GraphQL */ `query ViewsByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
  $productVariantFilter: ModelProductVariantFilterInput
  $productVariantLimit: Int
  $mediaLimit: Int
  $productVariantNextToken: String
) {
  viewsByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      product {
        id
        type
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        rating {
          one
          two
          three
          four
          five
        }
        bookmarks (userId: { eq: $userId}) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: { eq: $userId }) {
          items {
            id
            likeState
          }
        }
        company {
          name
        }
        userId
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            id
            title
            description
            price
            list_price
            productMediaList(limit: $mediaLimit) {
              items {
                createdAt
                file_type
                id
                key
                owner
                product_variant_id
                updatedAt
              }
            }
          }
        }
      }
      feedId
      postId
      post{
        id
        title
        description
        userId
        user {
          id
          name
          picture
        }
        mediaList (sortDirection: ASC) {
          items {
            id
            key
            file_type
          }
        }
      }
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
`;

export const viewsByTypeAndUserIdCustom = /* GraphQL */ `query ViewsByTypeAndUserId(
  $type: ViewProductType!
  $userId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
  $productVariantFilter: ModelProductVariantFilterInput
  $productVariantLimit: Int
  $productVariantNextToken: String
) {
  viewsByTypeAndUserId(
    type: $type
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      product {
        id
        type
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        rating {
          one
          two
          three
          four
          five
        }
        bookmarks (userId: $userId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $userId) {
          items {
            id
            likeState
          }
        }
        company {
          name
        }
        userId
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            id
            title
            description
            price
            list_price
            productMediaList(sortDirection: ASC) {
              items {
                createdAt
                file_type
                id
                key
                owner
                product_variant_id
                updatedAt
              }
            }
          }
        }
      }
      feedId
      postId
      post{
        id
        title
        description
        userId
        user {
          id
          name
          picture
        }
        mediaList (sortDirection: ASC) {
          items {
            id
            key
            file_type
          }
        }
      }
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` ;

export const mostViewedProductListBeforeAuth = /* GraphQL */ `query MostViewedProductList(
  $type: ProductType!
  $viewCount: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
  $productVariantFilter: ModelProductVariantFilterInput
  $productVariantLimit: Int
  $mediaLimit: Int
  $productVariantNextToken: String
) {
  mostViewedProductList(
    type: $type
    viewCount: $viewCount
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      allowToComment
      commentCount
      shareCount
      viewCount
      bookmarkCount
      rating {
        one
        two
        three
        four
        five
      }
      company {
        name
      }
      userId
      user {
          id
          name
          picture
        }
      companyId
      productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
        items {
          id
          title
          description
          price
          list_price
          productMediaList(sortDirection: ASC, limit: $mediaLimit) {
            items {
              createdAt
              file_type
              id
              key
              owner
              product_variant_id
              updatedAt
            }
          }
        }
      }
    }
    nextToken
    __typename
  }
}
` ;

export const mostViewedProductListAfterAuth = /* GraphQL */ `query MostViewedProductList(
  $type: ProductType!
  $viewCount: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $userId: ModelIDKeyConditionInput
  $limit: Int
  $nextToken: String
  $productVariantFilter: ModelProductVariantFilterInput
  $productVariantLimit: Int
  # $mediaLimit: Int
  $productVariantNextToken: String
) {
  mostViewedProductList(
    type: $type
    viewCount: $viewCount
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      allowToComment
      commentCount
      shareCount
      viewCount
      bookmarkCount
      rating {
        one
        two
        three
        four
        five
      }
      bookmarks (userId: $userId) {
        items {
          id
          bookmarkState
        }
      }
      likes (userId: $userId) {
        items {
          id
          likeState
        }
      }
      company {
        name
      }
      userId
      user {
          id
          name
          picture
        }
      companyId
      productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
        items {
          id
          title
          description
          price
          list_price
          productMediaList(sortDirection: ASC) {
            items {
              createdAt
              file_type
              id
              key
              position
              product_variant_id
              updatedAt
            }
          }
        }
      }
    }
    nextToken
    __typename
  }
}
` ;

export const viewsByTypeAndViewCountBeforeAuthCustom = /* GraphQL */ `query ViewsByTypeAndViewCount(
  $type: ViewProductType!
  $viewCount: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
  $productVariantFilter: ModelProductVariantFilterInput
  $productVariantLimit: Int
  $mediaLimit: Int
  $productVariantNextToken: String
) {
  viewsByTypeAndViewCount(
    type: $type
    viewCount: $viewCount
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      product {
        id
        type
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        rating {
          one
          two
          three
          four
          five
        }
        company {
          name
        }
        userId
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            id
            title
            description
            price
            list_price
            productMediaList(limit: $mediaLimit) {
              items {
                createdAt
                file_type
                id
                key
                owner
                product_variant_id
                updatedAt
              }
            }
          }
        }
      }
      feedId
      postId
      post{
        id
        title
        description
        userId
        user {
          id
          name
          picture
        }
        mediaList (sortDirection: ASC) {
          items {
            id
            key
            file_type
          }
        }
      }
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` ;

export const viewsByTypeAndViewCountCustomAfterAuth = /* GraphQL */ `query ViewsByTypeAndViewCount(
  $type: ViewProductType!
  $viewCount: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelViewsFilterInput
  $limit: Int
  $nextToken: String
  $userId: ModelIDKeyConditionInput
  $productVariantFilter: ModelProductVariantFilterInput
  $productVariantLimit: Int
  $mediaLimit: Int
  $productVariantNextToken: String
) {
  viewsByTypeAndViewCount(
    type: $type
    viewCount: $viewCount
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productId
      product {
        id
        type
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        rating {
          one
          two
          three
          four
          five
        }
        bookmarks (userId: $userId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $userId) {
          items {
            id
            likeState
          }
        }
        company {
          name
        }
        userId
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            id
            title
            description
            price
            list_price
            productMediaList(limit: $mediaLimit) {
              items {
                createdAt
                file_type
                id
                key
                owner
                product_variant_id
                updatedAt
              }
            }
          }
        }
      }
      feedId
      postId
      post{
        id
        title
        description
        userId
        user {
          id
          name
          picture
        }
        mediaList (sortDirection: ASC) {
          items {
            id
            key
            file_type
          }
        }
      }
      viewCount
      type
      userId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` ;

export const ProductFeedListByType = /* GraphQL */ `
  query ProductFeedListByType (
    $type: ProductType!
    $createdAtStatus: ModelProductByProductTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $productVariantFilter: ModelProductVariantFilterInput
    $productVariantLimit: Int
    $productVariantNextToken: String
  ) {
    productListByType(
      type: $type
      createdAtStatus: $createdAtStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        title
        type
        updatedAt
        createdAt
        userId
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        company {
          name
        }
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            createdAt
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            productCondition
            productId
            productProductVariantsId
            productUsed {
              day
              month
              year
            }
            quantity
            status
            title
            updatedAt
            videoLink
            productMediaList(sortDirection: ASC) {
              items {
                id
                file_type
                key
                position
              }
            }
          }
        }
      }
      nextToken
    }
}`;

export const ProductFeedListByTypeAfterAuth = /* GraphQL */ `
  query ProductFeedListByType (
    $type: ProductType!
    $createdAtStatus: ModelProductByProductTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $productVariantFilter: ModelProductVariantFilterInput
    $productVariantLimit: Int
    $productVariantNextToken: String
    $userId: ModelIDKeyConditionInput
  ) {
    productListByType(
      type: $type
      createdAtStatus: $createdAtStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        title
        type
        updatedAt
        createdAt
        userId
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        company {
          id
          picture
          name
        }
        bookmarks (userId: $userId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $userId) {
          items {
            id
            userId
            likeState
          }
        }
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            createdAt
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            productCondition
            productId
            productProductVariantsId
            productUsed {
              day
              month
              year
            }
            quantity
            status
            title
            updatedAt
            videoLink
            productMediaList(sortDirection: ASC) {
              items {
                id
                key
                file_type
                position
              }
            }
          }
        }
      }
      nextToken
    }
}`;

export const productListByFilterTypeAfterAuth = /* GraphQL */ `query ProductListByFilterType(
  $filterType: String!
  $createdAtStatus: ModelProductByProductFilterTypeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
  $productVariantFilter: ModelProductVariantFilterInput
  $productVariantLimit: Int
  $productVariantNextToken: String
  $userId: ModelIDKeyConditionInput
) {
  productListByFilterType(
    filterType: $filterType
    createdAtStatus: $createdAtStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
        id
        description
        title
        type
        updatedAt
        createdAt
        userId
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        company {
          id
          picture
          name
        }
        bookmarks (userId: $userId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $userId) {
          items {
            id
            userId
            likeState
          }
        }
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            createdAt
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            productCondition
            productId
            productProductVariantsId
            productUsed {
              day
              month
              year
            }
            quantity
            status
            title
            updatedAt
            videoLink
            productMediaList(sortDirection: ASC) {
              items {
                id
                key
                file_type
                position
              }
            }
          }
        }
      }
      nextToken
    }
}
`;
export const ProductListByFilterTypeBeforeAuth = /* GraphQL */ `
  query ProductListByFilterType(
    $filterType: String!
    $createdAtStatus: ModelProductByProductFilterTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $productVariantFilter: ModelProductVariantFilterInput
    $productVariantLimit: Int
    $productVariantNextToken: String
  ) {
    productListByFilterType(
      filterType: $filterType
      createdAtStatus: $createdAtStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        title
        type
        updatedAt
        createdAt
        userId
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        company {
          id
          picture
          name
        }
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            createdAt
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            productCondition
            productId
            productProductVariantsId
            productUsed {
              day
              month
              year
            }
            quantity
            status
            title
            updatedAt
            videoLink
            productMediaList(sortDirection: ASC) {
              items {
                id
                file_type
                key
                position
              }
            }
          }
        }
      }
      nextToken
    }
}`;

export const ProductsByUserIdAndTypeCustom = /* GraphQL */ `
  query productsByUserIdAndType (
    $userId: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $productVariantFilter: ModelProductVariantFilterInput
    $productVariantLimit: Int
    $myUserId: ModelIDKeyConditionInput
    $productVariantNextToken: String
  )  {
    productsByUserIdAndType(
      userId: $userId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        title
        type
        status
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        bookmarks (userId: $myUserId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $myUserId) {
          items {
            id
            userId
            likeState
          }
        }
        updatedAt
        createdAt
        userId
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            createdAt
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            productCondition
            productId
            productProductVariantsId
            productUsed {
              day
              month
              year
            }
            quantity
            status
            title
            updatedAt
            videoLink
            productMediaList(sortDirection: ASC) {
              items {
                createdAt
                file_type
                id
                key
                owner
                product_variant_id
                updatedAt
              }
            }
          }
        }
      }
      nextToken
    }
}`;

export const productsByCompanyIdAndIdCustom = /* GraphQL */ `query ProductsByCompanyIdAndId(
  $companyId: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
  $productVariantFilter: ModelProductVariantFilterInput
  $productVariantLimit: Int
  $myUserId: ModelIDKeyConditionInput
  $productVariantNextToken: String
) {
  productsByCompanyIdAndId(
    companyId: $companyId
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
      items {
        id
        description
        title
        type
        status
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        bookmarks (userId: $myUserId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $myUserId) {
          items {
            id
            userId
            likeState
          }
        }
        updatedAt
        createdAt
        userId
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            createdAt
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            productCondition
            productId
            productProductVariantsId
            productUsed {
              day
              month
              year
            }
            quantity
            status
            title
            updatedAt
            videoLink
            productMediaList(sortDirection: ASC) {
              items {
                createdAt
                file_type
                id
                key
                owner
                product_variant_id
                updatedAt
              }
            }
          }
        }
      }
      nextToken
    }
}`;

export const productListByCompanyIdAndTypeCustom = /* GraphQL */ `query ProductListByCompanyIdAndType(
  $companyId: ID!
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
  $productVariantFilter: ModelProductVariantFilterInput
  $productVariantLimit: Int
  $myUserId: ModelIDKeyConditionInput
  $productVariantNextToken: String
) {
  productListByCompanyIdAndType(
    companyId: $companyId
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
        id
        description
        title
        type
        status
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        bookmarks (userId: $myUserId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $myUserId) {
          items {
            id
            userId
            likeState
          }
        }
        updatedAt
        createdAt
        userId
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            createdAt
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            productCondition
            productId
            productProductVariantsId
            productUsed {
              day
              month
              year
            }
            quantity
            status
            title
            updatedAt
            videoLink
            productMediaList(sortDirection: ASC) {
              items {
                createdAt
                file_type
                id
                key
                owner
                product_variant_id
                updatedAt
              }
            }
          }
        }
      }
    nextToken
    __typename
  }
}
` 
export const productsByUserIdAndStatusCustom = /* GraphQL */ `query ProductsByUserIdAndStatus(
  $userId: ID!
  $myUserId: ModelIDKeyConditionInput
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
  $productVariantFilter: ModelProductVariantFilterInput
  $productVariantLimit: Int
) {
  productsByUserIdAndStatus(
    userId: $userId
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
        id
        description
        title
        type
        status
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        bookmarks (userId: $myUserId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $myUserId) {
          items {
            id
            userId
            likeState
          }
        }
        updatedAt
        createdAt
        userId
        user {
            id
            name
            picture
          }
        companyId
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit) {
          items {
            attribute
            createdAt
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            productCondition
            productId
            productProductVariantsId
            productUsed {
              day
              month
              year
            }
            quantity
            status
            title
            updatedAt
            videoLink
            productMediaList(sortDirection: ASC) {
              items {
                createdAt
                file_type
                id
                key
                owner
                product_variant_id
                updatedAt
              }
            }
          }
        }
      }
      nextToken
    }
}
`;

export const getProductVariant = /* GraphQL */ `
  query GetProductVariant($id: ID!) {
    getProductVariant(id: $id) {
      id
      productId
      product {
        id
        userId
        type
        rating {
          one
          two
          three
          four
          five
        }
        totalReviewCount
        total_sold
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        viewCount
        brand
        user {
          id
          email
          picture
          name
          mobile_no
        }
        companyId
        company {
          id
          name
          email
          picture
        }
        categories {
          items {
            id
            category {
              id
              name
              type
              parent_categoryId
            }
          }
        }
        createdAt
        updatedAt
        owner
      }
      title
      description
      quantity
      price
      list_price
      deliveryMethod
      designStyle
      designPropertyType
      productCondition
      productUsed {
        day
        month
        year
      }
      location {
        lat
        lng
        address
        pincode
      }
      status
      productMediaList(sortDirection: ASC) {
        items {
          id
          file_type
          key
          product_variant_id
          position
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getProductVariantAfterAuth = /* GraphQL */ `
  query GetProductVariant($id: ID!, $userId: ModelIDKeyConditionInput) {
    getProductVariant(id: $id) {
      id
      productId
      product {
        id
        userId
        type
        rating {
          five
          one
          three
          two
          four
        }
        totalReviewCount
        total_sold
        likeCount
        allowToComment
        commentCount
        shareCount
        bookmarkCount
        bookmarks (userId: $userId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $userId) {
          items {
            id
            userId
            likeState
          }
        }
        viewCount
        brand
        user {
          id
          email
          picture
          name
          mobile_no
        }
        companyId
        company {
          id
          type
          name
          email
          picture
        }
        categories {
          items {
            id
            category {
              id
              name
              type
              parent_categoryId
            }
          }
        }
        createdAt
        updatedAt
        owner
      }
      title
      description
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      productUsed {
        day
        month
        year
      }
      location {
        lat
        lng
        address
        pincode
      }
      price
      list_price
      description
      status
      productMediaList(sortDirection: ASC) {
        items {
          id
          file_type
          key
          product_variant_id
          position
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const getProductVariantAfterAuthForEdit = /* GraphQL */ `
  query GetProductVariant($id: ID!) {
    getProductVariant(id: $id) {
      id
      productId
      product {
        id
        userId
        type
        companyId
        categories {
          items {
            id
            categoryId
          }
        }
      }
      title
      description
      quantity
      deliveryMethod
      productCondition
      productUsed {
        day
        month
        year
      }
      location {
        lat
        lng
        address
        pincode
      }
      status
      productMediaList(sortDirection: ASC) {
        items {
          id
          file_type
          key
          product_variant_id
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const EstoreProductList = /* GraphQL */ `
 query ProductFeedListByType (
    $type: ProductType!
    $createdAtStatus: ModelProductByProductTypeCompositeKeyConditionInput
    $filter: ModelProductFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
    $productVariantFilter: ModelProductVariantFilterInput
    $productVariantLimit: Int
    $productVariantNextToken: String
  )  {
    productListByType(
      type: $type
      createdAtStatus: $createdAtStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        title
        type
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            quantity
            status
            title
            productMediaList(limit: 1,sortDirection: ASC) {
              items {
                file_type
                id
                key
              }
            }
          }
        }
      }
      nextToken
    }
}`;

export const EstoreProductDetailBeforeAuth = /* GraphQL */ `
 query GetProductVariant($id: ID!) {
    getProductVariant(id: $id) {
      id
      title
      attribute
      description
      quantity
      deliveryMethod
      price
      list_price
      productId
      add_ons {
        id
        title
        description
        imageKey
        price
        list_price
      }
      location {
        lat
        lng
        address
        pincode
      }
      status
      productMediaList(sortDirection: ASC) {
        items {
          id
          position
          file_type
          key
        }
      }
      product {
        id
        userId
        type
        rating {
          one
          two
          three
          four
          five
        }
        attributes {
          name
          options
        }
        user {
          id
          email
          name
          mobile_no
        }
        companyId
        company {
          id
          uen
          type
          name
          email
          picture
        }
        categories {
          items {
            id
            category {
              id
              name
              type
              parent_categoryId
            }
          }
        }      
      }
    }
}`;
export const EstoreProductDetailAfterAuth = /* GraphQL */ `
query GetProductVariant($id: ID!, $userId: ModelIDKeyConditionInput) {
   getProductVariant(id: $id) {
     id
     title
     attribute
     description
     quantity
     deliveryMethod
     price
     list_price
     productId
     add_ons {
       id
       title
       description
       imageKey
       price
       list_price
     }
     location {
       lat
       lng
       address
       pincode
     }
     status
     productMediaList(sortDirection: ASC) {
       items {
         id
         position
         file_type
         key
       }
     }
     product {
       id
       userId
       type
       likeCount
       likes (userId: $userId) {
        items {
          id
          likeState
        }
       }
       rating {
         one
         two
         three
         four
         five
       }
       attributes {
         name
         options
       }
       user {
         id
         email
         name
         mobile_no
       }
       companyId
       company {
         id
         uen
         type
         name
         email
         picture
       }
       categories {
         items {
           id
           category {
             id
             name
             type
             parent_categoryId
           }
         }
       }      
     }
   }
}`;

export const EstoreProductDetailByAttributeAndProductId = /* GraphQL */ `
 query ProductVariantsByProductIdAndId(
    $productId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductVariantFilterInput
    $limit: Int
    $nextToken: String
  )
  {
    productVariantsByProductIdAndId(
      productId: $productId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
        items{
          id
      title
      attribute
      description
      quantity
      deliveryMethod
      price
      list_price
      add_ons {
        id
        title
        description
        imageKey
        price
        list_price
      }
      location {
        lat
        lng
        address
        pincode
      }
      status
      productMediaList(sortDirection: ASC) {
        items {
          id
          position
          file_type
          key
        }
      }
      product {
        id
        userId
        attributes {
          name
          options
        }
        user {
          id
          email
          name
          mobile_no
        }
        companyId
        company {
          id
          uen
          type
          name
          email
          picture
        }
        categories {
          items {
            id
            category {
              id
              name
              type
              parent_categoryId
            }
          }
        }
      }
    }
  }
}`;

export const EstoreGetCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      type
      description
      picture
      parent_categoryId
      subCategories {
        items {
          id
          name
          type
          description
          picture
          parent_categoryId
          subCategories {
            items {
              id
              name
              type
              description
              picture
              parent_categoryId    
            }
          }
        }
        nextToken
      }
      productCategories {
        items {
          id
          categoryId
          productId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const FeatureProductListCustom = /* GraphQL */ `
 query ProductFeatureList (
    $feature: Int!
    $createdAtStatus: ModelProductByProductFeatureCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $productVariantFilter: ModelProductVariantFilterInput
    $productVariantLimit: Int
    $productVariantNextToken: String
  )  {
    productFeatureList(
      feature: $feature
      createdAtStatus: $createdAtStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        title
        type
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            quantity
            status
            title
            productMediaList(limit: 1,sortDirection: ASC) {
              items {
                file_type
                id
                key
              }
            }
          }
        }
      }
      nextToken
    }
}`;

export const ProductByCategory = /* GraphQL */ `
  query ProductCategoriesByCategoryIdAndId(
    $categoryId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productCategoriesByCategoryIdAndId(
      categoryId: $categoryId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        product {
          id
          productVariants(limit: 1) {
            items{
              id
              title
              description
              price
              list_price
              productMediaList (limit: 1) {
                items {
                  id
                  key
                  file_type
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const EstoreProductListByCategory = /* GraphQL */ `
 query ProductFeedListByType (
    $type: ProductType!
    $filter: ModelProductFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
    $productVariantFilter: ModelProductVariantFilterInput
    $productVariantLimit: Int
    $productVariantNextToken: String
  )  {
    productListByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        title
        type
        companyId
        company {
          id
          name
          registration_name
        }
        categories {
          items {
            id
            categoryId
          }
        }
        productVariants(filter: $productVariantFilter, limit: $productVariantLimit, nextToken: $productVariantNextToken) {
          items {
            attribute
            description
            id
            list_price
            location {
              address
              lat
              lng
              pincode
            }
            price
            quantity
            status
            title
            productMediaList(limit: 1,sortDirection: ASC) {
              items {
                file_type
                id
                key
              }
            }
          }
        }
      }
    }
}`;

/********* Estore Cart ************* */
export const createEstoreCart = /* GraphQL */ `
mutation CreateEstoreCart(
  $input: CreateEstoreCartInput!
  $condition: ModelEstoreCartConditionInput
) {
  createEstoreCart(input: $input, condition: $condition) {
    id
    quantity
    userId
    companyId
    productVariantId
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
    }
    createdAt
    updatedAt
  }
}
`;
export const deleteEstoreCart = /* GraphQL */ `
  mutation DeleteEstoreCart(
    $input: DeleteEstoreCartInput!
    $condition: ModelEstoreCartConditionInput
  ) {
    deleteEstoreCart(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateEstoreCart = /* GraphQL */ `
  mutation UpdateEstoreCart(
    $input: UpdateEstoreCartInput!
    $condition: ModelEstoreCartConditionInput
  ) {
    updateEstoreCart(input: $input, condition: $condition) {
      id
      quantity
      userId
      companyId
      productVariantId
      add_ons {
        id
        title
        description
        imageKey
        price
        list_price
      }
      createdAt
      updatedAt
    }
  }
`;
export const estoreCartsByUserIdAndCreatedAtCustom = /* GraphQL */ `
  query EstoreCartsByUserIdAndCreatedAt(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEstoreCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    estoreCartsByUserIdAndCreatedAt(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quantity
        userId
        productVariantId
        companyId
        company {
          id
          name
          picture
          registration_name
        }
        productVariant {
          id
          productId
          product {
            id
            brand
          }
          title
          description
          attribute
          price
          list_price
          quantity
          productUsed {
            day
            month
            year
          }
          location {
            lat
            lng
            address
            pincode
          }
          status
          productMediaList (limit: 1) {
            items {
              id
              key
              file_type
            }
          }
        }
        add_ons {
          id
          title
          description
          imageKey
          price
          list_price
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const estoreCartListByStatusCustom = /* GraphQL */ `
  query estoreCartListByStatus(
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEstoreCartFilterInput
    $limit: Int
    $nextToken: String
    $status: Status!
  ) {
    estoreCartListByStatus(
      status: $status,
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quantity
        userId
        productVariantId
        productVariant {
          id
          productId
          product {
            id
            brand
          }
          title
          description
          attribute
          price
          list_price
          quantity
          productUsed {
            day
            month
            year
          }
          location {
            lat
            lng
            address
            pincode
          }
          status
          productMediaList (limit: 1) {
            items {
              id
              key
              file_type
            }
          }
        }
        add_ons {
          id
          title
          description
          imageKey
          price
          list_price
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
/********* Estore Cart END ************* */

/********* Estore Order Start ************* */
export const EstoreOrdersByUserIdAndIdCustom = /* GraphQL */ `
  query EstoreOrdersByUserIdAndId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEstoreOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    estoreOrdersByUserIdAndId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address_one
        address_two
        city
        country
        createdAt
        delivery_fee
        discount
        id
        mobile_no
        orderId
        order_price
        pincode
        sell_price
        state
        status
        transaction_id
        createdAt
        estoreOrderProducts {
          items {
            companyId
            attribute
            description
            createdAt
            estore_order_id
            id
            list_price
            price
            productVariantId
            quantity
            status
            title
            add_ons {
              id
              title
              description
              imageKey
              price
              list_price
            }
            productVariant {
              id
              productMediaList(limit:1) {
                items {
                  key
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const estoreOrderProductsByUserIdAndCreatedAtCustom = /* GraphQL */ `
  query estoreOrderProductsByUserIdAndCreatedAt(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEstoreOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    estoreOrderProductsByUserIdAndCreatedAt(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        price
        list_price
        quantity
        userId
        companyId
        company {
          id
          uen
          type
          name
          picture
          registration_name
        }
        productVariantId
        productVariant {
          id
          productId
          title
          description
          product {
            attributes {
              name
              options
            }
          }
          productMediaList(limit:1) {
            items {
              id
              key
            }
          }
        }
        attribute
        add_ons {
          id
          title
          description
          imageKey
          price
          list_price
        }
        status
        estore_order_id
        estoreOrder {
          id
          orderId
          transaction_id
          order_price
          discount
          sell_price
          mobile_no
          address_one
          address_two
          pincode
          city
          state
          country
          offerCodeId
          userId
          delivery_fee
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const estoreOrderProductsByCompanyIdAndIdCustom = /* GraphQL */ `
  query EstoreOrderProductsByCompanyIdAndId(
    $companyId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEstoreOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    estoreOrderProductsByCompanyIdAndId(
      companyId: $companyId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    )
    {
      items {
        id
        title
        description
        price
        list_price
        quantity
        userId
        companyId
        company {
          id
          uen
          type
          name
          picture
          registration_name
        }
        productVariantId
        productVariant {
          id
          productId
          title
          description
          productMediaList(limit:1) {
            items {
              id
              key
            }
          }
        }
        attribute
        add_ons {
          id
          title
          description
          imageKey
          price
          list_price
        }
        status
        estore_order_id
        estoreOrder {
          id
          orderId
          transaction_id
          order_price
          discount
          sell_price
          mobile_no
          address_one
          address_two
          pincode
          city
          state
          country
          offerCodeId
          userId
          delivery_fee
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const estoreOrderDetailCustom = /* GraphQL */ `
  query GetEstoreOrders($id: ID!, $userId: ID) {
    getEstoreOrders(id: $id) {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      name
      user {
        name
        mobile_no
      }
      delivery_fee
      status
      statusDescription
      estoreOrderProducts {
        items {
          id
          title
          deliveryStatus
          invoice
          description
          price
          list_price
          quantity
          companyId
          company {
            id
            name
            email
            picture
            companyUserList(filter: { role: { eq: Admin } }) {
              items {
                userId
              }
            }
          }
          productVariantId
          attribute
          status
          statusHistory
          statusDescription
          createdAt
          updatedAt
          productReviews(userId: {eq: $userId}) {
            items {
              id
              attribute
              comment
              stars
            }
          }
          productVariant {
            product {
              attributes {
                name,
                options
              }
            }
          }
          productMediaList(sortDirection: ASC, limit: 1) {
            items {
              id
              key
              file_type
            }
          }
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const estoreOrdersByUserIdAndCreatedAtCustom = /* GraphQL */ `query EstoreOrdersByUserIdAndCreatedAt(
  $userId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstoreOrdersFilterInput
  $limit: Int
  $nextToken: String
) {
  estoreOrdersByUserIdAndCreatedAt(
    userId: $userId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      statusDescription
      estoreOrderProducts {
          items {
          id
          title
          description
          price
          list_price
          quantity
          userId
          companyId
          company {
            id
            uen
            type
            picture
            name
            registration_name
          }
          productVariantId
          productVariant {
            id
            productId
            title
            description
            product {
              attributes {
                name
                options
              }
            }
            productMediaList(limit:1) {
              items {
                id
                key
              }
            }
          }
          attribute
          add_ons {
            id
            title
            description
            imageKey
            price
            list_price
          }
          status
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
    nextToken
    __typename
  }
}
`

/** Reviews section start */
export const getProductReviewCustom = /* GraphQL */ `
  query GetProductReview($id: ID!) {
    getProductReview(id: $id) {
      id
      stars
      comment
      attribute
      userId
      productId
      bookingId
      booking {
        id
        companyId
        productVariantId
        productVariant {
          id
          productId
          title
          description
          attribute
          productMediaList (limit: 1) {
            items {
              key
              file_type
            }
          }
          product {
            company {
              id
              uen
              type
              name
              email
              registration_name
              picture
              status
            }
          }
        }
      }
      estoreOrderProductId
      estoreOrderProduct {
        id
        title
        description
        price
        list_price
        quantity
        userId
        companyId
        productVariantId
        productVariant {
          id
          productId
          title
          description
          attribute
          productMediaList (limit: 1) {
            items {
              key
              file_type
            }
          }
          product {
            company {
              id
              uen
              type
              name
              email
              registration_name
              picture
              status
            }
          }
        }
        attribute
        status
        statusDescription
        estore_order_id
      }
      reviewMediaList (sortDirection: ASC) {
        items {
          id
          file_type
          key
          reviewId
        }
      }
    }
  }
`;

export const productReviewsByProductIdAndCreatedAtCustom = /* GraphQL */ `
  query ProductReviewsByProductIdAndCreatedAt(
    $productId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productReviewsByProductIdAndCreatedAt(
      productId: $productId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stars
        comment
        attribute
        userId
        user {
          id
          username
          email
          mobile_no
          name
          gender
          country
          picture
        }
        productId
        estoreOrderProductId
        reviewMediaList (sortDirection: ASC) {
          items {
            id
            key
            file_type
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      __typename
    }
  }
`;

/** Review Section end */
/**Chat */
export const chatParticipantsByUserIdAndIsActiveCustom = /* GraphQL */ `
  query ChatParticipantsByUserIdAndIsActive(
    $userId: ID!
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatParticipantsFilterInput
    $limit: Int
    $nextToken: String
    $chatMessagesLimit: Int!
    $nextToken: String
  ) {
    chatParticipantsByUserIdAndIsActive(
      userId: $userId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatId
        chat {
          id
          name
          group
          createdAt
          deletedBy
          chatMessageUnreadCount(userId: {eq: $userId}) {
            items {
              id
              userId
              unreadCount
              createdAt
            }
          }
          participants {
            items {
              id
              userId
              isActive
              user {
                name
                email
                picture
              }
            }
          }
          chatMessages(limit: $chatMessagesLimit, sortDirection: DESC) {
            items {
              id
              message
              attachment
              attachment_type
              createdAt
            }
          }
        }
      }
    }
  }
`;
export const getChatCustom = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      name
      group
      image
      deletedBy
      participants {
        items {
          id
          userId
          isActive
          user {
            name
            email
            picture
          }
        }
      }
      chatMessages(limit: 1, sortDirection: DESC) {
        items {
          id
          message
          attachment
          attachment_type
          chatId
          userId
          createdAt
        }
      }
      companyId
      company {
        id
        name
        registration_name
        picture
      }
      createdAt
      updatedAt
    }
  }
`;

export const getBookingCustom = /* GraphQL */ `
  query GetBooking($id: ID!, $userId: ModelIDKeyConditionInput) {
    getBooking(id: $id) {
      id
      type
      quantity
      productReviews (userId: $userId) {
        items {
          id
          attribute
          bookingId
          comment
          stars
        }
      }
      productVariantId
      productVariant {
        id
        productId
        title
        description
        attribute
        price
        list_price
        quantity
        deliveryMethod
        productCondition
        videoLink
        status
        sku
        createdAt
        updatedAt
        productMediaList(sortDirection: ASC, limit: 1) {
          items {
            id
            file_type
            key
          }
        }
      }
      userId
      user {
        id
        email
        mobile_no
        name
        picture
      }
      fulfillerId
      fulfiller {
        id
        email
        mobile_no
        name
        picture
      }
      status
      createdAt
      updatedAt
    }
  }
`;

export const bookingListByTypeCustom = /* GraphQL */ `
  query BookingListByType(
    $type: ProductType!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingListByType(
      type: $type
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        quantity
        productReviews (userId: $userId) {
          items {
            id
            attribute
            bookingId
            comment
            stars
          }
        }
        productVariantId
        productVariant {
          id
          productId
          title
          description
          attribute
          price
          list_price
          quantity
          deliveryMethod
          productCondition
          videoLink
          status
          sku
          createdAt
          updatedAt
          productMediaList(sortDirection: ASC, limit: 1) {
            items {
              id
              file_type
              key
            }
          }
        }
        userId
        user {
          id
          email
          mobile_no
          name
          picture
        }
        fulfillerId
        fulfiller {
          id
          email
          mobile_no
          name
          picture
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const bookingsByFulfillerIdAndTypeCustom = /* GraphQL */ `
  query BookingsByFulfillerIdAndType(
    $fulfillerId: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByFulfillerIdAndType(
      fulfillerId: $fulfillerId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        quantity
        productVariantId
        productVariant {
          id
          productId
          title
          description
          attribute
          price
          list_price
          quantity
          deliveryMethod
          productCondition
          videoLink
          status
          sku
          createdAt
          updatedAt
          productMediaList(sortDirection: ASC, limit: 1) {
            items {
              id
              file_type
              key
            }
          }
        }
        productReviews {
          items {
            id
            attribute
            bookingId
            comment
            stars
          }
        }
        userId
        user {
          id
          email
          mobile_no
          name
          picture
        }
        fulfillerId
        fulfiller {
          id
          email
          mobile_no
          name
          picture
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
/** Subscriptions */
export const onCreateChatMessagesCustom = /* GraphQL */ `
  subscription OnCreateChatMessages(
    $filter: ModelSubscriptionChatMessagesFilterInput
    $owner: String
  ) {
    onCreateChatMessages(filter: $filter, owner: $owner) {
      id
      message
      attachment
      attachment_type
      chatId
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserCustom = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
    }
  }
`;
export const chatMessagesByChatIdAndCreatedAtCustom = /* GraphQL */ `
  query ChatMessagesByChatIdAndCreatedAt(
    $chatId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatMessagesByChatIdAndCreatedAt(
      chatId: $chatId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        attachment
        attachment_type
        bookingId
        chatId
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
/** Feed */
export const FeedByStatusCustomPublic = /* GraphQL */ `
  query FeedByStatus(
    $status: Status!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    FeedByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        type
        likeCount
        commentCount
        shareCount
        bookmarkCount
        tags {
          items {
            id
            productVariant {
              id
              title
              product {
                type
              }
              price
              list_price
              description
              productMediaList(sortDirection: ASC, limit: 1) {
                items {
                  id
                  file_type
                  key
                }
              }
            }
          }
        }
        collaborations {
          items {
            id
            company {
              id
              name
              registration_name
              picture
            }
            merchant {
              id
              name
            }
          }
        }
        userId
        user {
          id
          name
          picture
        }
        mediaList (sortDirection: ASC) {
          items {
            id
            key
            file_type
          }
        }
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const FeedByStatusCustom = /* GraphQL */ `
  query FeedByStatus(
    $status: Status!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
    $userId: ModelIDKeyConditionInput
  ) {
    FeedByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        type
        likeCount
        commentCount
        shareCount
        bookmarkCount
        bookmarks (userId: $userId) {
          items {
            id
            bookmarkState
          }
        }
        likes (userId: $userId) {
          items {
            id
            likeState
          }
        }
        tags {
          items {
            id
            productVariant {
              id
              title
              product {
                type
              }
              description
              price
              list_price
              productMediaList(sortDirection: ASC, limit: 1) {
                items {
                  id
                  file_type
                  key
                }
              }
            }
          }
        }
        collaborations {
          items {
            id
            company {
              id
              name
              picture
              registration_name
            }
            merchant {
              id
              name
            }
          }
        }
        userId
        user {
          id
          name
          picture
        }
        mediaList (sortDirection: ASC) {
          items {
            id
            key
            file_type
          }
        }
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductCustom = /* GraphQL */ `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    totalReviewCount
    rating {
      one
      two
      three
      four
      five
    }
  }
}
` 
import React, { lazy, Suspense } from 'react';

const LazyPageActivityList = lazy(() => import('./PageActivityList'));

const PageActivityList = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageActivityList {...props} />
  </Suspense>
);

export default PageActivityList;

import React, { lazy, Suspense } from 'react';

const LazyPageEstoreMyOrder = lazy(() => import('./PageEstoreMyOrder'));

const PageEstoreMyOrder = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageEstoreMyOrder {...props} />
  </Suspense>
);

export default PageEstoreMyOrder;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateUser = /* GraphQL */ `subscription OnCreateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onCreateUser(filter: $filter, owner: $owner) {
    id
    username
    email
    mobile_no
    name
    gender
    country
    mobile_verified
    bio
    picture
    userAddressList {
      nextToken
      __typename
    }
    companyUser {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    userProductList {
      nextToken
      __typename
    }
    productReview {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    estoreOrderList {
      nextToken
      __typename
    }
    followers
    followings
    followersList {
      nextToken
      __typename
    }
    online
    fcmToken
    merchantFcmToken
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onUpdateUser(filter: $filter, owner: $owner) {
    id
    username
    email
    mobile_no
    name
    gender
    country
    mobile_verified
    bio
    picture
    userAddressList {
      nextToken
      __typename
    }
    companyUser {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    userProductList {
      nextToken
      __typename
    }
    productReview {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    estoreOrderList {
      nextToken
      __typename
    }
    followers
    followings
    followersList {
      nextToken
      __typename
    }
    online
    fcmToken
    merchantFcmToken
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onDeleteUser(filter: $filter, owner: $owner) {
    id
    username
    email
    mobile_no
    name
    gender
    country
    mobile_verified
    bio
    picture
    userAddressList {
      nextToken
      __typename
    }
    companyUser {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    userProductList {
      nextToken
      __typename
    }
    productReview {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    estoreOrderList {
      nextToken
      __typename
    }
    followers
    followings
    followersList {
      nextToken
      __typename
    }
    online
    fcmToken
    merchantFcmToken
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateUserAddress = /* GraphQL */ `subscription OnCreateUserAddress(
  $filter: ModelSubscriptionUserAddressFilterInput
  $owner: String
) {
  onCreateUserAddress(filter: $filter, owner: $owner) {
    id
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    default
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserAddressSubscriptionVariables,
  APITypes.OnCreateUserAddressSubscription
>;
export const onUpdateUserAddress = /* GraphQL */ `subscription OnUpdateUserAddress(
  $filter: ModelSubscriptionUserAddressFilterInput
  $owner: String
) {
  onUpdateUserAddress(filter: $filter, owner: $owner) {
    id
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    default
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserAddressSubscriptionVariables,
  APITypes.OnUpdateUserAddressSubscription
>;
export const onDeleteUserAddress = /* GraphQL */ `subscription OnDeleteUserAddress(
  $filter: ModelSubscriptionUserAddressFilterInput
  $owner: String
) {
  onDeleteUserAddress(filter: $filter, owner: $owner) {
    id
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    default
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserAddressSubscriptionVariables,
  APITypes.OnDeleteUserAddressSubscription
>;
export const onCreateCompany = /* GraphQL */ `subscription OnCreateCompany(
  $filter: ModelSubscriptionCompanyFilterInput
  $owner: String
) {
  onCreateCompany(filter: $filter, owner: $owner) {
    id
    ownerType
    uen
    gstNo
    type
    name
    email
    registration_name
    picture
    bio
    wlcmMsg
    address
    category
    status
    companyUserList {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    productList {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    chats {
      nextToken
      __typename
    }
    followers
    followersList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanySubscriptionVariables,
  APITypes.OnCreateCompanySubscription
>;
export const onUpdateCompany = /* GraphQL */ `subscription OnUpdateCompany(
  $filter: ModelSubscriptionCompanyFilterInput
  $owner: String
) {
  onUpdateCompany(filter: $filter, owner: $owner) {
    id
    ownerType
    uen
    gstNo
    type
    name
    email
    registration_name
    picture
    bio
    wlcmMsg
    address
    category
    status
    companyUserList {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    productList {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    chats {
      nextToken
      __typename
    }
    followers
    followersList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCompanySubscriptionVariables,
  APITypes.OnUpdateCompanySubscription
>;
export const onDeleteCompany = /* GraphQL */ `subscription OnDeleteCompany(
  $filter: ModelSubscriptionCompanyFilterInput
  $owner: String
) {
  onDeleteCompany(filter: $filter, owner: $owner) {
    id
    ownerType
    uen
    gstNo
    type
    name
    email
    registration_name
    picture
    bio
    wlcmMsg
    address
    category
    status
    companyUserList {
      nextToken
      __typename
    }
    companyReviews {
      nextToken
      __typename
    }
    productList {
      nextToken
      __typename
    }
    bookings {
      nextToken
      __typename
    }
    chats {
      nextToken
      __typename
    }
    followers
    followersList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanySubscriptionVariables,
  APITypes.OnDeleteCompanySubscription
>;
export const onCreateCompanyPrivateInfo = /* GraphQL */ `subscription OnCreateCompanyPrivateInfo(
  $filter: ModelSubscriptionCompanyPrivateInfoFilterInput
  $owner: String
) {
  onCreateCompanyPrivateInfo(filter: $filter, owner: $owner) {
    id
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    companyDocImage
    contactName
    contactEmail
    contactNo
    conatactDocImage {
      front
      back
      __typename
    }
    bankName
    bankCode
    beneficiaryName
    bankAccNo
    bankDocumnet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanyPrivateInfoSubscriptionVariables,
  APITypes.OnCreateCompanyPrivateInfoSubscription
>;
export const onUpdateCompanyPrivateInfo = /* GraphQL */ `subscription OnUpdateCompanyPrivateInfo(
  $filter: ModelSubscriptionCompanyPrivateInfoFilterInput
  $owner: String
) {
  onUpdateCompanyPrivateInfo(filter: $filter, owner: $owner) {
    id
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    companyDocImage
    contactName
    contactEmail
    contactNo
    conatactDocImage {
      front
      back
      __typename
    }
    bankName
    bankCode
    beneficiaryName
    bankAccNo
    bankDocumnet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCompanyPrivateInfoSubscriptionVariables,
  APITypes.OnUpdateCompanyPrivateInfoSubscription
>;
export const onDeleteCompanyPrivateInfo = /* GraphQL */ `subscription OnDeleteCompanyPrivateInfo(
  $filter: ModelSubscriptionCompanyPrivateInfoFilterInput
  $owner: String
) {
  onDeleteCompanyPrivateInfo(filter: $filter, owner: $owner) {
    id
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    companyDocImage
    contactName
    contactEmail
    contactNo
    conatactDocImage {
      front
      back
      __typename
    }
    bankName
    bankCode
    beneficiaryName
    bankAccNo
    bankDocumnet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanyPrivateInfoSubscriptionVariables,
  APITypes.OnDeleteCompanyPrivateInfoSubscription
>;
export const onCreateCompanyUser = /* GraphQL */ `subscription OnCreateCompanyUser(
  $filter: ModelSubscriptionCompanyUserFilterInput
  $owner: String
) {
  onCreateCompanyUser(filter: $filter, owner: $owner) {
    id
    role
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    modules {
      moduleName
      add
      edit
      delete
      read
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanyUserSubscriptionVariables,
  APITypes.OnCreateCompanyUserSubscription
>;
export const onUpdateCompanyUser = /* GraphQL */ `subscription OnUpdateCompanyUser(
  $filter: ModelSubscriptionCompanyUserFilterInput
  $owner: String
) {
  onUpdateCompanyUser(filter: $filter, owner: $owner) {
    id
    role
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    modules {
      moduleName
      add
      edit
      delete
      read
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCompanyUserSubscriptionVariables,
  APITypes.OnUpdateCompanyUserSubscription
>;
export const onDeleteCompanyUser = /* GraphQL */ `subscription OnDeleteCompanyUser(
  $filter: ModelSubscriptionCompanyUserFilterInput
  $owner: String
) {
  onDeleteCompanyUser(filter: $filter, owner: $owner) {
    id
    role
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    modules {
      moduleName
      add
      edit
      delete
      read
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanyUserSubscriptionVariables,
  APITypes.OnDeleteCompanyUserSubscription
>;
export const onCreateCompanyReview = /* GraphQL */ `subscription OnCreateCompanyReview(
  $filter: ModelSubscriptionCompanyReviewFilterInput
  $owner: String
) {
  onCreateCompanyReview(filter: $filter, owner: $owner) {
    id
    stars
    comment
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanyReviewSubscriptionVariables,
  APITypes.OnCreateCompanyReviewSubscription
>;
export const onUpdateCompanyReview = /* GraphQL */ `subscription OnUpdateCompanyReview(
  $filter: ModelSubscriptionCompanyReviewFilterInput
  $owner: String
) {
  onUpdateCompanyReview(filter: $filter, owner: $owner) {
    id
    stars
    comment
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCompanyReviewSubscriptionVariables,
  APITypes.OnUpdateCompanyReviewSubscription
>;
export const onDeleteCompanyReview = /* GraphQL */ `subscription OnDeleteCompanyReview(
  $filter: ModelSubscriptionCompanyReviewFilterInput
  $owner: String
) {
  onDeleteCompanyReview(filter: $filter, owner: $owner) {
    id
    stars
    comment
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanyReviewSubscriptionVariables,
  APITypes.OnDeleteCompanyReviewSubscription
>;
export const onCreateCategory = /* GraphQL */ `subscription OnCreateCategory(
  $filter: ModelSubscriptionCategoryFilterInput
  $owner: String
) {
  onCreateCategory(filter: $filter, owner: $owner) {
    id
    name
    type
    description
    picture
    parent_categoryId
    subCategories {
      nextToken
      __typename
    }
    productCategories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCategorySubscriptionVariables,
  APITypes.OnCreateCategorySubscription
>;
export const onUpdateCategory = /* GraphQL */ `subscription OnUpdateCategory(
  $filter: ModelSubscriptionCategoryFilterInput
  $owner: String
) {
  onUpdateCategory(filter: $filter, owner: $owner) {
    id
    name
    type
    description
    picture
    parent_categoryId
    subCategories {
      nextToken
      __typename
    }
    productCategories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCategorySubscriptionVariables,
  APITypes.OnUpdateCategorySubscription
>;
export const onDeleteCategory = /* GraphQL */ `subscription OnDeleteCategory(
  $filter: ModelSubscriptionCategoryFilterInput
  $owner: String
) {
  onDeleteCategory(filter: $filter, owner: $owner) {
    id
    name
    type
    description
    picture
    parent_categoryId
    subCategories {
      nextToken
      __typename
    }
    productCategories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCategorySubscriptionVariables,
  APITypes.OnDeleteCategorySubscription
>;
export const onCreateProduct = /* GraphQL */ `subscription OnCreateProduct(
  $filter: ModelSubscriptionProductFilterInput
  $owner: String
) {
  onCreateProduct(filter: $filter, owner: $owner) {
    id
    title
    description
    type
    filterType
    attributes {
      name
      options
      __typename
    }
    allowToComment
    feature
    brand
    total_sold
    rating {
      one
      two
      three
      four
      five
      __typename
    }
    likeCount
    viewCount
    commentCount
    shareCount
    bookmarkCount
    totalReviewCount
    orderCount
    productVariants {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    categories {
      nextToken
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    metaKeywords
    sku
    likes {
      nextToken
      __typename
    }
    bookmarks {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductSubscriptionVariables,
  APITypes.OnCreateProductSubscription
>;
export const onUpdateProduct = /* GraphQL */ `subscription OnUpdateProduct(
  $filter: ModelSubscriptionProductFilterInput
  $owner: String
) {
  onUpdateProduct(filter: $filter, owner: $owner) {
    id
    title
    description
    type
    filterType
    attributes {
      name
      options
      __typename
    }
    allowToComment
    feature
    brand
    total_sold
    rating {
      one
      two
      three
      four
      five
      __typename
    }
    likeCount
    viewCount
    commentCount
    shareCount
    bookmarkCount
    totalReviewCount
    orderCount
    productVariants {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    categories {
      nextToken
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    metaKeywords
    sku
    likes {
      nextToken
      __typename
    }
    bookmarks {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductSubscriptionVariables,
  APITypes.OnUpdateProductSubscription
>;
export const onDeleteProduct = /* GraphQL */ `subscription OnDeleteProduct(
  $filter: ModelSubscriptionProductFilterInput
  $owner: String
) {
  onDeleteProduct(filter: $filter, owner: $owner) {
    id
    title
    description
    type
    filterType
    attributes {
      name
      options
      __typename
    }
    allowToComment
    feature
    brand
    total_sold
    rating {
      one
      two
      three
      four
      five
      __typename
    }
    likeCount
    viewCount
    commentCount
    shareCount
    bookmarkCount
    totalReviewCount
    orderCount
    productVariants {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    categories {
      nextToken
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    metaKeywords
    sku
    likes {
      nextToken
      __typename
    }
    bookmarks {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductSubscriptionVariables,
  APITypes.OnDeleteProductSubscription
>;
export const onCreateFeed = /* GraphQL */ `subscription OnCreateFeed(
  $filter: ModelSubscriptionFeedFilterInput
  $owner: String
) {
  onCreateFeed(filter: $filter, owner: $owner) {
    id
    title
    description
    type
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    tags {
      nextToken
      __typename
    }
    collaborations {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFeedSubscriptionVariables,
  APITypes.OnCreateFeedSubscription
>;
export const onUpdateFeed = /* GraphQL */ `subscription OnUpdateFeed(
  $filter: ModelSubscriptionFeedFilterInput
  $owner: String
) {
  onUpdateFeed(filter: $filter, owner: $owner) {
    id
    title
    description
    type
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    tags {
      nextToken
      __typename
    }
    collaborations {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFeedSubscriptionVariables,
  APITypes.OnUpdateFeedSubscription
>;
export const onDeleteFeed = /* GraphQL */ `subscription OnDeleteFeed(
  $filter: ModelSubscriptionFeedFilterInput
  $owner: String
) {
  onDeleteFeed(filter: $filter, owner: $owner) {
    id
    title
    description
    type
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    tags {
      nextToken
      __typename
    }
    collaborations {
      nextToken
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFeedSubscriptionVariables,
  APITypes.OnDeleteFeedSubscription
>;
export const onCreateFeedCollaboration = /* GraphQL */ `subscription OnCreateFeedCollaboration(
  $filter: ModelSubscriptionFeedCollaborationFilterInput
  $owner: String
) {
  onCreateFeedCollaboration(filter: $filter, owner: $owner) {
    id
    type
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    merchantId
    merchant {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFeedCollaborationSubscriptionVariables,
  APITypes.OnCreateFeedCollaborationSubscription
>;
export const onUpdateFeedCollaboration = /* GraphQL */ `subscription OnUpdateFeedCollaboration(
  $filter: ModelSubscriptionFeedCollaborationFilterInput
  $owner: String
) {
  onUpdateFeedCollaboration(filter: $filter, owner: $owner) {
    id
    type
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    merchantId
    merchant {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFeedCollaborationSubscriptionVariables,
  APITypes.OnUpdateFeedCollaborationSubscription
>;
export const onDeleteFeedCollaboration = /* GraphQL */ `subscription OnDeleteFeedCollaboration(
  $filter: ModelSubscriptionFeedCollaborationFilterInput
  $owner: String
) {
  onDeleteFeedCollaboration(filter: $filter, owner: $owner) {
    id
    type
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    merchantId
    merchant {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFeedCollaborationSubscriptionVariables,
  APITypes.OnDeleteFeedCollaborationSubscription
>;
export const onCreateFeedTags = /* GraphQL */ `subscription OnCreateFeedTags(
  $filter: ModelSubscriptionFeedTagsFilterInput
  $owner: String
) {
  onCreateFeedTags(filter: $filter, owner: $owner) {
    id
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    companyId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFeedTagsSubscriptionVariables,
  APITypes.OnCreateFeedTagsSubscription
>;
export const onUpdateFeedTags = /* GraphQL */ `subscription OnUpdateFeedTags(
  $filter: ModelSubscriptionFeedTagsFilterInput
  $owner: String
) {
  onUpdateFeedTags(filter: $filter, owner: $owner) {
    id
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    companyId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFeedTagsSubscriptionVariables,
  APITypes.OnUpdateFeedTagsSubscription
>;
export const onDeleteFeedTags = /* GraphQL */ `subscription OnDeleteFeedTags(
  $filter: ModelSubscriptionFeedTagsFilterInput
  $owner: String
) {
  onDeleteFeedTags(filter: $filter, owner: $owner) {
    id
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    companyId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFeedTagsSubscriptionVariables,
  APITypes.OnDeleteFeedTagsSubscription
>;
export const onCreateFeedMedia = /* GraphQL */ `subscription OnCreateFeedMedia(
  $filter: ModelSubscriptionFeedMediaFilterInput
  $owner: String
) {
  onCreateFeedMedia(filter: $filter, owner: $owner) {
    id
    file_type
    key
    feedId
    Feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFeedMediaSubscriptionVariables,
  APITypes.OnCreateFeedMediaSubscription
>;
export const onUpdateFeedMedia = /* GraphQL */ `subscription OnUpdateFeedMedia(
  $filter: ModelSubscriptionFeedMediaFilterInput
  $owner: String
) {
  onUpdateFeedMedia(filter: $filter, owner: $owner) {
    id
    file_type
    key
    feedId
    Feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFeedMediaSubscriptionVariables,
  APITypes.OnUpdateFeedMediaSubscription
>;
export const onDeleteFeedMedia = /* GraphQL */ `subscription OnDeleteFeedMedia(
  $filter: ModelSubscriptionFeedMediaFilterInput
  $owner: String
) {
  onDeleteFeedMedia(filter: $filter, owner: $owner) {
    id
    file_type
    key
    feedId
    Feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFeedMediaSubscriptionVariables,
  APITypes.OnDeleteFeedMediaSubscription
>;
export const onCreateLike = /* GraphQL */ `subscription OnCreateLike(
  $filter: ModelSubscriptionLikeFilterInput
  $owner: String
) {
  onCreateLike(filter: $filter, owner: $owner) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    likeState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLikeSubscriptionVariables,
  APITypes.OnCreateLikeSubscription
>;
export const onUpdateLike = /* GraphQL */ `subscription OnUpdateLike(
  $filter: ModelSubscriptionLikeFilterInput
  $owner: String
) {
  onUpdateLike(filter: $filter, owner: $owner) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    likeState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLikeSubscriptionVariables,
  APITypes.OnUpdateLikeSubscription
>;
export const onDeleteLike = /* GraphQL */ `subscription OnDeleteLike(
  $filter: ModelSubscriptionLikeFilterInput
  $owner: String
) {
  onDeleteLike(filter: $filter, owner: $owner) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    likeState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLikeSubscriptionVariables,
  APITypes.OnDeleteLikeSubscription
>;
export const onCreateViews = /* GraphQL */ `subscription OnCreateViews(
  $filter: ModelSubscriptionViewsFilterInput
  $owner: String
) {
  onCreateViews(filter: $filter, owner: $owner) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    viewCount
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateViewsSubscriptionVariables,
  APITypes.OnCreateViewsSubscription
>;
export const onUpdateViews = /* GraphQL */ `subscription OnUpdateViews(
  $filter: ModelSubscriptionViewsFilterInput
  $owner: String
) {
  onUpdateViews(filter: $filter, owner: $owner) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    viewCount
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateViewsSubscriptionVariables,
  APITypes.OnUpdateViewsSubscription
>;
export const onDeleteViews = /* GraphQL */ `subscription OnDeleteViews(
  $filter: ModelSubscriptionViewsFilterInput
  $owner: String
) {
  onDeleteViews(filter: $filter, owner: $owner) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    viewCount
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteViewsSubscriptionVariables,
  APITypes.OnDeleteViewsSubscription
>;
export const onCreateProductBookmarks = /* GraphQL */ `subscription OnCreateProductBookmarks(
  $filter: ModelSubscriptionProductBookmarksFilterInput
  $owner: String
) {
  onCreateProductBookmarks(filter: $filter, owner: $owner) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    bookmarkState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductBookmarksSubscriptionVariables,
  APITypes.OnCreateProductBookmarksSubscription
>;
export const onUpdateProductBookmarks = /* GraphQL */ `subscription OnUpdateProductBookmarks(
  $filter: ModelSubscriptionProductBookmarksFilterInput
  $owner: String
) {
  onUpdateProductBookmarks(filter: $filter, owner: $owner) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    bookmarkState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductBookmarksSubscriptionVariables,
  APITypes.OnUpdateProductBookmarksSubscription
>;
export const onDeleteProductBookmarks = /* GraphQL */ `subscription OnDeleteProductBookmarks(
  $filter: ModelSubscriptionProductBookmarksFilterInput
  $owner: String
) {
  onDeleteProductBookmarks(filter: $filter, owner: $owner) {
    id
    type
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    propertyId
    property {
      id
      title
      type
      description
      price
      bed
      bath
      sqft
      psf
      listingId
      category
      userId
      companyId
      bookmarkCount
      likeCount
      viewCount
      unitFeatures
      facilities
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    bookmarkState
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductBookmarksSubscriptionVariables,
  APITypes.OnDeleteProductBookmarksSubscription
>;
export const onCreateComments = /* GraphQL */ `subscription OnCreateComments(
  $filter: ModelSubscriptionCommentsFilterInput
  $owner: String
) {
  onCreateComments(filter: $filter, owner: $owner) {
    id
    comment
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    parentCommentId
    commentReplyList {
      nextToken
      __typename
    }
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCommentsSubscriptionVariables,
  APITypes.OnCreateCommentsSubscription
>;
export const onUpdateComments = /* GraphQL */ `subscription OnUpdateComments(
  $filter: ModelSubscriptionCommentsFilterInput
  $owner: String
) {
  onUpdateComments(filter: $filter, owner: $owner) {
    id
    comment
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    parentCommentId
    commentReplyList {
      nextToken
      __typename
    }
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCommentsSubscriptionVariables,
  APITypes.OnUpdateCommentsSubscription
>;
export const onDeleteComments = /* GraphQL */ `subscription OnDeleteComments(
  $filter: ModelSubscriptionCommentsFilterInput
  $owner: String
) {
  onDeleteComments(filter: $filter, owner: $owner) {
    id
    comment
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    feedId
    feed {
      id
      title
      description
      type
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      userId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    post {
      id
      type
      userId
      title
      description
      likeCount
      commentCount
      shareCount
      viewCount
      bookmarkCount
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    parentCommentId
    commentReplyList {
      nextToken
      __typename
    }
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCommentsSubscriptionVariables,
  APITypes.OnDeleteCommentsSubscription
>;
export const onCreateProductVariant = /* GraphQL */ `subscription OnCreateProductVariant(
  $filter: ModelSubscriptionProductVariantFilterInput
  $owner: String
) {
  onCreateProductVariant(filter: $filter, owner: $owner) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    attribute
    price
    list_price
    quantity
    deliveryMethod
    productCondition
    productUsed {
      day
      month
      year
      __typename
    }
    designStyle
    designPropertyType
    orderCount
    videoLink
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    status
    productMediaList {
      nextToken
      __typename
    }
    sku
    defaultPosition
    createdAt
    updatedAt
    productProductVariantsId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductVariantSubscriptionVariables,
  APITypes.OnCreateProductVariantSubscription
>;
export const onUpdateProductVariant = /* GraphQL */ `subscription OnUpdateProductVariant(
  $filter: ModelSubscriptionProductVariantFilterInput
  $owner: String
) {
  onUpdateProductVariant(filter: $filter, owner: $owner) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    attribute
    price
    list_price
    quantity
    deliveryMethod
    productCondition
    productUsed {
      day
      month
      year
      __typename
    }
    designStyle
    designPropertyType
    orderCount
    videoLink
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    status
    productMediaList {
      nextToken
      __typename
    }
    sku
    defaultPosition
    createdAt
    updatedAt
    productProductVariantsId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductVariantSubscriptionVariables,
  APITypes.OnUpdateProductVariantSubscription
>;
export const onDeleteProductVariant = /* GraphQL */ `subscription OnDeleteProductVariant(
  $filter: ModelSubscriptionProductVariantFilterInput
  $owner: String
) {
  onDeleteProductVariant(filter: $filter, owner: $owner) {
    id
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    attribute
    price
    list_price
    quantity
    deliveryMethod
    productCondition
    productUsed {
      day
      month
      year
      __typename
    }
    designStyle
    designPropertyType
    orderCount
    videoLink
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    status
    productMediaList {
      nextToken
      __typename
    }
    sku
    defaultPosition
    createdAt
    updatedAt
    productProductVariantsId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductVariantSubscriptionVariables,
  APITypes.OnDeleteProductVariantSubscription
>;
export const onCreateProductMedia = /* GraphQL */ `subscription OnCreateProductMedia(
  $filter: ModelSubscriptionProductMediaFilterInput
  $owner: String
) {
  onCreateProductMedia(filter: $filter, owner: $owner) {
    id
    file_type
    key
    product_variant_id
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductMediaSubscriptionVariables,
  APITypes.OnCreateProductMediaSubscription
>;
export const onUpdateProductMedia = /* GraphQL */ `subscription OnUpdateProductMedia(
  $filter: ModelSubscriptionProductMediaFilterInput
  $owner: String
) {
  onUpdateProductMedia(filter: $filter, owner: $owner) {
    id
    file_type
    key
    product_variant_id
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductMediaSubscriptionVariables,
  APITypes.OnUpdateProductMediaSubscription
>;
export const onDeleteProductMedia = /* GraphQL */ `subscription OnDeleteProductMedia(
  $filter: ModelSubscriptionProductMediaFilterInput
  $owner: String
) {
  onDeleteProductMedia(filter: $filter, owner: $owner) {
    id
    file_type
    key
    product_variant_id
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    position
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductMediaSubscriptionVariables,
  APITypes.OnDeleteProductMediaSubscription
>;
export const onCreateProductCategories = /* GraphQL */ `subscription OnCreateProductCategories(
  $filter: ModelSubscriptionProductCategoriesFilterInput
  $owner: String
) {
  onCreateProductCategories(filter: $filter, owner: $owner) {
    id
    categoryId
    category {
      id
      name
      type
      description
      picture
      parent_categoryId
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductCategoriesSubscriptionVariables,
  APITypes.OnCreateProductCategoriesSubscription
>;
export const onUpdateProductCategories = /* GraphQL */ `subscription OnUpdateProductCategories(
  $filter: ModelSubscriptionProductCategoriesFilterInput
  $owner: String
) {
  onUpdateProductCategories(filter: $filter, owner: $owner) {
    id
    categoryId
    category {
      id
      name
      type
      description
      picture
      parent_categoryId
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductCategoriesSubscriptionVariables,
  APITypes.OnUpdateProductCategoriesSubscription
>;
export const onDeleteProductCategories = /* GraphQL */ `subscription OnDeleteProductCategories(
  $filter: ModelSubscriptionProductCategoriesFilterInput
  $owner: String
) {
  onDeleteProductCategories(filter: $filter, owner: $owner) {
    id
    categoryId
    category {
      id
      name
      type
      description
      picture
      parent_categoryId
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductCategoriesSubscriptionVariables,
  APITypes.OnDeleteProductCategoriesSubscription
>;
export const onCreateProductReview = /* GraphQL */ `subscription OnCreateProductReview(
  $filter: ModelSubscriptionProductReviewFilterInput
  $owner: String
) {
  onCreateProductReview(filter: $filter, owner: $owner) {
    id
    stars
    comment
    attribute
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    totalImagesCount
    estoreOrderProductId
    estoreOrderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    bookingId
    booking {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    reviewMediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductReviewSubscriptionVariables,
  APITypes.OnCreateProductReviewSubscription
>;
export const onUpdateProductReview = /* GraphQL */ `subscription OnUpdateProductReview(
  $filter: ModelSubscriptionProductReviewFilterInput
  $owner: String
) {
  onUpdateProductReview(filter: $filter, owner: $owner) {
    id
    stars
    comment
    attribute
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    totalImagesCount
    estoreOrderProductId
    estoreOrderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    bookingId
    booking {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    reviewMediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductReviewSubscriptionVariables,
  APITypes.OnUpdateProductReviewSubscription
>;
export const onDeleteProductReview = /* GraphQL */ `subscription OnDeleteProductReview(
  $filter: ModelSubscriptionProductReviewFilterInput
  $owner: String
) {
  onDeleteProductReview(filter: $filter, owner: $owner) {
    id
    stars
    comment
    attribute
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    totalImagesCount
    estoreOrderProductId
    estoreOrderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    bookingId
    booking {
      id
      type
      quantity
      productVariantId
      userId
      fulfillerId
      companyId
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    reviewMediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductReviewSubscriptionVariables,
  APITypes.OnDeleteProductReviewSubscription
>;
export const onCreateReviewMedia = /* GraphQL */ `subscription OnCreateReviewMedia(
  $filter: ModelSubscriptionReviewMediaFilterInput
  $owner: String
) {
  onCreateReviewMedia(filter: $filter, owner: $owner) {
    id
    file_type
    key
    reviewId
    productReview {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReviewMediaSubscriptionVariables,
  APITypes.OnCreateReviewMediaSubscription
>;
export const onUpdateReviewMedia = /* GraphQL */ `subscription OnUpdateReviewMedia(
  $filter: ModelSubscriptionReviewMediaFilterInput
  $owner: String
) {
  onUpdateReviewMedia(filter: $filter, owner: $owner) {
    id
    file_type
    key
    reviewId
    productReview {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReviewMediaSubscriptionVariables,
  APITypes.OnUpdateReviewMediaSubscription
>;
export const onDeleteReviewMedia = /* GraphQL */ `subscription OnDeleteReviewMedia(
  $filter: ModelSubscriptionReviewMediaFilterInput
  $owner: String
) {
  onDeleteReviewMedia(filter: $filter, owner: $owner) {
    id
    file_type
    key
    reviewId
    productReview {
      id
      stars
      comment
      attribute
      userId
      productId
      totalImagesCount
      estoreOrderProductId
      bookingId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReviewMediaSubscriptionVariables,
  APITypes.OnDeleteReviewMediaSubscription
>;
export const onCreateEstoreOrders = /* GraphQL */ `subscription OnCreateEstoreOrders(
  $filter: ModelSubscriptionEstoreOrdersFilterInput
) {
  onCreateEstoreOrders(filter: $filter) {
    id
    orderId
    transaction_id
    order_price
    discount
    sell_price
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    offerCodeId
    offerCode {
      id
      code
      description
      discountType
      discountValue
      maxUsage
      usageCount
      validFrom
      validTo
      minOrderValue
      maxDiscountAmount
      customerRestrictions
      productRestrictions
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    delivery_fee
    status
    paymentStatus
    statusDescription
    estoreOrderProducts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEstoreOrdersSubscriptionVariables,
  APITypes.OnCreateEstoreOrdersSubscription
>;
export const onUpdateEstoreOrders = /* GraphQL */ `subscription OnUpdateEstoreOrders(
  $filter: ModelSubscriptionEstoreOrdersFilterInput
) {
  onUpdateEstoreOrders(filter: $filter) {
    id
    orderId
    transaction_id
    order_price
    discount
    sell_price
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    offerCodeId
    offerCode {
      id
      code
      description
      discountType
      discountValue
      maxUsage
      usageCount
      validFrom
      validTo
      minOrderValue
      maxDiscountAmount
      customerRestrictions
      productRestrictions
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    delivery_fee
    status
    paymentStatus
    statusDescription
    estoreOrderProducts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEstoreOrdersSubscriptionVariables,
  APITypes.OnUpdateEstoreOrdersSubscription
>;
export const onDeleteEstoreOrders = /* GraphQL */ `subscription OnDeleteEstoreOrders(
  $filter: ModelSubscriptionEstoreOrdersFilterInput
) {
  onDeleteEstoreOrders(filter: $filter) {
    id
    orderId
    transaction_id
    order_price
    discount
    sell_price
    name
    email
    mobile_no
    address_one
    address_two
    pincode
    city
    state
    country
    offerCodeId
    offerCode {
      id
      code
      description
      discountType
      discountValue
      maxUsage
      usageCount
      validFrom
      validTo
      minOrderValue
      maxDiscountAmount
      customerRestrictions
      productRestrictions
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    delivery_fee
    status
    paymentStatus
    statusDescription
    estoreOrderProducts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEstoreOrdersSubscriptionVariables,
  APITypes.OnDeleteEstoreOrdersSubscription
>;
export const onCreateEstoreOrderProduct = /* GraphQL */ `subscription OnCreateEstoreOrderProduct(
  $filter: ModelSubscriptionEstoreOrderProductFilterInput
) {
  onCreateEstoreOrderProduct(filter: $filter) {
    id
    title
    description
    price
    list_price
    quantity
    invoice
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    productMediaList {
      nextToken
      __typename
    }
    attribute
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    status
    paymentStatus
    deliveryStatus
    statusDescription
    statusHistory
    cancellationReason
    estore_order_id
    estoreOrder {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEstoreOrderProductSubscriptionVariables,
  APITypes.OnCreateEstoreOrderProductSubscription
>;
export const onUpdateEstoreOrderProduct = /* GraphQL */ `subscription OnUpdateEstoreOrderProduct(
  $filter: ModelSubscriptionEstoreOrderProductFilterInput
) {
  onUpdateEstoreOrderProduct(filter: $filter) {
    id
    title
    description
    price
    list_price
    quantity
    invoice
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    productMediaList {
      nextToken
      __typename
    }
    attribute
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    status
    paymentStatus
    deliveryStatus
    statusDescription
    statusHistory
    cancellationReason
    estore_order_id
    estoreOrder {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEstoreOrderProductSubscriptionVariables,
  APITypes.OnUpdateEstoreOrderProductSubscription
>;
export const onDeleteEstoreOrderProduct = /* GraphQL */ `subscription OnDeleteEstoreOrderProduct(
  $filter: ModelSubscriptionEstoreOrderProductFilterInput
) {
  onDeleteEstoreOrderProduct(filter: $filter) {
    id
    title
    description
    price
    list_price
    quantity
    invoice
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    productMediaList {
      nextToken
      __typename
    }
    attribute
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    status
    paymentStatus
    deliveryStatus
    statusDescription
    statusHistory
    cancellationReason
    estore_order_id
    estoreOrder {
      id
      orderId
      transaction_id
      order_price
      discount
      sell_price
      name
      email
      mobile_no
      address_one
      address_two
      pincode
      city
      state
      country
      offerCodeId
      userId
      delivery_fee
      status
      paymentStatus
      statusDescription
      createdAt
      updatedAt
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEstoreOrderProductSubscriptionVariables,
  APITypes.OnDeleteEstoreOrderProductSubscription
>;
export const onCreateEstoreOrdersRefund = /* GraphQL */ `subscription OnCreateEstoreOrdersRefund(
  $filter: ModelSubscriptionEstoreOrdersRefundFilterInput
  $owner: String
) {
  onCreateEstoreOrdersRefund(filter: $filter, owner: $owner) {
    id
    orderProductId
    orderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    refund_price
    reason
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEstoreOrdersRefundSubscriptionVariables,
  APITypes.OnCreateEstoreOrdersRefundSubscription
>;
export const onUpdateEstoreOrdersRefund = /* GraphQL */ `subscription OnUpdateEstoreOrdersRefund(
  $filter: ModelSubscriptionEstoreOrdersRefundFilterInput
  $owner: String
) {
  onUpdateEstoreOrdersRefund(filter: $filter, owner: $owner) {
    id
    orderProductId
    orderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    refund_price
    reason
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEstoreOrdersRefundSubscriptionVariables,
  APITypes.OnUpdateEstoreOrdersRefundSubscription
>;
export const onDeleteEstoreOrdersRefund = /* GraphQL */ `subscription OnDeleteEstoreOrdersRefund(
  $filter: ModelSubscriptionEstoreOrdersRefundFilterInput
  $owner: String
) {
  onDeleteEstoreOrdersRefund(filter: $filter, owner: $owner) {
    id
    orderProductId
    orderProduct {
      id
      title
      description
      price
      list_price
      quantity
      invoice
      userId
      companyId
      productVariantId
      attribute
      status
      paymentStatus
      deliveryStatus
      statusDescription
      statusHistory
      cancellationReason
      estore_order_id
      createdAt
      updatedAt
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    refund_price
    reason
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEstoreOrdersRefundSubscriptionVariables,
  APITypes.OnDeleteEstoreOrdersRefundSubscription
>;
export const onCreateEstoreCart = /* GraphQL */ `subscription OnCreateEstoreCart(
  $filter: ModelSubscriptionEstoreCartFilterInput
) {
  onCreateEstoreCart(filter: $filter) {
    id
    quantity
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEstoreCartSubscriptionVariables,
  APITypes.OnCreateEstoreCartSubscription
>;
export const onUpdateEstoreCart = /* GraphQL */ `subscription OnUpdateEstoreCart(
  $filter: ModelSubscriptionEstoreCartFilterInput
) {
  onUpdateEstoreCart(filter: $filter) {
    id
    quantity
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEstoreCartSubscriptionVariables,
  APITypes.OnUpdateEstoreCartSubscription
>;
export const onDeleteEstoreCart = /* GraphQL */ `subscription OnDeleteEstoreCart(
  $filter: ModelSubscriptionEstoreCartFilterInput
) {
  onDeleteEstoreCart(filter: $filter) {
    id
    quantity
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    add_ons {
      id
      title
      description
      imageKey
      price
      list_price
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEstoreCartSubscriptionVariables,
  APITypes.OnDeleteEstoreCartSubscription
>;
export const onCreateBooking = /* GraphQL */ `subscription OnCreateBooking(
  $filter: ModelSubscriptionBookingFilterInput
  $owner: String
) {
  onCreateBooking(filter: $filter, owner: $owner) {
    id
    type
    quantity
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    fulfillerId
    fulfiller {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBookingSubscriptionVariables,
  APITypes.OnCreateBookingSubscription
>;
export const onUpdateBooking = /* GraphQL */ `subscription OnUpdateBooking(
  $filter: ModelSubscriptionBookingFilterInput
  $owner: String
) {
  onUpdateBooking(filter: $filter, owner: $owner) {
    id
    type
    quantity
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    fulfillerId
    fulfiller {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBookingSubscriptionVariables,
  APITypes.OnUpdateBookingSubscription
>;
export const onDeleteBooking = /* GraphQL */ `subscription OnDeleteBooking(
  $filter: ModelSubscriptionBookingFilterInput
  $owner: String
) {
  onDeleteBooking(filter: $filter, owner: $owner) {
    id
    type
    quantity
    productVariantId
    productVariant {
      id
      productId
      title
      description
      attribute
      price
      list_price
      quantity
      deliveryMethod
      productCondition
      designStyle
      designPropertyType
      orderCount
      videoLink
      status
      sku
      defaultPosition
      createdAt
      updatedAt
      productProductVariantsId
      owner
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    fulfillerId
    fulfiller {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    productReviews {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBookingSubscriptionVariables,
  APITypes.OnDeleteBookingSubscription
>;
export const onCreateChat = /* GraphQL */ `subscription OnCreateChat($filter: ModelSubscriptionChatFilterInput) {
  onCreateChat(filter: $filter) {
    id
    name
    group
    image
    participants {
      nextToken
      __typename
    }
    chatMessages {
      nextToken
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    chatMessageUnreadCount {
      nextToken
      __typename
    }
    deletedBy
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChatSubscriptionVariables,
  APITypes.OnCreateChatSubscription
>;
export const onUpdateChat = /* GraphQL */ `subscription OnUpdateChat($filter: ModelSubscriptionChatFilterInput) {
  onUpdateChat(filter: $filter) {
    id
    name
    group
    image
    participants {
      nextToken
      __typename
    }
    chatMessages {
      nextToken
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    chatMessageUnreadCount {
      nextToken
      __typename
    }
    deletedBy
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChatSubscriptionVariables,
  APITypes.OnUpdateChatSubscription
>;
export const onDeleteChat = /* GraphQL */ `subscription OnDeleteChat($filter: ModelSubscriptionChatFilterInput) {
  onDeleteChat(filter: $filter) {
    id
    name
    group
    image
    participants {
      nextToken
      __typename
    }
    chatMessages {
      nextToken
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    chatMessageUnreadCount {
      nextToken
      __typename
    }
    deletedBy
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChatSubscriptionVariables,
  APITypes.OnDeleteChatSubscription
>;
export const onCreateChatParticipants = /* GraphQL */ `subscription OnCreateChatParticipants(
  $filter: ModelSubscriptionChatParticipantsFilterInput
) {
  onCreateChatParticipants(filter: $filter) {
    id
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChatParticipantsSubscriptionVariables,
  APITypes.OnCreateChatParticipantsSubscription
>;
export const onUpdateChatParticipants = /* GraphQL */ `subscription OnUpdateChatParticipants(
  $filter: ModelSubscriptionChatParticipantsFilterInput
) {
  onUpdateChatParticipants(filter: $filter) {
    id
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChatParticipantsSubscriptionVariables,
  APITypes.OnUpdateChatParticipantsSubscription
>;
export const onDeleteChatParticipants = /* GraphQL */ `subscription OnDeleteChatParticipants(
  $filter: ModelSubscriptionChatParticipantsFilterInput
) {
  onDeleteChatParticipants(filter: $filter) {
    id
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChatParticipantsSubscriptionVariables,
  APITypes.OnDeleteChatParticipantsSubscription
>;
export const onCreateChatMessages = /* GraphQL */ `subscription OnCreateChatMessages(
  $filter: ModelSubscriptionChatMessagesFilterInput
  $owner: String
) {
  onCreateChatMessages(filter: $filter, owner: $owner) {
    id
    message
    attachment
    attachment_type
    bookingId
    propertyId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    User {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChatMessagesSubscriptionVariables,
  APITypes.OnCreateChatMessagesSubscription
>;
export const onUpdateChatMessages = /* GraphQL */ `subscription OnUpdateChatMessages(
  $filter: ModelSubscriptionChatMessagesFilterInput
  $owner: String
) {
  onUpdateChatMessages(filter: $filter, owner: $owner) {
    id
    message
    attachment
    attachment_type
    bookingId
    propertyId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    User {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChatMessagesSubscriptionVariables,
  APITypes.OnUpdateChatMessagesSubscription
>;
export const onDeleteChatMessages = /* GraphQL */ `subscription OnDeleteChatMessages(
  $filter: ModelSubscriptionChatMessagesFilterInput
  $owner: String
) {
  onDeleteChatMessages(filter: $filter, owner: $owner) {
    id
    message
    attachment
    attachment_type
    bookingId
    propertyId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    userId
    User {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChatMessagesSubscriptionVariables,
  APITypes.OnDeleteChatMessagesSubscription
>;
export const onCreateChatMessageUnreadCount = /* GraphQL */ `subscription OnCreateChatMessageUnreadCount(
  $filter: ModelSubscriptionChatMessageUnreadCountFilterInput
  $owner: String
) {
  onCreateChatMessageUnreadCount(filter: $filter, owner: $owner) {
    id
    userId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    unreadCount
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChatMessageUnreadCountSubscriptionVariables,
  APITypes.OnCreateChatMessageUnreadCountSubscription
>;
export const onUpdateChatMessageUnreadCount = /* GraphQL */ `subscription OnUpdateChatMessageUnreadCount(
  $filter: ModelSubscriptionChatMessageUnreadCountFilterInput
  $owner: String
) {
  onUpdateChatMessageUnreadCount(filter: $filter, owner: $owner) {
    id
    userId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    unreadCount
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChatMessageUnreadCountSubscriptionVariables,
  APITypes.OnUpdateChatMessageUnreadCountSubscription
>;
export const onDeleteChatMessageUnreadCount = /* GraphQL */ `subscription OnDeleteChatMessageUnreadCount(
  $filter: ModelSubscriptionChatMessageUnreadCountFilterInput
  $owner: String
) {
  onDeleteChatMessageUnreadCount(filter: $filter, owner: $owner) {
    id
    userId
    chatId
    chat {
      id
      name
      group
      image
      companyId
      createdAt
      deletedBy
      updatedAt
      __typename
    }
    unreadCount
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChatMessageUnreadCountSubscriptionVariables,
  APITypes.OnDeleteChatMessageUnreadCountSubscription
>;
export const onCreateNotifications = /* GraphQL */ `subscription OnCreateNotifications(
  $filter: ModelSubscriptionNotificationsFilterInput
) {
  onCreateNotifications(filter: $filter) {
    id
    userId
    senderId
    sender {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    read
    title
    description
    imageUrl
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNotificationsSubscriptionVariables,
  APITypes.OnCreateNotificationsSubscription
>;
export const onUpdateNotifications = /* GraphQL */ `subscription OnUpdateNotifications(
  $filter: ModelSubscriptionNotificationsFilterInput
) {
  onUpdateNotifications(filter: $filter) {
    id
    userId
    senderId
    sender {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    read
    title
    description
    imageUrl
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNotificationsSubscriptionVariables,
  APITypes.OnUpdateNotificationsSubscription
>;
export const onDeleteNotifications = /* GraphQL */ `subscription OnDeleteNotifications(
  $filter: ModelSubscriptionNotificationsFilterInput
) {
  onDeleteNotifications(filter: $filter) {
    id
    userId
    senderId
    sender {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    read
    title
    description
    imageUrl
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationsSubscriptionVariables,
  APITypes.OnDeleteNotificationsSubscription
>;
export const onCreatePost = /* GraphQL */ `subscription OnCreatePost(
  $filter: ModelSubscriptionPostFilterInput
  $owner: String
) {
  onCreatePost(filter: $filter, owner: $owner) {
    id
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    comments {
      nextToken
      __typename
    }
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePostSubscriptionVariables,
  APITypes.OnCreatePostSubscription
>;
export const onUpdatePost = /* GraphQL */ `subscription OnUpdatePost(
  $filter: ModelSubscriptionPostFilterInput
  $owner: String
) {
  onUpdatePost(filter: $filter, owner: $owner) {
    id
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    comments {
      nextToken
      __typename
    }
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePostSubscriptionVariables,
  APITypes.OnUpdatePostSubscription
>;
export const onDeletePost = /* GraphQL */ `subscription OnDeletePost(
  $filter: ModelSubscriptionPostFilterInput
  $owner: String
) {
  onDeletePost(filter: $filter, owner: $owner) {
    id
    type
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    title
    description
    likeCount
    likes {
      nextToken
      __typename
    }
    commentCount
    comments {
      nextToken
      __typename
    }
    shareCount
    viewCount
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePostSubscriptionVariables,
  APITypes.OnDeletePostSubscription
>;
export const onCreateRecommendation = /* GraphQL */ `subscription OnCreateRecommendation(
  $filter: ModelSubscriptionRecommendationFilterInput
  $owner: String
) {
  onCreateRecommendation(filter: $filter, owner: $owner) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    feedId
    createdAt
    ttl
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRecommendationSubscriptionVariables,
  APITypes.OnCreateRecommendationSubscription
>;
export const onUpdateRecommendation = /* GraphQL */ `subscription OnUpdateRecommendation(
  $filter: ModelSubscriptionRecommendationFilterInput
  $owner: String
) {
  onUpdateRecommendation(filter: $filter, owner: $owner) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    feedId
    createdAt
    ttl
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRecommendationSubscriptionVariables,
  APITypes.OnUpdateRecommendationSubscription
>;
export const onDeleteRecommendation = /* GraphQL */ `subscription OnDeleteRecommendation(
  $filter: ModelSubscriptionRecommendationFilterInput
  $owner: String
) {
  onDeleteRecommendation(filter: $filter, owner: $owner) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    productId
    product {
      id
      title
      description
      type
      filterType
      allowToComment
      feature
      brand
      total_sold
      likeCount
      viewCount
      commentCount
      shareCount
      bookmarkCount
      totalReviewCount
      orderCount
      userId
      companyId
      metaKeywords
      sku
      status
      createdAt
      updatedAt
      owner
      __typename
    }
    postId
    feedId
    createdAt
    ttl
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRecommendationSubscriptionVariables,
  APITypes.OnDeleteRecommendationSubscription
>;
export const onCreateRecommendationViews = /* GraphQL */ `subscription OnCreateRecommendationViews(
  $filter: ModelSubscriptionRecommendationViewsFilterInput
  $owner: String
) {
  onCreateRecommendationViews(filter: $filter, owner: $owner) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRecommendationViewsSubscriptionVariables,
  APITypes.OnCreateRecommendationViewsSubscription
>;
export const onUpdateRecommendationViews = /* GraphQL */ `subscription OnUpdateRecommendationViews(
  $filter: ModelSubscriptionRecommendationViewsFilterInput
  $owner: String
) {
  onUpdateRecommendationViews(filter: $filter, owner: $owner) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRecommendationViewsSubscriptionVariables,
  APITypes.OnUpdateRecommendationViewsSubscription
>;
export const onDeleteRecommendationViews = /* GraphQL */ `subscription OnDeleteRecommendationViews(
  $filter: ModelSubscriptionRecommendationViewsFilterInput
  $owner: String
) {
  onDeleteRecommendationViews(filter: $filter, owner: $owner) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRecommendationViewsSubscriptionVariables,
  APITypes.OnDeleteRecommendationViewsSubscription
>;
export const onCreateFollow = /* GraphQL */ `subscription OnCreateFollow(
  $filter: ModelSubscriptionFollowFilterInput
  $owner: String
) {
  onCreateFollow(filter: $filter, owner: $owner) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    followerId
    follower {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFollowSubscriptionVariables,
  APITypes.OnCreateFollowSubscription
>;
export const onUpdateFollow = /* GraphQL */ `subscription OnUpdateFollow(
  $filter: ModelSubscriptionFollowFilterInput
  $owner: String
) {
  onUpdateFollow(filter: $filter, owner: $owner) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    followerId
    follower {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFollowSubscriptionVariables,
  APITypes.OnUpdateFollowSubscription
>;
export const onDeleteFollow = /* GraphQL */ `subscription OnDeleteFollow(
  $filter: ModelSubscriptionFollowFilterInput
  $owner: String
) {
  onDeleteFollow(filter: $filter, owner: $owner) {
    id
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    followerId
    follower {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFollowSubscriptionVariables,
  APITypes.OnDeleteFollowSubscription
>;
export const onCreateProperty = /* GraphQL */ `subscription OnCreateProperty(
  $filter: ModelSubscriptionPropertyFilterInput
  $owner: String
) {
  onCreateProperty(filter: $filter, owner: $owner) {
    id
    title
    type
    description
    price
    bed
    bath
    sqft
    psf
    listingId
    category
    detailsJson {
      key
      value
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    likeCount
    viewCount
    likes {
      nextToken
      __typename
    }
    unitFeatures
    facilities
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePropertySubscriptionVariables,
  APITypes.OnCreatePropertySubscription
>;
export const onUpdateProperty = /* GraphQL */ `subscription OnUpdateProperty(
  $filter: ModelSubscriptionPropertyFilterInput
  $owner: String
) {
  onUpdateProperty(filter: $filter, owner: $owner) {
    id
    title
    type
    description
    price
    bed
    bath
    sqft
    psf
    listingId
    category
    detailsJson {
      key
      value
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    likeCount
    viewCount
    likes {
      nextToken
      __typename
    }
    unitFeatures
    facilities
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePropertySubscriptionVariables,
  APITypes.OnUpdatePropertySubscription
>;
export const onDeleteProperty = /* GraphQL */ `subscription OnDeleteProperty(
  $filter: ModelSubscriptionPropertyFilterInput
  $owner: String
) {
  onDeleteProperty(filter: $filter, owner: $owner) {
    id
    title
    type
    description
    price
    bed
    bath
    sqft
    psf
    listingId
    category
    detailsJson {
      key
      value
      __typename
    }
    userId
    user {
      id
      username
      email
      mobile_no
      name
      gender
      country
      mobile_verified
      bio
      picture
      followers
      followings
      online
      fcmToken
      merchantFcmToken
      createdAt
      updatedAt
      owner
      __typename
    }
    companyId
    company {
      id
      ownerType
      uen
      gstNo
      type
      name
      email
      registration_name
      picture
      bio
      wlcmMsg
      address
      category
      status
      followers
      createdAt
      updatedAt
      owner
      __typename
    }
    mediaList {
      nextToken
      __typename
    }
    bookmarkCount
    bookmarks {
      nextToken
      __typename
    }
    likeCount
    viewCount
    likes {
      nextToken
      __typename
    }
    unitFeatures
    facilities
    status
    location {
      lat
      lng
      address
      pincode
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePropertySubscriptionVariables,
  APITypes.OnDeletePropertySubscription
>;
export const onCreateAdminConfig = /* GraphQL */ `subscription OnCreateAdminConfig(
  $filter: ModelSubscriptionAdminConfigFilterInput
  $owner: String
) {
  onCreateAdminConfig(filter: $filter, owner: $owner) {
    id
    type
    value
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdminConfigSubscriptionVariables,
  APITypes.OnCreateAdminConfigSubscription
>;
export const onUpdateAdminConfig = /* GraphQL */ `subscription OnUpdateAdminConfig(
  $filter: ModelSubscriptionAdminConfigFilterInput
  $owner: String
) {
  onUpdateAdminConfig(filter: $filter, owner: $owner) {
    id
    type
    value
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdminConfigSubscriptionVariables,
  APITypes.OnUpdateAdminConfigSubscription
>;
export const onDeleteAdminConfig = /* GraphQL */ `subscription OnDeleteAdminConfig(
  $filter: ModelSubscriptionAdminConfigFilterInput
  $owner: String
) {
  onDeleteAdminConfig(filter: $filter, owner: $owner) {
    id
    type
    value
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdminConfigSubscriptionVariables,
  APITypes.OnDeleteAdminConfigSubscription
>;
export const onCreateOfferCode = /* GraphQL */ `subscription OnCreateOfferCode(
  $filter: ModelSubscriptionOfferCodeFilterInput
  $owner: String
) {
  onCreateOfferCode(filter: $filter, owner: $owner) {
    id
    code
    description
    discountType
    discountValue
    maxUsage
    usageCount
    validFrom
    validTo
    minOrderValue
    maxDiscountAmount
    customerRestrictions
    productRestrictions
    status
    estoreOrders {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOfferCodeSubscriptionVariables,
  APITypes.OnCreateOfferCodeSubscription
>;
export const onUpdateOfferCode = /* GraphQL */ `subscription OnUpdateOfferCode(
  $filter: ModelSubscriptionOfferCodeFilterInput
  $owner: String
) {
  onUpdateOfferCode(filter: $filter, owner: $owner) {
    id
    code
    description
    discountType
    discountValue
    maxUsage
    usageCount
    validFrom
    validTo
    minOrderValue
    maxDiscountAmount
    customerRestrictions
    productRestrictions
    status
    estoreOrders {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOfferCodeSubscriptionVariables,
  APITypes.OnUpdateOfferCodeSubscription
>;
export const onDeleteOfferCode = /* GraphQL */ `subscription OnDeleteOfferCode(
  $filter: ModelSubscriptionOfferCodeFilterInput
  $owner: String
) {
  onDeleteOfferCode(filter: $filter, owner: $owner) {
    id
    code
    description
    discountType
    discountValue
    maxUsage
    usageCount
    validFrom
    validTo
    minOrderValue
    maxDiscountAmount
    customerRestrictions
    productRestrictions
    status
    estoreOrders {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOfferCodeSubscriptionVariables,
  APITypes.OnDeleteOfferCodeSubscription
>;
export const onCreatePageHtml = /* GraphQL */ `subscription OnCreatePageHtml(
  $filter: ModelSubscriptionPageHtmlFilterInput
  $owner: String
) {
  onCreatePageHtml(filter: $filter, owner: $owner) {
    id
    site
    page
    html
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePageHtmlSubscriptionVariables,
  APITypes.OnCreatePageHtmlSubscription
>;
export const onUpdatePageHtml = /* GraphQL */ `subscription OnUpdatePageHtml(
  $filter: ModelSubscriptionPageHtmlFilterInput
  $owner: String
) {
  onUpdatePageHtml(filter: $filter, owner: $owner) {
    id
    site
    page
    html
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePageHtmlSubscriptionVariables,
  APITypes.OnUpdatePageHtmlSubscription
>;
export const onDeletePageHtml = /* GraphQL */ `subscription OnDeletePageHtml(
  $filter: ModelSubscriptionPageHtmlFilterInput
  $owner: String
) {
  onDeletePageHtml(filter: $filter, owner: $owner) {
    id
    site
    page
    html
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePageHtmlSubscriptionVariables,
  APITypes.OnDeletePageHtmlSubscription
>;

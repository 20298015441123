import React, { lazy, Suspense } from 'react';

const LazyPageWishlist = lazy(() => import('./PageWishlist'));

const PageWishlist = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageWishlist {...props} />
  </Suspense>
);

export default PageWishlist;

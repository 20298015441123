import { IonButton, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonLabel, IonModal, IonPage, IonRow, IonText, useIonToast } from '@ionic/react';
import { Auth } from 'aws-amplify';
import { closeCircleOutline } from 'ionicons/icons';
import { useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SuccessMessage } from '../../shared/errorMsg';
import { UserContext } from '../../store/UserContext';
import PageResetPassword from '../PageResetPassword/PageResetPassword';
import styles from './PageForgotPassword.module.scss';

const ForgotPassword = (props:any) => {
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const [ present ] = useIonToast();
  const { setIsLoading } = useContext(UserContext);
  const { t } = useTranslation();
  const [ email, setEmail ] = useState('');

  let resetPassShowModel = false;
  const resetPassModal = useRef<HTMLIonModalElement>(null);

  /**
   * forgotPassword
   */
  async function forgotPassword(data: any) {
    setIsLoading(true);
    try {
      setEmail(data.email);
      await Auth.forgotPassword(data.email);
      present({ message: SuccessMessage.PasswordResetCodeSent, duration: 1000, color: 'success' });
      resetPassShowModel = true;
      resetPassModal.current?.present();
    }
    catch (error:any) {
      present({
        message: error.message,
        color: 'danger',
        duration: 1000
      });
      console.log('Exception in forgotPassword()', error);
    }
    finally {
      setIsLoading(false);
    }
  }

  /**
   * closeResetPassModal
   */
  function closeResetPassModal() {
    resetPassModal.current?.dismiss();
    props.emitCloseModal('E');
  }

  return  (
    <IonPage>
      <IonHeader className={styles.ForgotPasswordHeader + ' ion-no-border'}>
        <IonIcon className={styles.closeIcon + ' closeIcon'} icon={closeCircleOutline} onClick={props.emitCloseModal}></IonIcon>
      </IonHeader>
      <IonContent>
        <div className={styles.ForgotPassword} data-testid="ForgotPassword">
          <div>
            <form className="list-form" id='userForm' onSubmit={handleSubmit(forgotPassword)}>
              <IonText className="ion-text-center">
                <h5 className="regTitle">
                  <b>{t ('forgotPasswordPage.title')}</b>
                </h5>
              </IonText>
              <IonRow>
                <IonCol size='12'>
                  <IonText className={styles.textCenterGrey} >
                    {t('forgotPasswordPage.OtpWillSendTxt')}
                  </IonText>
                </IonCol>

                <IonCol size='12'>
                  <IonLabel position="stacked" className="inputLabel">
                    {t('forgotPasswordPage.emailId')}*
                  </IonLabel>
                  <IonInput {...register('email',
                    {
                      required: true,
                      /* eslint-disable */
                      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      /* eslint-enable */
                    })}  aria-invalid={errors.email ? 'true' : 'false'}   />
                  {errors.email && <div className="invalid-feedback">{t('forgotPasswordPage.emailNotValid')}</div>}
                </IonCol>
                <IonCol size='12'>
                  <IonButton expand='block' className={styles.submitBtn} type='submit'>{t('forgotPasswordPage.sendOtpBtn')}</IonButton>
                </IonCol>
              </IonRow>
            </form>
          </div>
        </div>
      </IonContent>
      <IonModal ref={resetPassModal} isOpen={resetPassShowModel} className="resetPasswordBottomModal" canDismiss={true} backdropDismiss={true} showBackdrop={true}>
        <PageResetPassword propEmail={email} emitCloseModal={closeResetPassModal}></PageResetPassword>
      </IonModal>
    </IonPage>
  );
};

export default ForgotPassword;

import React, { lazy, Suspense } from 'react';

const LazyRegister = lazy(() => import('./PageRegister'));

const PageRegister = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyRegister {...props} />
  </Suspense>
);

export default PageRegister;

import React, { lazy, Suspense } from 'react';

const LazyPageDeliveryInpormation = lazy(() => import('./PageDeliveryInpormation'));

const PageDeliveryInpormation = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageDeliveryInpormation {...props} />
  </Suspense>
);

export default PageDeliveryInpormation;

import { IonAvatar, IonCol, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonProgressBar, IonRow, IonText } from '@ionic/react';
import { star, starHalfSharp, starOutline } from 'ionicons/icons';
import { FC, useContext, useEffect, useState } from 'react';
import styles from './ComponentReview.module.scss';
import { useLazyLoading } from '../../customHooks/useLazyLoading';
import { API } from 'aws-amplify';
import { productReviewsByProductIdAndCreatedAtCustom } from '../../APIs/graphql/customQueries';
import { useHistory, useParams } from 'react-router';
import { AppContext } from '../../store/AppContext';
import { FILE_PATH } from '../../shared/errorMsg';
import { RatingType } from '../../APIs/API';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface ComponentReviewProps {
  productId?: string,
  rating?: RatingType | null
}

const ComponentReview: FC<ComponentReviewProps> = (props) => {
  const history = useHistory();
  const initialKey = props.productId + '';
  const { s3BucketUrl, onImageError, showFilePreview, getAverageRating } = useContext(AppContext);
  const params = useParams() as any;
  const { dataListObj } = useLazyLoading(getReviewList, initialKey);
  const LIMIT = 10;
  const averageRating = props.rating ? getAverageRating(props.rating) : '0';
  const [ totalRating, setTotalRating ] = useState(0);
  const { t } = useTranslation();

  useEffect(()=> {
    if (props.rating && props.rating.one != null && props.rating.two != null && props.rating.three != null && props.rating.four != null && props.rating.five != null) {
      let total = props.rating.one + props.rating.two + props.rating.three + props.rating.four + props.rating.five;
      setTotalRating(total);
    }
  }, [ props.rating ]);

  /**
   * getReviewList
   */
  async function getReviewList(nextToken?: string) {
    try {
      const apiResp:any = await API.graphql({ query: productReviewsByProductIdAndCreatedAtCustom, variables: { productId: props.productId || params.productId, limit: LIMIT } });
      const data = apiResp.data.productReviewsByProductIdAndCreatedAt;
      return { items: data.items, nextToken: nextToken, key: initialKey };
    }
    catch (error) {
      console.error('Exception in getReviewList()', error);
    }
  }
  return (
    <div className={styles.ComponentReview} data-testid="ComponentReview">
      {
        props.productId ?
          <IonRow>
            <IonCol size='4' sizeXs='4' sizeSm='4' sizeMd='4' className={styles.overAllRatingCol}>
              <div className={styles.ratingDiv}>
                <b> {averageRating}</b>
                <IonText>{totalRating} { t('ComponentReview.rating') }</IonText>
              </div>
            </IonCol>
            <IonCol size='8' sizeXs='8' sizeSm='8' sizeMd='8' className={styles.allRatingCol}>
              <div className={styles.starIconContainer}>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <span className={styles.progressBarSpan}>
                  <IonProgressBar value={(props.rating?.five || 0) / totalRating }></IonProgressBar>
                  <span>{ props.rating?.five || '0'}</span>
                </span>
              </div>
              <div className={styles.starIconContainer}>
                <IonIcon></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <span className={styles.progressBarSpan}>
                  <IonProgressBar value={((props.rating?.four || 0) / totalRating)}></IonProgressBar>
                  <span>{ props.rating?.four || '0'}</span>
                </span>
              </div>
              <div className={styles.starIconContainer}>
                <IonIcon></IonIcon>
                <IonIcon></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <span className={styles.progressBarSpan}>
                  <IonProgressBar value={((props.rating?.three || 0) / totalRating)}></IonProgressBar>
                  <span>{ props.rating?.three || '0'}</span>
                </span>
              </div>
              <div className={styles.starIconContainer}>
                <IonIcon></IonIcon>
                <IonIcon></IonIcon>
                <IonIcon></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <span className={styles.progressBarSpan}>
                  <IonProgressBar value={((props.rating?.two || 0) / totalRating)}></IonProgressBar>
                  <span>{ props.rating?.two || '0'}</span>
                </span>
              </div>
              <div className={styles.starIconContainer}>
                <IonIcon></IonIcon>
                <IonIcon></IonIcon>
                <IonIcon></IonIcon>
                <IonIcon></IonIcon>
                <IonIcon icon={star}></IonIcon>
                <span className={styles.progressBarSpan}>
                  <IonProgressBar value={((props.rating?.one || 0) / totalRating)}></IonProgressBar>
                  <span>{ props.rating?.one || '0'}</span>
                </span>
              </div>
            </IonCol>
          </IonRow>
          : ''
      }
      <IonRow>
        {dataListObj && dataListObj[initialKey] && dataListObj[initialKey].items && dataListObj[initialKey].items.map((review: any, index: number) =>
          <IonCol size='12' className={styles.reviewCol} key={'review' + index}>
            <section className={styles.reviewDetail}>
              <IonAvatar className={styles.userPic}>
                <IonImg src={review?.user?.picture ? s3BucketUrl + '/' + FILE_PATH.PUBLIC + '/' + review.user.picture : 'assets/svg/avatar.svg' } onError={()=> onImageError('profile')}></IonImg>
              </IonAvatar>
              <IonText className={styles.review}>
                <h6 className='m-0 capitalizeTransform'>{ review?.user?.name ? review.user.name : '' } <span>({moment(review?.createdAt).format('MMM DD, YYYY')})</span>
                  <span className={styles.ratingSpan}>
                    {
                      review.stars && review.stars > 0 ?
                        <div className={styles.reviewSocialIcons}>
                          <div className={styles.socailIconSub}>
                            {
                              Array.from({ length: 5 }, (_, index) => index).map((value, index)=>
                                <IonIcon icon={ value < review.stars ? star : starOutline } key={value + 'star'}></IonIcon>
                              )
                            }
                          </div>
                        </div> : ''

                    }
                  </span>
                </h6>

                { review.comment }

                {
                  review?.reviewMediaList?.items?.length ?
                    <section className={styles.imageSection}>
                      {
                        review?.reviewMediaList?.items?.length ? review.reviewMediaList.items.map((media:any, index: number)=>
                          <img onClick={(e)=> showFilePreview(review.reviewMediaList.items, index, FILE_PATH.REVIEWS)} src={ s3BucketUrl + `/${FILE_PATH.REVIEWS}/` + media.key } onError={onImageError} key={media.id + index}></img>
                        ) : ''
                      }
                    </section>
                    : ''
                }
              </IonText>
            </section>
            { props.productId && index == dataListObj[initialKey].items.length - 1 ?
              <a className={styles.viewAllBtn + ' pullRight'} onClick={()=> history.push(`/reviewList/${props.productId}`)}>
                View All
              </a>
              : ''
            }
            <hr></hr>
          </IonCol>
        )}

        { dataListObj[initialKey] && dataListObj[initialKey].isAllLoaded && !dataListObj[initialKey].items.length ? <h6 className='text-center m-0'>No review yet</h6> : '' }
        {
          !props.productId && !dataListObj?.[initialKey]?.isAllLoaded ? <IonInfiniteScroll><IonInfiniteScrollContent loadingSpinner="bubbles" ></IonInfiniteScrollContent></IonInfiniteScroll> : ''
        }
      </IonRow>
    </div>
  );
};

export default ComponentReview;

import { useState, useEffect, useRef } from 'react';

export function useWatchState(initialState, callback) {
  const [ state, setState ] = useState(initialState);
  const prevStateRef = useRef(initialState);

  useEffect(() => {
    if (JSON.stringify(state) !== JSON.stringify(prevStateRef.current)) {
      callback(state, prevStateRef.current);
      prevStateRef.current = state;
    }
  }, [ state, callback ]);

  return [ state, setState ];
}
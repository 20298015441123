/* eslint-disable */
import { Cache } from 'aws-amplify';
export const CacheLS = {
  /**
   * setAuthUser
   */
  setAuthUser (user: any) {
    Cache.setItem('userInfo', user)
  },
  /**
   * getAuthUser
   */
  getAuthUser () {
    return Cache.getItem('userInfo') || null;
  },
  /**
   * setFcmToken
   */
  setFcmToken (token: string) {
    Cache.setItem('fcmToken', token)
  },
  /**
   * getFcmToken
   */
  getFcmToken () {
    return Cache.getItem('fcmToken') || null;
  },
  /**
   * logout
   * */
  logout() {
    localStorage.clear();
    Cache.clear();
  },
  /**
   * setRecentEstoreSearches
   */
  setRecentEstoreSearches(data:any) {
    Cache.setItem('recentEstoreSearch', data);
  },
  /**
   * getRecentEstoreSearches
   */
  getRecentEstoreSearches() {
    const list = Cache.getItem('recentEstoreSearch') || [];
    return list;
  },
  /**
   * removeRecentEstoreSearches
   */
  removeRecentEstoreSearches() {
    Cache.removeItem('recentEstoreSearch');
  },
  /**
   * setCartItem
   */
  setCartItems (items:any) {
    Cache.setItem('cart', items)
  },
  /**
   * getCartItem
   */
  getCartItems () {
    return Cache.getItem('cart') || {}
  },
  /**
   * removeCartItems
   */
  removeCartItems () {
    return Cache.removeItem('cart')
  },
  /**
   * setCompanyListObj
   **/
  setCartCompanyListObj (items:any) {
    Cache.setItem('cartCompanyListObj', items)
  },
  /**
   * getCartItem
   */
  getCartCompanyListObj () {
    return Cache.getItem('cartCompanyListObj') || {}
  },
  /**
   * setCartItem
   */
  setCartProductVariantIdsCheckOutItems (items:any) {
    Cache.setItem('cartProductVariantIdsCheckOut', items)
  },
  /**
   * getcartProductVariantIdsCheckOutItems
   */
  getcartProductVariantIdsCheckOutItems () {
    return Cache.getItem('cartProductVariantIdsCheckOut') || {}
  },
  /**
   * setCartItem
   */
  setOpenChatRoom (items:any) {
    Cache.setItem('openChatRoom', items)
  },
  /**
   * getOpenChatRoom
   */
  getOpenChatRoom () {
    return Cache.getItem('openChatRoom') || null
  },
  /** 
   * setCartItem
  */
  setChatList (items:any) {
    Cache.setItem('chatRoomList', items)
  },
  /**
   * getOpenChatRoom
   */
  getChatList () {
    // return {} as any
    return Cache.getItem('chatRoomList') || {}
  },
  /**
   * setAuthUser
   */
  setRedirectUrl (user: any) {
    return Cache.setItem('redirectUrl', user)
  },
  /**
   * getRedirectUrl
   */
  getRedirectUrl () {
    return Cache.getItem('redirectUrl')
  },
  /**
   * removeRedirectUrl
   */
  removeRedirectUrl () {
    return Cache.removeItem('redirectUrl')
  },
};
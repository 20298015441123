import React, { lazy, Suspense } from 'react';

const LazyPageCart = lazy(() => import('./PageCart'));

const PageCart = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageCart {...props} />
  </Suspense>
);

export default PageCart;

import React, { lazy, Suspense } from 'react';

const LazyPageFollowersList = lazy(() => import('./PageFollowersList'));

const PageFollowersList = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPageFollowersList {...props} />
  </Suspense>
);

export default PageFollowersList;

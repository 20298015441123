import { IonButton, IonCol, IonContent, IonLabel, IonPage, IonRow } from '@ionic/react';
import { FC, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PhoneInput from 'react-phone-number-input';
import styles from './PageChangeMobileNo.module.scss';
import { UserContext } from '../../store/UserContext';
import ComponentHeader from '../ComponentHeader/ComponentHeader';
import 'react-phone-number-input/style.css';

interface PageChangeMobileNoProps {}

const PageChangeMobileNo: FC<PageChangeMobileNoProps> = (props:any) => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const history = useHistory();
  const { changePhoneNumber, user } = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    register('mobile_no', { required: true } );
  });

  /**
   * onMobChange
   * is use to set mobile number in form
  */
  const onMobChange = (e:any) =>{
    setValue('mobile_no', e);
  };
  /**
   * changeMobileNo
   */
  async function changeMobileNo(data:any) {
    try {
      if (data.mobile_no == user.attributes.phone_number && user.attributes.phone_number_verified) {
        history.goBack()
      }
      else {
        changePhoneNumber(data.mobile_no);
        history.push(`/otp/verify/phone/${data.mobile_no}`);
      }
    }
    catch (error) {
      console.error('Exception in changeMobileNo', error);
    }
  }
  return (
    <IonPage>
      <ComponentHeader title={ user?.attribute?.phone_number ? t('CmpChangeMobileNo.chngTitle') :  t('CmpChangeMobileNo.addTitle') }></ComponentHeader>
      <IonContent className={styles.ComponentChangeMobileNo} data-testid="ComponentChangeMobileNo">
        <>
          <form className="list-form" id='userForm' onSubmit={handleSubmit(changeMobileNo)}>
            <IonRow>
              <IonCol size='12' className='registerPhoneInput'>
                <IonLabel position="stacked" className="inputLabel">{t('CmpChangeMobileNo.mobile_no')}*</IonLabel>
                <PhoneInput defaultCountry='SG' limitMaxLength={true} international={true} placeholder="Enter mobile number"  onChange={(e:any) => onMobChange(e)}/>
                {errors.mobile_no && <div className="invalid-feedback">{t('CmpChangeMobileNo.mobile_no_is_require')}</div>}
              </IonCol>
              <IonCol size='12' className='registerPhoneInput'>
                <IonButton expand='block' className={styles.submitBtn} type='submit'>{t('CmpChangeMobileNo.save')}</IonButton>
              </IonCol>
            </IonRow>
          </form>
        </>
      </IonContent>
    </IonPage>
  );
};

export default PageChangeMobileNo;
